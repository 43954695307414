import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

//Importing images
import syringe100 from "../../../assets/images/100Unit.png";
import syringe50 from "../../../assets/images/50units.png";
import syringe30 from "../../../assets/images/30units.png";
import peptide from "../../../assets/images/peprc.png";
import bact from "../../../assets/images/bacrc.png";

import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  CardTitle,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"

class ReconstitutionCalculator extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      selectedFiles: [],
      projects: [],
      activeSyringe: syringe100,
      sval: 100,
      unitsPerTick: 2,
      popupMessage: "",
      drawTicks: " - ",
      eachUnit: " - ",
      eachTick: " - ",
    };
    // this.changeSyringe100 = this.changeSyringe100.bind(this)
    // this.changeSyringe50 = this.changeSyringe50.bind(this)
    // this.changeSyringe30 = this.changeSyringe30.bind(this)
  }

  changeSyringe100() {
    this.setState({
      sval: 100,
      activeSyringe: syringe100,
      unitsPerTick: 2,
    });
  }

  changeSyringe50() {
    this.setState({
      sval: 50,
      activeSyringe: syringe50,
      unitsPerTick: 1,
    });
  }

  changeSyringe30() {
    this.setState({
      sval: 30,
      activeSyringe: syringe30,
      unitsPerTick: 1,
    });
    console.log("SVAL inside");
    console.log(this.state.sval);
    console.log("inside");
  }

  calc() {
    this.setState({
      popupMessage: "",
    });
    var tunits = this.state.unitsPerTick;
    var sunits = this.state.sval;
    var sval = sunits / 100;
    var numOfTicks = sunits / tunits;
    var desiredUnit = document.getElementById("desiredUnit").value;
    var peptideUnit = document.getElementById("peptideUnit").value;
    // var bactUnit = document.getElementById('bctrUnit').value;
    // console.log(desiredUnit, peptideUnit, bactUnit);
    if (peptideUnit == "mcg") {
      var pepmg = document.getElementById("pepMG").value / 1000;
    } else {
      var pepmg = document.getElementById("pepMG").value;
    }

    var bwml = document.getElementById("bwML").value;

    console.log("bwml:", bwml, "peptide:", pepmg);
    var mcgPerUnit = Math.round(((pepmg * 1000) / bwml) * (sval / sunits), 2);
    var mcgPerTick = Math.round(
      ((pepmg * 1000) / bwml) * (sval / numOfTicks),
      2
    );
    var dose;
    if (desiredUnit == "mcg") {
      dose = document.getElementById("desiredResult").value / 1000;
      console.log(dose);
    } else {
      dose = document.getElementById("desiredResult").value;
      console.log(dose);
    }

    dose = dose * 1000;
    var IUs = dose / mcgPerUnit;
    var drawMark = Math.round(dose / mcgPerTick, 0);
    this.setState({
      drawTicks: drawMark,
      eachUnit: mcgPerUnit,
      eachTick: mcgPerTick,
    });
    console.log("****");
    console.log(drawMark);
    console.log(mcgPerUnit);
    console.log(mcgPerTick);
    console.log("*****");

    if (sunits - drawMark < 0) {
      //display pop up message
      this.setState({
        popupMessage:
          "WARNING: YOUR SYRINGE IS TOO SMALL! PLEASE CHOOSE LARGER SIZE!",
      });
    }
  }

  render() {
    const { projects } = this.props;

    return (
      <React.Fragment>
        <div className="mt-2">
          <MetaTags>
            <title>Tools | Reconstitution Calculator</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col>
                <Alert color="danger" align="center">
                  WARNING: THIS IS NOT MEDICAL ADVICE!
                  <br />
                  THIS INFORMATION IS FOR EDUCATIONAL PURPOSES ONLY.
                  <br />
                  DO NOT USE MEDICATIONS/SUPPLEMENTS WITHOUT THE SUPERVISION AND
                  DIRECTION OF A MEDICAL PROFESSIONAL.
                </Alert>
              </Col>
            </Row>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs title="Tools" breadcrumbItem="Reconstitution Calculator" /> */}

            <Row>
              <Col sm={6} md={12}>
                <Card>
                  <CardTitle>
                    <div>
                      <h3 align="center" className="mt-3 text-secondary">
                        Select Syring Size
                      </h3>
                    </div>
                  </CardTitle>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Row className="justify-content-center">
                        <Row>
                          <div align="center">
                            <Col md="6">
                              <div
                                className="btn-group mt-2 mt-xl-0"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio1"
                                  autoComplete="off"
                                  defaultChecked
                                  value="100"
                                  onClick={this.changeSyringe100.bind(this)}
                                  onChange={(e) => {}}
                                />
                                <label
                                  className="btn btn-info"
                                  htmlFor="btnradio1"
                                >
                                  1mL
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio2"
                                  autoComplete="off"
                                  value="50"
                                  onClick={this.changeSyringe50.bind(this)}
                                  onChange={(e) => {
                                    // if (e.target.checked){
                                    //   console.log("SVAL")
                                    //   console.log(this.state.sval)
                                    // }
                                  }}
                                />
                                <label
                                  className="btn btn-info"
                                  htmlFor="btnradio2"
                                >
                                  0.5mL
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio3"
                                  autoComplete="off"
                                  value="30"
                                  onClick={this.changeSyringe30.bind(this)}
                                  onChange={(e) => {
                                    // if (e.target.checked){
                                    //   console.log("SVAL")
                                    //   console.log(this.state.sval)
                                    // }
                                  }}
                                />
                                <label
                                  className="btn btn-info"
                                  htmlFor="btnradio3"
                                >
                                  0.3mL
                                </label>
                              </div>
                            </Col>
                          </div>
                        </Row>
                        <Row xs="7" md="12">
                          <img
                            style={{ height: 80, width: 450 }}
                            src={this.state.activeSyringe}
                            alt=""
                            className="mt-2 img-fluid mx-auto d-block"
                          />
                        </Row>
                      </Row>
                      <Row className="justify-content-center">
                        <div align="center">
                          <Row className="justify-content-center">
                            <h6 className="mt-3">
                              Your syringe has {this.state.sval} Units
                            </h6>

                            <h6 className="mt-3">
                              Units per tick mark: {this.state.unitsPerTick}
                            </h6>
                          </Row>
                          <Row className=" d-flex align-items-center">
                            <label
                              htmlFor="example-number-input"
                              className="col-form-label mt-2"
                            >
                              Desired Result:
                              <input
                                style={{ maxWidth: 80, minWidth: 80 }}
                                className="custom-select ms-1"
                                min="0"
                                type="number"
                                defaultValue=""
                                id="desiredResult"
                              />
                              <select
                                style={{ maxWidth: 80, minWidth: 80 }}
                                required
                                id="desiredUnit"
                                className="custom-select ms-1 col-3 select2"
                              >
                                <option value="mg" selected>
                                  MG
                                </option>
                                <option value="mcg">MCG</option>
                              </select>
                            </label>
                          </Row>
                        </div>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col sm={6} md={12}>
                <Card>
                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <CardBody>
                        <Col>
                          <div align="center">
                            <h5 className="mt-3 ms-2 text-secondary">
                              Peptide Specification
                            </h5>

                            <div align="center">
                              <img
                                className="ms-3 mt-4"
                                src={peptide}
                                alt=""
                                height="100"
                              />
                            </div>

                            <Row className="d-flex align-items-center mt-4">
                              <label
                                htmlFor="example-number-input"
                                className="  col-form-label"
                              >
                                Weight:
                                <input
                                  style={{ maxWidth: 78, minWidth: 78 }}
                                  className="custom-select custom-padding-top ms-1"
                                  min="0"
                                  type="number"
                                  defaultValue=""
                                  id="pepMG"
                                />
                                <select
                                  style={{ maxWidth: 80, minWidth: 80 }}
                                  required
                                  id="peptideUnit"
                                  className="custom-select ms-1 select2"
                                >
                                  <option value="mg" selected>
                                    MG
                                  </option>
                                  <option value="mcg">MCG</option>
                                </select>
                              </label>
                            </Row>
                          </div>
                        </Col>
                      </CardBody>
                    </Col>
                    <Col sm={4}>
                      <CardBody>
                        <Col>
                          <div align="center">
                            <h5 className="mt-3 ms-2 text-secondary">
                              Bacteriostatic Water
                            </h5>

                            <img
                              className="ms-3 mt-3"
                              src={bact}
                              alt=""
                              height="100"
                            />

                            <Row className="d-flex align-items-center mt-4 ">
                              <label
                                htmlFor="example-number-input"
                                className="  col-form-label"
                              >
                                Amount:
                                <input
                                  style={{ maxWidth: 80, minWidth: 80 }}
                                  className="custom-select ms-1 padding-top-amount-rc"
                                  min="0"
                                  type="number"
                                  defaultValue=""
                                  id="bwML"
                                />{" "}
                                mL
                              </label>
                            </Row>
                          </div>
                        </Col>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={6} md={12}>
                <Card>
                  <CardTitle>
                    <div>
                      <h5 align="center" className="text-secondary mt-3">
                        Result
                      </h5>
                    </div>
                    <div align="center" className="mt-5">
                      <Button
                        color="primary"
                        className="btn-rounded"
                        onClick={this.calc.bind(this)}
                      >
                        Calculate
                      </Button>
                    </div>
                    <div>
                      <h5 align="center" className="text-danger mt-3">
                        {this.state.popupMessage}
                      </h5>
                    </div>
                  </CardTitle>
                  <CardBody>
                    <Row>
                      <h5 align="center" className="my-1">
                        {" "}
                        Draw up {this.state.drawTicks} Tick marks{" "}
                      </h5>
                    </Row>
                    <Row>
                      <h5 align="center" className="my-1">
                        {" "}
                        Each unit has {this.state.eachUnit} MCG{" "}
                      </h5>
                    </Row>
                    <Row>
                      <h5 align="center" className="my-1">
                        {" "}
                        Each tick mark has {this.state.eachTick} MCG{" "}
                      </h5>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ReconstitutionCalculator;
