import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class RadialChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [props.value],
      options: {
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: '65%',
            },
            dataLabels: {
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '13px',
              },
              value: {
                color: '#111',
                fontSize: '15px',
                show: true,
              },
              total: {
                show: true,
                label: props.label,
                // formatter: function (w) {
                //   // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                //   return 249;
                // },
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        colors: [props.color],
      },
    };
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart options={this.state.options} series={this.state.series} type='radialBar' height='175' className='apex-charts' />
      </React.Fragment>
    );
  }
}

export default RadialChart;
