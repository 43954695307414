import { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import Cookies from "universal-cookie";
import Select from "react-dropdown-select";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";

const AddLabInformationModal = (props) => {
  const [complete, isComplete] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [labData, setLabData] = useState(null);
  const [selectedLab, setSelectedLab] = useState(null);

  const [message, setMessage] = useState("");
  const [showNonNumeric, setShowNonNumeric] = useState(false);

  const [error, isError] = useState("");

  useEffect(() => {
    if (props.dataSource === "API") {
      setFetching(true);
      axiosConfig
        .get("labBuilder/get/labNames", {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          let responseData = response?.data?.Data?.codes ?? [];
          // responseData = responseData.filter(
          //   (value, index, self) => index === self.findIndex((t) => t.description === value.description)
          // );
          setLabData(
            responseData?.map((element) => ({
              ...element,
              displayName: `${element.resultCode} - ${element.description}`,
            }))
          );
        })
        .catch((error) => {
          errorResponse(error);

          isError(error.response.data.message);
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }, [props.dataSource]);

  function handleValidSubmit(_e) {
    isComplete(false);
    var serialize = require("form-serialize");
    var form = document.getElementById("addLabInfoForm");
    var obj = serialize(form, { hash: true });

    var correctOptimalRange = "";
    var runApi = true;
    if (document.getElementById("optimalRange").value === "Non-numeric Value") {
      correctOptimalRange = document.getElementById("nonNumericValue").value;
    } else if (document.getElementById("optimalRange").value === "Greater than only") {
      if (document.getElementById("greaterRange").value) {
        correctOptimalRange = ">" + obj.greaterRange;
        isError("");
      } else {
        isError("Please fill greater value");
        runApi = false;
      }
    } else if (document.getElementById("optimalRange").value === "Less than only") {
      if (document.getElementById("lesserRange").value) {
        correctOptimalRange = "<" + obj.lesserRange;
        isError("");
      } else {
        isError("Please fill less than value");
        runApi = false;
      }
    } else if (document.getElementById("optimalRange").value === "Range") {
      if (document.getElementById("greaterRange").value && document.getElementById("lesserRange").value) {
        correctOptimalRange = obj.greaterRange + "-" + obj.lesserRange;
        isError("");
      } else {
        isError("Please fill less than and greater than values");
        runApi = false;
      }
    }

    var body = {
      testName: obj.testName.trim(),
      displayTestName: obj.displayTestName,
      shortDescription: obj.shortDescription,
      longDescription: obj.longDescription,
      unit: obj.unit.trim(),
      optimalRange: correctOptimalRange,
      entity_id: props.entityID,
    };
    if (props.dataSource === "API") {
      body.testName = selectedLab?.description;
      body.ApiTestId = selectedLab?.resultCode;
    }
    if (runApi === true) {
      axiosConfig
        .post("/labBuilder/TestLab", body, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          setMessage(response.data.message);
          props.reCallApi?.();
          closeIt();
        })
        .catch((error) => {
          errorResponse(error);

          isError(error.response.data.message);
        })
        .finally(() => {
          setSelectedLab(null);
        });
    }
  }

  function closeIt() {
    isComplete(false);
    setSelectedLab(null);
    props.closeModal();
  }
  
  return (
    <Modal isOpen={props.showModal} size="lg" centered={true} className="smallHeightmodal">
      <>
        <button type="button" onClick={closeIt} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <Container>
          <Row className="justify-content-center">
            <Card className="overflow-hidden">
              {complete ? null : (
                <>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <h2 className="text-center mt-4">Add New Test</h2>
                      <form
                        id="addLabInfoForm"
                        onSubmit={(e) => {
                          e.preventDefault();

                          e.stopPropagation();
                          handleValidSubmit(e);
                        }}
                        className="form-horizontal px-4"
                      >
                        {error ? (
                          <Alert color="danger" className="text-center">
                            {error}
                          </Alert>
                        ) : null}
                        <div className="my-5 px-4">
                          <div>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="testName" className="form-label">
                                  Test name (This name must be the exact same as the one in the lab file){" "}
                                  <span className="requiredStar">*</span>
                                </Label>
                                {props.dataSource === "API" ? (
                                  <Select
                                    required
                                    separator
                                    searchable
                                    name="testName"
                                    searchBy="displayName"
                                    className="form-control"
                                    loading={fetching}
                                    labelField="displayName"
                                    valueField="resultCode"
                                    multi={false}
                                    options={labData}
                                    onChange={(value) => setSelectedLab(value?.[0])}
                                  />
                                ) : (
                                  <Input name="testName" className="form-control" type="text" placeholder="" required />
                                )}
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="displayTestName" className="form-label">
                                  Display name <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="displayTestName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  required
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="shortDescription" className="form-label">
                                  Short Description <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="shortDescription"
                                  maxLength={3000}
                                  className="form-control"
                                  type="textarea"
                                  required
                                />
                              </FormGroup>
                            </Row>

                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="longDescription" className="form-label">
                                  Long Description{" "}
                                </Label>
                                <Input
                                  name="longDescription"
                                  maxLength={3000}
                                  className="form-control"
                                  type="textarea"
                                />
                              </FormGroup>
                            </Row>

                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="unit" className="form-label">
                                  Units <span className="requiredStar">*</span>{" "}
                                </Label>
                                <Input
                                  name="unit"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  required
                                  defaultValue={selectedLab?.units}
                                />
                                {props.dataSource === "API" && (
                                  <>
                                    <span className="requiredStar">Note: </span>{" "}
                                    <span className="text-muted">Enter "-" in case of missing unit.</span>
                                  </>
                                )}
                              </FormGroup>
                            </Row>

                            <Row>
                              <Label className="form-label">
                                Optimal Range <span className="requiredStar">*</span>
                              </Label>
                              <Label className="form-label">Range will be defined by the provider</Label>
                              <Col xs="4">
                                <FormGroup className="mb-4">
                                  <select
                                    onChange={() => {
                                      if (document.getElementById("optimalRange").value === "Non-numeric Value") {
                                        setShowNonNumeric(true);
                                        document.getElementById("nonNumericValue").style.display = "block";
                                        document.getElementById("lesserRange").style.display = "none";
                                        document.getElementById("greaterRange").style.display = "none";
                                      } else {
                                        setShowNonNumeric(false);
                                        document.getElementById("nonNumericValue").style.display = "none";
                                        if (document.getElementById("optimalRange").value === "Greater than only") {
                                          document.getElementById("lesserRange").style.display = "none";
                                          document.getElementById("greaterRange").style.display = "block";
                                        } else if (document.getElementById("optimalRange").value === "Less than only") {
                                          document.getElementById("lesserRange").style.display = "block";
                                          document.getElementById("greaterRange").style.display = "none";
                                        } else {
                                          document.getElementById("lesserRange").style.display = "block";
                                          document.getElementById("greaterRange").style.display = "block";
                                        }
                                      }
                                    }}
                                    className="form-select"
                                    id="optimalRange"
                                    type="select"
                                    name="optimalRange"
                                    required
                                  >
                                    <p className="dropdownArrow">
                                      <i className="fas fa-angle-down"></i>
                                    </p>
                                    <option style={{ color: "#495057" }} className="form-control" selected>
                                      Range
                                    </option>
                                    <option style={{ color: "#495057" }} className="form-control">
                                      Less than only
                                    </option>
                                    <option style={{ color: "#495057" }} className="form-control">
                                      Greater than only
                                    </option>
                                    <option style={{ color: "#495057" }} className="form-control">
                                      Non-numeric Value
                                    </option>
                                  </select>
                                </FormGroup>
                              </Col>

                              <Col xs="4">
                                <FormGroup className="mb-4">
                                  <Input
                                    name="greaterRange"
                                    id="greaterRange"
                                    className="form-control"
                                    type="text"
                                    placeholder="Greater than"
                                  />
                                </FormGroup>
                              </Col>

                              <Col xs="4">
                                <FormGroup className="mb-4">
                                  <Input
                                    name="lesserRange"
                                    id="lesserRange"
                                    className="form-control"
                                    type="text"
                                    placeholder="Less than"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs="4">
                                <FormGroup className="mb-4">
                                  <select
                                    className="form-select"
                                    id="nonNumericValue"
                                    type="select"
                                    name="nonNumericValue"
                                    style={{ display: "none" }}
                                  >
                                    <option style={{ color: "#495057" }} className="form-control" value="Negative">
                                      Negative
                                    </option>
                                    <option
                                      style={{ color: "#495057" }}
                                      className="form-control"
                                      value="Negative/Trace"
                                    >
                                      Negative/Trace
                                    </option>
                                    <option style={{ color: "#495057" }} className="form-control" value="Yellow">
                                      Yellow
                                    </option>
                                    <option style={{ color: "#495057" }} className="form-control" value="Positive">
                                      Positive
                                    </option>
                                    <option
                                      style={{ color: "#495057" }}
                                      className="form-control"
                                      value="Positive/Trace"
                                    >
                                      Positive/Trace
                                    </option>
                                    <option style={{ color: "#495057" }} className="form-control" value="Clear">
                                      Clear
                                    </option>
                                  </select>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row className="my-5 text-center">
                              <button className="btn btn-primary darkBg mt-3 mx-auto w-50" type="submit">
                                Add
                              </button>
                            </Row>
                          </div>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Row>
        </Container>
      </>
    </Modal>
  );
};

export default AddLabInformationModal;
