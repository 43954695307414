import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const termsOfService = () => {
  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Terms of Service | Marek Health</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md='2'>
              <Link to='/'>
                <button className='mb-4 btn btn-primary'>Back</button>
              </Link>
            </Col>
          </Row>
          <h1 style={{ textAlign: 'center' }}>TERMS OF SERVICE</h1>

          <div align='center'>
            <hr style={{ width: '100px' }} />
          </div>

          <div class='entry-content'>
            <p>
              <strong>DAAS, LLC Terms and Conditions for “FitDataMax”</strong>
            </p>

            <p>
              These “<strong>Terms and Conditions</strong>” apply to your usage of the website, through the website order form&nbsp; (“Order Form”), between DAAS, LLC, a Massachusetts limited liability company (“<strong>we</strong>,” “<strong>us</strong>,” “<strong>our</strong>,” or “<strong>DAAS</strong>”) and the person signing the order form, by electronic means, click through or otherwise (“<strong>you</strong>” or “<strong>your</strong>”).&nbsp; Together, these Terms and Conditions and the Order Form(s), constitute the “<strong>Agreement</strong>”.&nbsp;
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DAAS provides an online tool on a site available on the World Wide Web, accessed via your username and chosen password (the “<strong>Website</strong>”), which allows you to record the types of legal supplements you take and the results of your workout (the “<strong>Services</strong>”).&nbsp; DAAS services do not involve illegal activity and your use of the Services for illegal substances is strictly prohibited.&nbsp;
            </p>

            <p>
              <strong>1.&nbsp; DEFINITIONS</strong>
            </p>

            <ol type='a'>
              <li>
                “<strong>Agreement,</strong>” ”<strong>we</strong>,” ”<strong>us,</strong>” “<strong>you,</strong>” “<strong>your,” and</strong> “<strong>DAAS”</strong>, are defined in the preamble.&nbsp; Other terms may be defined inline and not specifically shown in this section.
              </li>
              <li>
                &nbsp;“<strong>Documentation</strong>”&nbsp;means any instruction or other items posted to the Website regarding the use of the Website or the Services, including any privacy policy.
              </li>
              <li>
                “<strong>Intellectual Property Rights</strong>” means patent rights, trademark rights, copyrights, moral rights, publicity and privacy rights, <em>sui generis</em> database rights, and all other proprietary rights, throughout the world.&nbsp;
              </li>
              <li>
                “<strong>Malicious Code</strong>” means viruses, worms, time bombs, Trojan horses and other harmful or malicious code, files, scripts or programs.
              </li>
              <li>
                “<strong>Order Form</strong>” means the electronic forms used for placing orders for the Services, including any amendments or addendums that are entered into between the parties.&nbsp; By entering into an Order Form or using the Services, an Affiliate agrees to be bound by these Terms and Conditions as if it were an original party hereto.
              </li>
              <li>
                “<strong>Services</strong>” are defined in the preamble and are subject to a fee charged by us.&nbsp; The Services are provided solely as a remote online service (i.e., as a “software as a service” or “SaaS” product).
              </li>
              <li>
                “<strong>Software</strong>” means any computer code (source, object or otherwise) which is used in providing the Services and for which you are not entitled to physical access to any Software under this Agreement.
              </li>
              <li>
                “<strong>Website</strong>” shall mean all files and internet enable content available at the domain ‘fitdatamax.com’.
              </li>
              <li>
                “<strong>Website Content</strong>” shall mean all content, files, articles, information, or other items, whether created by DAAS, licensed by DAAS ,or submitted by &nbsp;other users of the Website regardless of form.
              </li>
            </ol>

            <p>
              <strong>2. &nbsp;LICENSE.&nbsp; </strong>Subject to the terms and conditions of the Agreement and your payment of all fees required pursuant to the Agreement, DAAS grants to you a terminable, revocable, non-exclusive, non-transferable license (without the right to sublicense) during the term of the applicable Order Form, to access and use the Services solely in accordance with applicable Documentation and solely for your own purposes.&nbsp; DAAS reserves the right to modify the Services or Software at any time. &nbsp;You understand that this Agreement grants certain rights of
              access only, and that nothing in this Agreement shall be interpreted to require delivery of a copy of any Software to you or installation of a copy of such Software upon your computers or systems.&nbsp; This Agreement is not a sale of Software (but rather only a limited right of use) and does not transfer to you any title or any ownership right or interest in any item, whether on the Website or otherwise, &nbsp;nor in any Intellectual Property Rights in any item used by DAAS, party of the SAAS or associated with the website.&nbsp; Any attempt by you to transfer any of the rights,
              duties or obligations hereunder, except as expressly provided for in these Terms and Conditions, is void from its inception.&nbsp; DAAS reserves all rights not expressly granted under this Agreement.
            </p>

            <p>
              3. <strong>NO SHARING AND OTHER RESTRICTIONS</strong>. You may not share any account information, including your ID and password, provided to you, in whole or in part, with any other person. You may not share any of the Website Content or your access to the Website with any other person. If another person is interested in accessing the Services, you should direct them to create their own account and obtain their own license of the product. You will be responsible for all actions taken using your ID and password, even if you do not have any knowledge of this use. You agree not to
              access (or attempt to access) the Website by any means other than through the interface DAAS provides, unless you have been specifically allowed to do so in a separate agreement. You agree not to access (or attempt to access) the Website through any automated means (including use of scripts or web crawlers). You are not authorized to (i) sell, resell, sublicense, transfer, assign, or distribute the Website, Website Content, or any of the Services; (ii) modify or make derivative works based on the Website, the Website Content or the Services; or (iii) &#8220;frame&#8221; or
              &#8220;mirror&#8221; the Website, Website Content, or the Services or content on any other server or Internet-enabled device. You agree to at all times keep your password secure and immediately inform DAAS of any breach of your password security or any other unauthorized use. You agree that you will not (a) alter, copy, modify, or re-transmit the Website; (b) lease, license, rent, or sell any of the Website Content, access to the Website or the right to use and access the Services; (c) remove, obscure, or alter any text or proprietary notices contained on the Website or the
              Website Contents; (d) copy or imitate part or all of the design, layout, or look-and-feel of the Website or the Website Contents; (e) store, upload or attempt to upload or store any information on the Website other than what is allowed in the Documentation; (f) use the Website in an illegal or unlawful manner; (g) to solicit others to perform or participate in any unlawful acts; (h) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (i) to infringe upon or violate our Intellectual Property Rights or the Intellectual Property
              Rights of others; (j) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (k) to submit false or misleading information; (l) to upload or transmit viruses or any other type of Malicious Code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (m) to collect or track the personal information of others; (n) to spam, phish, pharm, pretext,
              spider, crawl, or scrape; (o) for any obscene or immoral purpose; or (p) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
            </p>

            <p>
              3. <strong>YOUR REPRESENTATIONS</strong>. You agree that you are the age of majority in the jurisdiction where you sign the Agreement, and that you are legally authorized to enter into his Agreement.
            </p>

            <p>
              4.<strong> PAYMENT AND TAXES</strong>. Your access to Services and Website Content is contingent on your payment of any fees currently shown on the Order Form (the “<strong>Fees</strong>”). If your payment is charged back, dishonored or otherwise unavailable to DAAS, in whole or in part, then DAAS may immediately terminate this Agreement, and may withhold access until all Fees are received.&nbsp; Unless otherwise stated, our fees do not include any taxes, levies, duties or similar governmental assessments of any nature, including but not limited to value-added, sales, use or
              withholding taxes, assessable by any local, state, provincial, federal or foreign jurisdiction (collectively—but excluding all taxes based solely on our income, properties, or employees—the “<strong>Taxes</strong>”).&nbsp; You are responsible for paying all Taxes associated with your purchase of Services.&nbsp; If we have the legal obligation to pay or collect Taxes for which you are responsible under this paragraph, the appropriate amount shall be invoiced to and paid by you, unless you have provided us with a valid tax exemption certificate authorized by the appropriate taxing
              authority.
            </p>

            <p>
              5.&nbsp; <strong>SERVICE TERM</strong>. We shall make the Services available to you pursuant to this Agreement and the relevant Order Forms during the subscription term set forth in the Order Form. &nbsp;You agree that your purchases hereunder are neither contingent on the delivery of any future functionality or features nor dependent on any oral or written public or private comments made by us regarding future functionality or features.
            </p>

            <p>
              <strong>6. YOUR ADDITIONAL OBLIGATIONS</strong>
            </p>

            <p>
              6.1.{' '}
              <strong>
                <em>Backing Up Data</em>
              </strong>
              .&nbsp;&nbsp;&nbsp; You are also responsible for protecting and backing-up the data created, used, stored and/or transmitted in connection with the use of the Services.&nbsp; DAAS shall have no liability whatsoever for any loss of, or failure to recover, such data, or any loss or disruption caused by failure to properly back-up Your Data on a periodic basis.&nbsp;
            </p>

            <p>
              6.2.{' '}
              <strong>
                <em>Additional Obligations</em>
              </strong>
              .&nbsp; You are also responsible for (a) using the Services only in accordance with the Documentation and applicable laws and government regulations; (b) communicating with your own health provider and clearing any training program or other physical activity with trained medical professionals; &nbsp;(c) obtaining and maintaining all computer hardware, software and communications equipment needed to access and use the Services; (d) paying all third-party fees and access charges (e.g., ISP, telecommunications) incurred while using the Services and providing sufficient bandwidth and
              network connectivity to ensure all Users can access and use the Services satisfactorily; (e) ensuring your firewalls and proxies permit access to the DAAS URLs / IP Addresses and meet any other technical specifications of which we reasonably advise you; (f) determining the security configurations of your systems (e.g. password construction rules and expiration intervals); (g) promptly notifying us of any actual or reasonably suspected information security breaches, of which you become aware; and (h) for periodically reviewing your security configurations and access rights to
              determine if they are appropriate for your needs.
            </p>

            <p>
              6.3.&nbsp; &nbsp;&nbsp;
              <strong>
                <em>Suspension</em>
              </strong>
              .&nbsp; We may, in our reasonable discretion, without prior notice, and without waiving subsequent termination rights, issue a warning or suspend your account and access to the Services if you breach or threaten to breach this Agreement or if we are unable to verify or authenticate any information you have provided.&nbsp; Notwithstanding the foregoing, we shall endeavor to provide you with notice prior to suspending your access (along with details as to the reason), except under exigent or emergency situations where we deem suspension reasonably necessary to prevent potential
              harm to our or other’s networks or a potential illegal act (in which case we will give you notice as soon thereafter as is reasonably practicable).&nbsp; Furthermore, we will reinstate your access as soon as you have demonstrated to our reasonable satisfaction that the issue has been resolved and will not reoccur, unless we have terminated this Agreement for cause prior to that time.
            </p>

            <p>
              <strong>7. &nbsp;PROPRIETARY RIGHTS</strong>
            </p>

            <p>
              7.1.{' '}
              <strong>
                <em>Our Rights in Services; License Restrictions. </em>
              </strong>
              &nbsp;You acknowledge and agree that the Website, Documentation, Website Content, and any materials provided to you with the Services are the property of DAAS, or other third parties an under license by DAAS,&nbsp; contain valuable assets, proprietary information and property of DAAS, and are provided to you only for use strictly in accordance with the terms and conditions of this Agreement.&nbsp; We reserve all rights, title and interest in and to the Services, including all related Intellectual Property Rights. No rights are granted to you hereunder other than as expressly set
              forth herein.&nbsp;
            </p>

            <p>
              6.2.{' '}
              <em>
                &nbsp;<strong>Your Comments, Data,</strong>
              </em>{' '}
              <strong>
                <em>and</em>
              </strong>{' '}
              <strong>
                <em>Suggestions</em>
              </strong>
              . You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are
              solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party. You hereby grant, and we shall have, a royalty-free, worldwide, irrevocable, perpetual license to use and incorporate into the Services, place on the Website any suggestions, enhancement requests, ideas, error identifications, recommendations, or other feedback provided by you, including Users, relating to the operation of the Services.&nbsp; Furthermore, you hereby grant, and we shall have, a royalty-free,
              worldwide, irrevocable, perpetual license to use , publish, and incorporate, place on the Website, transmit, broadcast, or otherwise exploit in any commercial context, any comments created by you on the Website or delivered to us for a contemplated use on the Website, on any media whether now existing or hereinafter coming into existence, including the Website.&nbsp; Nothing herein shall grant you any superior tight to such materials if we have agreed that we own such content in a separate agreement between the parties.
            </p>

            <p>
              8. <strong>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</strong>. WEBSITE, SERVICES, AND WEBSITE CONTENT ARE PROVIDED &#8220;AS IS,&#8221; AND “WHERE IS” WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. DAAS DOES NOT MAKE ANY WARRANTIES OR REPRESENTATIONS (i) THAT YOUR ACCESS TO WEBSITE, SERVICES, AND WEBSITE CONTENT WILL BE UNINTERRUPTED OR FREE OF ERRORS OR OMISSIONS, (ii) AS TO THE RELIABILITY, ACCURACY, CURRENCY OR COMPLETENESS OF WEBSITE, SERVICES, AND WEBSITE CONTENT;
              or (iii) ANY CONTENT LINKED FROM WEBSITE, SERVICES, AND WEBSITE CONTENT. DAAS SHALL NOT BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR ANY OTHER TYPE OF DAMAGES, ARISING OUT OF THIS AGREEMENT OR THE USE OF OR INABILITY TO USE WEBSITE, SERVICES, AND WEBSITE CONTENT EXCEPT AS MAY BE SPECIFICALLY SHOWN IN THIS AGREEMENT. UNDER NO CIRCUMSTANCES SHALL DAAS BE LIABLE TO YOU IN AMOUNT GREATER THAN THE FEES. THE LIMITATIONS AND EXCLUSIONS IN THIS SECTION APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW IN YOUR JURISDICTION. SOME
              JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES. ACCORDINGLY, THE LIMITATIONS AND EXCLUSIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. DAAS DOES NOT ASSUME LIABILITY FOR ANY ACTIONS UNDERTAKEN AFTER VISITING THESE PAGES, AND DOES NOT ASSUME LIABILITY IF ONE MISUSES SUPPLEMENTS, INCLUDING ANY LIABILITY FOR ANY SIDE EFFECTS FROM SUPPLEMENTS OR PRACTICES ON THE WEBSITE.
            </p>

            <p>
              9. <strong>INFORMATION FOR CALIFORNIA USERS ONLY</strong>. Under California Civil Code Section 1789.3, DAAS is required to provide the following information: (a) The provider of Website, Services, and Website Content is : _____________________________. (b) Website, Services, and Website Content is subject to the Fees you pay at checkout and, (c) The Consumer Affairs Center of the California Department of Consumer Affairs may be reached at 400 R. Street, Sacramento, CA 95814, telephone (800) 952-5210ompelled by law (including by order of a governmental authority of competent
              jurisdiction) to do so, provided the Receiving Party: (a) gives the Disclosing Party prompt notice of such compelled disclosure (to the extent legally permitted); (b) discloses only the minimum amount necessary to comply with the legal requirement; and (c) provides reasonable assistance, at the Disclosing Party&#8217;s cost, if the Disclosing Party wishes to contest the disclosure. &nbsp;If the Receiving Party is compelled by law to disclose the Disclosing Party’s Confidential Information as part of a civil proceeding to which the Disclosing Party is a party, the Disclosing Party
              will reimburse the Receiving Party for its reasonable cost of compiling and providing secure access to such Confidential Information.
            </p>

            <p>
              <strong>9. &nbsp;CHILDREN&#8217;S PRIVACY. </strong>We are committed to protecting the privacy of children. You should be aware that this Site is not intended or designed to attract children under the age of 13. We do not collect personally identifiable information from any person we actually know is a child under the age of 13.
            </p>

            <p>
              <strong>10. &nbsp;INDEMNIFICATION AND EXCLUSIVE REMEDY</strong>
            </p>

            <p>
              &nbsp;You shall defend us (for purposes of this paragraph, including any of our or our Affiliates’ officers, directors, and/or&nbsp; agents, the “<strong>Indemnified Party(ies)</strong>”) against any claim, demand, suit or proceeding made or brought against the Indemnified Parties by a third party alleging that any of your submissions, or your use of the Services in breach of this Agreement, infringes or misappropriates the Intellectual Property Rights of a third party or violates applicable law (a “<strong>Claim Against DAAS</strong>”), and shall indemnify the Indemnified
              Parties from and against any damages, attorney fees and costs pursuant to any verdict against the Indemnified Parties or any settlement you have agreed to settle in respect of a Claim Against DAAS.
            </p>

            <p>
              In the event of a claim against you, or if we reasonably believe the Services may infringe or misappropriate any Intellectual Property Rights, we may in our discretion and at no cost to you: (i) modify the Services so that they no longer infringe or misappropriate, (ii) obtain a license for your continued use of the Services, or (iii) terminate your User subscriptions for such Services upon thirty (30) days’ written notice and refund to you any Unearned Fees. This Section 10 states our sole liability to you and exclusive remedy against, the other party for any type of claim
              described in this Section.
            </p>

            <p>
              11.&nbsp;&nbsp; <strong>NO MEDICAL ADVICE. The Web Site Does Not Provide Medical Advice&nbsp; &nbsp;</strong>Website Content is for informational purposes only. The Website Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on the Website or obtain from the Services. If you think you may have a
              medical emergency, call your doctor or 911 immediately. DAAS does not recommend or endorse any specific supplements, tests, physicians, products, procedures, opinions, or other information that may be mentioned on the Website. Reliance on any information provided by DAAS, DAAS employees, others appearing on the Website at the invitation of DAAS, or other visitors to the Site is solely at your own risk. DAAS and its employees do not advocate nutritional supplementation over proper medical advice or treatment. If using any pharmaceuticals or drugs given to you by a doctor or
              received with a prescription, you must consult with the doctor in question prior to using any nutritional supplementation. If undergoing medical therapies, then consult with your respective health care professional about possible interactions between your treatment and any product ingested or applied to the body.&nbsp; ITEMS INGESTED OR APPLIED IN THE BODY MAY HAVE SIDE EFFECTS, YOU SHOULD CONSULT A MEDICAL PROFESSIONAL PRIOR TO USE.
            </p>

            <p>
              <strong>12.&nbsp; NO LEGAL ADVICE. The Web Site Does Not Provide Legal Advice&nbsp;&nbsp; </strong>Website Content is for informational purposes only. The Website Content is not intended to be a substitute for professional legal advice.&nbsp; The legality of supplements may vary across jurisdictions and you should obtain a competent legal opinion regarding your possession or use of products in any applicable jurisdiction.
            </p>

            <p>
              <strong>13.&nbsp; SECURITY</strong>.&nbsp; You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
            </p>

            <p>
              14.&nbsp; <strong>PRIVACY POLICY</strong>.&nbsp; Your personal information and other information you provide shall be subject to our privacy policy located at&nbsp;: https://fitdatamax.com/privacy-policy/.
            </p>

            <p>
              <strong>13 TERMINATION</strong>
            </p>

            <p>
              12.1. &nbsp;
              <strong>
                <em>Term of Agreement</em>
              </strong>
              . This Agreement commences on the date on which it is executed and continues until the date shown on the Order Form. &nbsp;
            </p>

            <p>
              12.2. &nbsp;
              <strong>
                <em>Term of Purchased User Subscriptions</em>
              </strong>
              . &nbsp;Except as otherwise specified in the applicable Order Form, all User subscriptions shall automatically renew for additional periods equal to the expiring subscription term, unless either party gives the other written notice of non-renewal at least one (1) day &nbsp;before the end of the relevant subscription. &nbsp;
            </p>

            <p>
              12.3. &nbsp;
              <strong>
                <em>Termination for Cause</em>
              </strong>
              . &nbsp;A party may terminate this Agreement for cause: (i) upon thirty (30) days’ written notice to the defaulting party of a material breach of this Agreement if the defaulting party fails to cure such breach within such notice period, or (ii) if the other party becomes the subject of a petition in bankruptcy (which in the case of involuntary filing, remains in effect for at least thirty (30) days) or any other proceeding relating to insolvency, receivership, or liquidation, or makes an assignment for the benefit of creditors.
            </p>

            <p>
              12.4. &nbsp;
              <strong>
                <em>Termination if We Cease Providing the Services</em>
              </strong>
              .&nbsp; If we stop providing the Services generally to customers at any time, we may terminate this Agreement and all Order Forms upon thirty (30) days’ prior written notice to you; provided that in such case we will refund to you the prorated Unearned Fees.
            </p>

            <p>
              12.5. &nbsp;
              <strong>
                <em>Effects of Termination</em>
              </strong>
              .&nbsp; Upon expiration or termination of this Agreement for any reason, the rights, licenses and access to the Services granted to you under this Agreement will immediately terminate.&nbsp;
            </p>

            <p>
              <strong>13. &nbsp;disputes/ARBITRATION; governing law</strong>
              <strong>&nbsp;</strong>
            </p>

            <p>
              <strong>
                13.1. &nbsp;<em>Binding Arbitration</em>
              </strong>
              .&nbsp; To the maximum extend allowed by law, any dispute, controversy or claim arising out of or relating to this Agreement, or the breach thereof, shall be submitted to the American Arbitration Association (“<strong>AAA</strong>”) for mandatory binding arbitration in front of a single arbitrator chosen in accordance with the AAA Commercial Arbitration Rules and Mediation Procedures (using Expedited Procedures) (“<strong>Rules</strong>”) available at&nbsp; https://www.adr.org/sites/default/files/commercial_rules.pdf (as of the date of these Terms and Conditions).&nbsp; Unless
              provided otherwise herein, the arbitrator may not award non-monetary or equitable relief of any sort.&nbsp; She shall have no power to award damages inconsistent with this Agreement, and the parties expressly waive their right to obtain such damages in arbitration.&nbsp; All aspects of the arbitration shall be treated as confidential.&nbsp; Neither the parties nor the arbitrator may disclose the existence, content or results of the arbitration, except as necessary to enforce the results of the arbitration or to comply with legal or regulatory requirements.&nbsp; Before making any
              such disclosure, a party shall give written notice to the other party and shall afford such party a reasonable opportunity to protect its interests.&nbsp; The arbitrator shall render its award in writing and will include the findings of fact and conclusions of law upon which the award is based.&nbsp; &nbsp;The result of the arbitration shall bind the parties and judgment on the arbitrators’ award may be entered in any court having jurisdiction.&nbsp; In addition to any and all other relief to which a party may be entitled, the arbitrator shall award reasonable attorneys’ fees and
              costs, including reasonable expert witness fees and costs (if applicable), to the prevailing party (should there be one) in any such arbitration.&nbsp; In the event of conflict between these Terms and Conditions and the Rules, these Terms and Conditions shall be first in precedence.
            </p>

            <p>
              <strong>13.2. &nbsp;</strong>
              <strong>
                <em>Waiver of Jury Trial; Individual Basis; Equitable Relief</em>
              </strong>
              <strong>.&nbsp; THE PARTIES SURRENDER AND WAIVE THE RIGHT TO SUBMIT ANY DISPUTE TO A COURT OR JURY, OR TO APPEAL TO A HIGHER COURT.&nbsp; The Parties agree to arbitration on an individual basis. </strong>If any provision of this arbitration agreement is found unenforceable, the unenforceable provision shall be severed, and the remaining arbitration terms shall be enforced (but in no case shall there be a class arbitration).&nbsp; Notwithstanding the foregoing, nothing in this Agreement shall prohibit either party (prior to the commencement of arbitration) from seeking and
              obtaining from a court of competent jurisdiction (without necessity of posting bond) injunctive relief in order to preserve the status quo and/or avoid irreparable harm which may be inadequately compensable by monetary damages, for example in respect of a threatened breach of the confidentiality or license-restriction provisions hereof.&nbsp; Nothing in this Section 13 shall be construed to prohibit DAAS from using an attorney or collections agency to collect unpaid fees.
            </p>

            <p>
              <strong>13.3.&nbsp; </strong>
              <strong>
                <em>Where Held; Language; Governing Law</em>
              </strong>
              <strong>.</strong>&nbsp; The place of arbitration shall be Worcester, MA, USA, and the proceedings shall be conducted in the English language.<strong>&nbsp; </strong>The Agreement shall be governed by the United States Federal Arbitration Act to the exclusion of any inconsistent state laws and the arbitrator shall follow the law and judicial precedents that a United States District Judge sitting in the District of Massachusetts would apply to the dispute.
            </p>

            <p>
              <strong>14. &nbsp;GENERAL PROVISIONS</strong>
            </p>

            <p>
              14.1. &nbsp;
              <strong>
                <em>Notice</em>
              </strong>
              . Except for Legal Notices: (a) we may provide you notice via the Services (e.g., by the message center or dashboard) or via email or to your contact person on file, and (b) you may provide us notice to the email address set forth in your Order Form, as updated from time to time.&nbsp; Such notice is deemed received on the first business day after posting or sending.&nbsp; Legal Notices must be in writing, are deemed received only upon actual delivery (or refusal), and shall be sent (if to you): to the address set forth on your most current Order Form (or such address as you have
              updated in writing), and (if to DAAS) to DAAS, LLC, : ________________________________.&nbsp; “<strong>Legal Notices</strong>” means any notice of termination (except if the recipient confirms receipt of the email by a human-generated response), notices alleging breach hereof, or pertaining to an indemnifiable claim.
            </p>

            <p>
              14.2. &nbsp;
              <strong>
                <em>Export Compliance</em>
              </strong>
              . &nbsp;You agree to comply with all applicable export and re-export control laws and regulations, including the Export Administration Regulations (“<strong>EAR</strong>”) maintained by the U.S. Department of Commerce, trade and economic sanctions maintained by the Treasury Department’s Office of Foreign Assets Control (“<strong>OFAC</strong>”), and the International Traffic in Arms Regulations (“<strong>ITAR</strong>”) maintained by the Department of State.&nbsp; You covenant that you will not — directly or indirectly — sell, export, re-export, transfer, divert, or otherwise
              dispose of any products, software, or technology (including products derived from or based on such technology) received from DAAS under this Agreement to any destination, entity, or person prohibited by the laws or regulations of the United States, without obtaining prior authorization from the competent government authorities as required by those laws and regulations. <strong>You agree to indemnify, to the fullest extent permitted by law, DAAS from and against any fines or penalties that may arise as a result of your breach of this provision</strong>.
            </p>

            <p>
              14.3. &nbsp;
              <strong>
                <em>Relationship of the Parties; No Third-Party Beneficiaries; Waiver. </em>
              </strong>
              &nbsp;The parties are independent contractors to each other. This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties, and neither party has the right to bind the other party to any agreement, understanding, or contract. &nbsp;There are no third-party beneficiaries to this Agreement.&nbsp; No failure or delay by either party in exercising any right under this Agreement shall constitute a waiver of that right. A waiver of any default is not a waiver of any subsequent default.
            </p>

            <p>
              14.4. &nbsp;
              <strong>
                <em>Assignment</em>
              </strong>
              . &nbsp;You may not assign any of its rights or obligations hereunder, whether by operation of law or otherwise, without the prior written consent of DAAS.&nbsp; Subject to the foregoing, this Agreement shall bind and inure to the benefit of the parties, their respective successors and permitted assigns.
            </p>

            <p>
              14.5. &nbsp;
              <strong>
                <em>Entire Agreement; No Reliance; Order of Precedence</em>
              </strong>
              . &nbsp;The Agreement (as defined above), constitutes the entire agreement between the parties and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. &nbsp;You acknowledge that you have not relied on any oral promises or any other terms, representations, commitments, understandings, or matters, other than those expressly set forth in writing in the Agreement.&nbsp; If we update this Agreement, we will email you notice or post notice of it in your online interface, and you will be bound by such change
              thirty (30) days after such posting/notice, unless you provide us written notice within such thirty (30) day period stating that you do not consent to such change.&nbsp; No modification, amendment, or waiver of any provision of this Agreement shall be effective against us unless in writing and signed by us. &nbsp;The order of precedence in the event of any inconsistency among different documents comprising the Agreement is as follows (from highest to lowest): (a) any provision of an Order Form expressly stating that is intended to modify or supersede a provision of these Terms
              and Conditions; (b) these Terms and Conditions; (c) Order Forms; (d) any online policy. &nbsp;Other than the mutually executed Order Form(s), no PO’s, preprinted forms, order documentation, or any other forms provided by you (“<strong>Forms</strong>”) shall be deemed to vary, add to, or delete any provisions hereof, and all such unilateral terms of yours shall be null and void, even if we execute the Forms.
            </p>

            <p>
              14.6.&nbsp;{' '}
              <strong>
                <em>Miscellaneous</em>
              </strong>
              .&nbsp; These Terms and Conditions are in effect pursuant to their incorporation into the executed Order Form; an Order Form (and/or this Agreement) may be executed by signature (electronic or “ink”), or via clicking to acknowledge acceptance, via click wrap and/or in counterparts, including counterparts delivered electronically, each of which will be deemed to be an original and such counterparts together will constitute one and the same agreement.&nbsp; DAAS shall be excused from performance hereunder to the extent that performance is prevented, delayed or obstructed by causes
              beyond its reasonable control including acts of God, weather events, labor strife, acts of war (declared or not) or terrorism, acts of government, civil unrest, or denial of service attacks.&nbsp; If any provision of the Agreement is held to be unenforceable for any reason, such provision shall be reformed to the extent necessary to make it enforceable to the maximum extent permissible so as to effect the intent of the parties (or deleted if reformation is not feasible), and the remainder of the Agreement shall continue in full force and effect.&nbsp; The United Nations
              Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transactions Act shall not apply to the Agreement.&nbsp; <strong>You may only resolve disputes with us on an individual basis, and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action. </strong>&nbsp;The terms “including” and “includes” are not to be construed as words of limitation but rather shall be interpreted as if followed by the words “but [is/are] not limited to”.
            </p>

            <p>
              14.7&nbsp;{' '}
              <strong>
                <em>Publication of Terms Does Not Create Rights or Constitute an Offer</em>
              </strong>
              .&nbsp;&nbsp; Our publication or providing you access to these terms are not an offer and do not create any right you may have to any Services.&nbsp;&nbsp;&nbsp; Only your signed order form that has been approved by us bind us to any agreement.&nbsp; We reserve the right to refuse service, limit access, reduce access or otherwise impose additional terms and conditions (prior to the finalization of the agreement) using any criteria of our choosing.
            </p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default termsOfService;
