import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import Cookies from "universal-cookie";
import axiosConfig from "../../../utils/axiosConfig";

function CronAction(props) {
  const activeCron = props.row?.cronConfig ?? false;
  const startFetching = (row) => {
    axiosConfig
      .post(
        "labBuilder/set/cronJob",
        {
          entityID: row.id,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        props?.cronSuccess?.(response?.data?.msg);
      })
      .catch((error) => {
        props.cronError(error);
      })
      .finally(() => {});
  };

  const stopFetching = (row) => {
    axiosConfig
      .post(
        "labBuilder/remove/cronJob",
        {
          entityID: row.id,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        props?.cronSuccess?.(response?.data?.msg);
      })
      .catch((error) => {
        props.cronError(error);
      });
  };

  return (
    <React.Fragment>
      <UncontrolledTooltip placement="auto" target="UncontrolledTooltipExample">
        {activeCron ? "Stop fetching from API" : "Start fetching from API"}
      </UncontrolledTooltip>
      <span
        id="UncontrolledTooltipExample"
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (activeCron) {
            stopFetching(props.row);
          } else {
            startFetching(props.row);
          }
        }}
      >
        <i class={`fas ${activeCron ? "fa-stop" : "fa-play"}`} style={{ color: activeCron ? "red" : "#556ee6" }}></i>
      </span>
    </React.Fragment>
  );
}

export default React.memo(CronAction);
