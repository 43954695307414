const productsData = [
  {
    id: 1,
    image: 'product1',
  },
  {
    id: 2,
    image: 'product2',
  },
  {
    id: 3,
    image: 'product3',
  },
  {
    id: 4,
    image: 'product4',
  },
  {
    id: 5,
    image: 'product5',
  },
  {
    id: 6,
    image: 'product6',
  },
  {
    id: 7,
    image: 'product7',
  },
  {
    id: 8,
    image: 'product8',
  },
  {
    id: 9,
    image: 'product9',
  },
  {
    id: 10,
    image: 'product10',
  },
  {
    id: 11,
    image: 'product11',
  },
  {
    id: 12,
    image: 'product12',
  },
  {
    id: 13,
    image: 'product13',
  },
  {
    id: 14,
    image: 'product14',
  },
  {
    id: 15,
    image: 'product15',
  },
];
//Data for OTC screen
const otcProductData = [
  {
    name: 'Carnitine',
    id: 1,
    image: 'product1',
    data: [{ title: 'In progress ', text: 'coming soon...' }],
  },
  {
    name: 'Creatine',
    id: 2,
    image: 'product2',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Beta-hydroxy-beta-methylbutyrate',
    id: 3,
    image: 'product3',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'N-Acetyl Cysteine',
    id: 4,
    image: 'product4',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Protein',
    id: 5,
    image: 'product5',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
];
//Data for AAS screen
const aasProductData = [
  {
    name: 'Testosterone',
    id: 1,
    image: 'product1',
    data: [
      { title: 'General Information', text: `Also Known As: Test, Test E, Test C${'\n'}Common Uses:${'\n'} Bulking, Strength, Hypogonadism Treatment${'\n'} Typical Administration Route: Injection, Topical Gel${'\n'}Relative Binding Affinity vs. Testosterone: 100%${'\n'}Anabolic:Androgenic Ratio: 1 : 1${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, FDA approved for hypogonadism 1${'\n'}Virilization Risk: Yes${'\n'}Storage: Room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Propionate: 0.8 – 1 day 2, 3${'\n'}Enanthate: 4 – 7 days 4, 5${'\n'}Cypionate: 4 – 7 days 4${'\n'}Undecanoate: 10 – 21 days 6, 7` },
      { title: 'Hormonal Effects', text: `Estrogenic: Yes, aromatized to estradiol${'\n'}Prolactin: No${'\n'}SHBG Impact: Reduces at supra-physiological${'\n'} levels 8${'\n'}HPTA Suppression: Yes${'\n'}HPTA Shut Down: Yes 9${'\n'}Recovery Time: varies` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'} May Increase 10${'\n'}Cholesterol:${'\n'} Supra-physiological doses decrease HDL and increase${'\n'} LDL 11, 15${'\n'}Kidney: Low Toxicity${'\n'}Liver: No Toxicity 12${'\n'}RBC/Hematocrit: Increases 12` },
      { title: 'Mental Health Effects', text: `Anxiety: Low${'\n'}Sleep: Moderate${'\n'}Aggression: Significantly increases ${'\n'}Increases manic symptoms in a subset of users` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} ${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered ${'\n'}Breathing among Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea ${'\n'}Screening Tool${'\n'}Hair Loss/ MPB: High Risk, 5a-reduced to DHT${'\n'}Hair Growth: Yes${'\n'}Acne: Yes` },
      { title: 'Anecdotal Reports', text: `Increased Sex Drive${'\n'}Increased Strength and Work Capacity${'\n'}Increased Confidence and Motivation` },
      {
        title: 'Dosing Observations',
        text: `Adult Male Replacement Dose (TRT/HRT): 100 – 200mg/week 1${'\n'}Diagnostic Labs required to tailor dose${'\n'} to the individual${'\n'}Commonly Used Doses: 100 – 600mg/week${'\n'}Frequently reported online forums, chat groups,${'\n'} etc.${'\n'}Dose Response: 600mg + Exercise for 10${'\n'} weeks in Healthy Men 13${'\n'}Fat free Mass increased average 6.1kg,${'\n'} no change in fat mass${'\n'}Bench press strength increased 23% vs${'\n'} 9% exercise only${'\n'}Squat strength increased 37% vs 20%${'\n'} exercise only${'\n'}Quads and tricep area increased ${'\n'}14% vs non ${'\n'}significant change in exercise only`,
      },
      {
        title: 'Literature used',
        text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.${'\n'}${'\n'}https://www.accessdata.fda.gov/${'\n'}drugsatfda_docs/label/2018/085635s040lbl.pdf${'\n'}https://doi.org/10.1007${'\n'}/978-3-642-72185-4_11${'\n'}https://doi.org/10.1210/jcem-63-6-1361${'\n'}https://doi.org/10.1007/${'\n'}978-3-642-72185-4_11${'\n'}https://doi.org/10.1210/${'\n'}jcem.81.3.8772547${'\n'}https://doi.org/10.1007/978-3-642-72185-4_11${'\n'}https://doi.org/10.1530/eje.0.1400414${'\n'}https://doi.org/10.1152/ajpendo.2001.281.6.E1172${'\n'}https://doi.org/10.1016/0010-7824(72)90055-8${'\n'}https://doi.org/10.1161/HYPERTENSIONAHA.112.195149${'\n'}https://doi.org/10.1097/00042752-199607000-00003${'\n'}https://doi.org/10.1152/ajpendo.2001.281.6.E1172${'\n'}https://doi.org/10.1056/NEJM199607043350101${'\n'}https://doi.org/10.1001/archpsyc.57.2.133`,
      },
    ],
  },
  {
    name: 'Nandrolone (‘Deca’)',
    id: 2,
    image: 'product2',
    data: [
      { title: 'General Information', text: `Also Known As: Deca Durabolin, Deca, NPP${'\n'}Common Uses:${'\n'} Bulking, Strength${'\n'}Typical Administration Route: Injection${'\n'}Relative Binding Affinity vs. Testosterone: 160%${'\n'}Anabolic:Androgenic Ratio: 1: 0.5${'\n'}Human Trials: Yes, FDA approved, withdrawn from market. ${'\n'}Virilization Risk: Yes${'\n'}Storage: Room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Decanoate: 7 – 10 days${'\n'}Phenylpropionate: 2.5 days` },
      { title: 'Hormonal Effects', text: `Estrogenic: Low Aromatization rate ${'\n'}Prolactin: Yes – Nandrolone is a progestin, and${'\n'} can cause related side effects ${'\n'}SHBG Impact: Moderate${'\n'}HPTA Suppression: Extremely suppressive${'\n'}HPTA Shut Down: Yes 9${'\n'}Recovery Time: Long, 3% remains after ~50 ${'\n'}days (5x half life)` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'} Increases${'\n'}Cholesterol:${'\n'} Little effect${'\n'}Kidney: Low Toxicity${'\n'}Liver: Low Toxicity 12${'\n'}RBC/Hematocrit: Moderate Increases` },
      { title: 'Mental Health Effects', text: `Anxiety: May Reduce${'\n'}Sleep: Moderate${'\n'}Aggression: Low` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} ${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Low, 5a-reduced to ${'\n'}DHN, a weak androgen ${'\n'}Hair Growth: Possible${'\n'}Acne: Yes` },
      { title: 'Anecdotal Reports', text: `Reduced anxiety${'\n'}Reduced joint pain` },
      { title: 'Dosing Observations', text: `Prescription Dose: Normally 100 – 200mg/week,${'\n'} up to 600mg has been trialed 11,12${'\n'}Common Dosage: 300 – 600mg/week${'\n'}Frequently reported online forums, chat groups, etc.` },
      {
        title: 'Literature used',
        text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.${'\n'}${'\n'}https://doi.org/10.1016/0022-4731(81)90220-X${'\n'}https://doi.org/10.1016/s00${'\n'}06-8993(98)00148-6${'\n'}https://www.federalregister.gov/documents${'\n'}/2010/08/10/2010-19698/determination-that-${'\n'}deca-durabolin-nandrolone-${'\n'}decanoate-injection-200-milligramsmilliliter-1${'\n'}https://www.ncbi.nlm.nih.gov/pub${'\n'}med/9103484${'\n'}https://doi.org/10.1080/13697130701380434${'\n'}https://doi.org/10.1111/and.12488${'\n'}https://doi.org/10.1111/and.12488${'\n'}https://doi.org/10.1016/0026-0495(94)90246-1${'\n'}https://doi.org/10.1038/ki.1977.134${'\n'}https://doi.org/10.21037/tau.2016.03.03${'\n'}https://www.drugs.com/pro/nandrolone.html${'\n'}https://doi.org/10.1152/ajpendo.00189.2002${'\n'}https://doi.org/10.2174/${'\n'}1570159X13666141210225822${'\n'}https://www.ncbi.nlm.nih.gov/${'\n'}pmc/articles/PMC7108994/`,
      },
    ],
  },
  {
    name: 'Methenolone (‘Primobolan’)',
    id: 3,
    image: 'product3',
    data: [
      { title: 'General Information', text: `Also Known As: Primobolan , Primo, Metenolone${'\n'}Common Uses:${'\n'} Bulking, Cutting, Recomp${'\n'}Typical Administration Route: Injection, Oral${'\n'}Relative Binding Affinity vs. Testosterone: 100% 1${'\n'}Anabolic:Androgenic Ratio: 1.2:0.5 2${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, withdrawn from market${'\n'}Virilization Risk: Yes, moderate${'\n'}Storage: Room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Methenolone Enanthate: 4-7 days${'\n'}Methenolone Acetate Oral: 3.5 hours` },
      { title: 'Hormonal Effects', text: `Estrogenic: No${'\n'}Prolactin: No${'\n'}SHBG Impact: High affinity, reduces SHBG${'\n'}HPTA Suppression: Yes${'\n'}HPTA Shut Down: Likely${'\n'}Recovery Time: Up to 35 days (5x half life)` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'} low${'\n'}Cholesterol:${'\n'} ${'\n'}Kidney: ${'\n'}Liver: Low Toxicity 12${'\n'}RBC/Hematocrit: Significant Increase` },
      { title: 'Mental Health Effects', text: `Anxiety: Low${'\n'}Sleep: Low${'\n'}Aggression: Low` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Low, not 5a-reduced, and has low AR affinity, 5a-reduced to DHT${'\n'}Hair Growth: No${'\n'}Acne: Mild` },
      { title: 'Anecdotal Reports', text: `Low side effects` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 50-200mg/day oral, ${'\n'}300-900mg injected${'\n'}Frequently reported online forums, ${'\n'}chat groups, etc.${'\n'}Prescription Dose: 1-2mg/kg oral${'\n'} or 100mg/ week injected 6` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.${'\n'}${'\n'}https://doi.org/10.1210/endo-114-6-2100${'\n'}https://doi.org/10.1016/S0039-${'\n'}128X(70)80004-6${'\n'}https://doi.org/10.1210/endo-114-6-2100${'\n'}https://doi.org/10.1159/000208491${'\n'}https://doi.org/10.1159/000208491${'\n'}https://doi.org/10.1159/000208491` },
    ],
  },
  {
    name: 'Boldenone (‘Equipoise’)',
    id: 4,
    image: 'product4',
    data: [
      { title: 'General Information', text: `Also Known As: Equipoise, EQ${'\n'}Common Uses:${'\n'} Bulking, Strength${'\n'}Typical Administration Route: Injection${'\n'}Relative Binding Affinity vs. Testosterone: 140%${'\n'}Anabolic:Androgenic Ratio: 1:0.5${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: No, Animal Only. Typically used in horses.${'\n'}Virilization Risk: Yes${'\n'}Storage: Room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Long` },
      { title: 'Esters / Half-Life', text: `Undecanoate: 14 days${'\n'}Cypionate: 4-7 days` },
      { title: 'Hormonal Effects', text: `Estrogenic:  yes, aromatizes less than testosterone${'\n'}Prolactin: No${'\n'}SHBG Impact: Moderate${'\n'}HPTA Suppression: Yes${'\n'}HPTA Shut Down: Yes${'\n'}Recovery Time: Varies` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'} increases blood pressure, can affect electrolyte balance${'\n'}Cholesterol:${'\n'} no human data, likely increases LDL and decreases HDL${'\n'}Kidney: Damages kidney in rabbits${'\n'}Liver: Damages liver in rabbits${'\n'}RBC/Hematocrit:  Anecdotes and animal data shows large increase 7` },
      { title: 'Mental Health Effects', text: `Anxiety: High${'\n'}Sleep: High${'\n'}Aggression: Moderate` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, relatively high AR affinity${'\n'}Hair Growth: No${'\n'}Acne: High Risk` },
      { title: 'Anecdotal Reports', text: `Improved aerobic performance` },
      { title: 'Dosing Observations', text: `In horses: 0.5mg/lb${'\n'}Common dosage:300-600mg/week${'\n'}Frequently reported online forums, chat groups, etc.` },
      {
        title: 'Literature used',
        text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.${'\n'}${'\n'}https://doi.org/10.1111/j.1600-0463.2001.${'\n'}tb05797.x${'\n'}https://doi.org/10.1021/jm01238a007${'\n'}https://books.google.com/books?id=w${'\n'}4ZUJAdleTsC&pg=PA358#v=onepage&q&f=false${'\n'}https://doi.org/10.21037/tau.2016.07.10${'\n'}https://doi.org/10.1163/157075611X616905${'\n'}https://doi.org/10.4995/wrs.2015.2261${'\n'}https://doi.org/10.1016/j.jfda.2015.12.001${'\n'}https://www.accessdata.fda.gov/scripts/cdrh/${'\n'}cfdocs/cfcfr/CFRSearch.cfm?fr=522.204`,
      },
    ],
  },
  {
    name: 'Drostanolone (‘Masteron’)',
    id: 5,
    image: 'product5',
    data: [
      { title: 'General Information', text: `Also Known As: Masteron, Mast${'\n'}Common Uses:${'\n'} Cutting, Estrogen managment${'\n'}Typical Administration Route: Injection${'\n'}Relative Binding Affinity vs. Testosterone: Unknown${'\n'}Anabolic:Androgenic Ratio: Unknown${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, for breast cancer${'\n'}Virilization Risk: Yes${'\n'}Storage: Room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Drostanolone propionate 0.8-1 day${'\n'}Drostanolone enanthate 4-7 days` },
      { title: 'Hormonal Effects', text: `Estrogenic:  Has anti estrogenic effects${'\n'}Prolactin: No${'\n'}SHBG Impact: High impact, decreases${'\n'}HPTA Suppression: Likely${'\n'}HPTA Shut Down: Likely${'\n'}Recovery Time: up to 30 days for enanthate,${'\n'} 5 days for propionate` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} may increase blood pressure${'\n'}Cholesterol:${'\n'} likely decreases HDL, and raises LDL${'\n'}Kidney: No reported adverse effects${'\n'}Liver: No reported adverse effects${'\n'}RBC/Hematocrit:  Likely Increase` },
      { title: 'Mental Health Effects', text: `Anxiety: Low${'\n'}Sleep: Low${'\n'}Aggression: Moderate` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate-high, strong${'\n'} decrease in SHBG, unknown AR affinity${'\n'}Hair Growth: No${'\n'}Acne: Yes` },
      { title: 'Anecdotal Reports', text: `Increased hardness, vascularity${'\n'}Decreased estrogenic effect of other steroids` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: Frequently reported${'\n'} online forums, chat groups, etc.${'\n'}Prescription Dose: 200mg per week4` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'}${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.${'\n'}${'\n'}https://doi.org/10.1016/0014-2964(77)90193-1${'\n'}https://doi.org/10.1210/endo.139.2.5762${'\n'}https://doi.org/10.1016/0014-2964(77)90193-1${'\n'}https://doi.org/10.1159/000213943${'\n'}https://europepmc.org/article/med/1243528` },
    ],
  },
  {
    name: 'Trenbolone',
    id: 6,
    image: 'product6',
    data: [
      { title: 'General Information', text: `Also Known As: Tren, Finaplex${'\n'}Common Uses:${'\n'} Cutting, Bulking, Strength, Fat loss${'\n'}Typical Administration Route: Injection${'\n'}Relative Binding Affinity vs. Testosterone: 350%${'\n'}Anabolic:Androgenic Ratio: 1:1${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, unpublished${'\n'}Virilization Risk: Yes${'\n'}Storage: Room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Trenbolone enanthate 5-7days${'\n'}Trenbolone acetate 0.8 days` },
      { title: 'Hormonal Effects', text: `Estrogenic: No${'\n'}Prolactin: Yes${'\n'}SHBG Impact: Higher affinity for the progesterone receptor${'\n'} than progesterone${'\n'}HPTA Suppression: High Impact${'\n'}HPTA Shut Down: Yes${'\n'}Recovery Time: up to 30 days for enanthate,${'\n'} 5 days for acetate` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} Increases${'\n'}Cholesterol:${'\n'} likely decreases HDL, and raises LDL${'\n'}Kidney: Unknown${'\n'}Liver: Reversible elevation in liver enzymes${'\n'}RBC/Hematocrit: Significantly increases` },
      { title: 'Mental Health Effects', text: `Anxiety: High${'\n'}Sleep: High${'\n'}Aggression: High` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered ${'\n'}Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: High, greater AR affinity than DHT${'\n'}Hair Growth: No${'\n'}Acne: Yes` },
      { title: 'Anecdotal Reports', text: `"Trensomnia"${'\n'}Extreme body temperature and sweating${'\n'} while sleeping (amplified if carbs consumed${'\n'} before bed)` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 200-500mg/week${'\n'}Frequently reported online forums, chat groups, etc.` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'}${'\n'} but this does not exist for every compound. ${'\n'}For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.${'\n'}${'\n'}https://doi.org/10.1111/j.1600-04${'\n'}63.2000.tb00007.x${'\n'}https://doi.org/10.1152/ajpendo.00440.2010${'\n'}https://doi.org/10.1016/j.steroids.2014.05.024` },
    ],
  },
  {
    name: 'Oxandrolone (‘Anavar’)',
    id: 7,
    image: 'product7',
    data: [
      { title: 'General Information', text: `Also Known As: Anavar, Var${'\n'}Common Uses:${'\n'} Cutting, Recomposition, Lean tissue gain, Fat loss${'\n'}Typical Administration Route: Oral (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone: 0.01%${'\n'}Anabolic:Androgenic Ratio: 3:1${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, FDA approved for wasting${'\n'} in burn victims${'\n'}Virilization Risk: Low${'\n'}Storage: Room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `10-13 hours` },
      { title: 'Hormonal Effects', text: `Estrogenic: No${'\n'}Prolactin: No${'\n'}SHBG Impact: high impact, decreases 50-70%${'\n'}HPTA Suppression: Yes, 20mg a day reduces  free${'\n'} and total testosterone by ~50%, reduces LH by ~25%${'\n'}HPTA Shut Down: Only when combined${'\n'}Recovery Time: Varies depending on use` },
      { title: 'Organ Health Effects', text: `Blood Pressure: May Increase${'\n'}Cholesterol: Significantly decreases HDL ${'\n'}and increases LDL, ~50% change${'\n'}Kidney: Can cause kidney damage, recommend ${'\n'}monitoring creatinine${'\n'} and eGFR periodically${'\n'}Liver: Low toxicity, reversible elevation in liver enzymes${'\n'}RBC/Hematocrit: Low increase` },
      { title: 'Mental Health Effects', text: `Anxiety: Low${'\n'}Sleep: Low${'\n'}Aggression: Moderate` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, strong SHBG${'\n'} suppression which increases free DHT${'\n'}Hair Growth: Possible${'\n'}Acne: Low Risk` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Prescription dose: 5-10mg twice a day${'\n'}Minimum effective dose: 5mg twice a day${'\n'}Max studied dose: 80mg per day` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.${'\n'}${'\n'}https://doi.org/10.1210/mend.13.3.0255${'\n'}https://doi.org/10.1016/0022-4731(70)90036-1${'\n'}https://www.accessdata.fda.gov/drugsa${'\n'}tfda_docs/label/2005/013718s023lbl.pdf${'\n'}https://doi.org/10.1097/01.qai.0000197546.56131.40${'\n'}https://doi.org/10.1093/ckj/sfv032${'\n'}https://doi.org/10.1038/sc.2009.82` },
    ],
  },
  {
    name: 'Oxymetholone (‘Anadrol’)',
    id: 8,
    image: 'product8',
    data: [
      { title: 'General Information', text: `Also Known As: Anadrol, Adrol, A-Bombs${'\n'}Common Uses:${'\n'} Bulking, Strength${'\n'}Typical Administration Route: Oral (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone: <0.01%${'\n'}Anabolic:Androgenic Ratio: 3:1 (rat)${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, FDA approved for anemia${'\n'}Virilization Risk: Yes${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `4-10 hours` },
      { title: 'Hormonal Effects', text: `Estrogenic: Does not aromatize, but has ${'\n'}estrogenic activity on its own${'\n'}Prolactin: No${'\n'}SHBG Impact: High Impact${'\n'}HPTA Suppression: Yes, 50mg twice a day reduces  free${'\n'} and total testosterone by ~60%, reduces LH by ~40%${'\n'}HPTA Shut Down: Only when combined${'\n'}Recovery Time: Varies depending on use` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} Increases blood pressure, can affect electrolyte balance${'\n'}Cholesterol:${'\n'} Significantly decreases HDL and increases LDL${'\n'}Kidney:  Low toxicity${'\n'}Liver: Low toxicity, reversible${'\n'} elevation in liver enzymes${'\n'}RBC/Hematocrit: Large increase` },
      { title: 'Mental Health Effects', text: `Anxiety: Low${'\n'}Sleep: Moderate${'\n'}Aggression: -` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, strong SHBG${'\n'} suppression which increases free DHT${'\n'}Hair Growth: Possible${'\n'}Acne: Low Risk` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Prescription Dose: 1-2mg/kg${'\n'}Minimum Effective Dose: 25mg twice a day${'\n'}Max Studied Dose: 5mg/kg ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'Methandienone (‘Dianabol’)',
    id: 9,
    image: 'product9',
    data: [
      { title: 'General Information', text: `Also Known As: "DBOL", Averbol, Dianabol, Danabol,${'\n'} Methandrostenolone, Metandienone, Methandienone${'\n'}Common Uses:${'\n'} Bulking${'\n'}Typical Administration Route: Oral ${'\n'}(Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio: 1 : 0.5 (rat)${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, withdrawn from market.${'\n'}Virilization Risk: Yes${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `3 to 6 hours` },
      { title: 'Hormonal Effects', text: `Estrogenic: yes, potent effects${'\n'}Prolactin: No${'\n'}SHBG Impact: Moderate, decreases 50-70%${'\n'}HPTA Suppression: Yes, 10mg a day reduces free and total${'\n'} testosterone by ~70%${'\n'}HPTA Shut Down: Yes${'\n'}Recovery Time: Varies` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} moderate, increased estrogen can cause sodium retention,${'\n'} causing increased blood pressure${'\n'}Cholesterol:${'\n'} Significantly decreases HDL and increases LDL${'\n'}Kidney:  Low toxicity${'\n'}Liver: Low toxicity, reversible elevation in liver enzymes${'\n'}RBC/Hematocrit: Increase` },
      { title: 'Mental Health Effects', text: `Anxiety: Has Anti-Anxiety Effects${'\n'}Sleep: Low${'\n'}Aggression: Low` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, significantly decreases SHBG increasing free DHT${'\n'}Hair Growth: Possible${'\n'}Acne: Yes` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 10-20mg twice a day${'\n'}Frequently reported online forums, chat groups, etc.${'\n'}Prescription Dose: 5-10mg twice a day${'\n'}Minimum Effective Dose: 5mg twice a day` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'Fluoxymesterone (‘Halotestin’)',
    id: 10,
    image: 'product10',
    data: [
      { title: 'General Information', text: `Also Known As: Halotestin, Halo${'\n'}Common Uses:${'\n'} Strength, Bodybuilding Show Prep${'\n'}Typical Administration Route: Oral (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone: 1%${'\n'}Anabolic:Androgenic Ratio: 3-4:1 (rat)${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, FDA approved, withdrawn from market${'\n'}Virilization Risk: ?${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `9.2hrs` },
      { title: 'Hormonal Effects', text: `Estrogenic: No${'\n'}Prolactin: No${'\n'}Pair w/ SERM: No${'\n'}Pair w/ AI: No${'\n'}Pair w/ Caber: No${'\n'}SHBG Impact: High impact, decreases by 75%${'\n'}HPTA Suppression: Yes, 40mg reduces free and total testosterone ${'\n'}by ~50%, reduces LH by ~50%${'\n'}HPTA Shut Down: Yes, testosterone${'\n'} levels less than 100ng/dL${'\n'} after repeated dosing${'\n'}PCT: Maybe, some of it's suppressive effect${'\n'} is due to acting on the testicles directly, which ${'\n'}may resolve shortly after stopping${'\n'}Recovery Time: Varies` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} Strong effect, inhibits cortisol deactivating enzymes${'\n'} leading to electrolyte imbalance and hypertension${'\n'}Cholesterol:${'\n'} Significantly decreases HDL and increases LDL${'\n'}Kidney: Low toxicity${'\n'}Liver: Low-moderate toxicity, reversible${'\n'} elevation in liver enzymes${'\n'}RBC/Hematocrit: Moderate increase` },
      { title: 'Mental Health Effects', text: `Anxiety: High${'\n'}Sleep: High${'\n'}Aggression: High` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, strong SHBG${'\n'} suppression which increases free DHT${'\n'}Hair Growth: Possible${'\n'}Acne: Possible` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 5-10mg${'\n'}Frequently reported online forums, chat groups, etc.${'\n'}Max Studied Dose: 160mg in a day${'\n'}Minimum Effective Dose: 2.5mg twice a day` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'Stanozolol (‘Winstrol’)',
    id: 11,
    image: 'product11',
    data: [
      { title: 'General Information', text: `Also Known As: "Winny", Winstrol${'\n'}Common Uses:${'\n'} Cutting, Fat loss${'\n'}Typical Administration Route: Oral (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone: 0.02%${'\n'}Anabolic:Androgenic Ratio: 2-3 : 1 (rat)${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, FDA approved, withdrawn from market${'\n'}Virilization Risk: ?${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Oral: 8 Hours${'\n'}Injection (Oil Carrier): 24 hours${'\n'}Injection (Suspension): Depends on particle size,${'\n'} possibly long lasting` },
      { title: 'Hormonal Effects', text: `Estrogenic: Yes – potent effects${'\n'}Prolactin: No${'\n'}SHBG Impact: Moderate, decreases 50%${'\n'}HPTA Suppression: Yes – 10mg decreases testosterone${'\n'} by 55%${'\n'}HPTA Shut Down: No${'\n'}Recovery Time: Varies` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} may increase blood pressure${'\n'}Cholesterol:${'\n'} Decreases HDL ~50%${'\n'}Kidney: Low toxicity${'\n'}Liver: moderate, reversible changes in liver enzymes${'\n'}RBC/Hematocrit: Moderate Increase${'\n'}Glucose/Insulin: May cause insulin resistance` },
      { title: 'Mental Health Effects', text: `Anxiety: High${'\n'}Sleep: Moderate${'\n'}Aggression: Moderate` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, strong SHBG${'\n'} suppression which increases free DHT${'\n'}Hair Growth: Possible${'\n'}Acne: Possible` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 10-40mg${'\n'}Frequently reported online forums, chat groups, etc.${'\n'}Minimum Effective Dose: 2mg${'\n'}Max Studied Dose: 10mg${'\n'}Prescription Dose: 2mg twice a day` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'Methasterone (‘Superdrol’)',
    id: 12,
    image: 'product12',
    data: [
      { title: 'General Information', text: `Also Known As: Superdrol${'\n'}Common Uses:${'\n'} Bulking${'\n'}Typical Administration Route: Oral (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio: 1:1${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: No${'\n'}Virilization Risk: Yes${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `-` },
      { title: 'Hormonal Effects', text: `Estrogenic: unknown, estrogenic side effects${'\n'} commonly reported${'\n'}Prolactin: unknown, prolactin side effects ${'\n'}commonly reported${'\n'}Pair w/ SERM: Unnown${'\n'}Pair w/ AI: Unknown${'\n'}Pair w/ Caber: Unknwon${'\n'}SHBG Impact: likely has a high impact${'\n'}HPTA Suppression: likely similar to other${'\n'} 17a-alkalayted sterods${'\n'}HPTA Shut Down: likely similar to other 17a-alkalayted sterods${'\n'}Recovery Time: Varies` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} may increase blood pressure${'\n'}Cholesterol:${'\n'} Unknown likely significantly decreases HDL${'\n'} and increases LDL similar to other steroids${'\n'}Kidney: Unknown likely similar to other 17a-alkalayted steroids${'\n'}Liver: Unknown likely similar to other 17a-alkalayted steroids${'\n'}RBC/Hematocrit: Unknown likely increases` },
      { title: 'Mental Health Effects', text: `Anxiety: Moderate${'\n'}Sleep: High${'\n'}Aggression: High` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, strong SHBG${'\n'} suppression which increases free DHT${'\n'}Hair Growth: Possible${'\n'}Acne: Possible` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 10-40mg${'\n'}Frequently reported online forums, chat groups, etc.` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'Chlorodehydromethyltestosterone (‘Turinabol’)',
    id: 13,
    image: 'product13',
    data: [
      { title: 'General Information', text: `Also Known As: "TBOL", Turinabol${'\n'}Common Uses:${'\n'} bulking, cutting, recomposition${'\n'}Typical Administration Route: Oral ${'\n'}(Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone: ?${'\n'}Anabolic:Androgenic Ratio: ?${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, but very little published${'\n'}Virilization Risk: ?${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Unknown, likely similar to other 17a alkylated steroids` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} may increase blood pressure${'\n'}Cholesterol:${'\n'} Likely significantly decreases HDL and ${'\n'}increases LDL similar to other steroids${'\n'}Kidney: Unknown${'\n'}Liver: unknown likely similar to other 17a-alkalayted steroids${'\n'}RBC/Hematocrit: Likely Increases` },
      { title: 'Mental Health Effects', text: `Anxiety: Low${'\n'}Sleep: Low${'\n'}Aggression: Moderate` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: Moderate, strong SHBG${'\n'} suppression which increases free DHT${'\n'}Hair Growth: Possible${'\n'}Acne: Possible` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 10-50mg${'\n'}Frequently reported online forums, chat groups, etc.${'\n'}Max Studied Dose: ?` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'Trestolone (‘Ment’)',
    id: 14,
    image: 'product14',
    data: [
      { title: 'General Information', text: `Also Known As: "Trest", "Ment"${'\n'}Common Uses:${'\n'} Bulking, Strength${'\n'}Typical Administration Route: Oral (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone: 300%${'\n'}Anabolic:Androgenic Ratio: 1:1${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, never approved.${'\n'}Virilization Risk: Yes${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Acetate: 1 day${'\n'}Enanthate: 4 – 7 days${'\n'}Undecanoate: 10 – 12 days` },
      { title: 'Hormonal Effects', text: `Estrogenic: Yes, potent.${'\n'}Progestin: Yes, has same PR affinity as progesterone${'\n'}SHBG Impact: High${'\n'}HPTA Suppression: High${'\n'}HPTA Shut Down: Yes${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} likely increases${'\n'}Cholesterol:${'\n'} no effect at replacement doses, likely has negative${'\n'} impact at supraphysiologic doses${'\n'}Kidney: low toxicity${'\n'}Liver: unknown, likely toxic as it is both${'\n'} potent and resistant to metabolism${'\n'}RBC/Hematocrit: dose dependent increase` },
      { title: 'Mental Health Effects', text: `Anxiety: Low${'\n'}Sleep: Low${'\n'}Aggression: Low` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing among${'\n'} Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: moderate, has the same AR affinity as DHT${'\n'} but is 5a-reduced to a weaker androgen${'\n'}Hair Growth: Possible${'\n'}Acne: Possible` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 50-200mg/week${'\n'}Frequently reported online forums, chat groups, etc.${'\n'}Replacement Dose: 0.5mg/day${'\n'}Minimum Effective Dose: 0.5mg/day${'\n'}Max Studied Dose: 1mg/day` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'Mesterolone (‘Proviron’)',
    id: 15,
    image: 'product15',
    data: [
      { title: 'General Information', text: `Also Known As: Proviron${'\n'}Common Uses:${'\n'} mood, libido${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone: 35%${'\n'}Anabolic:Androgenic Ratio: (rat, binding only): 0.35 : 1.7${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials: Yes, FDA approved, withdrawn from market${'\n'}Virilization Risk: Yes${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `Oral: 12 – 13 hours${'\n'}Esters: Similar to testosterone` },
      { title: 'Hormonal Effects', text: `Estrogenic: No${'\n'}Prolactin: No${'\n'}SHBG Impact: high affinity for SHBG${'\n'}HPTA Suppression: Slightly, 200mg oral decreases${'\n'} testosterone by 8% and LH by 15%${'\n'}HPTA Shut Down: No${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'} May Increase${'\n'}Cholesterol:${'\n'} Decreases HDL${'\n'}Kidney: low toxicity${'\n'}Liver: low toxicity${'\n'}RBC/Hematocrit: significant increase${'\n'}Glucose/Insulin: may cause insulin resistance` },
      { title: 'Mental Health Effects', text: `Anxiety: ${'\n'}Sleep: ${'\n'}Aggression: ` },
      { title: 'Other Health Effects', text: `Sleep Apnea: Increased muscle mass and bodyweight can${'\n'} contribute towards a higher risk for sleep apnea${'\n'}- Increased Prevalence of Sleep-Disordered Breathing${'\n'} among Professional Football Players${'\n'}- Toronto Western Hospital Sleep Apnea Screening Tool${'\n'}Hair Loss/ MPB: low, may compete with DHT for${'\n'} SHBG increasing free DHT${'\n'}Hair Growth: ${'\n'}Acne: ` },
      { title: 'Anecdotal Reports', text: `"Currently being compiled…` },
      { title: 'Dosing Observations', text: `Commonly Used Doses: 50-300mg/day${'\n'}Frequently reported online forums, chat ${'\n'}groups, etc.${'\n'}Prescription Dose: 50mg 3 times per day${'\n'}Minimum effective dose: 25mg 2 times per day${'\n'}Max studied dose: 450mg per day 134` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
];

//Sarms screen data
const sarmsProductData = [
  {
    name: 'RAD-140 (Testolone)',
    id: 1,
    image: 'product1',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'MK-2866 (Ostarine)',
    id: 2,
    image: 'product2',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral ${'\n'}(Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'LGD-4033 (Ligandrol)',
    id: 3,
    image: 'product3',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'MK-677 (Ibutamoren)',
    id: 4,
    image: 'product4',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'YK-11',
    id: 5,
    image: 'product5',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'S4 (Andarine)',
    id: 6,
    image: 'product6',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'SR9009 (Stenabolic)',
    id: 7,
    image: 'product7',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'SR9011',
    id: 8,
    image: 'product8',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: 'S23',
    id: 9,
    image: 'product9',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
  {
    name: '',
    id: 10,
    image: 'product10',
    data: [
      { title: 'General Information', text: `Also Known As:${'\n'}Common Uses:${'\n'}${'\n'}Typical Administration Route: Oral${'\n'} (Tablet, Capsule, Powder)${'\n'}Relative Binding Affinity vs. Testosterone:${'\n'}Anabolic:Androgenic Ratio:${'\n'}Anabolic/Androgenic Ratios Explained${'\n'}Human Trials:${'\n'}Virilization Risk:${'\n'}Storage: room temp${'\n'}WADA Status: Banned${'\n'}Detection Test(s) Developed: Yes${'\n'}Detection Time: Varies` },
      { title: 'Esters / Half-Life', text: `.` },
      { title: 'Hormonal Effects', text: `Estrogenic:${'\n'}Prolactin:${'\n'}Pair w/ SERM:${'\n'}Pair w/ AI:${'\n'}Pair w/ Caber:${'\n'}SHBG Impact:${'\n'}HPTA Suppression:${'\n'}HPTA Shut Down:${'\n'}PCT:${'\n'}Recovery Time:` },
      { title: 'Organ Health Effects', text: `Blood Pressure:${'\n'}${'\n'}Cholesterol:${'\n'}${'\n'}Kidney:${'\n'}Liver:${'\n'}RBC/Hematocrit:` },
      { title: 'Mental Health Effects', text: `Anxiety:${'\n'}Sleep:${'\n'}Aggression:` },
      { title: 'Other Health Effects', text: `Sleep Apnea:${'\n'}Hair Loss/ MPB:${'\n'}Hair Growth:${'\n'}Acne:` },
      { title: 'User Reported Positive Effects', text: `.` },
      { title: 'Common Dosing Observations', text: `Prescription Dose:${'\n'}Minimum Effective Dose:${'\n'}Max Studied Dose:  ` },
      { title: 'Literature used', text: `Human studies on healthy males are preferred,${'\n'} but this does not exist for every compound.${'\n'} For these drugs, animal studies and chemical assays${'\n'} as well as anecdotes will be listed.` },
    ],
  },
];

const peptidesProductData = [
  {
    name: 'BPC-157',
    id: 1,
    image: 'product1',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'TB-500',
    id: 2,
    image: 'product2',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'CJC-DAC',
    id: 3,
    image: 'product3',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'GHK-Cu',
    id: 4,
    image: 'product4',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'GHRH’s',
    id: 5,
    image: 'product5',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'GHRP’s',
    id: 6,
    image: 'product6',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'HGH',
    id: 7,
    image: 'product7',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'HGH Fragment 176-191',
    id: 8,
    image: 'product8',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'IGF',
    id: 9,
    image: 'product9',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Melanotan',
    id: 10,
    image: 'product10',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'ModGRF/Semorelin',
    id: 11,
    image: 'product11',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Tesamorelin',
    id: 12,
    image: 'product12',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
];

const ancillariesProductData = [
  {
    name: 'Anastrozole',
    id: 1,
    image: 'product1',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Cardarine (GW-501516)',
    id: 2,
    image: 'product2',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Clomiphene',
    id: 3,
    image: 'product3',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'DNP',
    id: 4,
    image: 'product4',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Exemestane',
    id: 5,
    image: 'product5',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Finasteride',
    id: 6,
    image: 'product6',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'HCG',
    id: 7,
    image: 'product7',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Insulin',
    id: 8,
    image: 'product8',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'ITPP',
    id: 9,
    image: 'product9',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Letrozole',
    id: 10,
    image: 'product10',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Raloxifene',
    id: 11,
    image: 'product11',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Tamoxifen',
    id: 12,
    image: 'product12',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Toremifene',
    id: 13,
    image: 'product13',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'Toremifene',
    id: 14,
    image: 'product14',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
  {
    name: 'AICAR',
    id: 15,
    image: 'product15',
    data: [{ title: 'In progress', text: 'coming soon...' }],
  },
];

export { otcProductData, aasProductData, sarmsProductData, peptidesProductData, ancillariesProductData, productsData };
