import PropTypes from 'prop-types';
import React, { Component } from 'react';

//Simple bar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
//i18n
import { withTranslation } from 'react-i18next';
import { logout } from '../../utils';
//import { autofill } from 'redux-form';

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  logoutFunction() {
    console.log('logout clicked');
    logout();
    this.props.history.push('/login');
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = (item) => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current) this.refDiv.current.getScrollElement().scrollTop = currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: '100%' }} ref={this.refDiv}>
          <div id='sidebar-menu'>
            <ul className='metismenu list-unstyled' id='side-menu'>
              {/* <li className="menu-title">{this.props.t("Menu")}</li> */}

              <li>
                <Link to='/dashboard'>
                  <i className='bx bx-home-alt' />

                  {this.props.t('Home')}
                </Link>
              </li>
              {/* <li className="menu-title">{this.props.t("Apps")}</li> */}

              <li>
                <Link to='/#' className='has-arrow'>
                  <i className='bx bx-book-content' />
                  <span>{this.props.t('Education')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/otc'>{this.props.t('OTC')}</Link>
                  </li>
                  <li>
                    <Link to='/aas'>{this.props.t('AAS')}</Link>
                  </li>
                  <li>
                    <Link to='/sarms'>{this.props.t('SARMS')}</Link>
                  </li>
                  <li>
                    <Link to='/peptides'>{this.props.t('PEPTIDES')}</Link>
                  </li>
                  <li>
                    <Link to='/ancillaries'>{this.props.t('ANCILLARIES')}</Link>
                  </li>
                  <li>
                    <Link to='/rx-compounding'>{this.props.t('RX Compounding')}</Link>
                  </li>
                  {/* <li>
                    <Link to='/technology'>{this.props.t('Technology')}</Link>
                  </li> */}
                  <li>
                    <Link to='/legal-compliance'>{this.props.t('Legal Compliance')}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to='/#' className='has-arrow'>
                  <i className='bx bx-calculator' />
                  <span>{this.props.t('Tools')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/analytical-testing'>{this.props.t('Analytical Testing')}</Link>
                  </li>
                  {/* <li>
                    <Link to='/diet-builder'>{this.props.t('Diet Builder')}</Link>
                  </li> */}
                  <li>
                    <Link to='/dose-calculator'>{this.props.t('Dose Calculators')}</Link>
                  </li>
                  <li>
                    <Link to='/reconstitution-calculator'>{this.props.t('Reconstitution Calculator')}</Link>
                  </li>
                  <li>
                    <Link to='/half-life-plotter'>{this.props.t('Half Life Plotter')}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to='/#' className='has-arrow'>
                  <i className='bx bx-task' />
                  <span>{this.props.t('Performance Standard')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/CyclingStandards'>{this.props.t('Cycling Standards')}</Link>
                  </li>
                  <li>
                    <Link to='/RunningStandards'>{this.props.t('Running Standards')}</Link>
                  </li>
                  <li>
                    <Link to='/StrengthStandards'>{this.props.t('Strength Standards')}</Link>
                  </li>
                  <li>
                    <Link to='/SwimmingStandards'>{this.props.t('Swimming Standards')}</Link>
                  </li>
                </ul>
              </li>
              <li>
                {/* <Link to='https://marekhealth.com/labs/?wpam_id=2'>{this.props.t('Order Labwork')}</Link> */}

                <a href='https://marekhealth.com/labs/?wpam_id=2'>
                  <i className='bx bx bxs-report' />
                  Order Labwork
                </a>
              </li>

              {/* <li>
                <Link to='/prescription-management'>
                  

                  {this.props.t('Prescription Management')}
                </Link>
              </li> */}

              <li>
                <Link to='/user-settings'>
                  <i className='dripicons-gear' />

                  {this.props.t('User Settings')}
                </Link>
              </li>
              <li>
                <Link to='' onClick={this.logoutFunction.bind(this)}>
                  {this.props.t('Logout')}
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
