import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import ReactApexChart from 'react-apexcharts';

class KnobChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [76],
      walletOptions: {
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 5,
              size: '80%',
              background: 'transparent',
              image: void 0,
            },
            track: {
              show: !0,
              startAngle: void 0,
              endAngle: void 0,
              background: '#f2f2f2',
              strokeWidth: '80%',
              opacity: 1,
              margin: 2,
              dropShadow: {
                enabled: !1,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              name: {
                show: !0,
                fontSize: '16px',
                fontWeight: 600,
                offsetY: -10,
              },
              value: {
                show: !0,
                fontSize: '14px',
                offsetY: 4,
                formatter: function (e) {
                  return e + '%';
                },
              },
              total: {
                show: !0,
                label: 'Total',
                color: '#373d3f',
                fontSize: '16px',
                fontFamily: void 0,
                fontWeight: 600,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        colors: ['#3452e1', '#f1b44c', '#50a5f1'],
        labels: ['Ethereum', 'Bitcoin', 'Ethereum'],
        legend: { show: !1 },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div>
                  <div className='apex-charts'>
                    <ReactApexChart options={this.state.walletOptions} series={this.state.series} type='radialBar' height={302} />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default KnobChart;
