import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { isEmpty, values } from "lodash";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Row,
  FormGroup,
  CardTitle,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Cookies from "universal-cookie";
import { BrowserRouter as Router, Link } from "react-router-dom";
//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { addNewEvent, deleteEvent, getCategories, getEvents, updateEvent } from '../../store/actions';
import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import moment from "moment";
//importing knob chart
import Knob from "../../../common/helper/knob";
import KnobChart from "../../../common/helper/KnobChart";
import RadialChart from "../../../common/helper/RadialChart";
import Spinner from "../../../common/helper/spinner";

var dashboardURL = "https://www.fitdatamax.net/dashboard";
var currentDate;
var upcomingEvent;
var upcomingEventClass;
const Calender = (props) => {
  const { propEvents, categories } = props;
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [event, setEvent] = useState({});
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [dropDate, setdropDate] = useState("");
  const [dropName, setdropName] = useState("");
  const [sleepWidgetVal, setSleepVal] = useState(0);
  const [stepsWidgetVal, setStepsVal] = useState(0);
  const [recoveryWidgetVal, setRecoveryVal] = useState(0);
  const [calsWidgetVal, setCalsVal] = useState(0);
  const [userName, setUsername] = useState("");
  const [user, setUser] = useState([]);
  const [age, setAge] = useState("");
  const [dials, setDials] = useState(false);
  const [eventID, setEventID] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showUpcomingEvent, setShowUpcomingEvent] = useState(false);
  const [totalSteps, setTotalSteps] = useState(0);
  const [totalCals, setTotalCals] = useState(0);
  const [totalRecovery, setTotalRecovery] = useState(0);

  const [totalSleep, setTotalSleep] = useState(0);
  const [showRecovery, setShowRecovery] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [showRepeat, setRepeat] = useState(false);

  //new modals code data
  // const [modalJournal, setModalJournal] = useState(false);

  useEffect(() => {
    // const { onGetCategories, onGetEvents } = props;
    // onGetCategories();
    // onGetEvents();
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, []);

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modal, event]);

  useEffect(() => {
    var events; //to store all get events and filter for upcoming event
    currentDate = new Date(); // date to compare and filter for upcoming event
    var currentDay = "" + currentDate.getDate(),
      currentMonth = "" + (currentDate.getMonth() + 1),
      currentYear = currentDate.getFullYear();
    if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
    if (currentDay.length < 2) currentDay = "0" + currentDay;

    var biostrapDate = [currentYear, currentMonth, currentDay].join("-");
    console.log(currentDate);
    setUser(JSON.parse(localStorage.getItem("user")));
    setUsername(localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"));
    setAge(localStorage.getItem("age"));
    // console.log( moment(new Date().setFullYear(new Date().getFullYear() + 1)).format('YYYY-MM-DD'));
    axiosConfig
      .post(
        "caldev/getEvents",
        {
          // Setting max date as 1 year head of todays date
          timeMax: moment(new Date().setFullYear(new Date().getFullYear() + 1)).format("YYYY-MM-DD"),
          // Setting min date as 1 year previous of todays date
          timeMin: moment(new Date().setFullYear(new Date().getFullYear() + 1)).format("YYYY-MM-DD"),
          maxResults: 100,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log(response.data);
        events = response.data;
        // setEvents(temparr);
        if (localStorage.getItem("caldevAuth") == "true") {
          setEvents(
            response.data.map((event) =>
              Object.assign(event, {
                title: event.summary,
                start: event.start.dateTime,
                className:
                  event.organizer.displayName === "Injury"
                    ? "btn-danger text-white"
                    : event.organizer.displayName === "Reminder"
                    ? "btn-warning text-white"
                    : "btn-success text-white",
              })
            )
          );
        } else {
          setEvents(
            response.data.map((event) =>
              Object.assign(event, {
                title: event.summary,
                start: event.start,
                className:
                  event.calandarName === "Injury"
                    ? "btn-danger text-white"
                    : event.calandarName === "Reminder"
                    ? "btn-warning text-white"
                    : "btn-success text-white",
              })
            )
          );
        }
        //setting upcoming event
        console.log(events);
        for (let i = 0; i < events.length; i++) {
          var tempDate = new Date(events[i].start);

          if (tempDate > currentDate) {
            upcomingEvent = events[i];
            if (events[i].organizer.displayName == "Journal") {
              upcomingEventClass = "success";
            } else if (events[i].organizer.displayName == "Reminder") {
              upcomingEventClass = "warning";
            } else {
              upcomingEventClass = "danger";
            }

            setShowUpcomingEvent(true);
            break;
          }
        }
        console.log(upcomingEvent);
      })
      .catch((error) => {
        errorResponse(error);
      });

    if (localStorage.getItem("biostrapAuth") == "true") {
      axiosConfig
        .post(
          "biostrap/stepsData",
          {
            Date: biostrapDate,
            granularity: "day",
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          console.log("biostrap data");
          console.log(response.data);
          console.log("biostrap data");
          setStepsVal(response.data.steps_goal_achieved_percentage);
          setTotalSteps(response.data.metrics[3].value);
          setTotalCals(response.data.metrics[0].value);
          var calPercentage = (response.data.metrics[0].value / 3000) * 100;
          calPercentage = Math.round(calPercentage);
          console.log(calPercentage);
          setCalsVal(calPercentage);
          setDials(true);
        })
        .catch((error) => {
          errorResponse(error);
          setDials(true);
        });

      axiosConfig
        .post(
          "biostrap/getRecoveryData",
          {
            Date: biostrapDate,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          console.log("recovery data");
          console.log(response.data);
          console.log("recovery data");
          setRecoveryVal(response.data.recovery.value);
          setSleepVal(response.data.sleep.value);
          setShowRecovery(true);
        })
        .catch((error) => {
          errorResponse(error);
        });
    }

    //api calls for biostrap widgets
  }, []);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    setModal("");
  };
  const openModal = (eventType = "", date = "", name = "", eventCategories = "", notes = "", tags = "", full = "") => {
    // setdropDate(date + 'T00:00');
    date = date.slice(0, date.search("T") + 6);
    setdropDate(date);
    setdropName(name);
    setModal(eventType.length > 1 ? eventType.toLowerCase() : "journal");
    console.log(full);
    if (full.includes(dashboardURL + " ")) {
      for (let i = 0; i < eventCategories.length; i++) {
        if (eventCategories[i] != "") {
          if (document.getElementById(eventCategories[i])) {
            document.getElementById(eventCategories[i]).checked = true;
          } else {
            // document.getElementById('notes').value = full;
          }
        }
      }
      if (eventType == "Journal" || eventType == "Injury") {
        if (notes.includes("notes:")) {
          notes = notes.slice(6, notes.length);
          document.getElementById("notes").value = notes;
        } else if (notes.includes("tags:")) {
          notes = notes.slice(6, notes.length);

          document.getElementById("tags").value = notes;
        }
        if (tags != "") {
          tags = tags.slice(6, tags.length);

          document.getElementById("tags").value = tags;
        }
      } else if (eventType == "Reminder") {
        if (notes.includes("tags:")) {
          notes = notes.slice(6, notes.length);

          document.getElementById("tags").value = notes;
        }
      }
    } else {
      if (eventType != "Reminder") {
        document.getElementById("notes").value = full;
      }
    }
  };

  const closeModal = () => {
    setRepeat(false);
    setModal("");
  };

  /**
   * Handling date click on calendar
   */
  const handleDateClick = (arg) => {
    console.log(arg);
    setSelectedDay(arg);
    toggle();
    openModal("", arg.dateStr + "T00:00");
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {
    const event = arg.event;
    console.log(arg.event);
    setIsEdit(true);
    toggle();
    // openModal();
    // console.log(arg.event._def.extendedProps)
    var d = event.startStr; //.slice(0, event.startStr.search('T'));
    var desc = event._def.extendedProps.description;
    if (localStorage.getItem("caldevAuth") == "true") {
      var eventType = event._def.extendedProps.organizer.displayName;
      setEventID(event.id);
    } else {
      var eventType = event._def.extendedProps.calandarName;
      setEventID(event._def.extendedProps._id);
    }

    console.log(event);

    if (desc && desc.includes(",") && desc.includes(dashboardURL)) {
      desc = desc.split(",");
      var eventCategories = desc[1].split(".");
      console.log(eventCategories);
      if (eventType == "Journal" || eventType == "Injury") {
        if (desc.length == 4) {
          //add open modal with tag and notes
          openModal(eventType, d, event.title, eventCategories, desc[2], desc[3], desc);
        } else if (desc.length == 3) {
          // add open modal with just notes
          openModal(eventType, d, event.title, eventCategories, desc[2], "", desc);
        } else {
          openModal(eventType, d, event.title, eventCategories, "", "", desc);
        }
      } else if (eventType == "Reminder") {
        if (desc.length == 3) {
          openModal(eventType, d, event.title, eventCategories, desc[2], "", desc);
        } else {
          openModal(eventType, d, event.title, eventCategories, "", "", desc);
        }
      } else {
        openModal(eventType, d, event.title, eventCategories, "", "", desc);
      }
    } else {
      // event added from phone
      openModal(eventType, d, event.title, eventCategories, "", "", desc);
    }

    // console.log(event._def.extendedProps.organizer.displayName, event.startStr, event.title);
    console.log(desc);
    console.log(eventCategories);
  };

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e) => {
    setShowSpinner(true);
    e.preventDefault();
    var tempFlag = true; // to remove last period from eventdetail categories
    var serialize = require("form-serialize");
    var form = document.getElementById("newEventForm");
    var obj = serialize(form, { hash: true });
    //new code for recurrence start
    var isRepeat = document.getElementById("repeatEvent").checked;
    var finalApiRecurrenceString = [];
    var popupTime = document.getElementById("popupBefore").value;
    var emailTime = document.getElementById("emailBefore").value;
    if (isRepeat) {
      //2021-10-27T00:00
      //20220701T170000Z
      var repeatType = document.getElementById("repeatEventType").value;
      var repeatUntil = document.getElementById("repeatEvent-date-and-time").value.toString();
      console.log("inside checkbox");
      repeatUntil = repeatUntil.replace("-", "");
      repeatUntil = repeatUntil.replace("-", "");

      repeatUntil = repeatUntil.replace(":", "");
      repeatUntil += "00Z";
      finalApiRecurrenceString[0] = "RRULE:FREQ=" + repeatType + ";UNTIL=" + repeatUntil;
      console.log(finalApiRecurrenceString);
    } else {
      console.log("unchecked");
    }
    let eventDetails = "";
    eventDetails += dashboardURL + " " + ",";
    for (const [key, value] of Object.entries(obj)) {
      if (key == "eventName" || key == "dateTime") {
        continue;
      } else if (key == "notes" || key == "tags") {
        if (tempFlag) {
          eventDetails = eventDetails.substring(0, eventDetails.length - 1);
          tempFlag = false;
        }
        eventDetails += `,${key}: ${value}`;
      } else {
        eventDetails += `${key}.`;
      }
    }

    if (isEdit) {
      if (deleteEvent) {
        console.log("delete called");
        axiosConfig
          .post(
            "caldev/deleteEvent",
            {
              calandarName: modal.charAt(0).toUpperCase() + modal.slice(1),
              event_id: eventID,
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            console.log(response.data);
            setShowSpinner(false);
            // setApiResponse(response.data.message);

            window.location.reload();
          })
          .catch((error) => {
            setShowSpinner(false);
            errorResponse(error);
          });
      } else {
        console.log("update called");
        axiosConfig
          .post(
            "caldev/updateEvent",
            {
              description: eventDetails,
              calandarName: modal.charAt(0).toUpperCase() + modal.slice(1),
              event_id: eventID,
              eventStartTime: obj.dateTime,
              eventEndTime: obj.dateTime,
              timeZone: "Asia/Karachi",
              summary: obj.eventName,
              recurrence: finalApiRecurrenceString,
              recurrenceBoolean: isRepeat,
              popupTime: popupTime,
              emailTime: emailTime,
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            setShowSpinner(false);
            console.log(response.data);
            setApiResponse(response.data.message);

            window.location.reload();
          })
          .catch((error) => {
            setShowSpinner(false);
            errorResponse(error);
          });
      }
    } else {
      console.log("submit called");
      setShowSpinner(true);
      console.log("recurrence: ", finalApiRecurrenceString, " true :", isRepeat);
      axiosConfig
        .post(
          "caldev/addEvent",
          {
            summary: obj.eventName,
            description: eventDetails,
            eventStartTime: obj.dateTime,
            eventEndTime: obj.dateTime,
            timeZone: "Asia/Karachi",
            email: localStorage.getItem("email"),
            calandarName: modal.charAt(0).toUpperCase() + modal.slice(1),
            recurrence: finalApiRecurrenceString,
            recurrenceBoolean: isRepeat,
            popupTime: popupTime,
            emailTime: emailTime,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          setShowSpinner(false);
          console.log(response.data);
          setApiResponse(response.data.message);

          window.location.reload();
        })
        .catch((error) => {
          setShowSpinner(false);
          errorResponse(error);
        });
    }

    setSelectedDay(null);
    // toggle();
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  };

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    const { onDeleteEvent } = props;
    onDeleteEvent(event);
    setDeleteModal(false);
    toggle();
  };

  const updateEventTemp = () => {
    console.log("update event called");
  };

  /**
   * On category darg event
   */
  // const onDrag = (event) => {
  //   event.preventDefault();
  // };

  /**
   * On calendar drop event
   */
  const onDrop = useCallback((event) => {
    // console.log(event);
    openModal(event.draggedEl.innerText, event.dateStr);
    const { onAddNewEvent } = props;
    const draggedEl = event.draggedEl;
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: event.date,
      className: draggedEl.className,
    };
    // onAddNewEvent(newEvent);
  });

  return (
    <React.Fragment>
      {/* header test start */}

      {/* header test */}
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setDeleteModal(false)} />
      <div className="mt-2">
        <MetaTags>
          <title>Dashboard | Marek Health</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Home" /> */}
          {apiResponse == "Event Created" && (
            <Row>
              <Col>
                <Alert color="success" align="center">
                  Event created successfully!
                </Alert>
              </Col>
            </Row>
          )}

          {/* {localStorage.getItem('caldevAuth') != true && (
            <Row>
              <Col>
                <Alert color='warning' align='center'>
                  Alert: Your account is not linked with google.
                  <br />
                  Link now by going into{' '}
                  <Button color='primary' className='font-16 btn-block btn-sm' onClick={() => props.history.push('user-settings')}>
                    Settings
                  </Button>{' '}
                  to sync your calendar on all devices.
                </Alert>
              </Col>
            </Row>
          )} */}
          {localStorage.getItem("caldevAuth") == "true" ? (
            <></>
          ) : (
            <Row>
              <Col>
                <Alert color="warning" align="center">
                  Alert: Your account is not linked with google.
                  <br />
                  Link now by going into{" "}
                  <Button
                    color="primary"
                    className="font-16 btn-block btn-sm"
                    onClick={() => props.history.push("user-settings")}
                  >
                    Settings
                  </Button>{" "}
                  to sync your calendar on all devices.
                </Alert>
              </Col>
            </Row>
          )}

          {dials && showRecovery ? (
            <Row>
              <Col md="2" sm="2">
                <Card>
                  <div className="text-center" dir="ltr">
                    <h5 className="font-size-14 mt-3">
                      Cals In/Out
                      <br />
                      <i className="fas fa-fire" />
                    </h5>
                    <h5 className="font-size-14 mt-3">{Math.round(totalCals)}</h5>
                    <RadialChart value={Math.round(calsWidgetVal)} label="Cals In/Out" color="#556ee6" />
                  </div>
                </Card>
              </Col>
              <Col md="2">
                <Card>
                  <div className="text-center" dir="ltr">
                    <h5 className="font-size-14 mt-3">
                      Recovery
                      <br />
                      <i className="bx bx-trending-up" />
                    </h5>
                    <h5 className="font-size-14 mt-3">{recoveryWidgetVal}</h5>

                    <RadialChart value={recoveryWidgetVal} label="Recovery" color="#34c38f" />
                  </div>
                </Card>
              </Col>
              <Col md="2" sm="2">
                <Card>
                  <div className="text-center" dir="ltr">
                    <h5 className="font-size-14 mt-3">
                      Steps
                      <br />
                      <i className="fas fa-walking" />
                    </h5>
                    <h5 className="font-size-14 mt-3">{totalSteps}</h5>
                    <RadialChart value={stepsWidgetVal} label="Steps" color="#50a5f1" />
                  </div>
                </Card>
              </Col>
              <Col md="2" sm="2">
                <Card>
                  <div className="text-center" dir="ltr">
                    <h5 className="font-size-14 mt-3">
                      Sleep
                      <br />
                      <i className="fas fa-moon" />
                    </h5>
                    <h5 className="font-size-14 mt-3">{sleepWidgetVal}</h5>

                    <RadialChart value={sleepWidgetVal} label="Sleep" color="#f1b44c" />
                  </div>
                </Card>
              </Col>
              <Col>
                <Link to="/biometrics">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="7">
                          <h4>Name: {userName}</h4>
                          <h4>Age: {age}</h4>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <h6>
                            Go to Biometrics <i className="fas fa-arrow-circle-right" />
                          </h6>
                        </Col>
                        <Col md="4">
                          <div className="product-img position-relative">
                            <img
                              style={{
                                minWidth: 120,
                                minHeight: 120,
                                objectFit: "cover",
                              }}
                              src={JSON.parse(localStorage.getItem("pictureURL"))}
                              alt=""
                              height="150"
                              width="150"
                              className="rounded-circle img-thumbnail"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col className="col-12">
              <div>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={3}>
                        <Button color="primary" className="font-16 btn-block" onClick={openModal}>
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Create New Event
                        </Button>
                        {/* {showSpinner ? <Spinner /> : null} */}

                        <div id="external-events" className="mt-3">
                          <p className="text-muted">Drag and drop your event or click in the calendar</p>
                          <div
                            className={`btn-success fc-daygrid-event  external-event text-white p-1 mb-2`}
                            key={"cat-j"}
                            draggable
                            // onDrag={(event) => onDrag(event)}
                          >
                            <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                            Journal
                          </div>

                          <div
                            className={`btn-warning fc-daygrid-event external-event text-white p-1 mb-2`}
                            key={"cat-r"}
                            draggable
                            // onDrag={(event) => onDrag(event)}
                          >
                            <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                            Reminder
                          </div>

                          <div
                            className={`btn-danger fc-daygrid-event external-event text-white p-1 mb-2`}
                            key={"cat-i"}
                            draggable
                            // onDrag={(event) => onDrag(event)}
                          >
                            <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                            Injury
                          </div>

                          {categories &&
                            categories.map((category, i) => (
                              <div
                                className={`${category.type} external-event text-white p-1 mb-2`}
                                key={"cat-" + category.id}
                                draggable
                                // onDrag={(event) => onDrag(event, category)}
                              >
                                <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                                {category.title}
                              </div>
                            ))}
                        </div>
                        {/* <div>
                          <h4 className='mt-5 text-muted' align='center'>
                            Upcoming Event
                          </h4>
                          
                          {showUpcomingEvent ? (
                            <Card className='mt-3 '>
                              <CardTitle>
                                <div align='center' className={`btn-${upcomingEventClass} fc-daygrid-event external-event text-white p-1 mb-2`} key={'cat-i'}>
                                  {upcomingEvent.title}
                                </div>
                              </CardTitle>
                              <CardBody>
                                <div align='center'>
                                  <h5>{new Date(upcomingEvent.start).toString().slice(0, 15)}</h5>

                                  <h6>Type: {upcomingEvent.organizer.displayName}</h6>
                                </div>
                              </CardBody>
                            </Card>
                          ) : null}
                        </div> */}
                      </Col>
                      <Col className="col-lg-9 app-calendar">
                        {/* fullcalendar control */}
                        <FullCalendar
                          plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                          slotDuration={"00:15:00"}
                          handleWindowResize={true}
                          height={500}
                          themeSystem="bootstrap"
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                          }}
                          events={events}
                          editable={true}
                          droppable={true}
                          selectable={true}
                          dateClick={handleDateClick}
                          eventClick={handleEventClick}
                          drop={onDrop}
                        />
                        {/* New/Edit event modal */}
                        <Modal isOpen={modal.length > 0} className={props.className}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Event" : "Add Event"}
                          </ModalHeader>
                          <ModalBody>
                            <form id="newEventForm" onSubmit={(e) => handleValidEventSubmit(e)}>
                              <Row>
                                <Col className="col-12 mb-3">
                                  {/* <AvField
                                  name='title'
                                  label='Event Name'
                                  type='text'
                                  errorMessage='Invalid name'
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={event ? event.title : ''}
                                /> */}
                                  <Label className="text-secondary" htmlFor="eventName">
                                    Event Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-input"
                                    name="eventName"
                                    required={true}
                                    defaultValue={dropName}
                                  />
                                </Col>
                                <Col className="col-12 mb-3">
                                  {!isEdit && (
                                    <div className="d-flex justify-content-between">
                                      <Button
                                        color="success"
                                        className="btn-rounded"
                                        value={(event.event_category = "bg-success")}
                                        onClick={() => {
                                          setModal("journal");
                                        }}
                                        style={
                                          modal === "journal"
                                            ? {
                                                transition: "transform 300ms",
                                                transform: "scale(1.1)",
                                              }
                                            : null
                                        }
                                      >
                                        Journal Entry
                                      </Button>
                                      <Button
                                        color="warning"
                                        className="btn-rounded"
                                        value={(event.event_category = "bg-warning")}
                                        onClick={() => {
                                          setModal("reminder");
                                        }}
                                        style={
                                          modal === "reminder"
                                            ? {
                                                transition: "transform 300ms",
                                                transform: "scale(1.1)",
                                              }
                                            : null
                                        }
                                      >
                                        Reminder
                                      </Button>
                                      <Button
                                        color="danger"
                                        className="btn-rounded"
                                        value={(event.event_category = "bg-danger")}
                                        onClick={() => {
                                          setModal("injury");
                                        }}
                                        style={
                                          modal === "injury"
                                            ? {
                                                transition: "transform 300ms",
                                                transform: "scale(1.1)",
                                              }
                                            : null
                                        }
                                      >
                                        Injury Event
                                      </Button>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row style={{ gridGutterWidth: 0 }}>
                                {/* heading for modal  */}
                                <div align="center">
                                  <h3 classname="" style={{ textTransform: "capitalize" }}>
                                    {modal}
                                  </h3>
                                </div>
                                {/* Date and time */}
                                <div className="mb-3 row" align="left">
                                  <Label htmlFor="datetime" className="col-md-6 col-form-label">
                                    Date and time
                                  </Label>
                                  <div className="col-md-12">
                                    <Input
                                      name="dateTime"
                                      className="form-control"
                                      type="datetime-local"
                                      id="journal-date-and-time"
                                      defaultValue={dropDate}
                                      required
                                    />
                                  </div>
                                </div>
                              </Row>
                              <Row style={{ gridGutterWidth: 0 }}>
                                <Col md="6">
                                  <Row>
                                    <Label>Email Before(Mins)</Label>
                                  </Row>
                                  <Row>
                                    <Col md="8">
                                      <Input
                                        name="emailBefore"
                                        className="form-control  mb-1"
                                        type="number"
                                        id="emailBefore"
                                        defaultValue={30}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md="6">
                                  <Row>
                                    <Label>Popup Before(Mins)</Label>
                                  </Row>
                                  <Row>
                                    <Col md="8">
                                      <Input
                                        name="popupBefore"
                                        className="form-control  mb-1"
                                        type="number"
                                        id="popupBefore"
                                        defaultValue={30}
                                        required
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              {/* All day event check box */}
                              <Row className="no-gutters">
                                <Col>
                                  <Input
                                    type="checkbox"
                                    className="form-check-input "
                                    name="allDayEvent"
                                    value="checked"
                                  />
                                  <Label className=" text-secondary ms-2" htmlFor="allDayEvent">
                                    All Day Event
                                  </Label>
                                </Col>
                              </Row>
                              {/* New code for repeat event start */}

                              <Row className="no-gutters mt-2">
                                <Col md="4">
                                  <Input
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setRepeat(true);
                                      }
                                      if (!e.target.checked) {
                                        setRepeat(false);
                                      }
                                    }}
                                    type="checkbox"
                                    className="form-check-input "
                                    name="repeatEvent"
                                    id="repeatEvent"
                                    value="checked"
                                  />
                                  <Label className=" text-secondary ms-2" htmlFor="repeatEvent">
                                    Repeat Event
                                  </Label>
                                </Col>
                              </Row>
                              <Row>
                                <Label className="col-md-6 col-form-label">Repeat Event Until</Label>
                              </Row>
                              <Row>
                                {showRepeat ? (
                                  <Col md="4">
                                    <select required id="repeatEventType" className="form-control col-md-4 select2">
                                      <option value="DAILY" selected>
                                        Daily
                                      </option>
                                      <option value="WEEKLY">Weekly</option>
                                      <option value="MONTHLY">Monthly</option>
                                    </select>
                                  </Col>
                                ) : (
                                  <Col md="4">
                                    <select
                                      required
                                      id="repeatEventType"
                                      disabled
                                      className="form-control col-md-4 select2"
                                    >
                                      <option value="DAILY" selected>
                                        Daily
                                      </option>
                                      <option value="WEEKLY">Weekly</option>
                                      <option value="MONTHLY">Monthly</option>
                                    </select>
                                  </Col>
                                )}

                                {showRepeat ? (
                                  <Col md="6">
                                    <div className="col-md-12">
                                      <Input
                                        name=""
                                        className="form-control"
                                        type="datetime-local"
                                        id="repeatEvent-date-and-time"
                                        defaultValue={dropDate}
                                        required
                                      />
                                    </div>
                                  </Col>
                                ) : (
                                  <Col md="6">
                                    <div className="col-md-12">
                                      <Input
                                        disabled
                                        name=""
                                        className="form-control"
                                        type="datetime-local"
                                        id="repeatEvent-date-and-time"
                                        defaultValue={dropDate}
                                        required
                                      />
                                    </div>
                                  </Col>
                                )}
                              </Row>
                              {/* New code for repeat event start */}
                              <Row>
                                {modal !== "injury" ? (
                                  <>
                                    <Col>
                                      <div align="center" className="mt-3">
                                        <h5>Categories</h5>
                                      </div>

                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="injuryHealth"
                                        id="injuryHealth"
                                        value="checked"
                                        onChange={() => console.log("Clicked")}
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="injuryHealth">
                                        Injury/Health
                                      </Label>
                                      <br />
                                      <div className="ms-3 mb-2">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="biometrics"
                                          id="biometrics"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="biometrics">
                                          Biometrics
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="diagnosticLabs"
                                          id="diagnosticLabs"
                                          value="checked"
                                        />
                                        <Label
                                          className="form-check-label text-secondary ms-2"
                                          htmlFor="diagnosticLabs"
                                        >
                                          Diagnostic Labs
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="majorInjury"
                                          id="majorInjury"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="majorInjury">
                                          Major Injury
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="minorInjury"
                                          id="minorInjury"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="minorInjury">
                                          Minor Injury
                                        </Label>
                                        <br />
                                      </div>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="medications"
                                        id="medications"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="medications">
                                        Medications
                                      </Label>
                                      <br />
                                      <div className="ms-3 mb-2">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="aas"
                                          id="aas"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="aas">
                                          AAS
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="ancillary"
                                          id="ancillary"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="ancillary">
                                          Ancillary
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="otc"
                                          id="otc"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="otc">
                                          OTC
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="peptide"
                                          id="peptide"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="peptide">
                                          Peptide
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="sarm"
                                          id="sarm"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="sarm">
                                          SARM
                                        </Label>
                                        <br />
                                      </div>
                                      <div className="mb-2">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="nutrition"
                                          id="nutrition"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="nutrition">
                                          Nutrition
                                        </Label>
                                        <br />
                                      </div>

                                      <div className="mb-2">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="rehab"
                                          id="rehab"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="rehab">
                                          Rehab
                                        </Label>
                                        <br />
                                      </div>

                                      <div className="mb-2">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="supplements"
                                          id="supplements"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="supplements">
                                          Supplements
                                        </Label>
                                        <br />
                                      </div>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="training"
                                        id="training"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="training">
                                        Training
                                      </Label>
                                      <br />
                                      <div className="ms-3">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="cardio"
                                          id="cardio"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="cardio">
                                          Cardio
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="strengthHypertrophy"
                                          id="strengthHypertrophy"
                                          value="checked"
                                        />
                                        <Label
                                          className="form-check-label text-secondary ms-2"
                                          htmlFor="strengthHypertrophy"
                                        >
                                          Strength/Hypertrophy
                                        </Label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col>
                                      {modal === "journal" ? (
                                        <>
                                          <div className="mb-2 mt-3">
                                            <Label htmlFor="notes">Notes</Label>
                                            <Input type="textarea" name="notes" id="notes" />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div align="center" className="mb-2 mt-3">
                                            <h5>Reminder Type</h5>
                                          </div>
                                          <Input
                                            type="checkbox"
                                            className="form-check-input input-mini"
                                            name="medication"
                                            id="medication"
                                            value="checked"
                                          />
                                          <Label
                                            className="form-check-label text-secondary ms-2"
                                            htmlFor="reminder-medication"
                                          >
                                            Medication
                                          </Label>
                                          <br />
                                          <Input
                                            type="checkbox"
                                            className="form-check-input input-mini"
                                            name="supplement"
                                            id="supplement"
                                            value="checked"
                                          />
                                          <Label
                                            className="form-check-label text-secondary ms-2"
                                            htmlFor="reminder-supplement"
                                          >
                                            Supplement
                                          </Label>
                                          <br />
                                          <Input
                                            type="checkbox"
                                            className="form-check-input input-mini"
                                            name="biometrics"
                                            id="biometrics"
                                            value="checked"
                                          />
                                          <Label
                                            className="form-check-label text-secondary ms-2"
                                            htmlFor="reminder-biometrics"
                                          >
                                            Biometrics
                                          </Label>
                                          <br />
                                          <Input
                                            type="checkbox"
                                            className="form-check-input input-mini"
                                            name="labwork"
                                            id="labwork"
                                            value="checked"
                                          />
                                          <Label
                                            className="form-check-label text-secondary ms-2"
                                            htmlFor="reminder-labwork"
                                          >
                                            Labwork
                                          </Label>
                                          <br />
                                        </>
                                      )}
                                      <div className="mt-3">
                                        <Label htmlFor="tags">TAGS</Label>
                                        <Input type="textarea" name="tags" id="tags" />
                                      </div>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col>
                                      <div align="center" className="mt-3">
                                        <h5>Categories</h5>
                                      </div>
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="injuryHealth"
                                        id="injuryHealth"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="injuryHealth">
                                        Injury/Health
                                      </Label>

                                      <br />
                                      <div className="ms-3 mb-2">
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="majorInjury"
                                          id="majorInjury"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="majorInjury">
                                          Major Injury
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="minorInjury"
                                          id="minorInjury"
                                          value="checked"
                                        />
                                        <Label className="form-check-label text-secondary ms-2" htmlFor="minorInjury">
                                          Minor Injury
                                        </Label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="mb-2 mt-3">
                                        <Label htmlFor="notes">Notes</Label>
                                        <Input type="textarea" name="notes" id="notes" />
                                      </div>
                                      <div className="mt-3 mb-4">
                                        <Label htmlFor="tags">TAGS</Label>
                                        <Input type="textarea" name="tags" id="tags" />
                                      </div>
                                    </Col>
                                  </>
                                )}
                              </Row>

                              <Row>
                                <Col>
                                  {showSpinner && <Spinner />}
                                  <div className="text-end">
                                    <button type="button" className="btn btn-light me-2" onClick={closeModal}>
                                      Close
                                    </button>
                                    {!!isEdit && (
                                      <button
                                        type="submit"
                                        onClick={() => {
                                          setDeleteEvent(true);
                                        }}
                                        id="delete"
                                        className="btn btn-danger mx-2"
                                      >
                                        Delete
                                      </button>
                                    )}
                                    {!!isEdit && (
                                      <button type="submit" id="submit" className="btn btn-primary save-event">
                                        Update
                                      </button>
                                    )}
                                    {!isEdit && (
                                      <button type="submit" id="submit" className="btn btn-success save-event">
                                        Submit
                                      </button>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </form>
                          </ModalBody>
                        </Modal>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Calender;
