import React, { useState, useEffect } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Cookies from "universal-cookie";

import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Lottie from "../../../common/helper/lottie";
import ConditionLab from "./ConditionLab";

import pluscircle from "../../../assets/images/plus-circle.svg";
import dashcircle from "../../../assets/images/dash-circle.svg";
import Spinner from "../../../common/helper/spinner";

const EditRule = (props) => {
  const [complete, isComplete] = useState(false);
  const [ageOp, setAgeOp] = useState(props.defaultData.ageCondition.operation);
  const [genderOp, setGenderOp] = useState(
    props.defaultData.genderCondition.operation
  );

  const [message, setMessage] = useState("");
  const [labMessage, setLabMessage] = useState("");

  const [error, isError] = useState("");
  const [selectedLabTest, setSelectedLabTest] = useState(
    props.defaultData.labId
  );
  const [currentLabCategory, setCurrentLabCategory] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const [addAnotherCompound, setAddAnotherCompound] = useState(
    props.defaultData.cList
  );
  const [plusCounter, setPlusCounter] = useState(
    props.defaultData.cList.length
  );
  const [plusArray, setPlusArray] = useState([]);
  const [labList, setLabList] = useState(props.listOfLabs);

  function handleValidSubmit(_e) {
    isComplete(false);
    var serialize = require("form-serialize");
    var form = document.getElementById("addRuleInfoForm");
    var obj = serialize(form, { hash: true });

    var optimalRangeArray = [];
    var dataCondition = [];
    var runApi = true;

    for (let i = 0; i < plusCounter; i++) {
      console.log("i:", i, "pc:", plusCounter);
      var correctOptimalRange = "";

      if (
        document.getElementById("optimalRange" + i.toString()).value ===
        "Non-numeric Value"
      ) {
        correctOptimalRange = document.getElementById(
          "nonNumericValue" + i.toString()
        ).value;
        optimalRangeArray.push(correctOptimalRange);
      } else if (
        document.getElementById("optimalRange" + i.toString()).value ===
        "Greater than only"
      ) {
        if (document.getElementById("greaterRange" + i.toString()).value) {
          correctOptimalRange =
            ">" + document.getElementById("greaterRange" + i.toString()).value;
          optimalRangeArray.push(correctOptimalRange);

          isError("");
        } else {
          isError("Please fill greater value");
          runApi = false;
        }
      } else if (
        document.getElementById("optimalRange" + i.toString()).value ===
        "Less than only"
      ) {
        if (document.getElementById("lesserRange" + i.toString()).value) {
          correctOptimalRange =
            "<" + document.getElementById("lesserRange" + i.toString()).value;
          optimalRangeArray.push(correctOptimalRange);

          isError("");
        } else {
          isError("Please fill less than value");
          runApi = false;
        }
      } else if (
        document.getElementById("optimalRange" + i.toString()).value === "Range"
      ) {
        if (
          document.getElementById("greaterRange" + i.toString()).value &&
          document.getElementById("lesserRange" + i.toString()).value
        ) {
          correctOptimalRange =
            document.getElementById("greaterRange" + i.toString()).value +
            "-" +
            document.getElementById("lesserRange" + i.toString()).value;
          console.log(correctOptimalRange);

          optimalRangeArray.push(correctOptimalRange);
          isError("");
        } else {
          isError("Please fill less than and greater than values");
          runApi = false;
        }
      }

      if (
        document.getElementById("selectInternalLab" + i.toString()).value ==
        "Select Lab"
      ) {
        isError("Please select lab for lab condition");
        runApi = false;
      }

      if (runApi) {
        dataCondition.push({
          condition: "LAB",
          id: document.getElementById("selectInternalLab" + i.toString()).value,
          optimalRange: correctOptimalRange,
          operation: document.getElementById("labcondition" + i.toString())
            .value,
        });
      }
    }
    if (document.getElementById("genderOperation").value != "NONE") {
      dataCondition.push({
        condition: "Gender",
        conditionalGender: document.getElementById("genderEquality").value,
        operation: document.getElementById("genderOperation").value,
      });
    }

    if (document.getElementById("ageOperation").value != "NONE") {
      let ageRange = "";
      if (document.getElementById("ageEquality").value == "GREATER") {
        ageRange = ">" + document.getElementById("ageNumber").value.toString();
      } else if (document.getElementById("ageEquality").value == "LESS") {
        ageRange = "<" + document.getElementById("ageNumber").value.toString();
      }

      dataCondition.push({
        condition: "Age",
        conditionalAge: ageRange,
        operation: document.getElementById("ageOperation").value,
      });
    }

    console.log("Check this array: ", dataCondition);

    // change the variables to pass in the body and add array of internal loop
    var body = {
      recommendation: obj.recommendation,
      Rule_id: props.ruleId,
      labId: document.getElementById("selectLab").value,
      ruleName: obj.ruleName,
      conditionLab: dataCondition,
      entity_id: props.entityID,
    };
    if (runApi === true) {
      axiosConfig
        .post("/labBuilder/updateRule", body, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          console.log(response.data);

          closeIt();
        })
        .catch((error) => {
          errorResponse(error);

          isError(error.response.data.message);
        });
    }
  }

  function closeIt() {
    setSelectedLabTest("NONE");
    setAddAnotherCompound([]);
    setCurrentLabCategory("");
    setPlusCounter(0);
    isComplete(false);
    props.closeModal();
  }

  // function getCategoryForLab() {
  //   console.log("inside timer:", selectedLabTest);
  //   if (selectedLabTest != "NONE") {
  //     setShowSpinner(true);

  //     axiosConfig
  //       .post(
  //         "/labBuilder/getLabTestById",
  //         {
  //           labtest_id: selectedLabTest,
  //         },
  //         {
  //           headers: {
  //             Authorization: "Bearer " + new Cookies().get("user_token"),
  //           },
  //         }
  //       )
  //       .then(async (response) => {
  //         console.log(response.data);
  //         setCurrentLabCategory(response.data.Data.category_id[0]);

  //         setShowSpinner(false);
  //       })
  //       .catch((error) => {
  //         errorResponse(error);
  //         setShowSpinner(false);

  //         isError(error.response.data.message);
  //       });
  //   }
  //   //get the category id from response and call getcategorybyid to get all labs
  //   // then display the labs in the internal dropdown and call condition lab again
  // }

  // function getLabsOfCategory() {
  //   console.log("current catego: ", currentLabCategory);
  //   axiosConfig
  //     .post(
  //       "/labBuilder/getLabsByCategory",
  //       {
  //         categoryID: currentLabCategory,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + new Cookies().get("user_token"),
  //         },
  //       }
  //     )
  //     .then(async (response) => {
  //       console.log(response.data);
  //       setShowSpinner(false);
  //     })
  //     .catch((error) => {
  //       errorResponse(error);
  //       setShowSpinner(false);

  //       isError(error.response.data.message);
  //     });
  // }

  return (
    <Modal
      isOpen={props.showModal}
      size="lg"
      centered={true}
      className="smallHeightmodal"
    >
      <>
        <button
          type="button"
          onClick={closeIt}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <Container>
          <Row className="justify-content-center">
            <Card className="overflow-hidden">
              {complete ? null : (
                <>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <h2 className="text-center mt-4">Update Rule</h2>
                      <form
                        id="addRuleInfoForm"
                        onSubmit={(e) => {
                          e.preventDefault();

                          e.stopPropagation();
                          handleValidSubmit(e);
                        }}
                        className="form-horizontal px-4"
                      >
                        {error ? (
                          <Alert color="danger" className="text-center">
                            {error}
                          </Alert>
                        ) : null}
                        <div className="my-5 px-4">
                          <div>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="testName"
                                  className="form-label"
                                >
                                  Rule Name
                                  <span className="requiredStar">*</span>
                                </Label>

                                <Input
                                  name="ruleName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  defaultValue={props.defaultData.ruleName}
                                  required
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="displayTestName"
                                  className="form-label"
                                >
                                  Select Lab{" "}
                                  <span className="requiredStar">*</span>
                                </Label>
                                <select
                                  id="selectLab"
                                  type="select"
                                  onChange={() => {
                                    setLabMessage("");
                                    setSelectedLabTest(
                                      document.getElementById("selectLab").value
                                    );
                                    console.log(
                                      "outside timer:",
                                      selectedLabTest
                                    );
                                  }}
                                  name="selectLab"
                                  className="form-select"
                                  disabled={plusCounter > 0}
                                >
                                  <option
                                    style={{ color: "#495057" }}
                                    className="form-select"
                                    value={props.defaultData.labId}
                                    selected
                                  >
                                    {props.defaultData.labName}
                                  </option>
                                  {props.labData.map((item, i) => {
                                    return (
                                      <option
                                        style={{ color: "#495057" }}
                                        className="form-select"
                                        value={item.id}
                                      >
                                        {item.displayTestName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </FormGroup>
                            </Row>
                            {showSpinner ? <Spinner /> : null}

                            {/* lab condition loop starts here  */}
                            <Label className="form-label">
                              Add Another Lab Condition
                            </Label>
                            <br />
                            {labMessage ? (
                              <Alert color="warning" className="text-center">
                                {labMessage}
                              </Alert>
                            ) : null}
                            <img
                              src={pluscircle}
                              className="mb-2 cursor-pointer mr-1"
                              onClick={() => {
                                // getCategoryForLab();

                                //Add the loop for conditions here
                                // {props.defaultData ? (
                                //   <div>
                                //     {props.defaultData.conditionLab.map(
                                //       (item, i) => {
                                //         return (
                                //           <div>

                                //           </div>
                                //         );
                                //       }
                                //     )}
                                //   </div>
                                // ) : null}

                                if (selectedLabTest != "NONE") {
                                  setLabMessage("");

                                  setPlusCounter(plusCounter + 1);
                                  setAddAnotherCompound((prevValues, index) => {
                                    return [
                                      ...prevValues,

                                      <ConditionLab
                                        index={plusCounter}
                                        labs={props.labData}
                                        defaultCondition=""
                                        isEdit="false"
                                      />,
                                    ];
                                  });
                                } else {
                                  setLabMessage("Please select a lab first");
                                }
                              }}
                              alt=""
                              style={{ width: 20, height: 20 }}
                            />
                            <img
                              src={dashcircle}
                              className="mb-2 cursor-pointer mx-1"
                              onClick={() => {
                                if (selectedLabTest != "NONE") {
                                  if (plusCounter != 0) {
                                    setPlusCounter(plusCounter - 1);
                                  }
                                  setAddAnotherCompound((prevValues, index) => {
                                    return addAnotherCompound.slice(0, -1);
                                  });
                                } else {
                                  setLabMessage("Please select a lab first");
                                }
                              }}
                              alt=""
                              style={{ width: 20, height: 20 }}
                            />
                            {addAnotherCompound}

                            {/* lab condition loop ends here */}
                            <Row>
                              <Label className="form-label">Age</Label>

                              <Col>
                                <FormGroup className="mb-4">
                                  {props.defaultData.ageCondition
                                    .conditionalAge ? (
                                    <div>
                                      {props.defaultData.ageCondition
                                        .operation === "AND" ? (
                                        <select
                                          id="ageOperation"
                                          type="select"
                                          name="ageOperation"
                                          className="form-select"
                                          onChange={() => {
                                            setAgeOp(
                                              document.getElementById(
                                                "ageOperation"
                                              ).value
                                            );
                                          }}
                                        >
                                          <option
                                            style={{ color: "#495057" }}
                                            className="form-select"
                                            value="NONE"
                                          >
                                            -
                                          </option>
                                          <option
                                            style={{ color: "#495057" }}
                                            className="form-select"
                                            value="AND"
                                            selected
                                          >
                                            AND
                                          </option>
                                          <option
                                            style={{ color: "#495057" }}
                                            value="OR"
                                            className="form-select"
                                          >
                                            OR
                                          </option>
                                        </select>
                                      ) : (
                                        <select
                                          id="ageOperation"
                                          type="select"
                                          name="ageOperation"
                                          className="form-select"
                                          onChange={() => {
                                            setAgeOp(
                                              document.getElementById(
                                                "ageOperation"
                                              ).value
                                            );
                                          }}
                                        >
                                          <option
                                            style={{ color: "#495057" }}
                                            className="form-select"
                                            value="NONE"
                                          >
                                            -
                                          </option>
                                          <option
                                            style={{ color: "#495057" }}
                                            className="form-select"
                                            value="AND"
                                          >
                                            AND
                                          </option>
                                          <option
                                            style={{ color: "#495057" }}
                                            value="OR"
                                            className="form-select"
                                            selected
                                          >
                                            OR
                                          </option>
                                        </select>
                                      )}
                                    </div>
                                  ) : (
                                    <select
                                      id="ageOperation"
                                      type="select"
                                      name="ageOperation"
                                      className="form-select"
                                      onChange={() => {
                                        setAgeOp(
                                          document.getElementById(
                                            "ageOperation"
                                          ).value
                                        );
                                      }}
                                    >
                                      <option
                                        style={{ color: "#495057" }}
                                        className="form-select"
                                        value="NONE"
                                        selected
                                      >
                                        -
                                      </option>
                                      <option
                                        style={{ color: "#495057" }}
                                        className="form-select"
                                        value="AND"
                                      >
                                        AND
                                      </option>
                                      <option
                                        style={{ color: "#495057" }}
                                        value="OR"
                                        className="form-select"
                                      >
                                        OR
                                      </option>
                                    </select>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col>
                                <FormGroup className="mb-4">
                                  {props.defaultData.ageCondition
                                    .conditionalAge ? (
                                    <div>
                                      {props.defaultData.ageCondition.conditionalAge.includes(
                                        ">"
                                      ) ? (
                                        <select
                                          id="ageEquality"
                                          type="select"
                                          name="ageEquality"
                                          className="form-select"
                                          required
                                          disabled={ageOp === "NONE"}
                                        >
                                          <option
                                            style={{ color: "#495057" }}
                                            className="form-select"
                                            value="GREATER"
                                            selected
                                          >
                                            Greater than
                                          </option>
                                          <option
                                            style={{ color: "#495057" }}
                                            value="LESS"
                                            className="form-select"
                                          >
                                            Less than
                                          </option>
                                        </select>
                                      ) : (
                                        <select
                                          id="ageEquality"
                                          type="select"
                                          name="ageEquality"
                                          className="form-select"
                                          required
                                          disabled={ageOp === "NONE"}
                                        >
                                          <option
                                            style={{ color: "#495057" }}
                                            className="form-select"
                                            value="GREATER"
                                          >
                                            Greater than
                                          </option>
                                          <option
                                            style={{ color: "#495057" }}
                                            value="LESS"
                                            className="form-select"
                                            selected
                                          >
                                            Less than
                                          </option>
                                        </select>
                                      )}
                                    </div>
                                  ) : (
                                    <select
                                      id="ageEquality"
                                      type="select"
                                      name="ageEquality"
                                      className="form-select"
                                      required
                                      disabled={ageOp === "NONE"}
                                    >
                                      <option
                                        style={{ color: "#495057" }}
                                        className="form-select"
                                        value="GREATER"
                                      >
                                        Greater than
                                      </option>
                                      <option
                                        style={{ color: "#495057" }}
                                        value="LESS"
                                        className="form-select"
                                      >
                                        Less than
                                      </option>
                                    </select>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup className="mb-4">
                                  {props.defaultData.ageCondition
                                    .conditionalAge ? (
                                    <Input
                                      name="ageNumber"
                                      id="ageNumber"
                                      className="form-control"
                                      type="number"
                                      placeholder="Age"
                                      disabled={ageOp === "NONE"}
                                      defaultValue={props.defaultData.ageCondition.conditionalAge.substring(
                                        1,
                                        props.defaultData.ageCondition
                                          .conditionalAge.length
                                      )}
                                    />
                                  ) : (
                                    <Input
                                      name="ageNumber"
                                      id="ageNumber"
                                      className="form-control"
                                      type="number"
                                      placeholder="Age"
                                      disabled={ageOp === "NONE"}
                                    />
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Label className="form-label">Gender</Label>

                              <Col sm="4">
                                <FormGroup className="mb-4">
                                  {props.defaultData.genderCondition ? (
                                    <div>
                                      {props.defaultData.genderCondition
                                        .operation === "AND" ? (
                                        <div>
                                          <select
                                            id="genderOperation"
                                            type="select"
                                            name="genderOperation"
                                            className="form-select"
                                            onChange={() => {
                                              setGenderOp(
                                                document.getElementById(
                                                  "genderOperation"
                                                ).value
                                              );
                                            }}
                                          >
                                            <option
                                              style={{ color: "#495057" }}
                                              className="form-select"
                                              value="NONE"
                                            >
                                              -
                                            </option>
                                            <option
                                              style={{ color: "#495057" }}
                                              className="form-select"
                                              value="AND"
                                              selected
                                            >
                                              AND
                                            </option>
                                            <option
                                              style={{ color: "#495057" }}
                                              value="OR"
                                              className="form-select"
                                            >
                                              OR
                                            </option>
                                          </select>
                                        </div>
                                      ) : (
                                        <div>
                                          <select
                                            id="genderOperation"
                                            type="select"
                                            name="genderOperation"
                                            className="form-select"
                                            onChange={() => {
                                              setGenderOp(
                                                document.getElementById(
                                                  "genderOperation"
                                                ).value
                                              );
                                            }}
                                          >
                                            <option
                                              style={{ color: "#495057" }}
                                              className="form-select"
                                              value="NONE"
                                            >
                                              -
                                            </option>
                                            <option
                                              style={{ color: "#495057" }}
                                              className="form-select"
                                              value="AND"
                                            >
                                              AND
                                            </option>
                                            <option
                                              style={{ color: "#495057" }}
                                              value="OR"
                                              className="form-select"
                                              selected
                                            >
                                              OR
                                            </option>
                                          </select>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <select
                                        id="genderOperation"
                                        type="select"
                                        name="genderOperation"
                                        className="form-select"
                                        onChange={() => {
                                          setGenderOp(
                                            document.getElementById(
                                              "genderOperation"
                                            ).value
                                          );
                                        }}
                                      >
                                        <option
                                          style={{ color: "#495057" }}
                                          className="form-select"
                                          value="NONE"
                                          selected
                                        >
                                          -
                                        </option>
                                        <option
                                          style={{ color: "#495057" }}
                                          className="form-select"
                                          value="AND"
                                        >
                                          AND
                                        </option>
                                        <option
                                          style={{ color: "#495057" }}
                                          value="OR"
                                          className="form-select"
                                        >
                                          OR
                                        </option>
                                      </select>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col sm="4">
                                <FormGroup className="mb-4">
                                  {props.defaultData.genderCondition ? (
                                    <div>
                                      {props.defaultData.genderCondition
                                        .conditionalGender === "MALE" ? (
                                        <div>
                                          <select
                                            id="genderEquality"
                                            type="select"
                                            name="genderEquality"
                                            className="form-select"
                                            required
                                            disabled={genderOp === "NONE"}
                                          >
                                            <option
                                              style={{ color: "#495057" }}
                                              className="form-select"
                                              value="MALE"
                                              selected
                                            >
                                              Male
                                            </option>
                                            <option
                                              style={{ color: "#495057" }}
                                              value="FEMALE"
                                              className="form-select"
                                            >
                                              Female
                                            </option>
                                          </select>
                                        </div>
                                      ) : (
                                        <div>
                                          <select
                                            id="genderEquality"
                                            type="select"
                                            name="genderEquality"
                                            className="form-select"
                                            required
                                            disabled={genderOp === "NONE"}
                                          >
                                            <option
                                              style={{ color: "#495057" }}
                                              className="form-select"
                                              value="MALE"
                                            >
                                              Male
                                            </option>
                                            <option
                                              style={{ color: "#495057" }}
                                              value="FEMALE"
                                              className="form-select"
                                              selected
                                            >
                                              Female
                                            </option>
                                          </select>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      <select
                                        id="genderEquality"
                                        type="select"
                                        name="genderEquality"
                                        className="form-select"
                                        required
                                        disabled={genderOp === "NONE"}
                                      >
                                        <option
                                          style={{ color: "#495057" }}
                                          className="form-select"
                                          value="MALE"
                                        >
                                          Male
                                        </option>
                                        <option
                                          style={{ color: "#495057" }}
                                          value="FEMALE"
                                          className="form-select"
                                        >
                                          Female
                                        </option>
                                      </select>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="recommendation"
                                  className="form-label"
                                >
                                  Recommendation{" "}
                                </Label>
                                <Input
                                  name="recommendation"
                                  maxLength={3000}
                                  className="form-control"
                                  type="textarea"
                                  required
                                  defaultValue={
                                    props.defaultData.recommendation
                                  }
                                />
                              </FormGroup>
                            </Row>

                            <Row className="my-1 text-center">
                              <button
                                className="btn btn-primary darkBg mt-3 mx-auto w-50"
                                type="submit"
                              >
                                Update
                              </button>
                            </Row>
                          </div>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Row>
        </Container>
      </>
    </Modal>
  );
};

export default EditRule;
