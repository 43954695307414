import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import * as moment from 'moment';

import { Alert, Badge, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown, Modal, ModalHeader, ModalBody } from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"

class DietBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      modal: false,
    };

    // this.handleProjectClick = this.handleProjectClick.bind(this);
    // this.toggle = this.toggle.bind(this);
    // this.handleValidProjectSubmit = this.handleValidProjectSubmit.bind(this);
    // this.handleProjectClicks = this.handleProjectClicks.bind(this);
  }

  componentDidMount() {}

  //   handleProjectClicks = (arg) => {
  //     this.setState({ selectedProject: arg });
  //     this.toggle();
  //   };

  //   handleDeleteProject = (project) => {
  //     const { onDeleteProject } = this.props;
  //     onDeleteProject(project);
  //   };

  //   handleProjectClick = (arg) => {
  //     const project = arg;

  //     this.setState({
  //       projects: {
  //         id: project.id,
  //         img: project.img,
  //         name: project.name,
  //         description: project.description,
  //         status: project.status,
  //         color: project.color,
  //         dueDate: project.dueDate,
  //         team: project.team,
  //       },
  //       isEdit: true,
  //     });

  //     this.toggle();
  //   };

  //   toggle() {
  //     this.setState((prevState) => ({
  //       modal: !prevState.modal,
  //     }));
  //   }

  //   /**
  //    * Handling submit user on user form
  //    */
  //   handleValidProjectSubmit = (e, values) => {
  //     const { onAddNewProject, onUpdateProject } = this.props;
  //     const { isEdit, projects, selectedProject } = this.state;

  //     if (isEdit) {
  //       const updateProject = {
  //         id: projects.id,
  //         img: values.img,
  //         name: values.name,
  //         description: values.description,
  //         status: values.status,
  //         color: values.color,
  //         dueDate: values.dueDate,
  //         team: values.team,
  //       };

  //       // update user
  //       onUpdateProject(updateProject);
  //     } else {
  //       const newProject = {
  //         id: Math.floor(Math.random() * (30 - 20)) + 20,
  //         name: values['name'],
  //         description: values['description'],
  //         status: values['status'],
  //         color: values['color'],
  //         dueDate: values['dueDate'],
  //         team: values['team'],
  //       };
  //       // save new user
  //       onAddNewProject(newProject);
  //     }
  //     this.setState({ selectedProject: null });
  //     this.toggle();
  //   };

  //   handleValidDate = (date) => {
  //     const date1 = moment(new Date(date)).format('DD MMM Y');
  //     return date1;
  //   };

  render() {
    const { projects } = this.props;
    const { isEdit } = this.state;

    return (
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>Tools | Diet Builder</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col>
                <Alert color='danger' align='center'>
                  WARNING: THIS IS NOT MEDICAL ADVICE!
                  <br />
                  THIS INFORMATION IS FOR EDUCATIONAL PURPOSES ONLY.
                  <br />
                  DO NOT USE MEDICATIONS/SUPPLEMENTS WITHOUT THE SUPERVISION AND DIRECTION OF A MEDICAL PROFESSIONAL.
                </Alert>
              </Col>
            </Row>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs title="Tools" breadcrumbItem="Diet Builder" /> */}
            <h3>Coming Soon...</h3>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

DietBuilder.propTypes = {
  projects: PropTypes.array,
};

export default DietBuilder;
