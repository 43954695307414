import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';

// Redux
//import { connect } from "react-redux"
import { Link, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';

// action
//import { userForgetPassword } from "../../store/actions"

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo.svg';

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    //this.props.userForgetPassword(values, this.props.history)
    var getemail = document.getElementById('email').value;
    console.log(getemail);
    axiosConfig
      .post('usermanagement/forgetpassword', {
        email: getemail,
      })
      .then(async (response) => {
        console.log(response.data);
        this.props.history.push('/reset-password-message');
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className='account-pages my-5 pt-sm-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft'>
                    <Row>
                      <div className='text-primary p-4'>
                        <h3 align='center' className='text-primary'>
                          Password Recovery
                        </h3>
                        <p align='center' className='text-primary'>
                          Please confirm the email address to start the recovery process
                        </p>
                      </div>
                    </Row>
                  </div>
                  <CardBody className='pt-0'>
                    <div className='p-2'>
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color='danger' style={{ marginTop: '13px' }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}
                      {this.props.forgetSuccessMsg ? (
                        <Alert color='success' style={{ marginTop: '13px' }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm className='form-horizontal' onValidSubmit={this.handleValidSubmit}>
                        <div className='mb-3 mt-5'>
                          <AvField id='email' name='email' label='Email' className='form-control' placeholder='Enter email' type='email' required />
                        </div>
                        <div className='text-end'>
                          <button className='btn btn-primary btn-block mt-4' type='submit'>
                            Reset
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center'>
                  <p>
                    Go back to{' '}
                    <Link to='login' className='fw-medium text-primary'>
                      Login
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgetPasswordPage;
