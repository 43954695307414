import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
//import { Route, Redirect } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// action
//import { apiError, registerUser, registerUserFailed } from "../../store/actions"

// Redux
//import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Spinner from '../../common/helper/spinner';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: '',
      firstName: '',
      lastName: '',
      age: '',
      dob: '',
      showSpinner: false,
      error: null,
      showError: false,
      showMessage: false,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(e) {
    e.preventDefault();

    axiosConfig
      .post('usermanagement/register', {
        email: this.state.email,
        password: this.state.pass,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        age: this.state.age,
        dob: this.state.dob,
      })
      .then(async (response) => {
        console.log(response);
        this.setState({
          showMessage: true,
          showError: false,
        });
        setTimeout(() => {
          this.props.history.push('/user-registered');
        }, 500);
      })
      .catch((error) => {
        errorResponse(error);
        this.setState({
          error: error.response.data.message,
          showError: true,
        });
      });
  }

  componentDidMount() {
    // this.props.apiError("")
    // this.props.registerUserFailed("")
  }

  render() {
    return (
      <React.Fragment>
        <div className='home-btn d-none d-sm-block'>
          <Link to='/' className='text-dark'>
            <i className='bx bx-home h2' />
          </Link>
        </div>
        <div className='account-pages my-5 pt-sm-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft mb-3'>
                    <Row>
                      <div className='text-primary p-4'>
                        <h3 align='center' className='text-primary'>
                          Getting Started
                        </h3>
                      </div>
                    </Row>
                  </div>
                  <CardBody className='pt-0'>
                    <div className='p-2'>
                      <AvForm className='needs-validation' onValidSubmit={this.handleValidSubmit}>
                        {this.state.showMessage ? (
                          <Alert align='center' color='success'>
                            User Registered Successfully
                          </Alert>
                        ) : null}
                        {this.state.showError ? (
                          <Alert align='center' color='danger'>
                            {this.state.error}
                          </Alert>
                        ) : null}

                        <div className='mb-3'>
                          <AvField
                            id='email'
                            name='email'
                            label='Email'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            onChange={(e) => {
                              this.setState({
                                email: e.target.value,
                              });
                            }}
                            required
                          />
                        </div>

                        <div className='mb-3'>
                          <AvField
                            id='firstname'
                            name='firstname'
                            label='First Name'
                            type='text'
                            required
                            placeholder='Enter First Name'
                            onChange={(e) => {
                              this.setState({
                                firstName: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className='mb-3'>
                          <AvField
                            id='lastname'
                            name='lastname'
                            label='Last Name'
                            type='text'
                            required
                            placeholder='Enter Last Name'
                            onChange={(e) => {
                              this.setState({
                                lastName: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className='mb-3'>
                          <AvField
                            id='dob'
                            name='dob'
                            label='Date of Birth'
                            type='date'
                            required
                            placeholder='Enter DOB'
                            onChange={(e) => {
                              var d = new Date(e.target.value);
                              var currentdate = new Date();
                              var ynew = currentdate.getFullYear();
                              var mnew = currentdate.getMonth();
                              var dnew = currentdate.getDate();
                              var yold = d.getFullYear();
                              var mold = d.getMonth();
                              var dold = d.getDate();
                              var diff = ynew - yold;
                              if (mold > mnew) diff--;
                              else {
                                if (mold == mnew) {
                                  if (dold > dnew) diff--;
                                }
                              }
                              this.setState({
                                dob: e.target.value,
                                age: diff,
                              });
                            }}
                          />
                        </div>
                        <div className='mb-3'>
                          <h6>Age: {this.state.age}</h6>
                        </div>

                        <div className='mb-3'>
                          <AvField
                            id='password'
                            name='password'
                            label='Password'
                            type='password'
                            required
                            placeholder='Enter Password'
                            onChange={(e) => {
                              this.setState({
                                pass: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className='mt-4 d-grid'>
                          <button className='btn btn-primary btn-block' type='submit'>
                            Register
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center'>
                  <p>
                    Already have an account ?{' '}
                    <Link to='/login' className='fw-medium text-primary'>
                      {' '}
                      Login
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default Register;
