import React, { Component } from "react";
import PropTypes from "prop-types";
//import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
// import { } from "../../store/actions"

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import ftd from "../../assets/images/fitdatamax.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, showNav: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleNav() {
    this.setState({
      showNav: !this.state.showNav,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.width < 426 ? (
          <nav className="navbar navbar-expand-md bg-light navbar-light">
            {/* Brand  */}

            <Link to="/dashboard" className="navbar-brand">
              <span className="logo-lg">
                <img src={ftd} alt="" height="100" />
              </span>
            </Link>

            {/* <!-- Toggler/collapsibe Button --> */}

            <button
              className="navbar-toggler me-3"
              type="button"
              onClick={this.toggleNav}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* <!-- Navbar links --> */}

            <div
              id="collapsibleNavbar"
              className={
                (this.state.showNav ? "show" : "") + "collapse navbar-collapse"
              }
            >
              <SidebarContent />
            </div>
          </nav>
        ) : (
          <div className="vertical-menu">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={ftd} alt="" height="115" />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                {/* <span className="logo-sm">
                <img src={logoLightSvg} alt="" height="22" />
              </span> */}
                <span className="logo-lg">
                  <img src={ftd} alt="" height="115" />
                </span>
              </Link>
            </div>
            <div data-simplebar className="h-100">
              {this.props.type !== "condensed" ? (
                <SidebarContent />
              ) : (
                <SidebarContent />
              )}
            </div>
            <div className="sidebar-background"></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default Sidebar;
