import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  Button,
  CardTitle,
  Form,
  Row,
  Col,
  Input,
  Label,
  Table,
} from "reactstrap";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Cookies from "universal-cookie";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Apaexlinecolumn from "../../../common/helper/apaexlinecolumn";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashedLine from "../../../common/helper/dashedLine";
import SleepGraph from "../../../common/helper/sleepGraph";
import RadialChart from "../../../common/helper/RadialChart";
import Spinner from "../../../common/helper/spinner";
//for date picker
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

var steps_data = [];
var steps_xAxis = [];
var cals_data = [];
var cals_xAxis = [];
var deepSleep = [];
var totalSleep = [];
var lightSleep = [];
var sleep_xAxis = [];
var current_deepSleep,
  dshours,
  dsminutes,
  current_lightSleep,
  lshours,
  lsminutes,
  current_totalSleep,
  tshours,
  tsminutes = 0;
var distance, duration, dhours, dminutes, calories, steps;
var sleepDial, activityDial, recoveryDial;
var graphDuration = "year";
var currentDate = new Date(); // date to compare and filter for upcoming event

//fixing biostrap data
var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
//************** */
class Biometrics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGraphs: false,
      showSleepGraph: false,
      showDials: false,
      showSpinner: false,
    };
  }

  componentDidMount() {
    steps_data = [];
    steps_xAxis = [];
    cals_data = [];
    cals_xAxis = [];
    deepSleep = [];
    totalSleep = [];
    lightSleep = [];
    sleep_xAxis = [];
    this.setState({
      showSpinner: true,
    });
    graphDuration = "year";
    //Getting today's date
    var currentDay = "" + currentDate.getDate(),
      currentMonth = "" + (currentDate.getMonth() + 1),
      currentYear = currentDate.getFullYear();
    if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
    if (currentDay.length < 2) currentDay = "0" + currentDay;

    var biostrapDate = [currentYear, currentMonth, currentDay].join("-");
    this.setState({
      showSpinner: true,
    });
    axiosConfig
      .post(
        "biostrap/stepsData",
        {
          Date: biostrapDate,
          granularity: graphDuration,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        // console.log('biostrap data');
        // console.log(response.data);
        // console.log('biostrap data');
        steps = response.data.metrics[3].value;
        distance = response.data.metrics[2].value;
        duration = response.data.metrics[1].value;
        dhours = Math.floor(duration / 3600);
        dminutes = Math.floor((duration - dhours * 3600) / 60);
        calories = response.data.metrics[0].value;

        for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
          steps_data.push(response.data.metrics[3].timeseries[i].value);

          steps_xAxis.push(
            months[
              new Date(response.data.metrics[3].timeseries[i].date).getMonth()
            ]
          );
          cals_data.push(response.data.metrics[0].timeseries[i].value);
          cals_xAxis.push(
            months[
              new Date(response.data.metrics[0].timeseries[i].date).getMonth()
            ]
          );
        }

        this.setState({
          showGraphs: true,
          showSpinner: false,
        });
        // setTimeout(() => {
        //   setDials(true);
        //   console.log(calPercentage);
        // }, 2000);
      })
      .catch((error) => {
        errorResponse(error);
      });

    axiosConfig
      .post(
        "biostrap/sleepData",
        {
          Date: biostrapDate,
          granularity: graphDuration,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        // console.log('sleep data');
        // console.log(response.data);
        // console.log('sleep data');
        current_deepSleep =
          response.data.avg_sleep_detail_metrics.deep_sleep_duration_in_mins;
        dshours = Math.floor(current_deepSleep / 60);
        dsminutes = Math.floor(current_deepSleep - dshours * 60);
        current_lightSleep =
          response.data.avg_sleep_detail_metrics.light_sleep_duration_in_mins;
        lshours = Math.floor(current_lightSleep / 60);
        lsminutes = Math.floor(current_lightSleep - lshours * 60);
        current_totalSleep =
          response.data.avg_sleep_detail_metrics.total_sleep_duration_in_mins;
        tshours = Math.floor(current_totalSleep / 60);
        tsminutes = Math.floor(current_totalSleep - tshours * 60);
        // console.log(current_deepSleep, current_lightSleep);

        //adding some changes to fix date

        var month = new Date(
          response.data.sleep_detail_metric_date_datapoints[0].date
        ).getMonth();
        var ds,
          ls,
          ts = 0;

        for (
          let i = 0;
          i < response.data.sleep_detail_metric_date_datapoints.length;
          i++
        ) {
          if (
            new Date(
              response.data.sleep_detail_metric_date_datapoints[i].date
            ).getMonth() == month &&
            i < response.data.sleep_detail_metric_date_datapoints.length - 1
          ) {
            ds +=
              response.data.sleep_detail_metric_date_datapoints[i]
                .deep_sleep_duration_in_mins;
            ls +=
              response.data.sleep_detail_metric_date_datapoints[i]
                .light_sleep_duration_in_mins;
            ts +=
              response.data.sleep_detail_metric_date_datapoints[i]
                .total_sleep_duration_in_mins;
          } else {
            deepSleep.push(ds);
            lightSleep.push(ls);
            totalSleep.push(ts);
            sleep_xAxis.push(
              months[
                new Date(
                  response.data.sleep_detail_metric_date_datapoints[i - 1].date
                ).getMonth()
              ]
            );
            ds = ls = ts = 0;
            month = new Date(
              response.data.sleep_detail_metric_date_datapoints[i].date
            ).getMonth();
          }
        }
        this.setState({
          showSleepGraph: true,
          showSpinner: false,
        });
        // setTimeout(() => {
        //   setDials(true);
        //   console.log(calPercentage);
        // }, 2000);
      })
      .catch((error) => {
        errorResponse(error);
      });

    //data for the dials
    axiosConfig
      .post(
        "biostrap/getRecoveryData",
        {
          Date: biostrapDate,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        // console.log('recovery data');
        // console.log(response.data);
        // console.log('recovery data');
        activityDial = response.data.activity.value;
        sleepDial = response.data.sleep.value;
        recoveryDial = response.data.recovery.value;
        this.setState({
          showDials: true,
          showSpinner: false,
        });
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  handleDateSelect() {
    currentDate = currentDate.replace("-", "/");
    currentDate = currentDate.replace("-", "/");
    console.log(currentDate);
    this.setState({
      showGraphs: false,
      showSleepGraph: false,
      showDials: false,
      showSpinner: true,
    });

    currentDate = new Date(currentDate);
    //default variables reset
    steps_data = [];
    steps_xAxis = [];
    cals_data = [];
    cals_xAxis = [];
    deepSleep = [];
    totalSleep = [];
    lightSleep = [];
    sleep_xAxis = [];

    //rest of the component code starts
    var currentDay = "" + currentDate.getDate(),
      currentMonth = "" + (currentDate.getMonth() + 1),
      currentYear = currentDate.getFullYear();
    if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
    if (currentDay.length < 2) currentDay = "0" + currentDay;

    var biostrapDate = [currentYear, currentMonth, currentDay].join("-");
    console.log("biostrap: ", biostrapDate);

    axiosConfig
      .post(
        "biostrap/stepsData",
        {
          Date: biostrapDate,
          granularity: graphDuration,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        // console.log('biostrap data');
        // console.log(response.data);
        // console.log('biostrap data');
        steps = response.data.metrics[3].value;
        distance = response.data.metrics[2].value;
        duration = response.data.metrics[1].value;
        dhours = Math.floor(duration / 3600);
        dminutes = Math.floor((duration - dhours * 3600) / 60);
        calories = response.data.metrics[0].value;
        if (graphDuration == "year") {
          for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
            steps_data.push(response.data.metrics[3].timeseries[i].value);

            steps_xAxis.push(
              months[
                new Date(response.data.metrics[3].timeseries[i].date).getMonth()
              ]
            );
            cals_data.push(response.data.metrics[0].timeseries[i].value);
            cals_xAxis.push(
              months[
                new Date(response.data.metrics[0].timeseries[i].date).getMonth()
              ]
            );
          }
        } else {
          for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
            if (graphDuration == "day") {
              var time = response.data.metrics[3].timeseries[i].time.slice(
                response.data.metrics[3].timeseries[i].time.search("T") + 1,
                response.data.metrics[3].timeseries[i].time.length - 4
              );

              if (time < "12:00") {
                steps_data.push(response.data.metrics[3].timeseries[i].value);
                steps_xAxis.push(
                  response.data.metrics[3].timeseries[i].time.slice(
                    response.data.metrics[3].timeseries[i].time.search("T") + 1,
                    response.data.metrics[3].timeseries[i].time.length - 4
                  ) + "am"
                );
              } else {
                if (time == "13:00") {
                  time = "01:00pm";
                } else if (time == "14:00") {
                  time = "02:00pm";
                } else if (time == "15:00") {
                  time = "03:00pm";
                } else if (time == "16:00") {
                  time = "04:00pm";
                } else if (time == "17:00") {
                  time = "05:00pm";
                } else if (time == "18:00") {
                  time = "06:00pm";
                } else if (time == "19:00") {
                  time = "07:00pm";
                } else if (time == "20:00") {
                  time = "08:00pm";
                } else if (time == "21:00") {
                  time = "09:00pm";
                } else if (time == "22:00") {
                  time = "10:00pm";
                } else if (time == "23:00") {
                  time = "11:00pm";
                } else if (time == "12:00") {
                  time = "12:00pm";
                }

                steps_data.push(response.data.metrics[3].timeseries[i].value);
                steps_xAxis.push(time);
              }
            } else {
              //data for week and month
              var stepDate = new Date(
                response.data.metrics[3].timeseries[i].date
              );
              var calDate = new Date(
                response.data.metrics[0].timeseries[i].date
              );
              var tempDay, tempMonth, tempYear;
              tempDay = stepDate.getDate();
              tempMonth = stepDate.getMonth() + 1;
              tempYear = stepDate.getFullYear();

              if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
              if (tempDay.length < 2) tempDay = "0" + tempDay;
              stepDate = [tempMonth, tempDay, tempYear].join("-");
              tempDay = calDate.getDate();
              tempMonth = calDate.getMonth() + 1;
              tempYear = calDate.getFullYear();

              if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
              if (tempDay.length < 2) tempDay = "0" + tempDay;
              calDate = [tempMonth, tempDay, tempYear].join("-");

              steps_data.push(response.data.metrics[3].timeseries[i].value);
              steps_xAxis.push(stepDate);
              cals_data.push(response.data.metrics[0].timeseries[i].value);
              cals_xAxis.push(calDate);
            }
          }
        }

        this.setState({
          showGraphs: true,
          showSpinner: false,
        });
      })
      .catch((error) => {
        errorResponse(error);
      });
    if (graphDuration != "day") {
      axiosConfig
        .post(
          "biostrap/sleepData",
          {
            Date: biostrapDate,
            granularity: graphDuration,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          // console.log('sleep data');
          // console.log(response.data);
          // console.log('sleep data');
          current_deepSleep =
            response.data.avg_sleep_detail_metrics.deep_sleep_duration_in_mins;
          dshours = Math.floor(current_deepSleep / 60);
          dsminutes = Math.floor(current_deepSleep - dshours * 60);
          current_lightSleep =
            response.data.avg_sleep_detail_metrics.light_sleep_duration_in_mins;
          lshours = Math.floor(current_lightSleep / 60);
          lsminutes = Math.floor(current_lightSleep - lshours * 60);
          current_totalSleep =
            response.data.avg_sleep_detail_metrics.total_sleep_duration_in_mins;
          tshours = Math.floor(current_totalSleep / 60);
          tsminutes = Math.floor(current_totalSleep - tshours * 60);

          if (graphDuration == "year") {
            var month = new Date(
              response.data.sleep_detail_metric_date_datapoints[0].date
            ).getMonth();
            var ds,
              ls,
              ts = 0;

            for (
              let i = 0;
              i < response.data.sleep_detail_metric_date_datapoints.length;
              i++
            ) {
              if (
                new Date(
                  response.data.sleep_detail_metric_date_datapoints[i].date
                ).getMonth() == month &&
                i < response.data.sleep_detail_metric_date_datapoints.length - 1
              ) {
                ds +=
                  response.data.sleep_detail_metric_date_datapoints[i]
                    .deep_sleep_duration_in_mins;
                ls +=
                  response.data.sleep_detail_metric_date_datapoints[i]
                    .light_sleep_duration_in_mins;
                ts +=
                  response.data.sleep_detail_metric_date_datapoints[i]
                    .total_sleep_duration_in_mins;
              } else {
                deepSleep.push(ds);
                lightSleep.push(ls);
                totalSleep.push(ts);
                sleep_xAxis.push(
                  months[
                    new Date(
                      response.data.sleep_detail_metric_date_datapoints[
                        i - 1
                      ].date
                    ).getMonth()
                  ]
                );
                ds = ls = ts = 0;
                month = new Date(
                  response.data.sleep_detail_metric_date_datapoints[i].date
                ).getMonth();
              }
            }
          } else {
            for (
              let i = 0;
              i < response.data.sleep_detail_metric_date_datapoints.length;
              i++
            ) {
              var sleepDate = new Date(
                response.data.sleep_detail_metric_date_datapoints[i].date
              );

              var tempDay, tempMonth, tempYear;
              tempDay = sleepDate.getDate();
              tempMonth = sleepDate.getMonth() + 1;
              tempYear = sleepDate.getFullYear();

              if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
              if (tempDay.length < 2) tempDay = "0" + tempDay;
              sleepDate = [tempMonth, tempDay, tempYear].join("-");

              deepSleep.push(
                response.data.sleep_detail_metric_date_datapoints[i]
                  .deep_sleep_duration_in_mins
              );
              lightSleep.push(
                response.data.sleep_detail_metric_date_datapoints[i]
                  .light_sleep_duration_in_mins
              );
              totalSleep.push(
                response.data.sleep_detail_metric_date_datapoints[i]
                  .total_sleep_duration_in_mins
              );
              sleep_xAxis.push(sleepDate);
            }
          }

          this.setState({
            showSleepGraph: true,
            showSpinner: false,
          });
          // setTimeout(() => {
          //   setDials(true);
          //   console.log(calPercentage);
          // }, 2000);
        })
        .catch((error) => {
          errorResponse(error);
        });
    } else {
      sleep_xAxis = [
        "12am",
        "1am",
        "2am",
        "3am",
        "4am",
        "5am",
        "6am",
        "7am",
        "8am",
        "9am",
        "10am",
        "11am",
        "12pm",
        "1pm",
        "2pm",
        "3pm",
        "4pm",
        "5pm",
        "6pm",
        "7pm",
        "8pm",
        "9pm",
        "10pm",
        "11pm",
      ];
      deepSleep = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      lightSleep = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      totalSleep = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];

      axiosConfig
        .post(
          "biostrap/getSleepDataByDay",
          {
            Date: biostrapDate,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          // console.log('sleep day data');
          // console.log(response.data);
          // console.log('sleep day data');
          current_deepSleep =
            response.data.main_sleep_detail_metrics.deep_sleep_duration_in_mins;
          dshours = Math.floor(current_deepSleep / 60);
          dsminutes = Math.floor(current_deepSleep - dshours * 60);
          current_lightSleep =
            response.data.main_sleep_detail_metrics
              .light_sleep_duration_in_mins;
          lshours = Math.floor(current_lightSleep / 60);
          lsminutes = Math.floor(current_lightSleep - lshours * 60);
          current_totalSleep =
            response.data.main_sleep_detail_metrics
              .total_sleep_duration_in_mins;
          tshours = Math.floor(current_totalSleep / 60);
          tsminutes = Math.floor(current_totalSleep - tshours * 60);

          for (var i = 0; i < response.data.sleep_stages.length; i++) {
            var startdate = new Date(response.data.sleep_stages[i].start_time);
            var enddate = new Date(response.data.sleep_stages[i].end_time);
            // console.log(response.data.sleep_stages[i].status);
            if (response.data.sleep_stages[i].status == "light_sleep") {
              var total_minutes =
                enddate.getHours() * 60 +
                enddate.getMinutes() -
                (startdate.getHours() * 60 + startdate.getMinutes());
              lightSleep[startdate.getHours()] += total_minutes;
            } else if (response.data.sleep_stages[i].status == "deep_sleep") {
              var total_minutes =
                enddate.getHours() * 60 +
                enddate.getMinutes() -
                (startdate.getHours() * 60 + startdate.getMinutes());
              deepSleep[startdate.getHours()] += total_minutes;
            }
          }
          for (var i = 0; i < sleep_xAxis.length; i++) {
            if (
              (deepSleep[i] == 0 && lightSleep[i] == 0) ||
              deepSleep[i] < 0 ||
              lightSleep[i] < 0
            ) {
              sleep_xAxis.splice(i, 1);
              deepSleep.splice(i, 1);
              lightSleep.splice(i, 1);
              totalSleep.splice(i, 1);
              i -= 1;
              // console.log(deepSleep, sleep_xAxis);
            }
            totalSleep[i] = deepSleep[i] + lightSleep[i];
          }

          this.setState({
            showSleepGraph: true,
            showSpinner: false,
          });
        })
        .catch((error) => {
          errorResponse(error);
        });
    }

    //data for the dials
    axiosConfig
      .post(
        "biostrap/getRecoveryData",
        {
          Date: biostrapDate,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        // console.log('recovery data');
        // console.log(response.data);
        // console.log('recovery data');
        activityDial = response.data.activity.value;
        sleepDial = response.data.sleep.value;
        recoveryDial = response.data.recovery.value;
        this.setState({
          showDials: true,
          showSpinner: false,
        });
      })
      .catch((error) => {
        errorResponse(error);
      });
    //rest of the component code ends
  }

  changeDuration() {
    // console.log('inside change');
    this.setState({
      showGraphs: false,
      showSleepGraph: false,
      showDials: false,
      showSpinner: true,
    });

    setTimeout(() => {
      steps_data = [];
      steps_xAxis = [];
      cals_data = [];
      cals_xAxis = [];
      deepSleep = [];
      totalSleep = [];
      lightSleep = [];
      sleep_xAxis = [];
      currentDate = new Date(currentDate);
      //default variables reset

      //rest of the component code starts
      var currentDay = "" + currentDate.getDate(),
        currentMonth = "" + (currentDate.getMonth() + 1),
        currentYear = currentDate.getFullYear();
      if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
      if (currentDay.length < 2) currentDay = "0" + currentDay;

      var biostrapDate = [currentYear, currentMonth, currentDay].join("-");

      axiosConfig
        .post(
          "biostrap/stepsData",
          {
            Date: biostrapDate,
            granularity: graphDuration,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          // console.log('biostrap data');
          // console.log(response.data);
          // console.log('biostrap data');
          steps = response.data.metrics[3].value;
          distance = response.data.metrics[2].value;
          duration = response.data.metrics[1].value;
          dhours = Math.floor(duration / 3600);
          dminutes = Math.floor((duration - dhours * 3600) / 60);
          calories = response.data.metrics[0].value;

          if (graphDuration == "year") {
            for (
              let i = 0;
              i < response.data.metrics[3].timeseries.length;
              i++
            ) {
              steps_data.push(response.data.metrics[3].timeseries[i].value);

              steps_xAxis.push(
                months[
                  new Date(
                    response.data.metrics[3].timeseries[i].date
                  ).getMonth()
                ]
              );
              cals_data.push(response.data.metrics[0].timeseries[i].value);
              cals_xAxis.push(
                months[
                  new Date(
                    response.data.metrics[0].timeseries[i].date
                  ).getMonth()
                ]
              );
            }
          } else {
            for (
              let i = 0;
              i < response.data.metrics[3].timeseries.length;
              i++
            ) {
              if (graphDuration == "day") {
                var time = response.data.metrics[3].timeseries[i].time.slice(
                  response.data.metrics[3].timeseries[i].time.search("T") + 1,
                  response.data.metrics[3].timeseries[i].time.length - 4
                );
                // console.log(time - '12:00');
                if (time < "12:00") {
                  steps_data.push(response.data.metrics[3].timeseries[i].value);
                  steps_xAxis.push(
                    response.data.metrics[3].timeseries[i].time.slice(
                      response.data.metrics[3].timeseries[i].time.search("T") +
                        1,
                      response.data.metrics[3].timeseries[i].time.length - 4
                    ) + "am"
                  );
                } else {
                  if (time == "13:00") {
                    time = "01:00pm";
                  } else if (time == "14:00") {
                    time = "02:00pm";
                  } else if (time == "15:00") {
                    time = "03:00pm";
                  } else if (time == "16:00") {
                    time = "04:00pm";
                  } else if (time == "17:00") {
                    time = "05:00pm";
                  } else if (time == "18:00") {
                    time = "06:00pm";
                  } else if (time == "19:00") {
                    time = "07:00pm";
                  } else if (time == "20:00") {
                    time = "08:00pm";
                  } else if (time == "21:00") {
                    time = "09:00pm";
                  } else if (time == "22:00") {
                    time = "10:00pm";
                  } else if (time == "23:00") {
                    time = "11:00pm";
                  } else if (time == "12:00") {
                    time = "12:00pm";
                  }

                  steps_data.push(response.data.metrics[3].timeseries[i].value);
                  steps_xAxis.push(time);
                }
              } else {
                //week or month
                var stepDate = new Date(
                  response.data.metrics[3].timeseries[i].date
                );
                var calDate = new Date(
                  response.data.metrics[0].timeseries[i].date
                );
                var tempDay, tempMonth, tempYear;
                tempDay = stepDate.getDate();
                tempMonth = stepDate.getMonth() + 1;
                tempYear = stepDate.getFullYear();

                if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
                if (tempDay.length < 2) tempDay = "0" + tempDay;
                stepDate = [tempMonth, tempDay, tempYear].join("-");
                tempDay = calDate.getDate();
                tempMonth = calDate.getMonth() + 1;
                tempYear = calDate.getFullYear();

                if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
                if (tempDay.length < 2) tempDay = "0" + tempDay;
                calDate = [tempMonth, tempDay, tempYear].join("-");

                steps_data.push(response.data.metrics[3].timeseries[i].value);
                steps_xAxis.push(stepDate);
                cals_data.push(response.data.metrics[0].timeseries[i].value);
                cals_xAxis.push(calDate);
              }
            }
          }

          this.setState({
            showGraphs: true,
            showSpinner: false,
          });
        })
        .catch((error) => {
          errorResponse(error);
        });

      if (graphDuration != "day") {
        axiosConfig
          .post(
            "biostrap/sleepData",
            {
              Date: biostrapDate,
              granularity: graphDuration,
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            // console.log('sleep data');
            // console.log(response.data);
            // console.log('sleep data');
            current_deepSleep =
              response.data.avg_sleep_detail_metrics
                .deep_sleep_duration_in_mins;
            dshours = Math.floor(current_deepSleep / 60);
            dsminutes = Math.floor(current_deepSleep - dshours * 60);
            current_lightSleep =
              response.data.avg_sleep_detail_metrics
                .light_sleep_duration_in_mins;
            lshours = Math.floor(current_lightSleep / 60);
            lsminutes = Math.floor(current_lightSleep - lshours * 60);
            current_totalSleep =
              response.data.avg_sleep_detail_metrics
                .total_sleep_duration_in_mins;
            tshours = Math.floor(current_totalSleep / 60);
            tsminutes = Math.floor(current_totalSleep - tshours * 60);
            if (graphDuration == "year") {
              var month = new Date(
                response.data.sleep_detail_metric_date_datapoints[0].date
              ).getMonth();
              var ds,
                ls,
                ts = 0;

              for (
                let i = 0;
                i < response.data.sleep_detail_metric_date_datapoints.length;
                i++
              ) {
                if (
                  new Date(
                    response.data.sleep_detail_metric_date_datapoints[i].date
                  ).getMonth() == month &&
                  i <
                    response.data.sleep_detail_metric_date_datapoints.length - 1
                ) {
                  ds +=
                    response.data.sleep_detail_metric_date_datapoints[i]
                      .deep_sleep_duration_in_mins;
                  ls +=
                    response.data.sleep_detail_metric_date_datapoints[i]
                      .light_sleep_duration_in_mins;
                  ts +=
                    response.data.sleep_detail_metric_date_datapoints[i]
                      .total_sleep_duration_in_mins;
                } else {
                  deepSleep.push(ds);
                  lightSleep.push(ls);
                  totalSleep.push(ts);
                  sleep_xAxis.push(
                    months[
                      new Date(
                        response.data.sleep_detail_metric_date_datapoints[
                          i - 1
                        ].date
                      ).getMonth()
                    ]
                  );
                  ds = ls = ts = 0;
                  month = new Date(
                    response.data.sleep_detail_metric_date_datapoints[i].date
                  ).getMonth();
                }
              }
            } else {
              for (
                let i = 0;
                i < response.data.sleep_detail_metric_date_datapoints.length;
                i++
              ) {
                var sleepDate = new Date(
                  response.data.sleep_detail_metric_date_datapoints[i].date
                );

                var tempDay, tempMonth, tempYear;
                tempDay = sleepDate.getDate();
                tempMonth = sleepDate.getMonth() + 1;
                tempYear = sleepDate.getFullYear();

                if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
                if (tempDay.length < 2) tempDay = "0" + tempDay;
                sleepDate = [tempMonth, tempDay, tempYear].join("-");

                deepSleep.push(
                  response.data.sleep_detail_metric_date_datapoints[i]
                    .deep_sleep_duration_in_mins
                );
                lightSleep.push(
                  response.data.sleep_detail_metric_date_datapoints[i]
                    .light_sleep_duration_in_mins
                );
                totalSleep.push(
                  response.data.sleep_detail_metric_date_datapoints[i]
                    .total_sleep_duration_in_mins
                );
                sleep_xAxis.push(sleepDate);
              }
            }
            this.setState({
              showSleepGraph: true,
              showSpinner: false,
            });
            // setTimeout(() => {
            //   setDials(true);
            //   console.log(calPercentage);
            // }, 2000);
          })
          .catch((error) => {
            errorResponse(error);
          });
      } else {
        sleep_xAxis = [
          "12am",
          "1am",
          "2am",
          "3am",
          "4am",
          "5am",
          "6am",
          "7am",
          "8am",
          "9am",
          "10am",
          "11am",
          "12pm",
          "1pm",
          "2pm",
          "3pm",
          "4pm",
          "5pm",
          "6pm",
          "7pm",
          "8pm",
          "9pm",
          "10pm",
          "11pm",
        ];
        deepSleep = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0,
        ];
        lightSleep = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0,
        ];
        totalSleep = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0,
        ];

        axiosConfig
          .post(
            "biostrap/getSleepDataByDay",
            {
              Date: biostrapDate,
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            // console.log('sleep day data');
            // console.log(response.data);
            // console.log('sleep day data');
            current_deepSleep =
              response.data.main_sleep_detail_metrics
                .deep_sleep_duration_in_mins;
            dshours = Math.floor(current_deepSleep / 60);
            dsminutes = Math.floor(current_deepSleep - dshours * 60);
            current_lightSleep =
              response.data.main_sleep_detail_metrics
                .light_sleep_duration_in_mins;
            lshours = Math.floor(current_lightSleep / 60);
            lsminutes = Math.floor(current_lightSleep - lshours * 60);
            current_totalSleep =
              response.data.main_sleep_detail_metrics
                .total_sleep_duration_in_mins;
            tshours = Math.floor(current_totalSleep / 60);
            tsminutes = Math.floor(current_totalSleep - tshours * 60);

            for (var i = 0; i < response.data.sleep_stages.length; i++) {
              var startdate = new Date(
                response.data.sleep_stages[i].start_time
              );
              var enddate = new Date(response.data.sleep_stages[i].end_time);
              // console.log(response.data.sleep_stages[i].status);
              if (response.data.sleep_stages[i].status == "light_sleep") {
                var total_minutes =
                  enddate.getHours() * 60 +
                  enddate.getMinutes() -
                  (startdate.getHours() * 60 + startdate.getMinutes());
                lightSleep[startdate.getHours()] += total_minutes;
              } else if (response.data.sleep_stages[i].status == "deep_sleep") {
                var total_minutes =
                  enddate.getHours() * 60 +
                  enddate.getMinutes() -
                  (startdate.getHours() * 60 + startdate.getMinutes());
                deepSleep[startdate.getHours()] += total_minutes;
              }
            }
            for (var i = 0; i < sleep_xAxis.length; i++) {
              if (
                (deepSleep[i] == 0 && lightSleep[i] == 0) ||
                deepSleep[i] < 0 ||
                lightSleep[i] < 0
              ) {
                sleep_xAxis.splice(i, 1);
                deepSleep.splice(i, 1);
                lightSleep.splice(i, 1);
                totalSleep.splice(i, 1);
                i -= 1;
              }
              totalSleep[i] = deepSleep[i] + lightSleep[i];
            }

            this.setState({
              showSleepGraph: true,
              showSpinner: false,
            });
          })
          .catch((error) => {
            errorResponse(error);
          });
      }

      //data for the dials
      axiosConfig
        .post(
          "biostrap/getRecoveryData",
          {
            Date: biostrapDate,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          // console.log('recovery data');
          // console.log(response.data);
          // console.log('recovery data');
          activityDial = response.data.activity.value;
          sleepDial = response.data.sleep.value;
          recoveryDial = response.data.recovery.value;
          this.setState({
            showDials: true,
            showSpinner: false,
          });
        })
        .catch((error) => {
          errorResponse(error);
        });
    }, 1000);
  }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col md="5" className="mt-5 mx-2">
            <AvForm>
              <Row>
                <Col>
                  <AvField
                    selected={currentDate}
                    id="date"
                    name="date"
                    label="Select Date"
                    type="date"
                    placeholder=""
                    onChange={(e) => {
                      currentDate = e.target.value;
                      console.log(currentDate);
                    }}
                  />{" "}
                </Col>
                <Col>
                  <button
                    type="submit"
                    id="submit"
                    className="btn btn-primary mt-4"
                    onClick={this.handleDateSelect.bind(this)}
                  >
                    Enter
                  </button>
                </Col>
              </Row>
            </AvForm>
            {/* <DatePicker
              selected={currentDate}
              onChange={(date) => {
                currentDate = date;
                this.dateChange.bind(this);
              }}
            /> */}
          </Col>
          <Col align="right" className="mt-5 mx-2">
            <div
              className="btn-group mt-2 mt-xl-0"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                autoComplete="off"
                defaultChecked
                value="year"
                onChange={(e) => {
                  graphDuration = e.target.value;
                  // console.log(graphDuration);
                }}
                onClick={this.changeDuration.bind(this)}
              />
              <label className="btn btn-primary" htmlFor="btnradio1">
                Year
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
                autoComplete="off"
                value="month"
                onChange={(e) => {
                  graphDuration = e.target.value;
                  // console.log(graphDuration);
                }}
                onClick={this.changeDuration.bind(this)}
              />
              <label className="btn btn-primary" htmlFor="btnradio2">
                Month
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio3"
                autoComplete="off"
                value="week"
                onChange={(e) => {
                  graphDuration = e.target.value;
                  // console.log(graphDuration);
                }}
                onClick={this.changeDuration.bind(this)}
              />
              <label className="btn btn-primary" htmlFor="btnradio3">
                Week
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio4"
                autoComplete="off"
                value="day"
                onChange={(e) => {
                  graphDuration = e.target.value;
                  // console.log(graphDuration);
                }}
                onClick={this.changeDuration.bind(this)}
              />
              <label className="btn btn-primary" htmlFor="btnradio4">
                Day
              </label>
            </div>
          </Col>
        </Row>

        <Row>
          <Card className="my-2">
            {this.state.showDials && (
              <Row>
                <Col md="4">
                  <RadialChart
                    value={activityDial}
                    label="Activity"
                    color="#50a5f1"
                  />{" "}
                </Col>
                <Col md="4">
                  <RadialChart
                    value={recoveryDial}
                    label="Recovery"
                    color="#34c38f"
                  />{" "}
                </Col>
                <Col md="4">
                  <RadialChart
                    value={sleepDial}
                    label="Sleep"
                    color="#f1b44c"
                  />{" "}
                </Col>
              </Row>
            )}
          </Card>
        </Row>
        {this.state.showSpinner && <Spinner />}

        <Row>
          <Card className="my-2">
            {this.state.showGraphs && (
              <Row>
                <Col md="9">
                  <Row>
                    <div align="center" className="mt-2">
                      <h5 className=" text-muted">Steps</h5>
                    </div>
                  </Row>
                  <Apaexlinecolumn
                    data={steps_data}
                    xAxis={steps_xAxis}
                    title="Steps"
                  />{" "}
                </Col>
                <Col md="3">
                  <Row>
                    <Col className="mt-5" md="6">
                      <div className="mt-5" align="center">
                        <h5 className="text-muted">{Math.round(steps)}</h5>
                        <h6 className="text-muted">Steps</h6>
                      </div>
                    </Col>
                    <Col className="mt-5" md="6">
                      <div className="mt-5" align="center">
                        <h5 className="text-muted">
                          {Math.round(distance)} KM
                        </h5>
                        <h6 className="text-muted">Distance</h6>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md="6">
                      <div className="mt-3" align="center">
                        <h5 className="text-muted">
                          {dhours} H {dminutes} M
                        </h5>

                        <h6 className="text-muted">Duration</h6>
                      </div>
                    </Col>
                    <Col className="" md="6">
                      <div className="mt-3" align="center">
                        <h5 className="text-muted">
                          {Math.round(calories)} KCAL
                        </h5>

                        <h6 className="text-muted">Calories</h6>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Card>
        </Row>
        {graphDuration != "day" && (
          <Row>
            <Card className="my-2">
              {this.state.showGraphs && (
                <Row>
                  <Col md="9">
                    <Row>
                      <div align="center" className="mt-2">
                        <h5 className=" text-muted">Calories</h5>
                      </div>
                    </Row>
                    <DashedLine data={cals_data} xAxis={cals_xAxis} title="" />{" "}
                  </Col>
                  <Col md="3">
                    <Row align="center" className="mt-5">
                      <br />
                      <br />
                      <h6 className="text-muted mt-5">
                        Goal <h3 className="text-muted">3000</h3>{" "}
                      </h6>
                      <h6 className="text-muted">
                        Cals In/Out{" "}
                        <h3 className="text-muted">{Math.round(calories)}</h3>{" "}
                      </h6>
                    </Row>
                  </Col>
                </Row>
              )}
            </Card>
          </Row>
        )}

        <Row>
          <Card className="my-2">
            {this.state.showSleepGraph && (
              <Row>
                <Col md="9">
                  <Row>
                    <div align="center" className="mt-2">
                      <h5 className=" text-muted">Sleep</h5>
                    </div>
                  </Row>
                  <SleepGraph
                    deepSleep={deepSleep}
                    lightSleep={lightSleep}
                    totalSleep={totalSleep}
                    xAxis={sleep_xAxis}
                    title="Sleep"
                    h1="Deep Sleep"
                    h2="Light Sleep"
                    h3="Total Sleep"
                  />{" "}
                </Col>
                <Col md="3">
                  <Row>
                    <Col className="mt-5" md="6">
                      <div className="mt-5" align="center">
                        <h5 className="text-muted">
                          {dshours} H {dsminutes} M{" "}
                        </h5>

                        <h6 className="text-muted">Deep Sleep</h6>
                      </div>
                    </Col>
                    <Col className="mt-5" md="6">
                      <div className="mt-5" align="center">
                        <h5 className="text-muted">
                          {lshours} H {lsminutes} M
                        </h5>

                        <h6 className="text-muted">Light Sleep</h6>
                      </div>
                    </Col>
                  </Row>

                  <Row align="center" className="mt-4">
                    <h5 className="text-muted">
                      {tshours} H {tsminutes} M
                    </h5>
                    <br />
                    <h6 className="text-muted">Total Sleep</h6>
                  </Row>
                </Col>
              </Row>
            )}
          </Card>
        </Row>
      </React.Fragment>
    );
  }
}

export default Biometrics;
