import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, Alert, CardBody, CardHeader, CardSubtitle, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

//Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb"

//Import images
import cr1 from '../../../assets/images/cr1.png';
import cr2 from '../../../assets/images/cr2.png';
import cr3 from '../../../assets/images/cr3.png';
import cr4 from '../../../assets/images/cr4.png';

import classnames from 'classnames';

class technology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      activeTab1: '5',
      activeTab2: '9',
      activeTab3: '13',
      verticalActiveTab: '1',
      verticalActiveTabWithIcon: '1',
      customActiveTab: '1',
      customIconActiveTab: '1',
      activeTabJustify: '5',

      col9: false,
      col10: false,
      col11: false,
      col12: false,
      col13: false,
      col14: false,
      col15: false,
      col16: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);

    this.t_col9 = this.t_col9.bind(this);
    this.t_col10 = this.t_col10.bind(this);
    this.t_col11 = this.t_col11.bind(this);
    this.t_col12 = this.t_col12.bind(this);
    this.t_col13 = this.t_col13.bind(this);
    this.t_col14 = this.t_col14.bind(this);
    this.t_col15 = this.t_col15.bind(this);
    this.t_col16 = this.t_col16.bind(this);

    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);

    this.toggleVertical = this.toggleVertical.bind(this);
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this);
    this.toggleCustom = this.toggleCustom.bind(this);
    this.toggleIconCustom = this.toggleIconCustom.bind(this);
  }

  t_col9() {
    this.setState({ col9: !this.state.col9 });
  }

  t_col10() {
    this.setState({ col10: !this.state.col10 });
  }

  t_col11() {
    this.setState({ col11: !this.state.col11 });
  }

  t_col12() {
    this.setState({ col12: !this.state.col12 });
  }

  t_col13() {
    this.setState({ col13: !this.state.col13 });
  }

  t_col14() {
    this.setState({ col14: !this.state.col14 });
  }

  t_col15() {
    this.setState({ col15: !this.state.col15 });
  }

  t_col16() {
    this.setState({ col16: !this.state.col16 });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      });
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      });
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      });
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>Education | Technology</title>
          </MetaTags>
          <Container fluid={true}>
            {/* <Breadcrumbs
              title="Education"
              breadcrumbItem="Technology"
            /> */}

            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className='mt-4'>
                        <h3 className='text-info'>Digital Payments</h3>
                        <p>In today’s global market, payments for products and services can become a bit tricky. Learn more about the most common payments methods in use.</p>
                        <div className='accordion accordion-flush' id='accordionFlushExample'>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingFlushOne'>
                              <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col9} style={{ cursor: 'pointer' }}>
                                Compare Payment Methods
                              </button>
                            </h2>

                            <Collapse isOpen={this.state.col9} className='accordion-collapse'>
                              <div className='accordion-body'>
                                <div className='text-muted'>In progress...</div>
                              </div>
                            </Collapse>
                          </div>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingFlushTwo'>
                              <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col10} style={{ cursor: 'pointer' }}>
                                CryptoCurrency   (Bitcoin, Etherium, Etc.)
                              </button>
                            </h2>

                            <Collapse isOpen={this.state.col10} className='accordion-collapse'>
                              <div className='accordion-body'>
                                <div className='text-muted'>
                                  {/* Add all information of bitcoin here   */}
                                  <Row>
                                    <Col>
                                      <Alert color='danger' align='center'>
                                        WARNING: CRYPTO IS A DIGITAL ASSET AND CAN BE LOST, HACKED, ETC AND IS NOT INSURED OR GUARANTEED BY ANY AUTHORITY, USE AT OWN RISK.
                                      </Alert>
                                    </Col>
                                  </Row>
                                  <h5>What is CryptoCurrency?</h5>
                                  <p>
                                    ‘Crypto’ as it is often referred to, is essentially digital money which uses internet-based exchange platforms built using cryptography to conduct financial transactions in a secure manner online.
                                    <br />
                                    Cryptocurrencies utilize ‘blockchain technology’ which is a publicly view-able decentralized and immutable ledger of all transactions ever made for the given currency.
                                  </p>
                                  <br />
                                  <h5>Why use Crypto?</h5>
                                  <p>
                                    Crypto has two untouchable features:
                                    <br />
                                    <br />
                                    1. It’s not controlled by any central authority! This decentralized nature is immune from government control and interference.
                                    <br />
                                    2. It can be sent directly between two people using public/private key technology which is as simple as scanning a QR code in an app now.
                                    <br />
                                    Transfers can be made with minimal to no processing fees. Typically only requiring a few seconds to verify the transaction and post it to the blockchain ledger.
                                  </p>
                                  <br />
                                  <h5>What are ‘stablecoins’?</h5>
                                  <p>
                                    Since the inception of the first cryptocurrency “Bitcoin” the biggest problem that has prevented widespread use is the value of the currency is extremely volatile as it is traded as speculative asset for the most part, and not for commerce. You could buy a bitcoin for 50$ and it could be worth 35$ seconds later, making it nearly impossible to use for commerce. This problem was ‘solved’ with the creation of ‘stablecoins’ which are designed to ALWAYS equal a specific value (typically 1USD). ‘DAI’ and ‘USDC’ are both cryptocurrencies intended to equal 1 USD .
                                    Both of these coins operate on the Ethereum (ERC20) Blockchain system.
                                  </p>

                                  <br />
                                  <h5>Ok… I just want to send some money, how?</h5>
                                  <p>Dharma.io is an incredibly easy and painless app for buying and sending Etherium (ETH) and DAI. They also pay you interest just like a bank account for any money sitting in the app. In less than 5 minutes you can create an account, add a Debit Card/Bank Account, buy crypto and send it to a friend/vendor using the instructions below. **CREDIT CARDS NOT SUPPORTED! DEBIT ONLY! **</p>
                                  <br />
                                  <p>
                                    1. Sign up at <a href='https://app.dharma.io/sign-up'>https://app.dharma.io/sign-up</a>, or download the iOS/Android app.
                                  </p>
                                  <img src={cr1} alt='' height='250' />
                                  <br />
                                  <p>2. Link Debit Card/Bank Account</p>
                                  <img src={cr2} alt='' height='250' />
                                  <br />
                                  <p>3. Purchase DAI at dharma.io, or within the iOS/Android app</p>
                                  <img src={cr3} alt='' height='250' />
                                  <br />
                                  <p>4. Send DAI as payment by scanning a QR code within the app</p>
                                  <img src={cr4} alt='' height='250' />
                                  <br />
                                </div>
                              </div>
                            </Collapse>
                          </div>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingFlushThree'>
                              <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col11} style={{ cursor: 'pointer' }}>
                                Western Union
                              </button>
                            </h2>
                            <Collapse isOpen={this.state.col11} className='accordion-collapse'>
                              <div className='accordion-body'>
                                <div className='text-muted'>In progress...</div>
                              </div>
                            </Collapse>
                          </div>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingFlushThree'>
                              <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col12} style={{ cursor: 'pointer' }}>
                                CashApp
                              </button>
                            </h2>
                            <Collapse isOpen={this.state.col12} className='accordion-collapse'>
                              <div className='accordion-body'>
                                <div className='text-muted'>In progress...</div>
                              </div>
                            </Collapse>
                          </div>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id='headingFlushThree'>
                              <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col13} style={{ cursor: 'pointer' }}>
                                Venmo
                              </button>
                            </h2>
                            <Collapse isOpen={this.state.col13} className='accordion-collapse'>
                              <div className='accordion-body'>
                                <div className='text-muted'>In progress...</div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>

            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className='mt-4'>
                        <h3 className='text-info'>Fitness Tracker Comparisons</h3>
                        <p>Find out which fitness tracker(s) best suit your needs.</p>
                      </div>
                      <div className='accordion accordion-flush' id='accordionFlushExample'>
                        <div className='accordion-item'>
                          <h2 className='accordion-header' id='headingFlushOne'>
                            <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col14} style={{ cursor: 'pointer' }}>
                              Garmin
                            </button>
                          </h2>

                          <Collapse isOpen={this.state.col14} className='accordion-collapse'>
                            <div className='accordion-body'>
                              <div className='text-muted'>In progress...</div>
                            </div>
                          </Collapse>
                        </div>

                        <div className='accordion-item'>
                          <h2 className='accordion-header' id='headingFlushOne'>
                            <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col15} style={{ cursor: 'pointer' }}>
                              Fitbit
                            </button>
                          </h2>

                          <Collapse isOpen={this.state.col15} className='accordion-collapse'>
                            <div className='accordion-body'>
                              <div className='text-muted'>In progress...</div>
                            </div>
                          </Collapse>
                        </div>

                        <div className='accordion-item'>
                          <h2 className='accordion-header' id='headingFlushOne'>
                            <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_col16} style={{ cursor: 'pointer' }}>
                              Polar
                            </button>
                          </h2>

                          <Collapse isOpen={this.state.col16} className='accordion-collapse'>
                            <div className='accordion-body'>
                              <div className='text-muted'>In progress...</div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default technology;
