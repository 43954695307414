import { CalendarContent } from '@fullcalendar/react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const login = (user, message, token, caldevAuth) => {
  writeCookie('user_logged_in', true, 300);
  writeCookie('user_message', message, 300);
  writeCookie('user_token', token, 300);
  localStorage.setItem('caldevAuth', caldevAuth);
  localStorage.setItem('caldavEmail', user.caldavAttendees);
  localStorage.setItem('authUser', user._id);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('firstName', user.firstName);
  localStorage.setItem('lastName', user.lastName);
  localStorage.setItem('age', user.age);
  localStorage.setItem('dob', user.dob);
  localStorage.setItem('email', user.email);
  localStorage.setItem('role', user.role);
  localStorage.setItem('biostrapAuth', user.bioStrapAuth);
  localStorage.setItem('biostrapEmail', user.biostrap_user_data.email);
  localStorage.setItem('pictureURL', JSON.stringify(user.pictureUrl));
  localStorage.setItem('loggedin', true);
  console.log(localStorage.getItem('firstName'));
  console.log(localStorage.getItem('lastName'));
  console.log(localStorage.getItem('age'));
  console.log(localStorage.getItem('dob'));
  console.log(localStorage.getItem('caldevAuth'));
};
export const logout = async () => {
  console.log('index logout called');
  cookies.remove('user_logged_in');
  cookies.remove('user_message');
  cookies.remove('user_token');
  localStorage.clear();
};
export const updateToken = (token, calDevAuth) => {
  console.log('updateToken called', token, calDevAuth);
  writeCookie('user_token', token, 300);
  localStorage.setItem('caldevAuth', calDevAuth);
};
export const isLoggedIn = () => {
  if (cookies.get('user_logged_in') && cookies.get('user_message') && cookies.get('user_token')) {
    return true;
  }

  return false;
};

export const writeCookie = (key, value, minutes) => {
  var date = new Date();

  date.setTime(date.getTime() + minutes * 60 * 1000);

  cookies.set(key, value, { path: '/', expires: date });

  return value;
};
