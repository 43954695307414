import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Alert, Container, Row, Col, Table, Input, Nav, NavItem, NavLink, TabContent, TabPane, Card, Form, FormGroup, Label, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

import classnames from 'classnames';

//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Images
import img1 from '../../../assets/images/product/img-1.png';
import rx1 from '../../../assets/images/rx1.gif';
import rx2 from '../../../assets/images/rx2.gif';
import rx3 from '../../../assets/images/rx3.gif';
import rx4 from '../../../assets/images/rx4.gif';
import rx5 from '../../../assets/images/rx5.gif';
import rx6 from '../../../assets/images/r6.gif';
import rx7 from '../../../assets/images/r7.gif';
import rx8 from '../../../assets/images/r8.gif';
import rx9 from '../../../assets/images/r9.gif';
import rx10 from '../../../assets/images/r10.gif';
import rx11 from '../../../assets/images/r11.gif';
import rx12 from '../../../assets/images/r12.gif';
import img7 from '../../../assets/images/product/img-7.png';

class rxCompounding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderSummary: [
        {
          id: 1,
          img: img1,
          productTitle: 'Half sleeve T-shirt (64GB)',
          price: 450,
          qty: 1,
        },
        {
          id: 2,
          img: img7,
          productTitle: 'Wireless Headphone',
          price: 225,
          qty: 1,
        },
      ],
      activeTab: '1',
      selectedGroup: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.handleSelectGroup = this.handleSelectGroup.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  render() {
    const { selectedGroup } = this.state;
    return (
      <React.Fragment>
        <div className='page-content'>
          <MetaTags>
            <title>Education | RX Compounding</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Row>
              <Col>
                <Alert color='danger' align='center'>
                  WARNING: THIS IS NOT MEDICAL ADVICE!
                  <br />
                  THIS INFORMATION IS FOR EDUCATIONAL PURPOSES ONLY.
                  <br />
                  DO NOT USE MEDICATIONS/SUPPLEMENTS WITHOUT THE SUPERVISION AND DIRECTION OF A MEDICAL PROFESSIONAL.
                </Alert>
              </Col>
            </Row>
            {/* <Breadcrumbs title='Education' breadcrumbItem='RX Compounding' /> */}

            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div style={{ lineHeight: '2' }}>
                      <h3 className='text-info '>What is RX Compounding?</h3>
                      <p className='ms-2'>
                        Compounded medications are drugs that are specifically tailored and prepared for you, based on a prescription from your physician.
                        <br />
                        It can be a simple as a single drug at a non-standard concentration (ex. Testosterone Cypionate 92mg/mL) or a complex mixture of multiple drugs at custom doses (ex. Testosterone Cyp. 120mg / Nandrolone Dec. 90mg blend per 1mL).
                        <br />
                        Compounded medications are not commercially available and must be made for you by a compounding pharmacist based on your prescription. Custom medications can be made for all routes of administration (capsules, oral solution, injection, etc).
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody style={{ lineHeight: '2' }}>
                <Row>
                  <Col>
                    <h3 className='text-info '>How is compounding done?</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col className='ms-2'>
                        <br />

                        <h5 className='text-secondary '>Oral/Inject Suspension/Solution Compounding</h5>

                        <h5 className='text-info '>
                          Per the University of North Carolina’s School of Pharmacy:
                          <br />
                        </h5>

                        <h5 className='text-secondary'>Liquid Aliquot Method</h5>
                        <br />
                        <Row>
                          <Col>
                            <p>This technique is similar to the trituration method but employs liquid rather than solid diluents. It is used only when the product to be dispensed is a liquid. A weighable quantity of drug is dissolved in a calculated volume of a suitable solvent, usually water or ethanol. Then, an aliquot of the solution containing the desired amount of drug is removed. Both the total volume of solution and the aliquot volume should be such that they are easily and accurately measured (preferably whole number multiples of 5 ml).</p>
                            <img className='img-fluid' src={rx1} alt='' />
                            <p>
                              <br />
                              e.g. Prepare 100 ml of a solution containing 0.2 mg/ml clonidine.
                              <br />
                              (Need 100 ml x 0.2 mg/ml = 20 mg clonidine)
                              <br />
                              1. Select a suitable solvent (based on drug solubility and stability, route of administration, and potential toxicity of the solvent).
                              <br />
                            </p>
                            <img className='img-fluid d-block' src={rx2} alt='' />
                            <p>
                              <br />
                              2. Select the aliquot volume (D) in which the desired amount of drug(C) will be contained. This establishes the concentration of the solution to be prepared. Volume must be large enough that concentration does not exceed the solubility of the drug, but small enough that it does not exceed the total volume of the prescription.
                              <br />
                              (Hint: check Remington’s or Merck Index for solubility data, then pick a concentration that is less than this solubility)
                            </p>
                            <img className='img-fluid' src={rx3} alt='' />
                            <p>
                              <br />
                              3. Select weight of drug (120 mg) to prepare the solution. Whenever practical, use the least weighable quantity so as to minimize waste. (This is A in the proportion)
                            </p>
                            <img className='img-fluid' src={rx4} alt='' height='25' />
                            <p>
                              <br />
                              4. Calculate the total volume (B) of solution to be prepared.
                            </p>
                            <img className='img-fluid' src={rx5} alt='' height='80' />
                            <p>
                              <br />
                              5. Remove aliquot of solution and q.s. to total Rx volume with the appropriate vehicle.
                              <br />
                              <br />
                              Steps 1-4 are simply the technique for weighing the drug. Step 5 completes the prescription compounding. Hence, we would:
                              <br />
                              <br />
                              1. Weigh 120 mg clonidine
                              <br />
                              2. Dissolve the clonidine in sufficient water to make 30 ml (Why not dissolve in 30 ml H2O?)
                              <br />
                              3. Remove 5 ml aliquot
                              <br />
                              4. q.s. with H2O to 100 ml to complete the prescription
                              <br />
                            </p>
                            <a href='https://pharmlabs.unc.edu/labs/triturations/aliquot.htm'>Source: https://pharmlabs.unc.edu/labs/triturations/aliquot.htm</a>
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <h5 className='text-secondary '>Capsule Compounding</h5>

                        <h5 className='text-info '>
                          Per the University of North Carolina’s School of Pharmacy:
                          <br />
                        </h5>

                        <h5 className='text-secondary'>Trituration Method</h5>
                        <br />

                        <Row>
                          <Col>
                            <p>
                              A trituration is a dilution of a potent drug powder with an inert diluent powder, usually lactose, in a definite proportion by weight. We can then remove a weighable portion (aliquot) of the mixture containing the desired quantity of substance to maintain an acceptable range of accuracy.
                              <br />
                              Using ratio and proportion, we can determine the weight of drug and lactose required to make the trituration as well as the weight of the aliquot to be used to fill the prescription.
                            </p>
                            <img className='img-fluid' src={rx6} alt='' height='50' />
                            <p>
                              <br />
                              Keep in mind that any substance that must be weighed on the balance drug (A), lactose (B-A), and aliquot (D) must be at least 120 mg.
                              <br />
                              <br />
                              Lactose is the most commonly used diluent in solid dosage form pharmaceuticals such as powders, tablets and capsules. Some of the reasons included its extremely low incidence of side effects, ready availability, and low cost.
                              <br />
                              Example: Make 10 capsules, each containing 0.6 mg Atropine Sulfate and each weighing a total of 200 mg.
                              <br />
                              Make Trituration
                              <br />
                              1. Total weight atropine sulfate required (this is C in the proportion)
                            </p>
                            <img className='img-fluid' src={rx7} alt='' height='25' />
                            <p>
                              <br />
                              2. Select some multiple of 6 mg that can be weighed with the desired degree of accuracy. (this is A in the proportion) (e.g. select multiple= 20)
                            </p>
                            <img className='img-fluid' src={rx8} alt='' height='25' />
                            <p>
                              <br />
                              3. Select the size of the aliquot portion to be taken to contain the 6 mg of drug. (this is D in the proportion)
                            </p>
                            <img className='img-fluid' src={rx9} alt='' height='23' />
                            <p>
                              <br />
                              4. Since we now have A, C, and D in our formula, we can solve for B(trituration weight).
                            </p>
                            <img className='img-fluid' src={rx10} alt='' height='80' />
                            <p>
                              <br />
                              [Note: this should be the multiple (20) x the weight of the aliquot (120 mg)]
                              <br />
                              <br />
                              5. Determine the weight of diluent (lactose) required to prepare the trituration by subtracting the weight of atropine sulfate from the trituration weight.
                            </p>
                            <img className='img-fluid' src={rx11} alt='' height='20' />
                            <p>
                              <br />
                              6. Fill Rx:
                              <br />
                              Determine the weight of lactose which must be added to the aliquot portion of trituration to complete the prescription, so that each capsule weighs 200 mg.
                            </p>
                            <img className='img-fluid' src={rx12} alt='' height='45' />
                            <p className='ms-2'>
                              <br />
                              After the necessary calculations are completed, the trituration is prepared using the following steps:
                              <br />
                              <br />
                              1. weigh a suitable amount of drug (at least 120 mg), and reduce it to a fine powder in a mortar (i.e., triturate it);
                              <br />
                              2. weigh a suitable amount of diluent;
                              <br />
                              3. mix the drug and diluent by geometric dilution and;
                              <br />
                              4. weigh an aliquot of the trituration containing the desired weight of drug.
                              <br />
                              <br />
                              Hence the compounding procedure for this product is as follows:
                              <br />
                              <br />
                              1. Weigh 120 mg atropine sulfate and triturate to a fine powder
                              <br />
                              2. Weigh 2280 mg lactose
                              <br />
                              3. Mix atropine sulfate and lactose by geometric dilution
                              <br />
                              4. Remove 120 mg of the trituration (contains 6 mg atropine sulfate)
                              <br />
                              (Hint: save left over trituration for refills)
                              <br />
                              5. Weigh 1880 mg lactose and mix with 120 mg aliquot by geometric dilution
                              <br />
                              6. Prepare capsules weighing 200 mg each
                            </p>
                            <a href='https://pharmlabs.unc.edu/labs/triturations/method.htm'>Source: https://pharmlabs.unc.edu/labs/triturations/method.htm</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default rxCompounding;
