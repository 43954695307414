import React, { useEffect, useState } from 'react';

import { Alert, Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import Spinner from '../../../common/helper/spinner';
const AddBiostrapEmailModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const toggle = () => {
    props.closeModal();
  };

  const submitButton = () => {
    //I NEED TO ADD API CALL HERE
    var biostrapEmail = document.getElementById('biostrapEmail').value;

    setShowSpinner(true);
    axiosConfig
      .post(
        '/biostrap/editBiostrapUserName',
        {
          user_id: props.id,
          email: biostrapEmail,
        },
        {
          headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
        }
      )
      .then(async (response) => {
        console.log('******response*******');
        console.log(response);
        console.log('******response*******');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        errorResponse(error);
        setMessage(error.response.data.message);
        setShowMessage(true);
        setShowSpinner(false);
      });
  };

  useEffect(() => {});

  return (
    <React.Fragment>
      {props.showModal ? (
        <Modal isOpen={props.showModal} className={props.className}>
          <ModalHeader>
            <div align='center'>
              <h4>Add Biostrap Email</h4>
            </div>
          </ModalHeader>
          <ModalBody>
            {showMessage && (
              <Alert align='center' color='danger'>
                {message}
              </Alert>
            )}

            <Row>
              <div align='center' className='mb-4'>
                <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                  Email
                </label>
                <div className='col-md-8'>
                  <input className='form-control mt-3' type='email' id='biostrapEmail' required />
                </div>
              </div>
            </Row>
            <Row>
              <div align='center'>
                {showSpinner && <Spinner />}

                <Col>
                  <button type='button' className='btn btn-success me-2' onClick={submitButton}>
                    Yes
                  </button>
                  <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                    Cancel
                  </button>
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default AddBiostrapEmailModal;
