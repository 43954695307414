import product1 from "./img-1.png"
import product2 from "./img-2.png"
import product3 from "./img-3.png"
import product4 from "./img-4.png"
import product5 from "./img-5.png"
import product6 from "./img-6.png"
import product7 from "./img-7.png"
import product8 from "./img-8.png"
import product9 from "./img-9.png"
import product10 from "./img-10.png"
import product11 from "./img-11.png"
import product12 from "./img-12.png"


export { product1, product2, product3, product4, product5, product6, product7, product8, product9, product10, product11, product12}

export const productImages = {
  product1: product1,
  product2: product2,
  product3: product3,
  product4: product4,
  product5: product5,
  product6: product6,
  product7: product7,
  product8: product8,
  product9: product9,
  product10: product10,
  product11: product11,
  product12: product12,
  
}
