import React, { useEffect, useRef, useState } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import Cookies from "universal-cookie";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import { logout } from "../../../utils";
import EntityDetails from "./EntityDetails";
import AttachImages from "./AttachImages";
import AddCategories from "./AddCategories";
import AddLabInformation from "./AddLabInformation";
import AssignCategories from "./AssignCategories";
import AddRecommendation from "./AddRecommendation";
import Lottie from "../../../common/helper/lottie";
import Spinner from "../../../common/helper/spinner";
import ftd from "../../../assets/images/maxfit.me2.svg";
import { LAB_DATA_SOURCE } from "../../../utils/constant";

const EditLab = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [complete, setComplete] = useState(false);
  const [gotData, setGotData] = useState(false);
  const [dataSource, setDataSource] = useState("PDF");

  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [entityID, setEntityID] = useState(props.match.params.id);

  const [defaultImageData, setDefaultImageData] = useState("");
  const [defaultEntityDetailsData, setDefaultEntityDetailsData] = useState("");
  const [showImage, setShowImage] = useState("");

  const imagesDataFetched = useRef(false);
  const entityDataFetched = useRef(false);

  useEffect(() => {
    if (!entityDataFetched.current && !!entityID) {
      entityDataFetched.current = true;
      axiosConfig
        .post(
          "/labBuilder/getAllEntity",
          {},

          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          await response.data.Data.forEach((s) => {
            if (s._id.toString() === entityID) {
              setDefaultImageData({
                waterMark: s.waterMark ? s.waterMark : "",
                coverPageLogo: s.coverPageLogoUrl,
                signatureImage: s.signatureLogoUrl,
                providerImage: s.providerImageUrl,
              });

              setDefaultEntityDetailsData({
                entityName: s.entityName,
                entityId: s.entityID,
                _id: s._id,
                reportTitle: s.reportTitle,
                templateName: s.templateName,
                prefaceTitle: s.prefaceTitle,
                prefaceText: s.prefaceText,
                providerName: s.providerName,
                providerText: s.providerText,
                providerGreeting: s.providerGreeting,
              });
              if (s?.dataSource) {
                setDataSource(s?.dataSource);
              }
            }
            setGotData(true);
          });
        })
        .catch((error) => {
          errorResponse(error);
        });
    }
  }, [entityID, defaultEntityDetailsData, props.defaultEntityDetailsData, entityDataFetched]);

  const handleValidSubmit = (e) => {
    e.preventDefault();
    if (step === 1) {
      var entityName = document.getElementById("entityName").value;
      var entityID = document.getElementById("entityID").value;
      var reportTitle = document.getElementById("reportTitle").value;
      var templateName = document.getElementById("templateName").value;
      var prefaceText = document.getElementById("prefaceText").value;
      var prefaceTitle = document.getElementById("prefaceTitle").value;
      var providerName = document.getElementById("providerName").value;
      var providerText = document.getElementById("providerText").value;
      var providerGreeting = document.getElementById("providerGreeting").value;

      if (entityID.length <= 10) {
        if (entityID === entityID.toUpperCase()) {
          setShowSpinner(true);
          if (defaultEntityDetailsData) {
            axiosConfig
              .post(
                "/labBuilder/updateEntity",
                {
                  providerName: providerName,
                  providerText: providerText,
                  providerGreeting: providerGreeting,
                  entityName: entityName,
                  reportTitle: reportTitle,
                  templateName: templateName,
                  prefaceText: prefaceText,
                  prefaceTitle: prefaceTitle,
                  entityID: entityID,
                  entity_id: defaultEntityDetailsData._id,
                  dateAdded: new Date(),
                  dataSource: dataSource,
                },
                {
                  headers: {
                    Authorization: "Bearer " + new Cookies().get("user_token"),
                  },
                }
              )
              .then(async (response) => {
                setEntityID(defaultEntityDetailsData._id);
                setShowSpinner(false);
                setStep(2);
                setError("");
              })
              .catch((error) => {
                errorResponse(error);
                setError(error.message);
                setShowSpinner(false);
              });
          } else {
            setShowSpinner(false);
          }

          setError("");
        } else {
          setError("Entity ID must be all CAPS and length must be less than or equal to 10 characters");
        }
      } else {
        setError("Entity ID must be all CAPS and length must be less than or equal to 10 characters");
      }
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
      setStep(6);
    } else if (step === 6) {
      props.history.push("/report-builder");
    }
  };
  function getImages(name, base64String) {
    if (!imagesDataFetched.current) {
      imagesDataFetched.current = true;
      axiosConfig
        .post(
          "/labBuilder/uploadpicture",
          {
            base64: base64String,
            verifyName: name,
            entity_id: entityID,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          if (name === "providerImage") {
            setShowImage({
              imageName: name,
              url: response.data.fileUrl,
            });
          } else if (name === "coverPageLogo") {
            setShowImage({
              imageName: name,
              url: response.data.fileUrl,
            });
          } else if (name === "waterMark") {
            setShowImage({
              imageName: name,
              url: response.data.fileUrl,
            });
          } else if (name === "signatureImage") {
            setShowImage({
              imageName: name,
              url: response.data.fileUrl,
            });
          }
        })
        .catch((error) => {
          errorResponse(error);
        });
    }
  }

  const logoutFunction = () => {
    logout();
    props.history.push("/login");
  };

  return (
    <React.Fragment>
      <div className="admin-home">
        <MetaTags>
          <title>Add Lab | Marek Health</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Card>
              <Row>
                <Col xs="4" md="4">
                  <Link to="/report-builder" align="left">
                    <img src={ftd} alt="" height="100" />
                  </Link>
                </Col>
                <Col xs="4" md="4">
                  <div align="center" className="admin-heading">
                    <h4>Admin Portal</h4>
                  </div>
                </Col>
                <Col xs="4" md="4">
                  <div align="right" className="mt-4">
                    <button onClick={logoutFunction} className="btn btn-danger">
                      Logout
                    </button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>

          <h4 className="p-3 text-muted">EDIT LAB</h4>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {complete ? (
                    <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                      <Lottie name="success-check" />
                      <p className="mb-5">
                        Lab is successfully submitted for preprocessing!
                        <br />
                        You will be notified when the process is complete
                      </p>
                      <Link to="/patients" className="btn btn-primary">
                        Report Builder
                      </Link>
                    </div>
                  ) : (
                    <>
                      <div className={`w-100 text-center my-4 stepsPillsContainer`}>
                        <div className="flexJCAC">
                          <div className="rounded-pill" style={{ width: "200px" }}>
                            <div className="activeProgresNumberCircle">
                              <p>1</p>
                            </div>
                            <p className="m-0">Add Entity Details</p>
                          </div>
                          <div className="rounded-pill" style={{ width: "200px" }}>
                            <div className={step >= 2 ? `activeProgresNumberCircle` : `inactiveProgresNumberCircle`}>
                              <p>2</p>
                            </div>
                            <p className="m-0">Attach Images</p>
                          </div>
                          <div className="rounded-pill" style={{ width: "200px" }}>
                            <div className={step >= 3 ? `activeProgresNumberCircle` : `inactiveProgresNumberCircle`}>
                              <p>3</p>
                            </div>
                            <p className="m-0">Add Categories</p>
                          </div>
                        </div>
                        <div className="flexJCAC">
                          <div className="rounded-pill" style={{ width: "200px" }}>
                            <div className={step >= 4 ? `activeProgresNumberCircle` : `inactiveProgresNumberCircle`}>
                              <p>4</p>
                            </div>
                            <p className="m-0">Add Lab Information</p>
                          </div>
                          <div className="rounded-pill" style={{ width: "200px" }}>
                            <div className={step >= 5 ? `activeProgresNumberCircle` : `inactiveProgresNumberCircle`}>
                              <p>5</p>
                            </div>
                            <p className="m-0">Assign Categories</p>
                          </div>
                          <div className="rounded-pill" style={{ width: "200px" }}>
                            <div className={step >= 6 ? `activeProgresNumberCircle` : `inactiveProgresNumberCircle`}>
                              <p>6</p>
                            </div>
                            <p className="m-0">Add Recommendations</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <form
                      id="newLabForm"
                      className="stepsForm form-horizontal px-sm-4 px-0"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      <div className="mb-5 px-sm-5 px-0">
                        {error && (
                          <Alert align="center" color="danger">
                            {error}
                          </Alert>
                        )}
                        {step === 1 && (
                          <>
                            <Row className="align-items-center justify-content-center">
                              <Col xs="auto" sm="5" md="2" xl="1">
                                <label className="d-inline">Data Source</label>
                              </Col>
                              <Col xs="auto">
                                <div
                                  className="btn-group mt-2 mt-xl-0"
                                  role="group"
                                  aria-label="Basic radio toggle button group"
                                >
                                  {LAB_DATA_SOURCE.map((element, index) => {
                                    const isSelected = element.value === dataSource;
                                    return (
                                      <>
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name="btnRadio"
                                          id={`btnRadio${index}`}
                                          autoComplete="off"
                                          value={element.value}
                                          checked={isSelected}
                                          onChange={(e) => {
                                            setDataSource(e.target.value);
                                          }}
                                        />
                                        <label className="btn btn-primary" htmlFor={`btnRadio${index}`}>
                                          {element.name}
                                        </label>
                                      </>
                                    );
                                  })}
                                </div>
                              </Col>
                            </Row>
                            <EntityDetails
                              step={step}
                              gotData={gotData}
                              isEdit={true}
                              defaultEntityDetailsData={defaultEntityDetailsData}
                            />
                          </>
                        )}
                        {step === 2 && (
                          <AttachImages
                            showImage={showImage}
                            defaultImageData={defaultImageData}
                            getImages={getImages}
                            step={step}
                            entityID={entityID}
                          />
                        )}
                        {step === 3 && <AddCategories step={step} entityID={entityID} />}
                        {step === 4 && <AddLabInformation step={step} entityID={entityID} dataSource={dataSource} />}
                        {step === 5 && <AssignCategories step={step} entityID={entityID} />}
                        {step === 6 && <AddRecommendation step={step} entityID={entityID} />}
                      </div>
                      {showSpinner && <Spinner />}
                      <Row className="m-sm-5 m-0 text-center flexJCAC pt-3">
                        {step === 1 ? null : (
                          <button
                            className="btn btn-light mt-4 mx-2"
                            style={{ width: "40%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setStep(step - 1);
                            }}
                          >
                            Back
                          </button>
                        )}
                        {step == 1 && (
                          <button
                            to="/ReportBuilder"
                            className="btn btn-light mt-4 w-50 mx-1"
                            type="button"
                            onClick={() => {
                              props.history.push("/report-builder");
                            }}
                          >
                            Cancel
                          </button>
                        )}
                        {step === 6 && (
                          <button className="btn btn-warning mt-4 w-50" type="submit">
                            Save
                          </button>
                        )}
                        {step === 6 ? (
                          <button className="btn btn-primary mt-4 w-50 ms-2" type="submit">
                            Submit
                          </button>
                        ) : (
                          <button className="btn btn-primary mt-4 w-50" type="submit">
                            Next
                          </button>
                        )}
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditLab;
