import React, { useState } from "react";
import { Col, Modal, ModalBody, Row, Input } from "reactstrap";
import axiosConfig from "../../../utils/axiosConfig";
import Cookies from "universal-cookie";

const AssignCategoryModal = (props) => {
  const {
    closeModal = () => {},
    isAssignState = false,
    show = false,
    selectedTests = [],
    categories = [],
    onSuccess = () => {},
  } = props;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const toggle = () => {
    closeModal();
  };

  const selectCategories = (id = "") => {
    const index = selectedCategories.findIndex((el) => el === id);
    if (index === -1) {
      const tempCategories = [...selectedCategories, id];
      setSelectedCategories(tempCategories);
    } else {
      const tempTests = [
        ...selectedCategories.slice(0, index),
        ...selectedCategories.slice(index + 1, selectedCategories.length),
      ];
      setSelectedCategories(tempTests);
    }
  };

  const submitButton = () => {
    setSubmitting(true);
    const payloads = buildPayload(selectedTests, selectedCategories);
    const requests = generateRequests(payloads, isAssignState);
    Promise.all(requests).then(() => {
      onSuccess();
      setSubmitting(false);
    });
  };
  console.log({ selectedCategories });
  return (
    <Modal isOpen={show} centered={true}>
      <ModalBody>
        <Row>
          <h3 className="my-3 mb-5" align="center">
            Assign Category
          </h3>
        </Row>

        <Row className="justify-content-center">
          <div className={"d-flex flex-column"}>
            {categories.map((cCat) => {
              const { id } = cCat || {};
              return (
                <span align="">
                  <Input
                    type="checkbox"
                    className="selection-input-4"
                    onClick={() => {
                      selectCategories(id);
                    }}
                  />
                  <span className="ms-1">{cCat.name}</span>
                </span>
              );
            })}
          </div>
        </Row>

        <Row className="mt-4">
          <div align="center">
            <Col>
              <button
                style={{ width: 100 }}
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Cancel
              </button>
              {props.isAssignState ? (
                <button
                  style={{ width: 100 }}
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={submitButton}
                  disabled={!selectedCategories.length}
                >
                  Assign
                </button>
              ) : (
                <button
                  style={{ width: 100 }}
                  type="button"
                  className="btn btn-danger me-2"
                  onClick={submitButton}
                  disabled={!selectedCategories.length}
                >
                  Unassign
                </button>
              )}
            </Col>
          </div>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AssignCategoryModal;

const buildPayload = (labTests = [], selectedCategories = []) => {
  const data = [];
  labTests.forEach((cLabTest) => {
    selectedCategories.forEach((cCat) => {
      const payload = {
        labtest_id: cLabTest.id || cLabTest,
        category_id: cCat,
      };
      data.push(payload);
    });
  });
  return data;
};

const generateRequests = (data = [], assignState = true) => {
  return data.map((cItem) => {
    if (assignState) {
      return axiosConfig.post("labBuilder/AssignCategory", cItem, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      });
    } else {
      return axiosConfig.post("labBuilder/unAssignCategory", cItem, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      });
    }
  });
};
