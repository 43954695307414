import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import BootstrapTheme from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { addNewEvent, deleteEvent, getCategories, getEvents, updateEvent } from '../../store/actions';
// import DeleteModal from './DeleteModal';
//css
import "@fullcalendar/bootstrap/main.css";
import moment from "moment";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
//importing knob chart
import "react-datepicker/dist/react-datepicker.css";
import RadialChart from "../../../common/helper/RadialChart";
import Spinner from "../../../common/helper/spinner";

import { AvField, AvForm } from "availity-reactstrap-validation";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import Apaexlinecolumn from "../../../common/helper/apaexlinecolumn";
import DashedLine from "../../../common/helper/dashedLine";
import SleepGraph from "../../../common/helper/sleepGraph";

var dashboardURL = "https://www.fitdatamax.net/dashboard";
var currentDate;
var upcomingEvent;
var upcomingEventClass;

//Data for biometrics
var steps_data = [];
var steps_xAxis = [];
var cals_data = [];
var cals_xAxis = [];
var deepSleep = [];
var totalSleep = [];
var lightSleep = [];
var sleep_xAxis = [];
var dhours, dminutes;
var current_deepSleep,
  dshours,
  dsminutes,
  current_lightSleep,
  lshours,
  lsminutes,
  current_totalSleep,
  tshours,
  tsminutes = 0;
var distance, duration, calories, steps;
var sleepDial, activityDial, recoveryDial;
var graphDuration = "year";
var currentDate2 = new Date(); // date to compare and filter for upcoming event
var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const UserInfo = (props) => {
  const { propEvents, categories } = props;
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [event, setEvent] = useState({});
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [dropDate, setdropDate] = useState("");
  const [dropName, setdropName] = useState("");

  const [eventID, setEventID] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showUpcomingEvent, setShowUpcomingEvent] = useState(false);

  //   const [totalSleep, setTotalSleep] = useState(0);
  const [showRecovery, setShowRecovery] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [showGraphs, setShowGraphs] = useState(false);
  const [showSleepGraph, setShowSleepGraph] = useState(false);
  const [showDials, setShowDials] = useState(false);
  const [showBiostrapMessage, setBiostrapMessage] = useState(false);
  const [biostrapMessage, setMessage] = useState("");

  //new modals code data
  // const [modalJournal, setModalJournal] = useState(false);

  useEffect(() => {
    // const { onGetCategories, onGetEvents } = props;
    // onGetCategories();
    // onGetEvents();
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, []);

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modal, event]);

  //main useeffect function
  useEffect(() => {
    //Code for biometrics start
    steps_data = [];
    steps_xAxis = [];
    cals_data = [];
    cals_xAxis = [];
    deepSleep = [];
    totalSleep = [];
    lightSleep = [];
    sleep_xAxis = [];
    setShowSpinner(true);
    graphDuration = "year";
    //Getting today's date
    var currentDay = "" + currentDate2.getDate(),
      currentMonth = "" + (currentDate2.getMonth() + 1),
      currentYear = currentDate2.getFullYear();
    if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
    if (currentDay.length < 2) currentDay = "0" + currentDay;

    var biostrapDate = [currentYear, currentMonth, currentDay].join("-");

    axiosConfig
      .post(
        "admins/userStepsData",
        {
          Date: biostrapDate,
          granularity: graphDuration,
          userID: localStorage.getItem("selectedUser"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("biostrap data");
        console.log(response.data);
        console.log("biostrap data");
        steps = response.data.metrics[3].value;
        distance = response.data.metrics[2].value;
        duration = response.data.metrics[1].value;
        dhours = Math.floor(duration / 3600);
        dminutes = Math.floor((duration - dhours * 3600) / 60);
        calories = response.data.metrics[0].value;

        for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
          steps_data.push(response.data.metrics[3].timeseries[i].value);

          steps_xAxis.push(months[new Date(response.data.metrics[3].timeseries[i].date).getMonth()]);
          cals_data.push(response.data.metrics[0].timeseries[i].value);
          cals_xAxis.push(months[new Date(response.data.metrics[0].timeseries[i].date).getMonth()]);
        }

        setShowGraphs(true);
        setShowSpinner(false);
        // setTimeout(() => {
        //   setDials(true);
        //   console.log(calPercentage);
        // }, 2000);
      })
      .catch((error) => {
        errorResponse(error);
        setShowSpinner(false);
      });

    axiosConfig
      .post(
        "admins/userSleepData",
        {
          Date: biostrapDate,
          granularity: graphDuration,
          userID: localStorage.getItem("selectedUser"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("sleep data");
        console.log(response.data);
        console.log("sleep data");
        current_deepSleep = response.data.avg_sleep_detail_metrics.deep_sleep_duration_in_mins;
        dshours = Math.floor(current_deepSleep / 60);
        dsminutes = Math.floor(current_deepSleep - dshours * 60);
        current_lightSleep = response.data.avg_sleep_detail_metrics.light_sleep_duration_in_mins;
        lshours = Math.floor(current_lightSleep / 60);
        lsminutes = Math.floor(current_lightSleep - lshours * 60);
        current_totalSleep = response.data.avg_sleep_detail_metrics.total_sleep_duration_in_mins;
        tshours = Math.floor(current_totalSleep / 60);
        tsminutes = Math.floor(current_totalSleep - tshours * 60);
        var month = new Date(response.data.sleep_detail_metric_date_datapoints[0].date).getMonth();
        var ds,
          ls,
          ts = 0;

        for (let i = 0; i < response.data.sleep_detail_metric_date_datapoints.length; i++) {
          if (
            new Date(response.data.sleep_detail_metric_date_datapoints[i].date).getMonth() == month &&
            i < response.data.sleep_detail_metric_date_datapoints.length - 1
          ) {
            ds += response.data.sleep_detail_metric_date_datapoints[i].deep_sleep_duration_in_mins;
            ls += response.data.sleep_detail_metric_date_datapoints[i].light_sleep_duration_in_mins;
            ts += response.data.sleep_detail_metric_date_datapoints[i].total_sleep_duration_in_mins;
          } else {
            deepSleep.push(ds);
            lightSleep.push(ls);
            totalSleep.push(ts);
            sleep_xAxis.push(
              months[new Date(response.data.sleep_detail_metric_date_datapoints[i - 1].date).getMonth()]
            );
            ds = ls = ts = 0;
            month = new Date(response.data.sleep_detail_metric_date_datapoints[i].date).getMonth();
          }
        }
        setShowSleepGraph(true);
        // setTimeout(() => {
        //   setDials(true);
        //   console.log(calPercentage);
        // }, 2000);
      })
      .catch((error) => {
        errorResponse(error);
        setShowSpinner(false);
        setBiostrapMessage(true);
        setMessage(error.response.data.message);
        setTimeout(() => {
          setBiostrapMessage(false);
        }, 5000);
      });

    //data for the dials
    axiosConfig
      .post(
        "admins/userRecoveryData",
        {
          Date: biostrapDate,
          userID: localStorage.getItem("selectedUser"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("recovery data");
        console.log(response.data);
        console.log("recovery data");
        activityDial = response.data.activity.value;
        sleepDial = response.data.sleep.value;
        recoveryDial = response.data.recovery.value;
        setShowDials(true);
      })
      .catch((error) => {
        errorResponse(error);
        setBiostrapMessage(true);
        setMessage(error.response.data.message);
        setShowSpinner(false);
        setTimeout(() => {
          setBiostrapMessage(false);
        }, 5000);
      });

    //Code for biometrics end

    var events; //to store all get events and filter for upcoming event
    currentDate = new Date(); // date to compare and filter for upcoming event
    var currentDay = "" + currentDate.getDate(),
      currentMonth = "" + (currentDate.getMonth() + 1),
      currentYear = currentDate.getFullYear();
    if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
    if (currentDay.length < 2) currentDay = "0" + currentDay;

    var biostrapDate = [currentYear, currentMonth, currentDay].join("-");
    console.log(currentDate);

    // console.log( moment(new Date().setFullYear(new Date().getFullYear() + 1)).format('YYYY-MM-DD'));
    axiosConfig
      .post(
        "admins/getUserEvents",
        {
          // Setting max date as 1 year head of todays date
          timeMax: moment(new Date().setFullYear(new Date().getFullYear() + 1)).format("YYYY-MM-DD"),
          // Setting min date as 1 year previous of todays date
          timeMin: moment(new Date().setFullYear(new Date().getFullYear() + 1)).format("YYYY-MM-DD"),
          maxResults: 100,
          userID: localStorage.getItem("selectedUser"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log(response.data);
        events = response.data;
        // setEvents(temparr);
        setEvents(
          response.data.map((event) =>
            Object.assign(event, {
              title: event.summary,
              start: event.start.dateTime,
              className:
                event.organizer.displayName === "Injury"
                  ? "btn-danger text-white"
                  : event.organizer.displayName === "Reminder"
                  ? "btn-warning text-white"
                  : "btn-success text-white",
            })
          )
        );
        //setting upcoming event
        console.log(events);
        for (let i = 0; i < events.length; i++) {
          var tempDate = new Date(events[i].start);

          if (tempDate > currentDate) {
            upcomingEvent = events[i];
            if (events[i].organizer.displayName == "Journal") {
              upcomingEventClass = "success";
            } else if (events[i].organizer.displayName == "Reminder") {
              upcomingEventClass = "warning";
            } else {
              upcomingEventClass = "danger";
            }

            setShowUpcomingEvent(true);
            break;
          }
        }
        console.log(upcomingEvent);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, []);

  //handleDateSelectFunction
  const handleDateSelect = () => {
    currentDate2 = currentDate2.replace("-", "/");
    currentDate2 = currentDate2.replace("-", "/");
    console.log(currentDate2);

    setShowGraphs(false);
    setShowSpinner(true);
    setShowSleepGraph(false);
    setShowDials(false);
    currentDate2 = new Date(currentDate2);
    //default variables reset
    steps_data = [];
    steps_xAxis = [];
    cals_data = [];
    cals_xAxis = [];
    deepSleep = [];
    totalSleep = [];
    lightSleep = [];
    sleep_xAxis = [];

    //rest of the component code starts
    var currentDay = "" + currentDate2.getDate(),
      currentMonth = "" + (currentDate2.getMonth() + 1),
      currentYear = currentDate2.getFullYear();
    if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
    if (currentDay.length < 2) currentDay = "0" + currentDay;

    var biostrapDate = [currentYear, currentMonth, currentDay].join("-");

    axiosConfig
      .post(
        "admins/userStepsData",
        {
          Date: biostrapDate,
          granularity: graphDuration,
          userID: localStorage.getItem("selectedUser"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("biostrap data");
        console.log(response.data);
        console.log("biostrap data");
        steps = response.data.metrics[3].value;
        distance = response.data.metrics[2].value;
        duration = response.data.metrics[1].value;
        dhours = Math.floor(duration / 3600);
        dminutes = Math.floor((duration - dhours * 3600) / 60);
        calories = response.data.metrics[0].value;

        if (graphDuration == "year") {
          for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
            steps_data.push(response.data.metrics[3].timeseries[i].value);

            steps_xAxis.push(months[new Date(response.data.metrics[3].timeseries[i].date).getMonth()]);
            cals_data.push(response.data.metrics[0].timeseries[i].value);
            cals_xAxis.push(months[new Date(response.data.metrics[0].timeseries[i].date).getMonth()]);
          }
        } else {
          for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
            if (graphDuration == "day") {
              var time = response.data.metrics[3].timeseries[i].time.slice(
                response.data.metrics[3].timeseries[i].time.search("T") + 1,
                response.data.metrics[3].timeseries[i].time.length - 4
              );
              console.log(time - "12:00");
              if (time < "12:00") {
                steps_data.push(response.data.metrics[3].timeseries[i].value);
                steps_xAxis.push(
                  response.data.metrics[3].timeseries[i].time.slice(
                    response.data.metrics[3].timeseries[i].time.search("T") + 1,
                    response.data.metrics[3].timeseries[i].time.length - 4
                  ) + "am"
                );
              } else {
                if (time == "13:00") {
                  time = "01:00pm";
                } else if (time == "14:00") {
                  time = "02:00pm";
                } else if (time == "15:00") {
                  time = "03:00pm";
                } else if (time == "16:00") {
                  time = "04:00pm";
                } else if (time == "17:00") {
                  time = "05:00pm";
                } else if (time == "18:00") {
                  time = "06:00pm";
                } else if (time == "19:00") {
                  time = "07:00pm";
                } else if (time == "20:00") {
                  time = "08:00pm";
                } else if (time == "21:00") {
                  time = "09:00pm";
                } else if (time == "22:00") {
                  time = "10:00pm";
                } else if (time == "23:00") {
                  time = "11:00pm";
                } else if (time == "12:00") {
                  time = "12:00pm";
                }

                steps_data.push(response.data.metrics[3].timeseries[i].value);
                steps_xAxis.push(time);
              }
            } else {
              var stepDate = new Date(response.data.metrics[3].timeseries[i].date);
              var calDate = new Date(response.data.metrics[0].timeseries[i].date);
              var tempDay, tempMonth, tempYear;
              tempDay = stepDate.getDate();
              tempMonth = stepDate.getMonth() + 1;
              tempYear = stepDate.getFullYear();

              if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
              if (tempDay.length < 2) tempDay = "0" + tempDay;
              stepDate = [tempMonth, tempDay, tempYear].join("-");
              tempDay = calDate.getDate();
              tempMonth = calDate.getMonth() + 1;
              tempYear = calDate.getFullYear();

              if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
              if (tempDay.length < 2) tempDay = "0" + tempDay;
              calDate = [tempMonth, tempDay, tempYear].join("-");

              steps_data.push(response.data.metrics[3].timeseries[i].value);
              steps_xAxis.push(stepDate);
              cals_data.push(response.data.metrics[0].timeseries[i].value);
              cals_xAxis.push(calDate);
            }
          }
        }

        setShowGraphs(true);
        setShowSpinner(false);
        // setTimeout(() => {
        //   setDials(true);
        //   console.log(calPercentage);
        // }, 2000);
      })
      .catch((error) => {
        errorResponse(error);
        setBiostrapMessage(true);
        setMessage(error.response.data.message);
        setShowSpinner(false);
        setTimeout(() => {
          setBiostrapMessage(false);
        }, 5000);
      });
    if (graphDuration != "day") {
      axiosConfig
        .post(
          "admins/userSleepData",
          {
            Date: biostrapDate,
            granularity: graphDuration,
            userID: localStorage.getItem("selectedUser"),
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          console.log("sleep data");
          console.log(response.data);
          console.log("sleep data");
          current_deepSleep = response.data.avg_sleep_detail_metrics.deep_sleep_duration_in_mins;
          dshours = Math.floor(current_deepSleep / 60);
          dsminutes = Math.floor(current_deepSleep - dshours * 60);
          current_lightSleep = response.data.avg_sleep_detail_metrics.light_sleep_duration_in_mins;
          lshours = Math.floor(current_lightSleep / 60);
          lsminutes = Math.floor(current_lightSleep - lshours * 60);
          current_totalSleep = response.data.avg_sleep_detail_metrics.total_sleep_duration_in_mins;
          tshours = Math.floor(current_totalSleep / 60);
          tsminutes = Math.floor(current_totalSleep - tshours * 60);
          if (graphDuration == "year") {
            var month = new Date(response.data.sleep_detail_metric_date_datapoints[0].date).getMonth();
            var ds,
              ls,
              ts = 0;

            for (let i = 0; i < response.data.sleep_detail_metric_date_datapoints.length; i++) {
              if (
                new Date(response.data.sleep_detail_metric_date_datapoints[i].date).getMonth() == month &&
                i < response.data.sleep_detail_metric_date_datapoints.length - 1
              ) {
                ds += response.data.sleep_detail_metric_date_datapoints[i].deep_sleep_duration_in_mins;
                ls += response.data.sleep_detail_metric_date_datapoints[i].light_sleep_duration_in_mins;
                ts += response.data.sleep_detail_metric_date_datapoints[i].total_sleep_duration_in_mins;
              } else {
                deepSleep.push(ds);
                lightSleep.push(ls);
                totalSleep.push(ts);
                sleep_xAxis.push(
                  months[new Date(response.data.sleep_detail_metric_date_datapoints[i - 1].date).getMonth()]
                );
                ds = ls = ts = 0;
                month = new Date(response.data.sleep_detail_metric_date_datapoints[i].date).getMonth();
              }
            }
          } else {
            for (let i = 0; i < response.data.sleep_detail_metric_date_datapoints.length; i++) {
              var sleepDate = new Date(response.data.sleep_detail_metric_date_datapoints[i].date);

              var tempDay, tempMonth, tempYear;
              tempDay = sleepDate.getDate();
              tempMonth = sleepDate.getMonth() + 1;
              tempYear = sleepDate.getFullYear();

              if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
              if (tempDay.length < 2) tempDay = "0" + tempDay;
              sleepDate = [tempMonth, tempDay, tempYear].join("-");

              deepSleep.push(response.data.sleep_detail_metric_date_datapoints[i].deep_sleep_duration_in_mins);
              lightSleep.push(response.data.sleep_detail_metric_date_datapoints[i].light_sleep_duration_in_mins);
              totalSleep.push(response.data.sleep_detail_metric_date_datapoints[i].total_sleep_duration_in_mins);
              sleep_xAxis.push(sleepDate);
            }
          }
          setShowSleepGraph(true);
          // setTimeout(() => {
          //   setDials(true);
          //   console.log(calPercentage);
          // }, 2000);
        })
        .catch((error) => {
          errorResponse(error);
          setBiostrapMessage(true);
          setMessage(error.response.data.message);
          setShowSpinner(false);
          setTimeout(() => {
            setBiostrapMessage(false);
          }, 5000);
        });
    } else {
      sleep_xAxis = [
        "12am",
        "1am",
        "2am",
        "3am",
        "4am",
        "5am",
        "6am",
        "7am",
        "8am",
        "9am",
        "10am",
        "11am",
        "12pm",
        "1pm",
        "2pm",
        "3pm",
        "4pm",
        "5pm",
        "6pm",
        "7pm",
        "8pm",
        "9pm",
        "10pm",
        "11pm",
      ];
      deepSleep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      lightSleep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      totalSleep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      axiosConfig
        .post(
          "admins/userSleepDataByDay",
          {
            userID: localStorage.getItem("selectedUser"),
            Date: biostrapDate,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          console.log("sleep day data");
          console.log(response.data);
          console.log("sleep day data");
          current_deepSleep = response.data.main_sleep_detail_metrics.deep_sleep_duration_in_mins;
          dshours = Math.floor(current_deepSleep / 60);
          dsminutes = Math.floor(current_deepSleep - dshours * 60);
          current_lightSleep = response.data.main_sleep_detail_metrics.light_sleep_duration_in_mins;
          lshours = Math.floor(current_lightSleep / 60);
          lsminutes = Math.floor(current_lightSleep - lshours * 60);
          current_totalSleep = response.data.main_sleep_detail_metrics.total_sleep_duration_in_mins;
          tshours = Math.floor(current_totalSleep / 60);
          tsminutes = Math.floor(current_totalSleep - tshours * 60);

          for (var i = 0; i < response.data.sleep_stages.length; i++) {
            var startdate = new Date(response.data.sleep_stages[i].start_time);
            var enddate = new Date(response.data.sleep_stages[i].end_time);
            // console.log(response.data.sleep_stages[i].status);
            if (response.data.sleep_stages[i].status == "light_sleep") {
              var total_minutes =
                enddate.getHours() * 60 + enddate.getMinutes() - (startdate.getHours() * 60 + startdate.getMinutes());
              lightSleep[startdate.getHours()] += total_minutes;
            } else if (response.data.sleep_stages[i].status == "deep_sleep") {
              var total_minutes =
                enddate.getHours() * 60 + enddate.getMinutes() - (startdate.getHours() * 60 + startdate.getMinutes());
              deepSleep[startdate.getHours()] += total_minutes;
            }
          }
          for (var i = 0; i < sleep_xAxis.length; i++) {
            if ((deepSleep[i] == 0 && lightSleep[i] == 0) || deepSleep[i] < 0 || lightSleep[i] < 0) {
              sleep_xAxis.splice(i, 1);
              deepSleep.splice(i, 1);
              lightSleep.splice(i, 1);
              totalSleep.splice(i, 1);
              i -= 1;
            }
            totalSleep[i] = deepSleep[i] + lightSleep[i];
          }

          setShowSleepGraph(true);
          setShowSpinner(false);
        })
        .catch((error) => {
          errorResponse(error);
          setBiostrapMessage(true);
          setMessage(error.response.data.message);
          setShowSpinner(false);
          setTimeout(() => {
            setBiostrapMessage(false);
          }, 5000);
        });
    }

    //data for the dials
    axiosConfig
      .post(
        "admins/userRecoveryData",
        {
          Date: biostrapDate,
          userID: localStorage.getItem("selectedUser"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("recovery data");
        console.log(response.data);
        console.log("recovery data");
        activityDial = response.data.activity.value;
        sleepDial = response.data.sleep.value;
        recoveryDial = response.data.recovery.value;
        setShowDials(true);
      })
      .catch((error) => {
        errorResponse(error);
        setShowSpinner(false);
        setBiostrapMessage(true);
        setMessage(error.response.data.message);
        setTimeout(() => {
          setBiostrapMessage(false);
        }, 5000);
      });
    //rest of the component code ends
  };

  const changeDuration = () => {
    console.log("inside change");
    setShowGraphs(false);
    setShowSpinner(true);
    setShowSleepGraph(false);
    setShowDials(false);
    setTimeout(() => {
      currentDate2 = new Date(currentDate2);
      //default variables reset
      steps_data = [];
      steps_xAxis = [];
      cals_data = [];
      cals_xAxis = [];
      deepSleep = [];
      totalSleep = [];
      lightSleep = [];
      sleep_xAxis = [];

      //rest of the component code starts
      var currentDay = "" + currentDate2.getDate(),
        currentMonth = "" + (currentDate2.getMonth() + 1),
        currentYear = currentDate2.getFullYear();
      if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
      if (currentDay.length < 2) currentDay = "0" + currentDay;

      var biostrapDate = [currentYear, currentMonth, currentDay].join("-");

      axiosConfig
        .post(
          "admins/userStepsData",
          {
            Date: biostrapDate,
            granularity: graphDuration,
            userID: localStorage.getItem("selectedUser"),
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          console.log("biostrap data");
          console.log(response.data);
          console.log("biostrap data");
          steps = response.data.metrics[3].value;
          distance = response.data.metrics[2].value;
          duration = response.data.metrics[1].value;
          dhours = Math.floor(duration / 3600);
          dminutes = Math.floor((duration - dhours * 3600) / 60);
          calories = response.data.metrics[0].value;

          if (graphDuration == "year") {
            for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
              steps_data.push(response.data.metrics[3].timeseries[i].value);

              steps_xAxis.push(months[new Date(response.data.metrics[3].timeseries[i].date).getMonth()]);
              cals_data.push(response.data.metrics[0].timeseries[i].value);
              cals_xAxis.push(months[new Date(response.data.metrics[0].timeseries[i].date).getMonth()]);
            }
          } else {
            for (let i = 0; i < response.data.metrics[3].timeseries.length; i++) {
              if (graphDuration == "day") {
                var time = response.data.metrics[3].timeseries[i].time.slice(
                  response.data.metrics[3].timeseries[i].time.search("T") + 1,
                  response.data.metrics[3].timeseries[i].time.length - 4
                );
                console.log(time - "12:00");
                if (time < "12:00") {
                  steps_data.push(response.data.metrics[3].timeseries[i].value);
                  steps_xAxis.push(
                    response.data.metrics[3].timeseries[i].time.slice(
                      response.data.metrics[3].timeseries[i].time.search("T") + 1,
                      response.data.metrics[3].timeseries[i].time.length - 4
                    ) + "am"
                  );
                } else {
                  if (time == "13:00") {
                    time = "01:00pm";
                  } else if (time == "14:00") {
                    time = "02:00pm";
                  } else if (time == "15:00") {
                    time = "03:00pm";
                  } else if (time == "16:00") {
                    time = "04:00pm";
                  } else if (time == "17:00") {
                    time = "05:00pm";
                  } else if (time == "18:00") {
                    time = "06:00pm";
                  } else if (time == "19:00") {
                    time = "07:00pm";
                  } else if (time == "20:00") {
                    time = "08:00pm";
                  } else if (time == "21:00") {
                    time = "09:00pm";
                  } else if (time == "22:00") {
                    time = "10:00pm";
                  } else if (time == "23:00") {
                    time = "11:00pm";
                  } else if (time == "12:00") {
                    time = "12:00pm";
                  }

                  steps_data.push(response.data.metrics[3].timeseries[i].value);
                  steps_xAxis.push(time);
                }
              } else {
                var stepDate = new Date(response.data.metrics[3].timeseries[i].date);
                var calDate = new Date(response.data.metrics[0].timeseries[i].date);
                var tempDay, tempMonth, tempYear;
                tempDay = stepDate.getDate();
                tempMonth = stepDate.getMonth() + 1;
                tempYear = stepDate.getFullYear();

                if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
                if (tempDay.length < 2) tempDay = "0" + tempDay;
                stepDate = [tempMonth, tempDay, tempYear].join("-");
                tempDay = calDate.getDate();
                tempMonth = calDate.getMonth() + 1;
                tempYear = calDate.getFullYear();

                if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
                if (tempDay.length < 2) tempDay = "0" + tempDay;
                calDate = [tempMonth, tempDay, tempYear].join("-");

                steps_data.push(response.data.metrics[3].timeseries[i].value);
                steps_xAxis.push(stepDate);
                cals_data.push(response.data.metrics[0].timeseries[i].value);
                cals_xAxis.push(calDate);
              }
            }
          }

          setShowGraphs(true);
          setShowSpinner(false);
          // setTimeout(() => {
          //   setDials(true);
          //   console.log(calPercentage);
          // }, 2000);
        })
        .catch((error) => {
          errorResponse(error);
          setShowSpinner(false);
          setBiostrapMessage(true);
          setMessage(error.response.data.message);
          setTimeout(() => {
            setBiostrapMessage(false);
          }, 5000);
        });
      if (graphDuration != "day") {
        axiosConfig
          .post(
            "admins/userSleepData",
            {
              Date: biostrapDate,
              granularity: graphDuration,
              userID: localStorage.getItem("selectedUser"),
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            console.log("sleep data");
            console.log(response.data);
            console.log("sleep data");
            current_deepSleep = response.data.avg_sleep_detail_metrics.deep_sleep_duration_in_mins;
            dshours = Math.floor(current_deepSleep / 60);
            dsminutes = Math.floor(current_deepSleep - dshours * 60);
            current_lightSleep = response.data.avg_sleep_detail_metrics.light_sleep_duration_in_mins;
            lshours = Math.floor(current_lightSleep / 60);
            lsminutes = Math.floor(current_lightSleep - lshours * 60);
            current_totalSleep = response.data.avg_sleep_detail_metrics.total_sleep_duration_in_mins;
            tshours = Math.floor(current_totalSleep / 60);
            tsminutes = Math.floor(current_totalSleep - tshours * 60);
            if (graphDuration == "year") {
              var month = new Date(response.data.sleep_detail_metric_date_datapoints[0].date).getMonth();
              var ds,
                ls,
                ts = 0;

              for (let i = 0; i < response.data.sleep_detail_metric_date_datapoints.length; i++) {
                if (
                  new Date(response.data.sleep_detail_metric_date_datapoints[i].date).getMonth() == month &&
                  i < response.data.sleep_detail_metric_date_datapoints.length - 1
                ) {
                  ds += response.data.sleep_detail_metric_date_datapoints[i].deep_sleep_duration_in_mins;
                  ls += response.data.sleep_detail_metric_date_datapoints[i].light_sleep_duration_in_mins;
                  ts += response.data.sleep_detail_metric_date_datapoints[i].total_sleep_duration_in_mins;
                } else {
                  deepSleep.push(ds);
                  lightSleep.push(ls);
                  totalSleep.push(ts);
                  sleep_xAxis.push(
                    months[new Date(response.data.sleep_detail_metric_date_datapoints[i - 1].date).getMonth()]
                  );
                  ds = ls = ts = 0;
                  month = new Date(response.data.sleep_detail_metric_date_datapoints[i].date).getMonth();
                }
              }
            } else {
              for (let i = 0; i < response.data.sleep_detail_metric_date_datapoints.length; i++) {
                var sleepDate = new Date(response.data.sleep_detail_metric_date_datapoints[i].date);

                var tempDay, tempMonth, tempYear;
                tempDay = sleepDate.getDate();
                tempMonth = sleepDate.getMonth() + 1;
                tempYear = sleepDate.getFullYear();

                if (tempMonth.length < 2) tempMonth = "0" + tempMonth;
                if (tempDay.length < 2) tempDay = "0" + tempDay;
                sleepDate = [tempMonth, tempDay, tempYear].join("-");

                deepSleep.push(response.data.sleep_detail_metric_date_datapoints[i].deep_sleep_duration_in_mins);
                lightSleep.push(response.data.sleep_detail_metric_date_datapoints[i].light_sleep_duration_in_mins);
                totalSleep.push(response.data.sleep_detail_metric_date_datapoints[i].total_sleep_duration_in_mins);
                sleep_xAxis.push(sleepDate);
              }
            }
            setShowSleepGraph(true);
            // setTimeout(() => {
            //   setDials(true);
            //   console.log(calPercentage);
            // }, 2000);
          })
          .catch((error) => {
            errorResponse(error);
            setShowSpinner(false);
            setBiostrapMessage(true);
            setMessage(error.response.data.message);
            setTimeout(() => {
              setBiostrapMessage(false);
            }, 5000);
          });
      } else {
        sleep_xAxis = [
          "12am",
          "1am",
          "2am",
          "3am",
          "4am",
          "5am",
          "6am",
          "7am",
          "8am",
          "9am",
          "10am",
          "11am",
          "12pm",
          "1pm",
          "2pm",
          "3pm",
          "4pm",
          "5pm",
          "6pm",
          "7pm",
          "8pm",
          "9pm",
          "10pm",
          "11pm",
        ];
        deepSleep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        lightSleep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        totalSleep = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        axiosConfig
          .post(
            "admins/userSleepDataByDay",
            {
              userID: localStorage.getItem("selectedUser"),
              Date: biostrapDate,
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            console.log("sleep day data");
            console.log(response.data);
            console.log("sleep day data");
            current_deepSleep = response.data.main_sleep_detail_metrics.deep_sleep_duration_in_mins;
            dshours = Math.floor(current_deepSleep / 60);
            dsminutes = Math.floor(current_deepSleep - dshours * 60);
            current_lightSleep = response.data.main_sleep_detail_metrics.light_sleep_duration_in_mins;
            lshours = Math.floor(current_lightSleep / 60);
            lsminutes = Math.floor(current_lightSleep - lshours * 60);
            current_totalSleep = response.data.main_sleep_detail_metrics.total_sleep_duration_in_mins;
            tshours = Math.floor(current_totalSleep / 60);
            tsminutes = Math.floor(current_totalSleep - tshours * 60);

            for (var i = 0; i < response.data.sleep_stages.length; i++) {
              var startdate = new Date(response.data.sleep_stages[i].start_time);
              var enddate = new Date(response.data.sleep_stages[i].end_time);
              // console.log(response.data.sleep_stages[i].status);
              if (response.data.sleep_stages[i].status == "light_sleep") {
                var total_minutes =
                  enddate.getHours() * 60 + enddate.getMinutes() - (startdate.getHours() * 60 + startdate.getMinutes());
                lightSleep[startdate.getHours()] += total_minutes;
              } else if (response.data.sleep_stages[i].status == "deep_sleep") {
                var total_minutes =
                  enddate.getHours() * 60 + enddate.getMinutes() - (startdate.getHours() * 60 + startdate.getMinutes());
                deepSleep[startdate.getHours()] += total_minutes;
              }
            }
            for (var i = 0; i < sleep_xAxis.length; i++) {
              if ((deepSleep[i] == 0 && lightSleep[i] == 0) || deepSleep[i] < 0 || lightSleep[i] < 0) {
                sleep_xAxis.splice(i, 1);
                deepSleep.splice(i, 1);
                lightSleep.splice(i, 1);
                totalSleep.splice(i, 1);
                i -= 1;
              }
              totalSleep[i] = deepSleep[i] + lightSleep[i];
            }

            setShowSpinner(false);
            setShowSleepGraph(true);
          })
          .catch((error) => {
            errorResponse(error);
            setBiostrapMessage(true);
            setMessage(error.response.data.message);
            setShowSpinner(false);
            setTimeout(() => {
              setBiostrapMessage(false);
            }, 5000);
          });
      }

      //data for the dials
      axiosConfig
        .post(
          "admins/userRecoveryData",
          {
            Date: biostrapDate,
            userID: localStorage.getItem("selectedUser"),
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          console.log("recovery data");
          console.log(response.data);
          console.log("recovery data");
          activityDial = response.data.activity.value;
          sleepDial = response.data.sleep.value;
          recoveryDial = response.data.recovery.value;
          setShowDials(true);
        })
        .catch((error) => {
          errorResponse(error);
          setShowSpinner(false);
        });
    }, 1000);
  };

  const toggle = () => {
    setModal("");
  };
  const openModal = (eventType = "", date = "", name = "", eventCategories = "", notes = "", tags = "", full = "") => {
    // setdropDate(date + 'T00:00');
    date = date.slice(0, date.search("T") + 6);
    setdropDate(date);
    setdropName(name);
    setModal(eventType.length > 1 ? eventType.toLowerCase() : "journal");
    console.log(full);
    if (full.includes(dashboardURL + " ")) {
      for (let i = 0; i < eventCategories.length; i++) {
        if (eventCategories[i] != "") {
          if (document.getElementById(eventCategories[i])) {
            document.getElementById(eventCategories[i]).checked = true;
          } else {
            document.getElementById("notes").value = full;
          }
        }
      }
      if (eventType == "Journal" || eventType == "Injury") {
        if (notes.includes("notes:")) {
          notes = notes.slice(6, notes.length);
          document.getElementById("notes").value = notes;
        } else if (notes.includes("tags:")) {
          notes = notes.slice(6, notes.length);

          document.getElementById("tags").value = notes;
        }
        if (tags != "") {
          tags = tags.slice(6, tags.length);

          document.getElementById("tags").value = tags;
        }
      } else if (eventType == "Reminder") {
        if (notes.includes("tags:")) {
          notes = notes.slice(6, notes.length);

          document.getElementById("tags").value = notes;
        }
      }
    } else {
      document.getElementById("notes").value = full;
    }
  };

  const closeModal = () => {
    setModal("");
  };

  /**
   * Handling date click on calendar
   */
  const handleDateClick = (arg) => {
    console.log(arg);
    setSelectedDay(arg);
    toggle();
    openModal("", arg.dateStr + "T00:00");
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {
    const event = arg.event;
    console.log(arg.event);
    setIsEdit(true);
    toggle();
    // openModal();
    // console.log(arg.event._def.extendedProps)
    var d = event.startStr; //.slice(0, event.startStr.search('T'));
    var desc = event._def.extendedProps.description;
    if (localStorage.getItem("caldevAuth") == "true") {
      var eventType = event._def.extendedProps.organizer.displayName;
      setEventID(event.id);
    } else {
      var eventType = event._def.extendedProps.calandarName;
      setEventID(event._def.extendedProps._id);
    }

    console.log(event);

    if (desc && desc.includes(",") && desc.includes(dashboardURL)) {
      desc = desc.split(",");
      var eventCategories = desc[1].split(".");
      console.log(eventType);
      if (eventType == "Journal" || eventType == "Injury") {
        if (desc.length == 4) {
          //add open modal with tag and notes
          openModal(eventType, d, event.title, eventCategories, desc[2], desc[3], desc);
        } else if (desc.length == 3) {
          // add open modal with just notes
          openModal(eventType, d, event.title, eventCategories, desc[2], "", desc);
        } else {
          openModal(eventType, d, event.title, eventCategories, "", "", desc);
        }
      } else if (eventType == "Reminder") {
        if (desc.length == 3) {
          openModal(eventType, d, event.title, eventCategories, desc[2], "", desc);
        } else {
          openModal(eventType, d, event.title, eventCategories, "", "", desc);
        }
      } else {
        openModal(eventType, d, event.title, eventCategories, "", "", desc);
      }
    } else {
      // event added from phone
      openModal(eventType, d, event.title, eventCategories, "", "", desc);
    }
    // console.log(event._def.extendedProps.organizer.displayName, event.startStr, event.title);
    console.log(desc);
    console.log(eventCategories);
  };

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e) => {
    setShowSpinner(true);
    e.preventDefault();
    var tempFlag = true; // to remove last period from eventdetail categories
    var serialize = require("form-serialize");
    var form = document.getElementById("newEventForm");
    var obj = serialize(form, { hash: true });

    var isRepeat = document.getElementById("repeatEvent").checked;
    var finalApiRecurrenceString = [];
    var popupTime = document.getElementById("popupBefore").value;
    var emailTime = document.getElementById("emailBefore").value;
    if (isRepeat) {
      //2021-10-27T00:00
      //20220701T170000Z
      var repeatType = document.getElementById("repeatEventType").value;
      var repeatUntil = document.getElementById("repeatEvent-date-and-time").value.toString();
      console.log("inside checkbox");
      repeatUntil = repeatUntil.replace("-", "");
      repeatUntil = repeatUntil.replace("-", "");

      repeatUntil = repeatUntil.replace(":", "");
      repeatUntil += "00Z";
      finalApiRecurrenceString[0] = "RRULE:FREQ=" + repeatType + ";UNTIL=" + repeatUntil;
      console.log(finalApiRecurrenceString);
    } else {
      console.log("unchecked");
    }

    let eventDetails = "";
    eventDetails += dashboardURL + " " + ",";
    for (const [key, value] of Object.entries(obj)) {
      if (key == "eventName" || key == "dateTime") {
        continue;
      } else if (key == "notes" || key == "tags") {
        if (tempFlag) {
          eventDetails = eventDetails.substring(0, eventDetails.length - 1);
          tempFlag = false;
        }
        eventDetails += `,${key}: ${value}`;
      } else {
        eventDetails += `${key}.`;
      }
    }

    if (isEdit) {
      if (deleteEvent) {
        console.log("delete called");
        axiosConfig
          .post(
            "admins/deleteUserEvent",
            {
              calandarName: modal.charAt(0).toUpperCase() + modal.slice(1),
              event_id: eventID,
              userID: localStorage.getItem("selectedUser"),
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            console.log(response.data);
            setShowSpinner(false);
            // setApiResponse(response.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch((error) => {
            errorResponse(error);
          });
      } else {
        console.log("update called");
        axiosConfig
          .post(
            "admins/updateUserEvent",
            {
              description: eventDetails,
              calandarName: modal.charAt(0).toUpperCase() + modal.slice(1),
              event_id: eventID,
              eventStartTime: obj.dateTime,
              eventEndTime: obj.dateTime,
              timeZone: "Asia/Karachi",
              summary: obj.eventName,
              userID: localStorage.getItem("selectedUser"),
              recurrence: finalApiRecurrenceString,
              recurrenceBoolean: isRepeat,
              popupTime: popupTime,
              emailTime: emailTime,
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            console.log(response.data);
            setShowSpinner(false);
            // setApiResponse(response.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch((error) => {
            errorResponse(error);
          });
      }
    } else {
      console.log("submit called");
      setShowSpinner(true);
      axiosConfig
        .post(
          "admins/addUserEvent",
          {
            summary: obj.eventName,
            description: eventDetails,
            eventStartTime: obj.dateTime,
            eventEndTime: obj.dateTime,
            timeZone: "Asia/Karachi",
            email: localStorage.getItem("email"),
            calandarName: modal.charAt(0).toUpperCase() + modal.slice(1),
            userID: localStorage.getItem("selectedUser"),
            recurrence: finalApiRecurrenceString,
            recurrenceBoolean: isRepeat,
            popupTime: popupTime,
            emailTime: emailTime,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          setShowSpinner(false);
          console.log(response.data);
          setShowSpinner(false);
          // setApiResponse(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((error) => {
          errorResponse(error);
        });
    }

    setSelectedDay(null);
  };

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    const { onDeleteEvent } = props;
    onDeleteEvent(event);
    setDeleteModal(false);
    toggle();
  };

  const updateEventTemp = () => {
    console.log("update event called");
  };

  const onDrop = useCallback((event) => {
    // console.log(event);
    openModal(event.draggedEl.innerText, event.dateStr);
    const { onAddNewEvent } = props;
    const draggedEl = event.draggedEl;
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: event.date,
      className: draggedEl.className,
    };
    // onAddNewEvent(newEvent);
  });

  return (
    <React.Fragment>
      {/* <DeleteModal show={deleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setDeleteModal(false)} /> */}
      <div className="mt-4">
        <MetaTags>
          <title>Dashboard | Marek Health</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Home" /> */}
          {apiResponse == "Event Created" && (
            <Row>
              <Col>
                <Alert color="success" align="center">
                  Event created successfully!
                </Alert>
              </Col>
            </Row>
          )}

          {/* {localStorage.getItem('caldevAuth') != true && (
            <Row>
              <Col>
                <Alert color='warning' align='center'>
                  Alert: Your account is not linked with google.
                  <br />
                  Link now by going into{' '}
                  <Button color='primary' className='font-16 btn-block btn-sm' onClick={() => props.history.push('user-settings')}>
                    Settings
                  </Button>{' '}
                  to sync your calendar on all devices.
                </Alert>
              </Col>
            </Row>
          )} */}
          {/* {localStorage.getItem('caldevAuth') == 'true' ? (
            <></>
          ) : (
            <Row>
              <Col>
                <Alert color='warning' align='center'>
                  Alert: User account is not linked with google.
                  
                </Alert>
              </Col>
            </Row>
          )} */}

          <Row>
            <Col md="2">
              <Link to="/home">
                <button className="mb-4 btn btn-primary">Back</button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                      <Button color="primary" className="font-16 btn-block" onClick={openModal}>
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Create New Event
                      </Button>
                      {/* {showSpinner ? <Spinner /> : null} */}

                      <div id="external-events" className="mt-3">
                        <p className="text-muted">Drag and drop your event or click in the calendar</p>
                        <div
                          className={`btn-success fc-daygrid-event  external-event text-white p-1 mb-2`}
                          key={"cat-j"}
                          draggable
                          // onDrag={(event) => onDrag(event)}
                        >
                          <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                          Journal
                        </div>

                        <div
                          className={`btn-warning fc-daygrid-event external-event text-white p-1 mb-2`}
                          key={"cat-r"}
                          draggable
                          // onDrag={(event) => onDrag(event)}
                        >
                          <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                          Reminder
                        </div>

                        <div
                          className={`btn-danger fc-daygrid-event external-event text-white p-1 mb-2`}
                          key={"cat-i"}
                          draggable
                          // onDrag={(event) => onDrag(event)}
                        >
                          <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                          Injury
                        </div>

                        {categories &&
                          categories.map((category, i) => (
                            <div
                              className={`${category.type} external-event text-white p-1 mb-2`}
                              key={"cat-" + category.id}
                              draggable
                              // onDrag={(event) => onDrag(event, category)}
                            >
                              <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                              {category.title}
                            </div>
                          ))}
                      </div>
                      <div>
                        <h4 className="mt-5 text-muted" align="center">
                          Upcoming Event
                        </h4>
                        {/* Retrieve upcoming event from data */}
                        {showUpcomingEvent ? (
                          <Card className="mt-3 ">
                            <CardTitle>
                              <div
                                align="center"
                                className={`btn-${upcomingEventClass} fc-daygrid-event external-event text-white p-1 mb-2`}
                                key={"cat-i"}
                              >
                                {upcomingEvent.title}
                              </div>
                            </CardTitle>
                            <CardBody>
                              <div align="center">
                                <h5>{new Date(upcomingEvent.start).toString().slice(0, 15)}</h5>

                                <h6>Type: {upcomingEvent.organizer.displayName}</h6>
                              </div>
                            </CardBody>
                          </Card>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-lg-9 app-calendar">
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        events={events}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />
                      {/* New/Edit event modal */}
                      <Modal isOpen={modal.length > 0} className={props.className}>
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? "Edit Event" : "Add Event"}
                        </ModalHeader>
                        <ModalBody>
                          <form id="newEventForm" onSubmit={(e) => handleValidEventSubmit(e)}>
                            <Row>
                              <Col className="col-12 mb-3">
                                {/* <AvField
                                  name='title'
                                  label='Event Name'
                                  type='text'
                                  errorMessage='Invalid name'
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={event ? event.title : ''}
                                /> */}
                                <Label className="text-secondary" htmlFor="eventName">
                                  Event Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-input"
                                  name="eventName"
                                  required={true}
                                  defaultValue={dropName}
                                />
                              </Col>
                              <Col className="col-12 mb-3">
                                {!isEdit && (
                                  <div className="d-flex justify-content-between">
                                    <Button
                                      color="success"
                                      className="btn-rounded"
                                      value={(event.event_category = "bg-success")}
                                      onClick={() => {
                                        setModal("journal");
                                      }}
                                      style={
                                        modal === "journal"
                                          ? {
                                              transition: "transform 300ms",
                                              transform: "scale(1.1)",
                                            }
                                          : null
                                      }
                                    >
                                      Journal Entry
                                    </Button>
                                    <Button
                                      color="warning"
                                      className="btn-rounded"
                                      value={(event.event_category = "bg-warning")}
                                      onClick={() => {
                                        setModal("reminder");
                                      }}
                                      style={
                                        modal === "reminder"
                                          ? {
                                              transition: "transform 300ms",
                                              transform: "scale(1.1)",
                                            }
                                          : null
                                      }
                                    >
                                      Reminder
                                    </Button>
                                    <Button
                                      color="danger"
                                      className="btn-rounded"
                                      value={(event.event_category = "bg-danger")}
                                      onClick={() => {
                                        setModal("injury");
                                      }}
                                      style={
                                        modal === "injury"
                                          ? {
                                              transition: "transform 300ms",
                                              transform: "scale(1.1)",
                                            }
                                          : null
                                      }
                                    >
                                      Injury Event
                                    </Button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row style={{ gridGutterWidth: 0 }}>
                              {/* heading for modal  */}
                              <div align="center">
                                <h3 classname="" style={{ textTransform: "capitalize" }}>
                                  {modal}
                                </h3>
                              </div>
                              {/* Date and time */}
                              <div className="mb-3 row" align="left">
                                <Label htmlFor="datetime" className="col-md-6 col-form-label">
                                  Date and time
                                </Label>
                                <div className="col-md-12">
                                  <Input
                                    name="dateTime"
                                    className="form-control"
                                    type="datetime-local"
                                    id="journal-date-and-time"
                                    defaultValue={dropDate}
                                    required
                                  />
                                </div>
                              </div>
                            </Row>
                            <Row style={{ gridGutterWidth: 0 }}>
                              <Col md="6">
                                <Row>
                                  <Label>Email Before(Mins)</Label>
                                </Row>
                                <Row>
                                  <Col md="8">
                                    <Input
                                      name="emailBefore"
                                      className="form-control  mb-1"
                                      type="number"
                                      id="emailBefore"
                                      defaultValue={30}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="6">
                                <Row>
                                  <Label>Popup Before(Mins)</Label>
                                </Row>
                                <Row>
                                  <Col md="8">
                                    <Input
                                      name="popupBefore"
                                      className="form-control  mb-1"
                                      type="number"
                                      id="popupBefore"
                                      defaultValue={30}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {/* All day event check box */}
                            <Row className="no-gutters">
                              <Col>
                                <Input
                                  type="checkbox"
                                  className="form-check-input "
                                  name="allDayEvent"
                                  value="checked"
                                />
                                <Label className=" text-secondary ms-2" htmlFor="allDayEvent">
                                  All Day Event
                                </Label>
                              </Col>
                            </Row>
                            {/* New code for repeat event start */}
                            <Row>
                              <Label className="col-md-6 col-form-label">Repeat Event Until</Label>
                            </Row>
                            <Row className="no-gutters mt-2">
                              <Col md="4">
                                <Input
                                  type="checkbox"
                                  className="form-check-input "
                                  name="repeatEvent"
                                  id="repeatEvent"
                                  value="checked"
                                />
                                <Label className=" text-secondary ms-2" htmlFor="repeatEvent">
                                  Repeat Event
                                </Label>
                              </Col>
                              <Col md="4">
                                <select required id="repeatEventType" className="form-control col-md-4 select2">
                                  <option value="DAILY" selected>
                                    Daily
                                  </option>
                                  <option value="WEEKLY">Weekly</option>
                                  <option value="MONTHLY">Monthly</option>
                                </select>
                              </Col>
                              <Col md="4">
                                <div className="col-md-12">
                                  <Input
                                    name=""
                                    className="form-control"
                                    type="datetime-local"
                                    id="repeatEvent-date-and-time"
                                    defaultValue={dropDate}
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              {modal !== "injury" ? (
                                <>
                                  <Col>
                                    <div align="center" className="mt-3">
                                      <h5>Categories</h5>
                                    </div>

                                    <Input
                                      type="checkbox"
                                      className="form-check-input input-mini"
                                      name="injuryHealth"
                                      id="injuryHealth"
                                      value="checked"
                                      onChange={() => console.log("Clicked")}
                                    />
                                    <Label className="form-check-label text-secondary ms-2" htmlFor="injuryHealth">
                                      Injury/Health
                                    </Label>
                                    <br />
                                    <div className="ms-3 mb-2">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="biometrics"
                                        id="biometrics"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="biometrics">
                                        Biometrics
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="diagnosticLabs"
                                        id="diagnosticLabs"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="diagnosticLabs">
                                        Diagnostic Labs
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="majorInjury"
                                        id="majorInjury"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="majorInjury">
                                        Major Injury
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="minorInjury"
                                        id="minorInjury"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="minorInjury">
                                        Minor Injury
                                      </Label>
                                      <br />
                                    </div>
                                    <Input
                                      type="checkbox"
                                      className="form-check-input input-mini"
                                      name="medications"
                                      id="medications"
                                      value="checked"
                                    />
                                    <Label className="form-check-label text-secondary ms-2" htmlFor="medications">
                                      Medications
                                    </Label>
                                    <br />
                                    <div className="ms-3 mb-2">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="aas"
                                        id="aas"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="aas">
                                        AAS
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="ancillary"
                                        id="ancillary"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="ancillary">
                                        Ancillary
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="otc"
                                        id="otc"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="otc">
                                        OTC
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="peptide"
                                        id="peptide"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="peptide">
                                        Peptide
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="sarm"
                                        id="sarm"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="sarm">
                                        SARM
                                      </Label>
                                      <br />
                                    </div>
                                    <div className="mb-2">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="nutrition"
                                        id="nutrition"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="nutrition">
                                        Nutrition
                                      </Label>
                                      <br />
                                    </div>

                                    <div className="mb-2">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="rehab"
                                        id="rehab"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="rehab">
                                        Rehab
                                      </Label>
                                      <br />
                                    </div>

                                    <div className="mb-2">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="supplements"
                                        id="supplements"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="supplements">
                                        Supplements
                                      </Label>
                                      <br />
                                    </div>
                                    <Input
                                      type="checkbox"
                                      className="form-check-input input-mini"
                                      name="training"
                                      id="training"
                                      value="checked"
                                    />
                                    <Label className="form-check-label text-secondary ms-2" htmlFor="training">
                                      Training
                                    </Label>
                                    <br />
                                    <div className="ms-3">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="cardio"
                                        id="cardio"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="cardio">
                                        Cardio
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="strengthHypertrophy"
                                        id="strengthHypertrophy"
                                        value="checked"
                                      />
                                      <Label
                                        className="form-check-label text-secondary ms-2"
                                        htmlFor="strengthHypertrophy"
                                      >
                                        Strength/Hypertrophy
                                      </Label>
                                      <br />
                                    </div>
                                  </Col>
                                  <Col>
                                    {modal === "journal" ? (
                                      <>
                                        <div className="mb-2 mt-3">
                                          <Label htmlFor="notes">Notes</Label>
                                          <Input type="textarea" name="notes" id="notes" />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div align="center" className="mb-2 mt-3">
                                          <h5>Reminder Type</h5>
                                        </div>
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="medication"
                                          id="medication"
                                          value="checked"
                                        />
                                        <Label
                                          className="form-check-label text-secondary ms-2"
                                          htmlFor="reminder-medication"
                                        >
                                          Medication
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="supplement"
                                          id="supplement"
                                          value="checked"
                                        />
                                        <Label
                                          className="form-check-label text-secondary ms-2"
                                          htmlFor="reminder-supplement"
                                        >
                                          Supplement
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="biometrics"
                                          id="biometrics"
                                          value="checked"
                                        />
                                        <Label
                                          className="form-check-label text-secondary ms-2"
                                          htmlFor="reminder-biometrics"
                                        >
                                          Biometrics
                                        </Label>
                                        <br />
                                        <Input
                                          type="checkbox"
                                          className="form-check-input input-mini"
                                          name="labwork"
                                          id="labwork"
                                          value="checked"
                                        />
                                        <Label
                                          className="form-check-label text-secondary ms-2"
                                          htmlFor="reminder-labwork"
                                        >
                                          Labwork
                                        </Label>
                                        <br />
                                      </>
                                    )}
                                    <div className="mt-3">
                                      <Label htmlFor="tags">TAGS</Label>
                                      <Input type="textarea" name="tags" id="tags" />
                                    </div>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col>
                                    <div align="center" className="mt-3">
                                      <h5>Categories</h5>
                                    </div>
                                    <Input
                                      type="checkbox"
                                      className="form-check-input input-mini"
                                      name="injuryHealth"
                                      id="injuryHealth"
                                      value="checked"
                                    />
                                    <Label className="form-check-label text-secondary ms-2" htmlFor="injuryHealth">
                                      Injury/Health
                                    </Label>

                                    <br />
                                    <div className="ms-3 mb-2">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="majorInjury"
                                        id="majorInjury"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="majorInjury">
                                        Major Injury
                                      </Label>
                                      <br />
                                      <Input
                                        type="checkbox"
                                        className="form-check-input input-mini"
                                        name="minorInjury"
                                        id="minorInjury"
                                        value="checked"
                                      />
                                      <Label className="form-check-label text-secondary ms-2" htmlFor="minorInjury">
                                        Minor Injury
                                      </Label>
                                      <br />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="mb-2 mt-3">
                                      <Label htmlFor="notes">Notes</Label>
                                      <Input type="textarea" name="notes" id="notes" />
                                    </div>
                                    <div className="mt-3 mb-4">
                                      <Label htmlFor="tags">TAGS</Label>
                                      <Input type="textarea" name="tags" id="tags" />
                                    </div>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              <Col>
                                {showSpinner && <Spinner />}
                                <div className="text-end">
                                  <button type="button" className="btn btn-light me-2" onClick={closeModal}>
                                    Close
                                  </button>
                                  {!!isEdit && (
                                    <button
                                      type="submit"
                                      onClick={() => {
                                        setDeleteEvent(true);
                                      }}
                                      id="delete"
                                      className="btn btn-danger mx-2"
                                    >
                                      Delete
                                    </button>
                                  )}
                                  {!!isEdit && (
                                    <button type="submit" id="submit" className="btn btn-primary save-event">
                                      Update
                                    </button>
                                  )}
                                  {!isEdit && (
                                    <button type="submit" id="submit" className="btn btn-success save-event">
                                      Submit
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* biometrics code for admin start */}

      <Row>
        <Col md="5" className="mt-5 mx-2">
          <AvForm>
            <Row>
              <Col>
                <AvField
                  selected={currentDate2}
                  id="date"
                  name="date"
                  label="Select Date"
                  type="date"
                  placeholder=""
                  onChange={(e) => {
                    currentDate2 = e.target.value;
                  }}
                />{" "}
              </Col>
              <Col>
                <button type="button" id="dateSelect" className="btn btn-primary mt-4" onClick={handleDateSelect}>
                  Enter
                </button>
              </Col>
            </Row>
          </AvForm>
          {/* <DatePicker
              selected={currentDate}
              onChange={(date) => {
                currentDate = date;
                this.dateChange.bind(this);
              }}
            /> */}
        </Col>
        <Col align="right" className="mt-5 mx-2">
          <div className="btn-group mt-2 mt-xl-0" role="group" aria-label="Basic radio toggle button group">
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio1"
              autoComplete="off"
              defaultChecked
              value="year"
              onChange={(e) => {
                graphDuration = e.target.value;
                console.log(graphDuration);
              }}
              onClick={changeDuration}
            />
            <label className="btn btn-primary" htmlFor="btnradio1">
              Year
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio2"
              autoComplete="off"
              value="month"
              onChange={(e) => {
                graphDuration = e.target.value;
                console.log(graphDuration);
              }}
              onClick={changeDuration}
            />
            <label className="btn btn-primary" htmlFor="btnradio2">
              Month
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio3"
              autoComplete="off"
              value="week"
              onChange={(e) => {
                graphDuration = e.target.value;
                console.log(graphDuration);
              }}
              onClick={changeDuration}
            />
            <label className="btn btn-primary" htmlFor="btnradio3">
              Week
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio4"
              autoComplete="off"
              value="day"
              onChange={(e) => {
                graphDuration = e.target.value;
                console.log(graphDuration);
              }}
              onClick={changeDuration}
            />
            <label className="btn btn-primary" htmlFor="btnradio4">
              Day
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Card className="my-2">
          {showDials && (
            <Row>
              <Col md="4">
                <RadialChart value={activityDial} label="Activity" color="#50a5f1" />{" "}
              </Col>
              <Col md="4">
                <RadialChart value={recoveryDial} label="Recovery" color="#34c38f" />{" "}
              </Col>
              <Col md="4">
                <RadialChart value={sleepDial} label="Sleep" color="#f1b44c" />{" "}
              </Col>
            </Row>
          )}
        </Card>
      </Row>
      {console.log("spinner value: ", showSpinner)}
      {showSpinner && <Spinner />}
      {showBiostrapMessage && (
        <Alert align="center" color="warning">
          {biostrapMessage}
        </Alert>
      )}
      <Row>
        <Card className="my-2">
          {showGraphs && (
            <Row>
              <Col md="9">
                <Row>
                  <div align="center" className="mt-2">
                    <h5 className=" text-muted">Steps</h5>
                  </div>
                </Row>
                <Apaexlinecolumn data={steps_data} xAxis={steps_xAxis} title="Steps" />{" "}
              </Col>
              <Col md="3">
                <Row>
                  <Col className="mt-5" md="6">
                    <div className="mt-5" align="center">
                      <h5 className="text-muted">{Math.round(steps)}</h5>
                      <h6 className="text-muted">Steps</h6>
                    </div>
                  </Col>
                  <Col className="mt-5" md="6">
                    <div className="mt-5" align="center">
                      <h5 className="text-muted">{Math.round(distance)} KM</h5>
                      <h6 className="text-muted">Distance</h6>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="" md="6">
                    <div className="mt-3" align="center">
                      <h5 className="text-muted">
                        {dhours} H {dminutes} M
                      </h5>

                      <h6 className="text-muted">Duration</h6>
                    </div>
                  </Col>
                  <Col className="" md="6">
                    <div className="mt-3" align="center">
                      <h5 className="text-muted">{Math.round(calories)} KCAL</h5>

                      <h6 className="text-muted">Calories</h6>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Card>
      </Row>
      {graphDuration != "day" && (
        <Row>
          <Card className="my-2">
            {showGraphs && (
              <Row>
                <Col md="9">
                  <Row>
                    <div align="center" className="mt-2">
                      <h5 className=" text-muted">Calories</h5>
                    </div>
                  </Row>
                  <DashedLine data={cals_data} xAxis={cals_xAxis} title="" />{" "}
                </Col>
                <Col md="3">
                  <Row align="center" className="mt-5">
                    <br />
                    <br />
                    <h6 className="text-muted mt-5">
                      Goal <h3 className="text-muted">3000</h3>{" "}
                    </h6>
                    <h6 className="text-muted">
                      Cals In/Out <h3 className="text-muted">{Math.round(calories)}</h3>{" "}
                    </h6>
                  </Row>
                </Col>
              </Row>
            )}
          </Card>
        </Row>
      )}

      <Row>
        <Card className="my-2">
          {showSleepGraph && (
            <Row>
              <Col md="9">
                <Row>
                  <div align="center" className="mt-2">
                    <h5 className=" text-muted">Sleep</h5>
                  </div>
                </Row>
                <SleepGraph
                  deepSleep={deepSleep}
                  lightSleep={lightSleep}
                  totalSleep={totalSleep}
                  xAxis={sleep_xAxis}
                  title="Sleep"
                  h1="Deep Sleep"
                  h2="Light Sleep"
                  h3="Total Sleep"
                />{" "}
              </Col>
              <Col md="3">
                <Row>
                  <Col className="mt-5" md="6">
                    <div className="mt-5" align="center">
                      <h5 className="text-muted">
                        {dshours} H {dsminutes} M{" "}
                      </h5>

                      <h6 className="text-muted">Deep Sleep</h6>
                    </div>
                  </Col>
                  <Col className="mt-5" md="6">
                    <div className="mt-5" align="center">
                      <h5 className="text-muted">
                        {lshours} H {lsminutes} M
                      </h5>

                      <h6 className="text-muted">Light Sleep</h6>
                    </div>
                  </Col>
                </Row>

                <Row align="center" className="mt-4">
                  <h5 className="text-muted">
                    {tshours} H {tsminutes} M
                  </h5>
                  <br />
                  <h6 className="text-muted">Total Sleep</h6>
                </Row>
              </Col>
            </Row>
          )}
        </Card>
      </Row>

      {/* biomtrics code for admin end */}
    </React.Fragment>
  );
};

export default UserInfo;
