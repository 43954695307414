import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div align='center'>
          <Link to='/privacy-policy' target="_blank">Privacy Policy</Link> | <Link to='/terms-of-service' target="_blank">Terms of Services</Link>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
