import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAdminRoute,
  isMemberRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isAuthProtected && !isMemberRoute && !isAdminRoute) {
        return <Component {...props} />;
      }
      if (!localStorage.getItem("loggedin")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (
        isAuthProtected &&
        isAdminRoute &&
        (localStorage.getItem("role") == "admin" ||
          localStorage.getItem("role") == "superAdmin" ||
          localStorage.getItem("role") == "reportBuilder")
      ) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }
      if (
        isAuthProtected &&
        isMemberRoute &&
        localStorage.getItem("role") == "member"
      ) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }
      // if (isAuthProtected && isMemberRoute) {
      //   return <Component {...props} />;
      // }
      // return (
      //   <Layout>
      //     <Component {...props} />
      //   </Layout>
      // );
    }}
  />
);

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AppRoute;
