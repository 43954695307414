import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../screens/Member/Dashboard/index";
import Login from "../screens/Authentication/Login";
import Register from "../screens/Authentication/Register";
import ForgetPwd from "../screens/Authentication/ForgetPassword";

//Education
import otc from "../screens/Member/Education/otc";
import aas from "../screens/Member/Education/aas";
import sarms from "../screens/Member/Education/sarms";
import peptides from "../screens/Member/Education/peptides";
import rxCompounding from "../screens/Member/Education/rx-compounding";
import technology from "../screens/Member/Education/technology";
import LegalCompliance from "../screens/Member/Education/legal-compliance";
import ancillaries from "../screens/Member/Education/ancillaries";

//Tools
import AnalyticalTesting from "../screens/Member/Tools/analytical-testing";
import DietBuilder from "../screens/Member/Tools/diet-builder";
import DoseCalculator from "../screens/Member/Tools/dose-calculator";
import HalfLifePlotter from "../screens/Member/Tools/half-life-plotter";
import ReconstitutionCalculator from "../screens/Member/Tools/reconstitution-calculator";

//Standards
import CyclingStandards from "../screens/Member/Performance Standards/CyclingStandards";
import RunningStandards from "../screens/Member/Performance Standards/RunningStandards";
import StrengthStandards from "../screens/Member/Performance Standards/StrengthStandards";
import SwimmingStandards from "../screens/Member/Performance Standards/SwimmingStandards";
//settings
import UserSettings from "../screens/Member/User Settings/user-settings";
import Home from "../screens/Admin/Dashboard/Home";
import ChangePassword from "../screens/Authentication/ChangePassword";
import ResetPasswordMessage from "../screens/Authentication/ResetPasswordMessage";
import Biometrics from "../screens/Member/Dashboard/biometrics";
//terms-of-service
import termsOfService from "../screens/Authentication/terms-of-services";
//privacy-policy
import privacyPolicy from "../screens/Authentication/privacy-policy";
//landing-page
// import LandingHome from '../screens/Landing/home';
import LandingHome from "../screens/landing_page";
import About from "../screens/Landing/about";
import TermsOfService from "../screens/Authentication/terms-of-services";
import PrivacyPolicy from "../screens/Authentication/privacy-policy";

import UserInfo from "../screens/Admin/UserInfo/userInfo";
import PrescriptionManagement from "../screens/Member/Prescription Management/prescription-management";
import AdminSettings from "../screens/Admin/UserInfo/adminSettings";
import UserRegistered from "../screens/Authentication/UserRegistered";
import EmailVerified from "../screens/Authentication/EmailVerified";
import ReportBuilder from "../screens/Admin/Report Builder/ReportBuilder";
import AddLab from "../screens/Admin/Report Builder/AddLab";
import EditLab from "../screens/Admin/Report Builder/EditLab";
import DuplicateReport from "../screens/Admin/Report Builder/DuplicateReport";

const publicRoutes = [
  // { path: '/logout', component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/change-password", component: ChangePassword },
  { path: "/reset-password-message", component: ResetPasswordMessage },
  { path: "/user-registered", component: UserRegistered },
  { path: "/email-verified", component: EmailVerified },
  { path: "/terms-of-service", component: TermsOfService },
  { path: "/privacy-policy", component: PrivacyPolicy },

  { path: "/", component: LandingHome },
];

const authProtectedRoutes = [
  //education screens
  { path: "/otc", component: otc },
  { path: "/aas", component: aas },
  { path: "/sarms", component: sarms },
  { path: "/peptides", component: peptides },
  { path: "/ancillaries", component: ancillaries },
  { path: "/rx-compounding", component: rxCompounding },
  { path: "/technology", component: technology },
  { path: "/legal-compliance", component: LegalCompliance },

  //tools
  { path: "/analytical-testing", component: AnalyticalTesting },
  { path: "/diet-builder", component: DietBuilder },
  { path: "/dose-calculator", component: DoseCalculator },
  { path: "/half-life-plotter", component: HalfLifePlotter },
  { path: "/reconstitution-calculator", component: ReconstitutionCalculator },

  //Performance Standards
  { path: "/CyclingStandards", component: CyclingStandards },
  { path: "/RunningStandards", component: RunningStandards },
  { path: "/StrengthStandards", component: StrengthStandards },
  { path: "/SwimmingStandards", component: SwimmingStandards },

  //User settings
  { path: "/user-settings", component: UserSettings },

  { path: "/dashboard", component: Dashboard },

  { path: "/biometrics", component: Biometrics },

  { path: "/prescription-management", component: PrescriptionManagement },

  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const adminRoutes = [
  //admin paths
  { path: "/home", component: Home },
  { path: "/user-info", component: UserInfo },
  { path: "/admin-settings", component: AdminSettings },
  { path: "/report-builder", component: ReportBuilder },
  { path: "/add-lab", component: AddLab },
  { path: "/edit-lab/:id", component: EditLab },
  { path: "/duplicate-report/:id", component: DuplicateReport },
];

export { authProtectedRoutes, publicRoutes, adminRoutes };
