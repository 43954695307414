import classnames from "classnames";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import { Alert, Card, Col, Container, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Cookies from "universal-cookie";

import Spinner from "../../../common/helper/spinner";
import { logout } from "../../../utils";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import CompleteReports from "./CompleteReports";
import SavedReports from "./SavedReports";

import ftd from "../../../assets/images/maxfit.me2.svg";
import { DateConversion } from "../../../utils/utilityFunctions";

const ReportBuilder = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [success, setSuccess] = useState();
  const [sizePerPage, setSizePerPage] = useState(15);
  const [page, setPage] = useState(1);
  const [showSpinner, setShowSpinner] = useState(true);
  const [error, setError] = useState("");
  const [completedData, setCompletedData] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [searching, setSearching] = useState(false);

  //functions for pagination and search start

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function logoutFunction() {
    logout();
    props.history.push("/login");
  }
  useEffect(() => {
    setShowSpinner(true);

    axiosConfig
      .post(
        "/labBuilder/getAllEntity",
        { keyword: keyword, currentPage: 1, pageSize: 15 },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        const savedDataList = [];
        const completedDataList = [];
        await response.data.Data.forEach((s) => {
          const formattedDate = DateConversion(s.dateAdded);
          const listObj = {
            id: s._id,
            cronConfig: s.cronConfig,
            entityID: s.entityID,
            name: s.reportTitle,
            orgName: s.entityName,
            createdBy: s.createdBy,
            templateName: s.templateName,
            date: formattedDate,
            sampleReportUrl: s.sampleReportUrl,
            dataSource: s.dataSource || "-",
          };

          if (s.status.toString() === "In_Complete") {
            savedDataList.push(listObj);
          } else {
            completedDataList.push(listObj);
          }
        });
        await setSavedData(savedDataList);
        await setCompletedData(completedDataList);
        await setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, []);

  //functions for search and pagination start

  function clearSearch(keyword) {
    if (keyword === "") {
      reloadAPINow();
    }
  }

  function handlePageChange(pageNo) {
    setSizePerPage(15);
    setKeyword("");
    setPage(pageNo);

    getAllReportPageChange(sizePerPage, pageNo);
  }

  function getAllReportPageChange(sizePerPage, pageNo) {
    setSearching(true);
    axiosConfig
      .post(
        "labBuilder/getAllEntity",
        {
          keyword: "",
          currentPage: pageNo,
          pageSize: sizePerPage,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        const savedDataList1 = [];
        const completedDataList1 = [];
        await response.data.Data.forEach((s) => {
          const formattedDate = DateConversion(s.dateAdded);
          const listObj = {
            id: s._id,
            cronConfig: s.cronConfig,
            entityID: s.entityID,
            name: s.reportTitle,
            orgName: s.entityName,
            createdBy: s.createdBy,
            templateName: s.templateName,
            date: formattedDate,
            biostrapEmail: "-",
            sampleReportUrl: s.sampleReportUrl,
            dataSource: s.dataSource || "-",
          };

          if (s.status.toString() === "In_Complete") {
            savedDataList1.push(listObj);
          } else {
            completedDataList1.push(listObj);
          }
        });

        await setSavedData(savedDataList1);
        await setCompletedData(completedDataList1);
        await setShowSpinner(false);
      })
      .catch((error) => {
        setShowSpinner(false);
        setError("An error occurred while trying to fetch user list. " + error?.response?.data?.message);

        errorResponse(error);
      });
  }

  function getAllReportSearch(keyword) {
    setSearching(true);
    axiosConfig
      .post(
        "labBuilder/getAllEntity",
        {
          keyword: keyword,
          currentPage: 1,
          pageSize: 15,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        const savedDataList1 = [];
        const completedDataList1 = [];
        await response.data.Data.forEach((s) => {
          const formattedDate = DateConversion(s.dateAdded);
          const listObj = {
            id: s._id,
            cronConfig: s.cronConfig,
            entityID: s.entityID,
            name: s.reportTitle,
            orgName: s.entityName,
            createdBy: s.createdBy,
            templateName: s.templateName,
            date: formattedDate,
            biostrapEmail: "-",
            sampleReportUrl: s.sampleReportUrl,
            dataSource: s.dataSource || "-",
          };

          if (s.status.toString() === "In_Complete") {
            savedDataList1.push(listObj);
          } else {
            completedDataList1.push(listObj);
          }
        });

        await setSavedData(savedDataList1);
        await setCompletedData(completedDataList1);
        await setShowSpinner(false);
      })
      .catch((error) => {
        setShowSpinner(false);
        setError("An error occurred while trying to fetch user list. " + error?.response?.data?.message);

        errorResponse(error);
      });
  }

  function reloadAPINow(keyword = "", limit = 15, pageNo = 1, message = "") {
    if (message) {
      setSuccess(message);
    }
    setPage(pageNo);
    setSizePerPage(limit);
    setKeyword(keyword);

    setShowSpinner(true);

    axiosConfig
      .post(
        "/labBuilder/getAllEntity",
        {
          keyword: keyword,
          pageSize: sizePerPage,
          currentPage: pageNo,
        },

        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        const savedDataList1 = [];
        const completedDataList1 = [];
        await response.data.Data.forEach((s) => {
          const formattedDate = DateConversion(s.dateAdded);
          const listObj = {
            id: s._id,
            cronConfig: s.cronConfig,
            entityID: s.entityID,
            name: s.reportTitle,
            orgName: s.entityName,
            createdBy: s.createdBy,
            templateName: s.templateName,
            date: formattedDate,
            biostrapEmail: "-",
            sampleReportUrl: s.sampleReportUrl,
            dataSource: s.dataSource || "-",
          };

          if (s.status.toString() === "In_Complete") {
            savedDataList1.push(listObj);
          } else {
            completedDataList1.push(listObj);
          }
        });

        await setSavedData(savedDataList1);
        await setCompletedData(completedDataList1);
        await setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  return (
    <React.Fragment>
      {/* <ClinicNavbar /> */}
      <div className="admin-home">
        <MetaTags>
          <title>Report Builder | Marek Health</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Card>
              <Row>
                <Col xs="4" md="4">
                  <Link to="/home" align="left">
                    <img src={ftd} alt="" height="100" />
                  </Link>
                </Col>
                <Col xs="4" md="4">
                  <div align="center" className="admin-heading">
                    <h4>Admin Portal</h4>
                  </div>
                </Col>
                <Col xs="4" md="4">
                  <div align="right" className="mt-4">
                    <button onClick={logoutFunction} className="btn btn-danger">
                      Logout
                    </button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>

          <Row>
            <Col xs="12">
              <Card className="p-3">
                <Row>
                  <Col xs="12">
                    <div className="mt-2">
                      <Link to="/home" className="btn btn-primary">
                        Back
                      </Link>{" "}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div align="right" className="mt-4">
                      <Link to="/add-lab" className="btn btn-primary">
                        Add Lab
                      </Link>{" "}
                    </div>
                  </Col>
                </Row>

                <Nav tabs className="nav-tabs-custom nav-justified pt-2">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Completed
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Saved
                    </NavLink>
                  </NavItem>
                </Nav>

                {/* add search bar here */}

                <Row className="my-3">
                  <Col xs={6} sm={8} m={1} lg={2}>
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          value={keyword}
                          onChange={(e) => {
                            setKeyword(e.target.value);

                            // this.filterChanged(e.target.value);
                            clearSearch(e.target.value);
                          }}
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>

                  <Col xs={4} sm={3} m={9} lg={1}>
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        var key = keyword;

                        getAllReportSearch(key);
                      }}
                    >
                      Search
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Alert align="center" color="warning">
                    Lab report with Setting will be displayed as a Sample ONLY!
                  </Alert>
                  {success ? (
                    <Alert color="success" className="text-center" isOpen={success} toggle={() => setSuccess("")}>
                      {success}
                    </Alert>
                  ) : null}
                </Row>

                <Row>
                  {showSpinner ? (
                    <Spinner />
                  ) : (
                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <CompleteReports reloadAPINow={reloadAPINow} cData={completedData} />
                      </TabPane>
                      <TabPane tabId="2">
                        <SavedReports reloadAPINow={reloadAPINow} sData={savedData} />
                      </TabPane>
                    </TabContent>
                  )}
                </Row>
                <Row className="align-items-md-center mt-3 px-3">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="text-md-right ms-auto">
                      <PaginationComponent
                        size="sm"
                        totalItems={0}
                        pageSize={sizePerPage}
                        onSelect={(e) => handlePageChange(e)}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportBuilder;
