import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class DashedLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: props.title,
          data: props.data,
        },
      ],
      options: {
        chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
        colors: ['#556ee6', '#f46a6a', '#34c38f'],
        dataLabels: { enabled: !1 },
        stroke: { width: [3, 4, 3], curve: 'straight', dashArray: [0, 8, 5] },
        title: { text: props.title, align: 'center' },
        markers: { size: 0, hover: { sizeOffset: 6 } },
        xaxis: {
          categories: props.xAxis,
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (e) {
                  return e + ' kcal';
                },
              },
            },
            {
              title: {
                formatter: function (e) {
                  return e;
                },
              },
            },
            {
              title: {
                formatter: function (e) {
                  return e;
                },
              },
            },
          ],
        },
        grid: { borderColor: '#f1f1f1' },
      },
    };
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart options={this.state.options} series={this.state.series} type='line' height='380' className='apex-charts' />
      </React.Fragment>
    );
  }
}

export default DashedLine;
