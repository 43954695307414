import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import Spinner from '../../../common/helper/spinner';

const RemoveFromOrganizationModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const toggle = () => {
    props.closeModal();
  };

  const submitButton = () => {
    //I NEED TO ADD API CALL HERE

    if (props.removeAccount == 'biostrap') {
      setShowSpinner(true);
      axiosConfig
        .get('userSetting/removeBiostrap', {
          headers: {
            Authorization: 'Bearer ' + new Cookies().get('user_token'),
          },
        })
        .then(async (response) => {
          console.log(response.data);
          localStorage.setItem('biostrapEmail', '-');
          localStorage.setItem('biostrapAuth', 'false');
        })
        .catch((error) => {
          errorResponse(error);
        });
    } else {
      setShowSpinner(true);
      axiosConfig
        .get('userSetting/removeCalDav', {
          headers: {
            Authorization: 'Bearer ' + new Cookies().get('user_token'),
          },
        })
        .then(async (response) => {
          console.log(response.data);
          localStorage.setItem('caldevAuth', 'false');
          localStorage.setItem('caldavEmail', '-');
        })
        .catch((error) => {
          errorResponse(error);
        });
    }

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {});

  return (
    <React.Fragment>
      {props.showModal ? (
        <Modal isOpen={props.showModal} className={props.className}>
          <ModalHeader>
            <div align='center'>
              <h3>Are you sure you want to remove this {props.removeAccount} account?</h3>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <div align='center' className='mt-4'>
                <Col>
                  {showSpinner && <Spinner />}
                  {!showSpinner && (
                    <button type='button' className='btn btn-success me-2' onClick={submitButton}>
                      Yes
                    </button>
                  )}
                  {!showSpinner && (
                    <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                      Cancel
                    </button>
                  )}
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default RemoveFromOrganizationModal;
