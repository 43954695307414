import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { landingImages } from '../../../assets/images/landing';

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <div>
      <div className={click ? 'main-container' : ''} onClick={() => Close()} />
      <nav className='landing-navbar' onClick={(e) => e.stopPropagation()}>
        <div className='landing-nav-container'>
          <Link exact to='/landing' className='landing-nav-logo'>
            <i>
              {' '}
              <img className='logo-img' src={landingImages['image3']} alt='logo' />{' '}
            </i>
          </Link>

          <ul className={click ? 'landing-nav-menu active' : 'landing-nav-menu'}>
            <li className='landing-nav-item'>
              <Link exact to='/landing' activeClassName='active' className='landing-nav-links' onClick={click ? handleClick : null}>
                Home
              </Link>
            </li>
            <li className='landing-nav-item'>
              <Link exact to='/about' activeClassName='active' className='landing-nav-links' onClick={click ? handleClick : null}>
                About
              </Link>
            </li>
            <li className='landing-nav-item'>
              <Link exact to='/login' activeClassName='active' className='landing-nav-links' onClick={click ? handleClick : null}>
                Sign in
              </Link>
            </li>
            <li className='landing-nav-item'>
              <Link exact to='/register' activeClassName='active' className='landing-nav-links' onClick={click ? handleClick : null}>
                Register
              </Link>
            </li>
          </ul>
          <div className='landing-nav-icon' onClick={handleClick}>
            <i className={click ? 'fa fa-times' : 'fa fa-bars'}></i>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
