import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const privacyPolicy = () => {
  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Privacy Policy | Marek Health</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col md='2'>
              <Link to='/'>
                <button className='mb-4 btn btn-primary'>Back</button>
              </Link>
            </Col>
          </Row>

          <h1 style={{ textAlign: 'center' }}>PRIVACY POLICY</h1>

          <div align='center'>
            <hr style={{ width: '100px' }} />
          </div>

          <div class='entry-content'>
            <p>
              <strong>DAAS, LLC Privacy Policy</strong>
            </p>

            <p>
              <em>
                <u>Last Updated: __</u>
              </em>
              <em>
                <u>6</u>
              </em>
              <em>
                <u>/</u>
              </em>
              <em>
                <u>20/2020</u>
              </em>
            </p>

            <p>DAAS, LLC is a Massachusetts limited liability company (“DAAS”) that maintains the FitDataMax software as a service application (the “SAAS App”). This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>

            <p>Topics:</p>

            <ul>
              <li>What data do we collect?</li>
              <li>How do we collect your data?</li>
              <li>How will we use your data?</li>
              <li>Information we share.</li>
              <li>How do we store your data?</li>
              <li>How long do we keep your data?</li>
              <li>Where we send your data?</li>
              <li>How do we protect personal information?</li>
              <li>Children’s Privacy.</li>
              <li>Marketing</li>
              <li>What are your data protection rights?</li>
              <li>What are cookies?</li>
              <li>How do we use cookies?</li>
              <li>What types of cookies do we use?</li>
              <li>How to manage your cookies</li>
              <li>Privacy policies of other websites.</li>
              <li>Data Breaches</li>
              <li>Changes to our privacy policy</li>
              <li>How to contact us</li>
              <li>How to contact the appropriate authorities</li>
            </ul>

            <h1>What data do we collect?</h1>

            <p>DAAS collects the following data:</p>

            <ul>
              <li>Personal identification information (Email address)</li>
              <li>Information you provide us to be used in the SAAS App (supplements, types of workouts, times and workout outputs, diagnostic lab results, etc.)</li>
              <li>Usage information associated with cookies, web beacons, and similar technology tools</li>
            </ul>

            <h2>Contact and Personal Information</h2>

            <p>
              You will need to provide contact information (such as your name, address, telephone number and email address) on this web site. Providing this information is necessary to create an account. You may browse the web site without providing this information, but failure to provide the information will prevent you from creating an account and using the SAAS App. We will not ask you for any credit card number, social security number, driver’s license number or other financial account number on the website. Here are some of the ways you may provide the information and the types of
              information you may submit. We also tell you how we may use the information.
            </p>

            <h2>Contact Us</h2>

            <p>If you email us through the “Contact Us” page&nbsp;on this site, we ask you for information such as your name and email address so we can respond to your questions and comments. You may choose to provide additional information as well. You should not provide any credit card number, social security number or other financial account number when using the contact us screen.</p>

            <h2>Send Content to a Friend</h2>

            <p>You can send invite friends to view our website. To do so, we ask you to provide your name and your friend’s email address. We use this information to send the content to your friend.</p>

            <h2>Account Creation</h2>

            <p>We require you to provide an email address for access and use of the SAAS App. If you wish to maintain maximum privacy, it is suggested to use a throwaway email address created solely for use on this site and not a personal one.</p>

            <h2>Usage of SAAS App</h2>

            <p>During the use of the SAAS App, you may choose to provide data such as: Workout types, workout durations, times or other outputs of workouts, supplements/medications taken, bio-metric information, diagnostic lab results, diet, etc.</p>

            <h2>Other Information</h2>

            <p>We collect certain aggregate and non-personal information when you visit this web site. Aggregate and non-personal information does not relate to a single identifiable visitor. It tells us such things as how many users visited our site and the pages accessed. By collecting this information, we learn how to best tailor our web site to our visitors. We collect this information either through “cookie” technology or with “web beacons,” as explained in the cookies section.</p>

            <h1>How do we collect your data?</h1>

            <p>You directly provide DAAS with most of the data we collect. We collect data and process data when you:</p>

            <ul>
              <li>Register online for an account</li>
              <li>Voluntarily complete a form or send us email</li>
              <li>Use or view our website via your browser&#8217;s cookies.</li>
              <li>Enter information through the SAAS App</li>
            </ul>

            <h1>How will we use your data?</h1>

            <p>DAAS collects your data so that we can:</p>

            <ul>
              <li>Process your payments</li>
              <li>Email you updates for our services and other administrative matters</li>
              <li>Email you with information on products and services we think you may be interested in.</li>
              <li>Email you with information regarding fitness or bodybuilding topics</li>
              <li>Perform calculations with our calculators</li>
              <li>Provide the functionality of the SAAS App</li>
              <li>Deliver targeted advertising to you.</li>
              <li>Request Feedback.</li>
              <li>To protect our Services.</li>
              <li>To enforce our terms, conditions and policies for business, legal or policy reasons</li>
              <li>To respond to legal requests and prevent harm.</li>
              <li>To manage user accounts.</li>
              <li>To respond to user inquiries/offer support to users.</li>
              <li>
                For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information as we do not collect any beyond your email address if using a personal one which can be
                tied to you. We suggest using a throwaway account created solely for use on this site to maintain maximum privacy.
              </li>
            </ul>

            <h1>Information We Share</h1>

            <p>We may process or share data based on the following legal basis:</p>

            <ul>
              <li>
                <strong>Consent:</strong>&nbsp;We may process your data if you have given us specific consent to use your personal information in a specific purpose.
              </li>
              <li>
                <strong>Legitimate Interests:</strong>&nbsp;We may process your data when it is reasonably necessary to achieve our legitimate business interests.
              </li>
              <li>
                <strong>Performance of a Contract:&nbsp;</strong>Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
              </li>
              <li>
                <strong>Legal Obligations:</strong>&nbsp;We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
              </li>
              <li>
                <strong>Vital Interests:</strong>&nbsp;We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
              </li>
            </ul>

            <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>

            <ul>
              <li>
                <strong>Vendors, Consultants and Other Third-Party Service Providers.</strong>&nbsp;We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the&nbsp;Services, which will enable them to collect data about how you interact with the&nbsp;Services&nbsp;over
                time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.&nbsp;We have contracts in place with our data processors. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will not share your personal information with any organization apart from us. They will hold it
                securely and retain it for the period we instruct.
              </li>
              <li>
                <strong>Business Transfers.</strong>&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
              </li>
              <li>
                <strong>Business Partners.</strong>&nbsp;We may share your information with our business partners to offer you certain products, services or promotions.
              </li>
            </ul>

            <p>Among the data that we do plan to share from fitdatamax.com with service providers for processing, includes the following third parties.</p>

            <h1>How do we store your data?</h1>

            <p>DAAS securely stores your data in our services located in the United States of America. . We may from time to time create temporary copies of this data when performing calculations, but this data is not stored longer than required to perform the calculation. DAAS does not store any financial account information.</p>

            <p>If you are accessing our Services from outside United States, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information, in United States, and other countries.</p>

            <p>If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.</p>

            <h1>How long will we keep your data?</h1>

            <p>
              We will retain your information for as long as it is necessary for the purposes set out in this&nbsp;privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until
              deletion is possible.
            </p>

            <h1>How We Protect Personal Information</h1>

            <p>We maintain administrative, technical and physical safeguards to protect against unauthorized disclosure, use, alteration or destruction of the personal information you provide on this web site.</p>

            <h1>Children’s Privacy</h1>

            <p>This web site is not directed to children under the age of thirteen and we do not knowingly collect personal information from children under the age of thirteen on the site. If we become aware that we have inadvertently received personal information from a visitor under the age of thirteen on the site, we will delete the information from our records.</p>

            <h1>Marketing</h1>

            <p>DAAS would like to send you information about new services and share with other companies that we believe have products and services that interest your</p>

            <p>If you have agreed to receive marketing, you may always opt out at a later date.</p>

            <p>You have the right at any time to stop DAAS from contacting you for marketing purposes or giving your data to other persons</p>

            <p>If you no longer wish to be contacted for marketing purposes, please login to your account and indicate via the checkboxes that you no longer wish to receive the data. .</p>

            <h1>What are your data protection rights?</h1>

            <p>DAAS would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>

            <ul>
              <li>
                <strong>The right to access </strong>&#8211; You have the right to request DAAS for copies of your personal data. We may charge you a small fee for this service.
              </li>
              <li>
                <strong>The right to rectification </strong>&#8211; You have the right to request that DAAS correct any information you believe is inaccurate. You also have the right to request DAAS to complete information you believe is incomplete.
              </li>
              <li>
                <strong>The right to erasure </strong>&#8211; You have the right to request that DAAS erase your personal data, under certain conditions.
              </li>
              <li>
                <strong>The right to restrict processing </strong>&#8211; You have the right to request that DAAS restrict the processing of your personal data, under certain conditions.
              </li>
              <li>
                <strong>The right to object to processing </strong>&#8211; You have the right to object to DAAS&#8217;s processing of your personal data, under certain conditions.
              </li>
              <li>
                <strong>The right to data portability </strong>&#8211; You have the right to request that DAAS transfer the data that we have collected to another organization, or directly to you, under certain conditions.
              </li>
            </ul>

            <h2>EU Specific Rights</h2>

            <p>
              In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>

            <p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.</p>

            <p>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</p>

            <p>______________________</p>

            <h2>Account Information</h2>

            <p>If you would at any time like to review or change the information in your account or terminate your account, you can log into your account settings and update your user account.</p>

            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>

            <h2>California Users</h2>

            <p>
              California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact
              information provided below.
            </p>

            <p>
              If you are under 18 years of age, reside in California, and have a registered account with the&nbsp;Services, you have the right to request removal of unwanted data that you publicly post on the&nbsp;Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the&nbsp;Services, but please be aware that the data may not be completely or comprehensively removed from our
              systems.
            </p>

            <h2>Contacting Us on Privacy Information</h2>

            <p>
              If you have a question on any of these rights, please contact us at our email: <a href='mailto:admin@fitdatamax.com'>admin@fitdatamax.com</a> or any of the contact’s shown in the Contact Us section below.
            </p>

            <h1>What are cookies?</h1>

            <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.</p>

            <p>For further information , visit allaboutcookies.org.</p>

            <h1>How do we use cookies?</h1>

            <p>DAAS uses cookies in a range of ways to improve your experience on our website , including:</p>

            <ul>
              <li>Keeping you signed in</li>
              <li>Understanding how you use our website</li>
              <li>Targeting advertising to your and improving our own marketing campaigns</li>
              <li>Providing some functions with our applications</li>
              <li>Performing calculations watthour calculator</li>
            </ul>

            <h1>What types of cookies do we use?</h1>

            <p>There are a number of different types of cookies, however, our website uses:</p>

            <ul>
              <li>Functionality &#8211; DAAS uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li>
              <li>Advertising &#8211; DAAS uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. DAAS sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
            </ul>

            <h2>Web Beacons</h2>

            <p>
              Certain pages on our site contain “web beacons” (also known as Internet tags, pixel tags and clear GIFs). These web beacons allow third parties to obtain information such as the IP address of the computer that downloaded the page on which the beacon appears, the URL of the page on which the beacon appears, the time the page containing the beacon was viewed, the type of browser used to view the page, and the information in cookies set by the third party. We use log files to store the data that is collected through web beacons. DAAS may also use third party web beacons to help
              manage online advertising. These web beacons would be provided by our ad management partners and Google Analytics. These files enable Google Analytics to recognize a unique cookie on your web browser, which in turn enables us to learn which advertisements bring users to our web site. The cookie on your web browser was placed by DAAS, or by another advertiser who works with Google Analytics. With both cookies and web beacon technology, the information that we collect and share is anonymous and not personally identifiable. It does not contain your name, address, telephone number,
              or email address.
            </p>

            <h2>Cookies and Tracking</h2>

            <p>
              The SAAS App use “cookies” to enable you to sign in to our services, to track your traffic patterns, and maintain certain “state” information so that data is retained long enough to allow the application to work. A cookie is a text file that is placed on your computer’s hard drive. Cookies help us personalize and make improvements to&nbsp;DAAS’s website&nbsp;so that you can have a pleasant and productive experience, and are needed for the SAAS App. You can set your browser to send a warning message before accepting cookies or to refuse cookies. Check your browser for instructions
              on how to handle cookies. If you choose to decline cookies, you may not be able to use all of the features of DAAS website or the features of the SAAS App.
            </p>

            <h2>Online Behavioral Advertising by&nbsp;DAAS&nbsp;and Others</h2>

            <p>DAAS also works with third party advertising and personalization partners that use cookies to help us display personalized content and appropriate advertising during your visits to DAAS&nbsp;Sites and other websites. These cookies identify the pages you view, the links and ads you click on, other actions you take on those web pages, and the site from which you came before arriving at a web page. This also helps us measure the performance of our advertising campaigns.</p>

            <p>Similarly, third parties use cookies to deliver advertising for companies other than DAAS to you on the Internet, whether you are on DAAS&nbsp;Sites or other websites, based on your visits to DAAS Sites and other websites. These cookies assist the third parties in identifying the pages you view, the links and ads you click on, other actions you take on those web pages, and the site from which you came before arriving at a web page.</p>

            <p>
              If you would like to opt out of this program, please visit the Network Advertising Initiative opt-out page located at&nbsp;<a href='http://www.networkadvertising.org/choices'>http://www.networkadvertising.org/choices</a>.
            </p>

            <h1>How to manage cookies</h1>

            <p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>

            <h2>“Do Not Track” Signals</h2>

            <p>
              Please note that while you may have the ability to opt-out of targeted advertising through the&nbsp;<a href='http://www.networkadvertising.org/choices'>http://www.networkadvertising.org/choices</a>&nbsp;link above and you may be able to control the use of cookies through your web browser as described in the “Cookies and Tracking” section above, some web browsers may give you the ability to enable a “do not track” setting that sends a special signal to the websites you encounter while web browsing. This is different from disabling certain forms of tracking by declining cookies in
              your browser settings, as browsers with the “do not track” setting enabled still have the ability to accept cookies. DAAS&nbsp;does not respond to web browser “do not track signals” at this time. If we do so in the future, we will describe how we do so in this&nbsp;Privacy&nbsp;Policy.
            </p>

            <h1>Privacy policies of other websites</h1>

            <p>DAAS website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>

            <h1>Data Breaches</h1>

            <p>
              A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or disposal of personal information. You will be notified about data breaches when&nbsp;DAAS LLC&nbsp;believes you are likely to be at risk or serious harm. For example, a data breach may be likely to result in serious financial harm or harm to your mental or physical well-being. In the event that&nbsp;DAAS LLC&nbsp;becomes aware of a security breach which has resulted or may result in unauthorized access, use or disclosure of personal information&nbsp;DAAS LLC&nbsp;will promptly
              investigate the matter and notify the applicable Supervisory Authority not later than 72 hours after having become aware of it, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons.
            </p>

            <h1>How To Opt-Out of receiving Emails From DAAS</h1>

            <p>
              If you no longer wish to receive email news, announcements, requests for donations or similar email communications please log into your account and indicate you no longer wish to receive, the communications. You may also email us at <a href='mailto:admin@fitdatamax.com'>admin@fitdatamax.com</a> and write, “unsubscribe” in the subject line.
            </p>

            <h1>Changes to Our Privacy Policy</h1>

            <p>This Privacy Statement may be updated periodically and without prior notice to you to reflect changes in our online information practices. We will post a prominent notice on this web site to notify you of any significant changes to our Privacy Statement and indicate at the top of the statement when it was most recently updated. DAAS keeps its privacy policy under regular review and places any updates on this web page.</p>

            <h1>How to contact us</h1>

            <p>If you have any questions about DAAS&#8217;s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. If you have any questions or comments about this Privacy Policy, or if you would like us to update information we have about you or your preferences, You may reach us at:</p>

            <p>
              our email: <a href='mailto:admin@fitdatamax.com'>admin@fitdatamax.com</a>
            </p>

            <p>Please note, we have 1 month to respond to you.</p>

            <h1>How to contact the appropriate authority</h1>

            <p>
              DAAS is located in the Commonwealth of Massachusetts. Massachusett’s Attorney General’s office can be contacted at:{' '}
              <a href='mailto:ago@state.ma.us'>
                <strong>ago@state.ma.us</strong>
              </a>{' '}
              or by phone at: <a href='tel:+16177272200'>(617) 727-2200</a>
            </p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default privacyPolicy;
