import React, { useState, useEffect } from "react";
import { FormGroup, Row, Col } from "reactstrap";

const MainForm = (props) => {
  const { CallBack } = props;
  const list1 = [
    "Suspension",
    "Propionate",
    "Phenylpropionate",
    "Isocaproate",
    "Enanthate",
    "Cypionate",
    "Decanoate",
    "Undecanoate",
  ];
  const list2 = ["Suspension", "Acetate", "Enanthate"];
  const list3 = ["Propionate (standard)", "Enanthate"];
  const list4 = ["Phenylpropionate (NPP)", "Decanoate (Deca Durabolin)"];
  const list5 = ["-"];
  const list6 = ["Oral", "Injectable"];
  const list7 = ["Crystal", "Powder"];
  const [weeks, setWeeks] = useState([]);
  const [mainFormData, setMainFormData] = useState({
    id: props.Count,
    Mg: "",
    Compound: "",
    Compound2: "",
    Day: "",
    StartingWeek: "",
    EndingWeek: "",
  });

  function handleChange(e) {
    const { title, value } = e.target;
    setMainFormData((prevValues) => {
      return { ...prevValues, [title]: value };
    });
  }

  function setWeek() {
    const weekLength = props.Week();
    setWeeks([]);
    for (let i = 1; i <= weekLength; i++) {
      setWeeks((prevValues) => [...prevValues, i]);
    }
  }

  useEffect(() => {
    CallBack(mainFormData);
  }, [mainFormData]);

  return (
    <div>
      <Row row>
        <Col md="4" sm="6">
          <div className="mb-3 row">
            <div className=" col-4">
              <input
                onChange={handleChange}
                className="form-control mt-3"
                value={mainFormData.Mg}
                title="Mg"
                type="number"
                id="amount"
                required
              />
            </div>
            <label
              htmlFor="example-number-input"
              className="col-md-3 col-2 mt-3 col-form-label"
            >
              mg
            </label>
          </div>
        </Col>

        <Col md="4" sm="6">
          <div className="col-8">
            <FormGroup className="input-group select2-container mt-3" row>
              <Col md="10">
                <select
                  required
                  id="selectCompound"
                  className="form-select select2"
                  title="Compound"
                  onChange={handleChange}
                >
                  <option selected disabled>
                    Select Compound
                  </option>
                  <option value="Testosterone">Testosterone</option>
                  <option value="Trenbolone">Trenbolone</option>
                  <option value="Masteron">Masteron</option>
                  <option value="Nandrolone (Decca/NPP)">
                    Nandrolone (Decca/NPP)
                  </option>
                  <option value="Equipoise">Equipoise</option>
                  <option value="Primobolan">Primobolan</option>
                  <option value="Halotestin">Halotestin</option>
                  <option value="Anadrol">Anadrol</option>
                  <option value="Dianabol">Dianabol</option>
                  <option value="Turinabol">Turinabol</option>
                  <option value="Winstrol">Winstrol</option>
                  <option value="Anavar">Anavar</option>
                  <option value="Superdrol">Superdrol</option>
                  <option value="DNP">DNP</option>
                  <option value="Aromasin">Aromasin</option>
                  <option value="Arimidex">Arimidex</option>
                </select>
              </Col>
            </FormGroup>
          </div>
        </Col>

        <Col md="4" sm="6">
          <div className="col-md-9 col-7">
            <FormGroup required className="select2-container mt-3" row>
              <Col md="10">
                <select
                  required
                  id="selectCompound"
                  className="form-select select2"
                  title="Compound2"
                  onChange={handleChange}
                >
                  <option selected disabled>
                    Select a Compound-form
                  </option>
                  {mainFormData.Compound === "Testosterone"
                    ? list1.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Trenbolone"
                    ? list2.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Masteron"
                    ? list3.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Nandrolone (Decca/NPP)"
                    ? list4.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Equipoise"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Primobolan"
                    ? list6.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Halotestin"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Anadrol"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Dianabol"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Turinabol"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Winstrol"
                    ? list6.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Anavar"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Superdrol"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "DNP"
                    ? list7.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Aromasin"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                  {mainFormData.Compound === "Arimidex"
                    ? list5.map((item, i) => {
                        if (i === 0) {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        );
                      })
                    : null}
                </select>
              </Col>
            </FormGroup>
          </div>
        </Col>
      </Row>

      <Row row>
        <Col md="4">
          <div className="col-md-8 col-7">
            <FormGroup className="select2-container mt-3" aria-required row>
              <Col md="10">
                <select
                  required
                  id="frequencyMed"
                  className="form-select select2"
                  title="Day"
                  onChange={handleChange}
                >
                  <option selected disabled>
                    Select your Schedule
                  </option>
                  <option value="0.5">Twice a day</option>
                  <option value="1">Every day</option>
                  <option value="2">Every other day</option>
                  <option value="3">Every 3 days</option>
                  <option value="3.5">Every 3.5 days</option>
                  <option value="4">Every 4 days</option>
                  <option value="5">Every 5 days</option>
                  <option value="6">Every 6 days</option>
                  <option value="7">Every 7 days</option>
                  <option value="10">Every 10 days</option>
                  <option value="14">Every 14 days</option>
                  <option value="21">Every 21 days</option>
                  <option value="28">Every 4 weeks</option>
                  <option value="35">Every 5 weeks</option>
                  <option value="42">Every 6 weeks</option>
                  <option value="49">Every 7 weeks</option>
                  <option value="56">Every 8 weeks</option>
                  <option value="Mon">Every Monday</option>
                  <option value="Tue">Every Tuesday</option>
                  <option value="Wed">Every Wednesday</option>
                  <option value="Thu">Every Thursday</option>
                  <option value="Fri">Every Friday</option>
                  <option value="Sat">Every Saturday</option>
                  <option value="Sun">Every Sunday</option>
                </select>
              </Col>
            </FormGroup>
          </div>
        </Col>

        <Col md="4">
          <div className=" col-6">
            <FormGroup className="select2-container mt-3" row>
              <Col md="10">
                <select
                  required
                  id="fromWeek"
                  className="form-select select2"
                  title="StartingWeek"
                  onClick={() => {
                    setWeek();
                  }}
                  onChange={handleChange}
                >
                  <option selected disabled>
                    From
                  </option>

                  {weeks.map((week, index) => {
                    return (
                      <option key={index} value={week}>
                        From Week {week}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </FormGroup>
          </div>
        </Col>

        <Col md="4">
          <div className="col-6">
            <FormGroup className="select2-container mt-3" row>
              <Col md="10">
                <select
                  required
                  id="toWeek"
                  className="form-select select2"
                  title="EndingWeek"
                  onClick={() => {
                    setWeek();
                  }}
                  onChange={handleChange}
                >
                  <option selected disabled>
                    To Week
                  </option>
                  {weeks.map((week, index) => {
                    return (
                      <option key={index} value={week}>
                        To Week {week}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </FormGroup>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MainForm;
