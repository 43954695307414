import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";

import {
  Alert,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";

import bottle from "../../../assets/images/bottle.png";
import scale from "../../../assets/images/scale.png";

//Import Breadcrumb
//import Breadcrumbs from "components/Common/Breadcrumb"

class DoseCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultDisplay1: "",
      resultDisplay2: "",
      resultDisplay3: "",
      resultDisplay4: "",
      resultDisplay5: "",
    };
  }

  componentDidMount() {}

  calc() {
    var doseUnit = document.getElementById("doseUnit").value;
    // var concentrationUnit = document.getElementById('concentrationUnit').value;
    var dosePerInjection = document.getElementById("dosePerInjection").value;
    var concentrationOfMedication = document.getElementById(
      "concentrationOfMedication"
    ).value;
    var sizeOfMedication = document.getElementById("sizeOfMedication").value;
    var doseOfFrequency = document.getElementById("doseOfFrequency").value;
    var numberOfWeeks = document.getElementById("numberOfWeeks").value;

    if (doseUnit == "mcg") {
      dosePerInjection = dosePerInjection / 1000;
    }
    // if (concentrationUnit == 'mcg') {
    //   concentrationOfMedication = concentrationOfMedication / 1000;
    // }

    var res = Math.ceil(
      (dosePerInjection * doseOfFrequency * numberOfWeeks) /
        (concentrationOfMedication * sizeOfMedication)
    );
    //document.getElementById("result").value = Math.ceil(res);
    // console.log(doseUnit, concentrationUnit);

    this.setState({
      resultDisplay1: "The amount of medication required is: ",
      resultDisplay2: res,
    });
  }

  calc2() {
    var medicationStrength =
      document.getElementById("medicationStrength").value;
    var desiredDose = document.getElementById("desiredDose").value;
    var desiredUnit = document.getElementById("desiredUnit").value;
    if (desiredUnit == "mcg") {
      desiredDose = desiredDose / 1000;
    }
    var ans = desiredDose / medicationStrength;
    this.setState({
      resultDisplay3: `Draw ${ans} mL`,
      resultDisplay4: `to obtain ${desiredDose} mg`,
      resultDisplay5: `from a ${medicationStrength} mg/mL Solution`,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="mt-2">
          <MetaTags>
            <title>Tools | Dose Calculator</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col>
                <Alert color="danger" align="center">
                  WARNING: THIS IS NOT MEDICAL ADVICE!
                  <br />
                  THIS INFORMATION IS FOR EDUCATIONAL PURPOSES ONLY.
                  <br />
                  DO NOT USE MEDICATIONS/SUPPLEMENTS WITHOUT THE SUPERVISION AND
                  DIRECTION OF A MEDICAL PROFESSIONAL.
                </Alert>
              </Col>
            </Row>

            <Card>
              <Row>
                <Col md="6">
                  <CardTitle>
                    <div align="center " className="mt-3">
                      <h5>Units of Medication Needed for Treatment Plan</h5>
                    </div>
                  </CardTitle>
                  <CardBody>
                    <Row className="justify-content-center">
                      {/* <Col xs='3' sm='3'></Col> */}
                      <Col /*xs='9' sm='9'*/ align="">
                        <Row className=" d-flex align-items-center mb-4">
                          <div className="d-flex justify-content-center">
                            <label
                              style={{ maxWidth: 160, minWidth: 160 }}
                              htmlFor="example-number-input"
                              align="left"
                            >
                              Dose Per Injection
                            </label>
                            <input
                              required
                              style={{ maxWidth: 80 }}
                              className="custom-select dose-calculator-values"
                              min="0"
                              type="number"
                              defaultValue="105"
                              id="dosePerInjection"
                            />
                            <select
                              style={{ maxWidth: 80 }}
                              required
                              id="doseUnit"
                              className="custom-select dose-calculator-values ms-1 col-md-2"
                            >
                              <option value="mg" selected>
                                MG
                              </option>
                              <option value="mcg">MCG</option>
                            </select>
                          </div>
                        </Row>

                        <Row className=" d-flex align-items-center mb-4">
                          <div className="d-flex justify-content-center">
                            <label
                              style={{ maxWidth: 160, minWidth: 160 }}
                              htmlFor="example-number-input"
                              align="left"
                            >
                              Frequency of Dosing
                            </label>
                            <input
                              style={{ maxWidth: 80 }}
                              required
                              className="custom-select dose-calculator-values"
                              min="0"
                              type="number"
                              defaultValue="2"
                              id="doseOfFrequency"
                            />
                            <label
                              style={{ maxWidth: 80 }}
                              htmlFor="example-number-input"
                              className="col-sm-4 dose-calculator-values-label col-form-label"
                            >
                              per week
                            </label>
                          </div>
                        </Row>

                        <Row className=" d-flex align-items-center mb-4">
                          <div className="d-flex justify-content-center">
                            <label
                              style={{ maxWidth: 160, minWidth: 160 }}
                              htmlFor="example-number-input"
                              align="left"
                            >
                              Length of Treatment Plan
                            </label>
                            <input
                              style={{ maxWidth: 80 }}
                              required
                              className="custom-select dose-calculator-values-rp"
                              min="0"
                              type="number"
                              defaultValue="10"
                              id="numberOfWeeks"
                            />
                          </div>
                        </Row>

                        <Row className=" d-flex align-items-center mb-4">
                          <div className="d-flex justify-content-center">
                            <label
                              style={{ maxWidth: 160, minWidth: 160 }}
                              htmlFor="example-number-input"
                              align="left"
                            >
                              Concentration of Medication
                            </label>
                            <input
                              style={{ maxWidth: 80 }}
                              required
                              className="custom-select dose-calculator-values"
                              min="0"
                              type="number"
                              defaultValue="200"
                              id="concentrationOfMedication"
                            />
                            <label
                              style={{ maxWidth: 80 }}
                              htmlFor="example-number-input"
                              className="col-sm-4 dose-calculator-values-label-rp col-form-label"
                            >
                              mg/mL
                            </label>
                          </div>
                        </Row>

                        <Row className=" d-flex align-items-center mb-4">
                          <div className="d-flex justify-content-center">
                            <label
                              style={{ maxWidth: 160, minWidth: 160 }}
                              htmlFor="example-number-input"
                              align="left"
                            >
                              Size of Medication Vial
                            </label>
                            <input
                              style={{ maxWidth: 80 }}
                              required
                              className="custom-select dose-calculator-values"
                              min="0"
                              type="number"
                              defaultValue="10"
                              id="sizeOfMedication"
                            />
                            <label
                              style={{ maxWidth: 80 }}
                              htmlFor="example-number-input"
                              className="col-sm-4 dose-calculator-values-label-rp2 col-form-label"
                            >
                              mL
                            </label>
                          </div>
                        </Row>
                      </Col>
                      <div align="center" className="mb-5">
                        <Button
                          color="primary"
                          className="btn-rounded"
                          onClick={this.calc.bind(this)}
                        >
                          Calculate
                        </Button>
                      </div>
                      <div align="center">
                        <h3>{this.state.resultDisplay1}</h3>
                        <h3>{this.state.resultDisplay2}</h3>
                      </div>
                    </Row>
                  </CardBody>
                </Col>
                <Col sm={6}>
                  <CardTitle>
                    <div align="center " className="mt-3">
                      <h5>Dose Calculator</h5>
                    </div>
                  </CardTitle>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col align="center" md="6">
                        <Row>
                          <div align="center">
                            <Col md="4">
                              <img src={bottle} height="80" />
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <label
                            htmlFor="example-number-input"
                            className=" col-form-label"
                          >
                            Medication Strength
                          </label>
                        </Row>
                        {/* <input style={{ maxWidth: 80, minWidth: 80 }} required className='form-control' min='0' type='number' defaultValue='2' id='doseOfFrequency' />
                        <label htmlFor='example-number-input' className='col-md-4  col-form-label'>
                          per week
                        </label> */}
                        <Row className=" d-flex align-items-center mb-4">
                          <div className="d-flex justify-content-center">
                            <input
                              style={{ maxWidth: 80, minWidth: 80 }}
                              required
                              className="custom-select"
                              min="0"
                              type="number"
                              defaultValue="200"
                              id="medicationStrength"
                            />

                            <label
                              htmlFor="example-number-input"
                              className="ms-1 col-form-label"
                            >
                              mg/mL
                            </label>
                          </div>
                        </Row>
                      </Col>
                      <Col align="center" md="6">
                        <Row>
                          <div align="center">
                            <Col md="4">
                              <img src={scale} height="80" />
                            </Col>
                          </div>
                        </Row>
                        <Row>
                          <label
                            htmlFor="example-number-input"
                            className=" col-form-label"
                          >
                            Desired Dose
                          </label>
                        </Row>
                        <Row>
                          <Col>
                            <Row className="justify-content-center">
                              <input
                                style={{ maxWidth: 75, minWidth: 75 }}
                                required
                                className="form-control"
                                min="0"
                                type="number"
                                defaultValue="33"
                                id="desiredDose"
                              />

                              <select
                                style={{ maxWidth: 80, minWidth: 80 }}
                                required
                                id="desiredUnit"
                                className="form-select ms-1 col-md-4 select2"
                              >
                                <option value="mg" selected>
                                  MG
                                </option>
                                <option value="mcg">MCG</option>
                              </select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <div align="center" className="mb-5 ">
                        <Button
                          color="primary"
                          className="btn-rounded mt-5"
                          onClick={this.calc2.bind(this)}
                        >
                          Calculate
                        </Button>
                      </div>
                      <div align="center">
                        <h4>{this.state.resultDisplay3}</h4>
                        <h4>{this.state.resultDisplay4}</h4>
                        <h4>{this.state.resultDisplay5}</h4>
                      </div>
                    </Row>
                  </CardBody>
                </Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}></Col>
              </Row>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

DoseCalculator.propTypes = {
  projectDetail: PropTypes.any,
};

export default DoseCalculator;
