import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardTitle, Input, Label, Table } from 'reactstrap';

class RunningStandards extends Component {
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h2>Coming Soon...</h2>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default RunningStandards;
