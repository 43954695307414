const DateConversion = (dateAdded) => {
  const tempDate = new Date(dateAdded);

  let currentDay = "" + tempDate.getDate(),
    currentMonth = "" + (tempDate.getMonth() + 1),
    currentYear = tempDate.getFullYear();
  if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
  if (currentDay.length < 2) currentDay = "0" + currentDay;

  return [currentMonth, currentDay, currentYear].join("-");
};

export { DateConversion };
