import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
//import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
//import { loginUser, apiError, socialLogin } from "../../store/actions"
import { useEffect } from "react";

// import images
import logoftd from "../../assets/images/fitdatamax.png";

//imports for api integration
import { login } from "../../utils/";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import Cookies from "universal-cookie";
import Spinner from "../../common/helper/spinner";
import Footer from "../../common/VerticalLayout/Footer";
//code for api of login is done, just need to replace postman creds of prev with this
const Login = (props) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    console.log("login start");
  });
  // handleValidSubmit
  const handleValidSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    axiosConfig
      .post("usermanagement/login", {
        email: email,
        password: pass,
      })
      .then(async (response) => {
        await login(
          response.data.userDetails,
          response.data.message,
          response.data.token,
          response.data.userDetails.calDevAuth
        );
        console.log(response.data);
        setTimeout(() => {
          setShowSpinner(false);
          if (response.data.userDetails.role === "member") {
            props.history.push("/dashboard");
          } else if (
            response.data.userDetails.role === "superAdmin" ||
            response.data.userDetails.role === "admin" ||
            response.data.userDetails.role === "reportBuilder"
          ) {
            props.history.push("/home");
          }
          setMessage(response.data.message);
        }, 500);
      })
      .catch((error) => {
        setShowSpinner(false);
        if (error?.response?.data?.message) {
          if (error?.response?.data?.message?.name) {
            setMessage(error?.response?.data?.message?.name);
          } else {
            setMessage(error?.response?.data?.message);
          }
        }
        errorResponse(error);
      });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Marek Health</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h4 className="text-primary">Welcome to FitDataMax</h4>
                        <p>Sign in</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <Link to="/">
                        <img src={logoftd} alt="" className="img-fluid" />
                      </Link>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      {/* <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span> */}
                    </div>
                  </div>
                  {message != "" ? (
                    <Alert align="center" color="danger">
                      {message}
                    </Alert>
                  ) : null}
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onSubmit={(e) => {
                        handleValidSubmit(e);
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={(e) => {
                            setError("");
                            setEmail(e.target.value);
                          }}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          onChange={(e) => {
                            setError("");
                            setPass(e.target.value);
                          }}
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="customControlInline" />
                        <label className="form-check-label" htmlFor="customControlInline">
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        {showSpinner ? (
                          <Spinner />
                        ) : (
                          <button className="btn btn-primary btn-block" type="submit">
                            Log In
                          </button>
                        )}
                      </div>

                      <div className="mt-4 text-center">
                        {/* add signup here */}
                        <div className="mt-5 text-center">
                          <p>
                            Don&#39;t have an account ?{" "}
                            <Link to="register" className="fw-medium text-primary">
                              {" "}
                              Signup now{" "}
                            </Link>{" "}
                          </p>
                          <p>
                            <br />
                          </p>
                        </div>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Footer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
