import product1 from "./img-1.png"
import product2 from "./img-2.png"
import product3 from "./img-3.png"
import product4 from "./img-4.png"
import product5 from "./img-5.png"
// import product6 from "./img-6.png"

export { product1, product2, product3, product4, product5}

export const productImages = {
  product1: product1,
  product2: product2,
  product3: product3,
  product4: product4,
  product5: product5,
  // product6: product6,
}
