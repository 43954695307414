import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Input, Row } from "reactstrap";
import Cookies from "universal-cookie";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../common/helper/spinner";
import AddCategoryModal from "./AddCategoryModal";

const AddCategories = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [pagination, setPagination] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editHideResult, setEditHideResult] = useState(false);
  const [editID, setEditID] = useState("");

  const [tableHeaders, setTableHeaders] = useState([
    { text: "Id", dataField: "id", sort: true, hidden: true },
    { text: "Category Name", dataField: "name", sort: true },
    // { text: 'Category Description', dataField: 'description', sort: true },
    {
      text: "Category Description",
      dataField: "description",
      formatter: (cellContent, row) => {
        var result = row.description.slice(0, 30);
        return (
          <>
            <p>
              {result}
              {row.description.length > 30 ? "..." : ""}
            </p>
          </>
        );
      },
    },

    { text: "Hide if Result in Range", dataField: "hideResult", sort: true },

    {
      text: "Actions",
      dataField: "actions",
      headerStyle: () => {
        return { width: "80px", textAlign: "" };
      },
      formatter: (cellContent, row) => {
        return (
          <>
            {/* <button style={{ maxWidth: 100 }} value={row.name} className='btn btn-primary'>
              Edit
            </button> */}
            <div align="center">
              <i
                onClick={() => {
                  setEditName(row.name);
                  setEditDescription(row.description);
                  setEditHideResult(row.hideResult);
                  setEditID(row.id);
                  setIsEdit(true);
                  setShowModal(true);
                }}
                className="fas fa-edit mx-2 cursor-pointer"
              />
              <i
                onClick={() => {
                  setEditName(row.name);
                  setEditDescription(row.description);
                  setEditHideResult(row.hideResult);
                  setEditID(row.id);
                  setIsDelete(true);
                  setShowModal(true);
                }}
                style={{ color: "red" }}
                className="fas fa-trash mx-2 cursor-pointer"
              />
            </div>
          </>
        );
      },
    },
  ]);

  useEffect(() => {
    axiosConfig
      .post(
        "labBuilder/getAllCategories",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: page,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var responseData = [];
        if (response.data.Data) {
          await response.data.Data.forEach((category) => {
            responseData.push({
              id: category._id,
              name: category.categoryName,
              description: category.categoryDiscription,
              hideResult: category.hideResult,
            });
          });
        }
        setData(responseData);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
  };
  const toggleEdit = () => {
    setIsEdit(false);
    setIsDelete(false);
  };

  //Search and pagination function start

  function clearSearch(keyword) {
    if (keyword === "") {
      // this.filterChanged(keyword);
      reloadAPINow();
    }
  }

  function handlePageChange(pageNo) {
    setSizePerPage(10);
    setKeyword("");
    setPage(pageNo);

    getAllCategoryPageChange(sizePerPage, pageNo);
  }

  function getAllCategoryPageChange(sizePerPage, pageNo) {
    setSearching(true);

    axiosConfig
      .post(
        "labBuilder/getAllCategories",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: pageNo,
          pageSize: sizePerPage,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        setSearching(false);
        var responseData = [];
        if (response.data.Data) {
          await response.data.Data.forEach((category) => {
            responseData.push({
              id: category._id,
              name: category.categoryName,
              description: category.categoryDiscription,
              hideResult: category.hideResult,
            });
          });
        }
        setData(responseData);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  function getAllCategorySearch(keyword) {
    setSearching(true);
    axiosConfig
      .post(
        "labBuilder/getAllCategories",
        {
          entity_id: props.entityID,
          keyword: keyword,
          currentPage: 1,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        setSearching(false);
        var responseData = [];
        if (response.data.Data) {
          await response.data.Data.forEach((category) => {
            responseData.push({
              id: category._id,
              name: category.categoryName,
              description: category.categoryDiscription,
              hideResult: category.hideResult,
            });
          });
        }
        setData(responseData);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  function reloadAPINow(keyword = "", limit = 10, pageNo = 1) {
    setPage(pageNo);
    setSizePerPage(limit);
    setKeyword(keyword);

    setShowSpinner(true);

    axiosConfig
      .post(
        "labBuilder/getAllCategories",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: 1,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var responseData = [];
        if (response.data.Data) {
          await response.data.Data.forEach((category) => {
            responseData.push({
              id: category._id,
              name: category.categoryName,
              description: category.categoryDiscription,
              hideResult: category.hideResult,
            });
          });
        }
        setData(responseData);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  function uploadCsvCategory(e) {
    const csv = e.target.files[0];
    if (csv) {
      const formData = new FormData();
      formData.append("file", csv);
      axiosConfig
        .post("uploadCategoryCSV/CategoryCSV", formData, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          console.log(response.data);
          setSuccess(response.data.message);
          reloadAPINow();
          setTimeout(() => {
            setSuccess("");
          }, 4000);
        })
        .catch((error) => {
          errorResponse(error);
          setError(error.response.data.message);
        });
    }
  }

  //Search and pagination function end

  const pageOptions = {
    sizePerPage: 10,
    totalSize: data.length,
    custom: true,
  };
  const defaultSorted = [{ dataField: "id", order: "asc" }];
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className={`${props.step === 3 ? "d-block" : "d-none"}`}>
        <Row>
          <Alert align="center" color="warning" className="mb-4">
            Please upload a pdf in workdrive in the correct entity folder with
            file format of
            Setting_EntityID_PatientID_firstname.lastname_dateofservice_dob
            (e.g. Setting_MT4_MH272_Jalali.Amir_08.06.91_11.11.21), please
            review the sample once file is uploaded.
          </Alert>
          <Col xs="12">
            <Card>
              <CardBody>
                {error ? (
                  <Alert color="danger" className="text-center">
                    {error}
                  </Alert>
                ) : null}
                {success ? (
                  <Alert color="success" className="text-center">
                    {success}
                  </Alert>
                ) : null}

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={tableHeaders}
                  data={data}
                >
                  {({ paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={tableHeaders}
                      data={data}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="my-3">
                            <Col xs={6} sm={8} m={1} lg={2}>
                              <div className="search-box me-2">
                                <div className="position-relative">
                                  <Input
                                    value={keyword}
                                    onChange={(e) => {
                                      setKeyword(e.target.value);

                                      // this.filterChanged(e.target.value);
                                      clearSearch(e.target.value);
                                    }}
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Search..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>

                            <Col xs={4} sm={3} m={9} lg={1}>
                              <button
                                style={{ maxWidth: 80 }}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                  var key = keyword;

                                  getAllCategorySearch(key);
                                }}
                              >
                                Search
                              </button>
                            </Col>
                          </Row>

                          <Row>
                            <div align="right">
                              <Col>
                                <button
                                  style={{ maxWidth: 150 }}
                                  className="btn btn-primary mx-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document
                                      .getElementById("csvCategory")
                                      .click();
                                  }}
                                >
                                  Upload CSV
                                </button>
                                <Input
                                  accept=".csv"
                                  onChange={(e) => {
                                    uploadCsvCategory(e);
                                  }}
                                  name="csvCategory"
                                  className="form-control d-none"
                                  type="file"
                                  id="csvCategory"
                                />
                                <button
                                  style={{ maxWidth: 150 }}
                                  type="button"
                                  onClick={() => {
                                    setShowModal(true);
                                  }}
                                  className="btn btn-primary"
                                >
                                  Add Category
                                </button>
                              </Col>
                            </div>
                          </Row>

                          {!searching ? (
                            <Row>
                              <Col xl="12">
                                <div
                                  className="table-responsive"
                                  style={{ minHeight: "300px" }}
                                >
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {data.length === 0 ? (
                                    <p className="w-100 text-center m-5">
                                      No data found!
                                    </p>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Spinner />
                          )}
                          <Row>
                            <Col align="right">
                              <div
                                style={{ maxWidth: 200 }}
                                className="btn-group mt-2 mt-xl-0"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio1"
                                  autoComplete="off"
                                  onClick={() => {
                                    if (page === 1) {
                                      handlePageChange(page);
                                    } else {
                                      var tempPage = page - 1;

                                      handlePageChange(tempPage);
                                    }
                                  }}
                                />
                                <label
                                  className="btn btn-light"
                                  htmlFor="btnradio1"
                                >
                                  Back
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio2"
                                  autoComplete="off"
                                  onClick={() => {
                                    var tempPage = page + 1;

                                    handlePageChange(tempPage);
                                  }}
                                />
                                <label
                                  className="btn btn-light"
                                  htmlFor="btnradio2"
                                >
                                  Next
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <AddCategoryModal
        showModal={showModal}
        entityID={props.entityID}
        toggleEdit={toggleEdit}
        isDelete={isDelete}
        isEdit={isEdit}
        editName={editName}
        editDescription={editDescription}
        editHideResult={editHideResult}
        editID={editID}
        closeModal={closeModal}
      />
    </React.Fragment>
  );
};

export default AddCategories;
