import React from 'react';
import Lottie from 'react-lottie';
import SpinnerLottie from '../helper/spinner.json';

export default function Spinner(props) {
  const defaultLottieOptions = {
    loop: true,
    animationData: SpinnerLottie,
  };

  return (
    <div className='spinnerOverlay' style={{ pointerEvents: 'none !important' }}>
      <Lottie options={defaultLottieOptions} height={100} width={200} isClickToPauseDisabled={true} />
    </div>
  );
}
