import React, { useEffect, useState } from 'react';

import { Alert, Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import Spinner from '../../../common/helper/spinner';

const DeactivateMemberModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const toggle = () => {
    props.closeModal();
  };

  const submitButton = () => {
    //I NEED TO ADD API CALL HERE
    setShowSpinner(true);
    axiosConfig
      .post(
        '/admins/deactivateUser',
        {
          user_id: props.id,
        },
        {
          headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
        }
      )
      .then(async (response) => {
        console.log('******response*******');
        console.log(response);
        console.log('******response*******');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        errorResponse(error);
        setMessage(error.response.data.message);
        setShowMessage(true);
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    console.log(props.id);
  });

  return (
    <React.Fragment>
      {props.showModal ? (
        <Modal isOpen={props.showModal} className={props.className}>
          <ModalHeader>
            <div align='center'>
              <h3>Deactivate Member?</h3>
            </div>
          </ModalHeader>
          {showMessage && (
            <Alert align='center' color='danger'>
              {message}
            </Alert>
          )}
          <ModalBody>
            <Row>
              {showSpinner && <Spinner />}

              <div align='center'>
                <Col>
                  <button type='button' className='btn btn-success me-2' onClick={submitButton}>
                    Yes
                  </button>
                  <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                    Cancel
                  </button>
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default DeactivateMemberModal;
