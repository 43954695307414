import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Input, Row } from "reactstrap";
import Cookies from "universal-cookie";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../common/helper/spinner";
import AddRuleModal from "./AddRuleModal";
import EditRule from "./EditRule";
import ViewRule from "./ViewRule";
import DeleteModal from "./DeleteModal";
import ConditionLab from "./ConditionLab";

const AddRecommendation = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [labData, setLabData] = useState([]);
  const [ruleData, setRuleData] = useState([]);
  const [listOfSelectedLabs, setListOfSelectedLabs] = useState([]);
  const [stringOfLabs, setStringOfLabs] = useState([]);

  const [keyword, setKeyword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedRule, setSelectedRule] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [passConditionArray, setPassConditionArray] = useState([]);
  const [conditionCounter, setConditionCounter] = useState(0);
  const [listForSearchAndPagination, setListForSearchAndPagination] = useState(
    []
  );

  const [tableHeaders, setTableHeaders] = useState([
    { text: "id", dataField: "id", sort: true, hidden: true },
    { text: "Rule Name", dataField: "ruleName", sort: true },
    { text: "Primary Lab", dataField: "labName", sort: true },

    {
      text: "Recommendation",
      dataField: "recommendation",
      formatter: (cellContent, row) => {
        var result = row.recommendation.slice(0, 30);
        return (
          <>
            <p>
              {result}
              {row.recommendation.length > 30 ? "..." : ""}
            </p>
          </>
        );
      },
    },

    {
      text: "Actions",
      dataField: "actions",
      headerStyle: () => {
        return { width: "80px", textAlign: "" };
      },
      formatter: (cellContent, row) => {
        return (
          <>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setSelectedRule(row.id);
                setShowEditModal(true);
              }}
            >
              <i className="fas fa-edit medText mx-3"></i>
            </span>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRule(row.id);
                setShowDeleteModal(true);
              }}
            >
              <i className="fas fa-trash" style={{ color: "red" }}></i>
            </span>
          </>
        );
      },
    },
  ]);
  // runs first
  useEffect(() => {
    setShowSpinner(true);
    var listToPassInCondition = [];
    axiosConfig
      .post(
        "/labBuilder/getAllLabTest",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: 1,
          pageSize: 1000000,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var dataList = [];

        await response.data.Data.forEach((s) => {
          dataList.push({
            id: s._id,
            displayTestName: s.displayTestName,
          });
        });
        setLabData(dataList);
        listToPassInCondition = dataList;
        setListForSearchAndPagination(dataList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });

    setShowSpinner(true);
    axiosConfig
      .post(
        "/labBuilder/getRules",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: page,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log(response);
        var dataList = [];
        var lstring = "";

        await response.data.Data.forEach((s) => {
          var ca = [];

          var ll = [];

          var ageCond = [];
          var genderCond = [];

          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ll.push({
                labName: s.conditionLab[i].labName,
                labId: s.conditionLab[i].id,
              });
              lstring += s.conditionLab[i].labName + ", ";
            }
          }
          var condIndex = 0;
          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ca.push(
                <ConditionLab
                  index={condIndex}
                  labs={listToPassInCondition}
                  defaultCondition={s.conditionLab[i]}
                  isEdit="true"
                />
              );

              condIndex = condIndex + 1;
            } else if (s.conditionLab[i].condition == "Age") {
              ageCond = s.conditionLab[i];
            } else if (s.conditionLab[i].condition == "Gender") {
              genderCond = s.conditionLab[i];
            }
          }
          var srec = "";
          if (s.recommendation) {
            srec = s.recommendation;
          } else {
            srec = "-";
          }
          if (genderCond == [] && ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
            });
          } else if (ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              genderCondition: genderCond,
            });
          } else if (genderCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
            });
          } else {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
              genderCondition: genderCond,
            });
          }

          setStringOfLabs(lstring);
          setListOfSelectedLabs(ll);
        });

        console.log("look at data", dataList);
        console.log("Different data", dataList.cList);

        setRuleData(dataList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, [showModal, showEditModal, showDeleteModal]);

  const closeModal = () => {
    setShowModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  function onClickNo() {
    setShowDeleteModal(false);
  }
  function onClickYes() {
    axiosConfig
      .post(
        "/labBuilder/deleteRule",
        {
          Rule_id: selectedRule,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async () => {
        setShowDeleteModal(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  //Pagination start

  function clearSearch(keyword) {
    if (keyword === "") {
      // this.filterChanged(keyword);
      reloadAPINow();
    }
  }

  function handlePageChangeLab(pageNo) {
    setSizePerPage(10);
    setKeyword("");
    setPage(pageNo);

    getAllLabPageChange(sizePerPage, pageNo);
  }

  function getAllLabPageChange(sizePerPage, pageNo) {
    setSearching(true);
    axiosConfig
      .post(
        "labBuilder/getRules",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: pageNo,
          pageSize: sizePerPage,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var dataList = [];
        var lstring = "";

        await response.data.Data.forEach((s) => {
          var ca = [];

          var ll = [];

          var ageCond = [];
          var genderCond = [];

          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ll.push({
                labName: s.conditionLab[i].labName,
                labId: s.conditionLab[i].id,
              });
              lstring += s.conditionLab[i].labName + ", ";
            }
          }
          var condIndex = 0;
          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ca.push(
                <ConditionLab
                  index={condIndex}
                  labs={listForSearchAndPagination}
                  defaultCondition={s.conditionLab[i]}
                  isEdit="true"
                />
              );

              condIndex = condIndex + 1;
            } else if (s.conditionLab[i].condition == "Age") {
              ageCond = s.conditionLab[i];
            } else if (s.conditionLab[i].condition == "Gender") {
              genderCond = s.conditionLab[i];
            }
          }
          var srec = "";
          if (s.recommendation) {
            srec = s.recommendation;
          } else {
            srec = "-";
          }
          if (genderCond == [] && ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
            });
          } else if (ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              genderCondition: genderCond,
            });
          } else if (genderCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
            });
          } else {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
              genderCondition: genderCond,
            });
          }

          setStringOfLabs(lstring);
          setListOfSelectedLabs(ll);
        });

        console.log("look at data", dataList);
        console.log("Different data", dataList.cList);
        setSearching(false);

        setRuleData(dataList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
    setShowSpinner(false);
  }

  function getAllCategorySearch(keyword) {
    setSearching(true);
    axiosConfig
      .post(
        "labBuilder/getRules",
        {
          entity_id: props.entityID,
          keyword: keyword,
          currentPage: 1,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var dataList = [];
        var lstring = "";

        await response.data.Data.forEach((s) => {
          var ca = [];

          var ll = [];

          var ageCond = [];
          var genderCond = [];

          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ll.push({
                labName: s.conditionLab[i].labName,
                labId: s.conditionLab[i].id,
              });
              lstring += s.conditionLab[i].labName + ", ";
            }
          }
          var condIndex = 0;
          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ca.push(
                <ConditionLab
                  index={condIndex}
                  labs={listForSearchAndPagination}
                  defaultCondition={s.conditionLab[i]}
                  isEdit="true"
                />
              );

              condIndex = condIndex + 1;
            } else if (s.conditionLab[i].condition == "Age") {
              ageCond = s.conditionLab[i];
            } else if (s.conditionLab[i].condition == "Gender") {
              genderCond = s.conditionLab[i];
            }
          }
          var srec = "";
          if (s.recommendation) {
            srec = s.recommendation;
          } else {
            srec = "-";
          }
          if (genderCond == [] && ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
            });
          } else if (ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              genderCondition: genderCond,
            });
          } else if (genderCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
            });
          } else {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
              genderCondition: genderCond,
            });
          }

          setStringOfLabs(lstring);
          setListOfSelectedLabs(ll);
        });

        console.log("look at data", dataList);
        setSearching(false);

        setRuleData(dataList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
    setShowSpinner(false);
  }

  function reloadAPINow(keyword = "", limit = 10, pageNo = 1) {
    setPage(pageNo);
    setSizePerPage(limit);
    setKeyword(keyword);

    setShowSpinner(true);

    axiosConfig
      .post(
        "labBuilder/getRules",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: 1,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var dataList = [];
        var lstring = "";

        await response.data.Data.forEach((s) => {
          var ca = [];

          var ll = [];

          var ageCond = [];
          var genderCond = [];

          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ll.push({
                labName: s.conditionLab[i].labName,
                labId: s.conditionLab[i].id,
              });
              lstring += s.conditionLab[i].labName + ", ";
            }
          }
          var condIndex = 0;
          for (let i = 0; i < s.conditionLab.length; i++) {
            if (s.conditionLab[i].condition == "LAB") {
              ca.push(
                <ConditionLab
                  index={condIndex}
                  labs={listForSearchAndPagination}
                  defaultCondition={s.conditionLab[i]}
                  isEdit="true"
                />
              );

              condIndex = condIndex + 1;
            } else if (s.conditionLab[i].condition == "Age") {
              ageCond = s.conditionLab[i];
            } else if (s.conditionLab[i].condition == "Gender") {
              genderCond = s.conditionLab[i];
            }
          }
          var srec = "";
          if (s.recommendation) {
            srec = s.recommendation;
          } else {
            srec = "-";
          }
          if (genderCond == [] && ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
            });
          } else if (ageCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              genderCondition: genderCond,
            });
          } else if (genderCond == []) {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
            });
          } else {
            dataList.push({
              recommendation: srec,
              ruleName: s.ruleName,
              labId: s.labId,
              conditionLab: s.conditionLab,
              labName: s.labName,
              id: s._id,
              cList: ca,
              LabsUsed: lstring,
              LabArray: ll,
              ageCondition: ageCond,
              genderCondition: genderCond,
            });
          }

          setStringOfLabs(lstring);
          setListOfSelectedLabs(ll);
        });

        console.log("look at data", dataList);
        console.log("Different data", dataList.cList);
        setSearching(false);

        setRuleData(dataList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
    setShowSpinner(false);
  }

  //Pagination end

  const pageOptions = {
    sizePerPage: 10,
    totalSize: ruleData.length,
    custom: true,
  };
  const defaultSorted = [{ dataField: "id", order: "asc" }];

  return (
    <React.Fragment>
      <div className={`${props.step === 6 ? "d-block" : "d-none"}`}>
        <Row>
          <Alert align="center" color="warning" className="mb-4">
            Please upload a pdf in workdrive in the correct entity folder with
            file format of
            Setting_EntityID_PatientID_firstname.lastname_dateofservice_dob
            (e.g. Setting_MT4_MH272_Jalali.Amir_08.06.91_11.11.21), please
            review the sample once file is uploaded.
          </Alert>
          <Col xs="12">
            <Card>
              <CardBody>
                {showSpinner ? <Spinner /> : null}
                {error ? (
                  <Alert color="danger" className="text-center">
                    {error}
                  </Alert>
                ) : null}
                {success ? (
                  <Alert color="success" className="text-center">
                    {success}
                  </Alert>
                ) : null}

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={tableHeaders}
                  data={ruleData}
                >
                  {({ paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={tableHeaders}
                      data={ruleData}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          {
                            <Row className="my-3">
                              <Col xs={6} sm={8} m={1} lg={2}>
                                <div className="search-box me-2">
                                  <div className="position-relative">
                                    <Input
                                      value={keyword}
                                      onChange={(e) => {
                                        setKeyword(e.target.value);

                                        // this.filterChanged(e.target.value);
                                        clearSearch(e.target.value);
                                      }}
                                      type="text"
                                      className="form-control border-0"
                                      placeholder="Search..."
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>

                              <Col xs={4} sm={3} m={9} lg={1}>
                                <button
                                  style={{ maxWidth: 80 }}
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    var key = keyword;

                                    getAllCategorySearch(key);
                                  }}
                                >
                                  Search
                                </button>
                              </Col>
                            </Row>
                          }
                          <Row>
                            <div align="right">
                              <button
                                style={{ maxWidth: 150 }}
                                type="button"
                                onClick={() => {
                                  setShowModal(true);
                                }}
                                className="btn btn-primary mb-2"
                              >
                                Add Rule
                              </button>
                            </div>
                          </Row>

                          {!searching ? (
                            <Row>
                              <Col xl="12">
                                <div
                                  className="table-responsive"
                                  style={{ minHeight: "300px" }}
                                >
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {ruleData.length === 0 ? (
                                    <p className="w-100 text-center m-5">
                                      No data found!
                                    </p>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Spinner />
                          )}
                          <Row>
                            <Col align="right">
                              <div
                                style={{ maxWidth: 200 }}
                                className="btn-group mt-2 mt-xl-0"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="labPaginationBack"
                                  id="labPaginationBack"
                                  autoComplete="off"
                                  onClick={() => {
                                    if (page === 1) {
                                      handlePageChangeLab(page);
                                    } else {
                                      var tempPage = page - 1;

                                      handlePageChangeLab(tempPage);
                                    }
                                  }}
                                />
                                <label
                                  className="btn btn-light"
                                  htmlFor="labPaginationBack"
                                >
                                  Back
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="labPaginationStart"
                                  id="labPaginationStart"
                                  autoComplete="off"
                                  onClick={() => {
                                    var tempPage = page + 1;

                                    handlePageChangeLab(tempPage);
                                  }}
                                />
                                <label
                                  className="btn btn-light"
                                  htmlFor="labPaginationStart"
                                >
                                  Next
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <AddRuleModal
          entityID={props.entityID}
          labData={labData}
          showModal={showModal}
          closeModal={closeModal}
        />
        {showEditModal ? (
          <EditRule
            showModal={showEditModal}
            defaultData={ruleData.find((o) => o.id === selectedRule)}
            ruleId={selectedRule}
            entityID={props.entityID}
            labData={labData}
            listOfLabs={listOfSelectedLabs}
            conditionArray={passConditionArray}
            closeModal={closeModal}
          />
        ) : null}

        <DeleteModal
          onClickYes={onClickYes}
          onClickNo={onClickNo}
          showDeleteModal={showDeleteModal}
          closeModal={closeModal}
        />
      </div>
    </React.Fragment>
  );
};

export default AddRecommendation;
