import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Badge, Button, Card, CardBody, Collapse, Container, CardTitle, Row, Col, Media, Input, Label, Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
//Import images
import randomprofile from '../../../assets/images/random-profile.PNG';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import { updateToken } from '../../../utils';
import { WindowScrollController } from '@fullcalendar/react';
import RemoveAccountModal from '../User Settings/RemoveAccountModal';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Spinner from '../../../common/helper/spinner';

var googleLink = '';
class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      apiResponse: '',
      updateInformation: false,
      changePassword: false,
      biostrapIntegrate: false,
      token: '',
      notEqualPassword: false,
      passwordUpdateSuccess: false,
      syncButtonLoad: true,
      biostrapMessage: '',
      biostrapAPI: false,
      profileImageSrc: null,
      profileImageURL: null,
      errorMessage: '',
      showUserDetails: false,
      showModal: false,
      removeAccount: '',
      showSpinner: false,
      showProfile: false,
      showMessage: false,
      messageDisplay: '',
      showUpdateInfoMessage: false,
      updateInfoMessage: '',
      age: '',
    };
    this.t_updateInformation = this.t_updateInformation.bind(this);
    this.t_changePassword = this.t_changePassword.bind(this);
    this.t_biostrapIntegrate = this.t_biostrapIntegrate.bind(this);

    // handleValidSubmit
    // this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  t_updateInformation() {
    this.setState({ updateInformation: !this.state.updateInformation });
  }
  t_changePassword() {
    this.setState({ changePassword: !this.state.changePassword });
  }
  t_biostrapIntegrate() {
    this.setState({ biostrapIntegrate: !this.state.biostrapIntegrate });
  }
  componentDidMount() {
    console.log(this.props.location);
    if (this.props.location.search.length > 1) {
      this.setState({
        syncButtonLoad: false,
      });
      axiosConfig
        .get(`caldev/getCode?code=${this.props.location.search.slice(6, this.props.location.search.search('&scope='))}`, {
          headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
        })
        .then(async (response) => {
          console.log(response);
          // updateToken(response.data.token, response.data.calDevAuth);
          localStorage.setItem('caldevAuth', true);
          localStorage.setItem('caldavEmail', response.data.userDetails.caldavAttendees);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          errorResponse(error);
        });
    }

    // console.log(location.href);
    this.setState({
      user: JSON.parse(localStorage.getItem('user')),
      profileImageURL: JSON.parse(localStorage.getItem('pictureURL')),
      showUserDetails: true,
      showProfile: true,
    });
    setTimeout(() => {
      console.log(this.state.user.pictureUrl);
      // this.setState({ profileImageURL: this.state.user.pictureUrl, showProfile: true });
    }, 1000);

    axiosConfig
      .get('caldev/auth', {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
        },
      })
      .then(async (response) => {
        console.log(response);
        googleLink = response.data.AuthUrl;
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error: error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }

  updateInformation(user) {
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({
      user: JSON.parse(localStorage.getItem('user')),
    });
  }
  //updating user information
  updateInformationFunc() {
    var fn = document.getElementById('changeFirstName').value;
    var ln = document.getElementById('changeLastName').value;
    var a = this.state.age;
    var d = document.getElementById('changeDOB').value;
    axiosConfig
      .post(
        '/userSetting/updateInfo',
        {
          firstName: fn,
          lastName: ln,
          age: a,
          dob: d,
        },
        {
          headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
        }
      )
      .then(async (response) => {
        console.log(response);

        this.updateInformation(response.data.userDetails);
        this.setState({
          showUpdateInfoMessage: true,
          updateInfoMessage: response.data.message,
        });
        setTimeout(() => {
          this.setState({ showUpdateInfoMessage: false });
        }, 5000);
      })
      .catch((error) => {
        errorResponse(error);
        this.setState({
          showUpdateInfoMessage: true,
          updateInfoMessage: error.response.data.message,
        });
        setTimeout(() => {
          this.setState({ showUpdateInfoMessage: false });
        }, 5000);
      });
  }

  linkwithGoogle() {
    console.log('Link with google Clicked');
    console.log(googleLink);
  }
  updatePassword() {
    console.log('inside function');
    var newPassword = document.getElementById('newPassword').value;
    var confirmPassword = document.getElementById('comfirm-new-password').value;
    console.log(newPassword);
    console.log(confirmPassword);
    if (newPassword == '') {
      this.setState({
        passwordUpdateSuccess: false,
        notEqualPassword: true,
      });
    } else if (newPassword != confirmPassword) {
      this.setState({
        passwordUpdateSuccess: false,
        notEqualPassword: true,
      });
    } else if (newPassword == confirmPassword) {
      this.setState({
        showSpinner: true,
      });
      axiosConfig
        .post(
          '/userSetting/updatePassword',
          {
            password: newPassword,
          },
          {
            headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
          }
        )
        .then(async (response) => {
          console.log(response);
          this.setState({
            notEqualPassword: false,
            passwordUpdateSuccess: true,
            showSpinner: false,
          });
        })
        .catch((error) => {
          errorResponse(error);
        });
    }
  }

  biostrapIntegrateFunction() {
    var e = document.getElementById('biostrapEmail').value;
    console.log(e);
    axiosConfig
      .post(
        '/userSetting/biostrapUserName',
        {
          email: e,
        },
        {
          headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
        }
      )
      .then(async (response) => {
        console.log(response.Message);
        this.setState({ biostrapMessage: response.data.message, biostrapAPI: true });
        localStorage.setItem('biostrapAuth', 'true');
        localStorage.setItem('biostrapEmail', e);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error.response.data.Message);

        this.setState({ biostrapMessage: error.response.data.message, biostrapAPI: true });
        setTimeout(() => {
          this.setState({ biostrapAPI: false });
        }, 5000);
        errorResponse(error);
      });
  }

  async uploadImage(e) {
    const image = e.target.files[0];
    var url = URL.createObjectURL(image);
    this.setState({
      // profileImageSrc: <img className='staffProfileImgCircle' src={url} alt='' />,
      profileImageURL: url,
    });

    if (image) {
      const formData = new FormData();
      formData.append('file', image);
      setTimeout(() => {
        console.log('check picture', formData);
      }, 1000);
      axiosConfig
        .post('upload/picture', formData, {
          headers: {
            Authorization: 'Bearer ' + new Cookies().get('user_token'),
            //'Content-Type': 'multipart/form-data;',
          },
        })
        .then(async (response) => {
          console.log(response.data);
          localStorage.setItem('pictureURL', JSON.stringify(url));
          this.setState({
            showMessage: true,
            messageDisplay: 'Profile picture updated',
          });
          setTimeout(() => {
            this.setState({ showMessage: false });
          }, 4000);
        })
        .catch((error) => {
          this.setState({
            showMessage: true,
            messageDisplay: 'An error occurred while trying to upload profile photo.',
          });
          setTimeout(() => {
            this.setState({ showMessage: false });
          }, 4000);
          this.setState({ showSpinner: false });
          errorResponse(error);
        });
    }
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            {/* Render Breadcrumb */}
            {/* <Breadcrumb title="User Settings" breadcrumbItem="Profile" /> */}
            {this.state.apiResponse == 200 && (
              <Row>
                <Col>
                  <Alert color='success' align='center'>
                    User information updated!
                  </Alert>
                </Col>
              </Row>
            )}
            {this.state.showMessage && (
              <Row>
                <Col>
                  <Alert color='success' align='center'>
                    {this.state.messageDisplay}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Media>
                      {console.log('check user:', this.state.user)}
                      {this.state.showUserDetails && (
                        <div className=''>
                          {/* <img src={this.state.user.pictureUrl} alt='' className='avatar-md rounded-circle img-thumbnail' /> */}
                          <img style={{ minWidth: 120, minHeight: 120, objectFit: 'cover' }} src={this.state.profileImageURL} alt='' height='150' width='150' className='rounded-circle img-thumbnail' />
                          <Row>
                            <div align='center' className='mb-4 mr-4 flexJCAC flex-column'>
                              <button
                                className='btn btn-primary my-4'
                                onClick={(e) => {
                                  e.preventDefault();
                                  document.getElementById('profilePhoto').click();
                                }}
                              >
                                Upload Image
                              </button>
                              <Input
                                accept='.jpg, .png, .jpeg'
                                onChange={(e) => {
                                  this.uploadImage(e);
                                }}
                                name='profile_photo'
                                className='form-control d-none'
                                type='file'
                                id='profilePhoto'
                              />
                            </div>
                          </Row>
                        </div>
                      )}
                      {/* trying image upload */}

                      {/* trying image upload */}
                      {console.log('bio: ', localStorage.getItem('biostrapEmail'))}
                      {this.state.showUserDetails && (
                        <Media body className='align-self-center'>
                          <div className='text-muted ms-3'>
                            <Row>
                              <Col md='7'>
                                <h5>
                                  {this.state.user.firstName} {this.state.user.lastName}
                                </h5>
                                <p className='mt-4'>Age: {this.state.user.age}</p>
                                <p>DOB: {this.state.user.dob}</p>
                                <p>
                                  Caldav account: {localStorage.getItem('caldavEmail')}{' '}
                                  <i
                                    onClick={() => {
                                      this.setState({
                                        removeAccount: 'caldav',
                                        showModal: true,
                                      });
                                    }}
                                    className='fas fa-trash-alt ms-4'
                                  />
                                </p>
                                <p>
                                  Biostrap account: {localStorage.getItem('biostrapEmail')}{' '}
                                  <i
                                    onClick={() => {
                                      this.setState({
                                        removeAccount: 'biostrap',
                                        showModal: true,
                                      });
                                    }}
                                    className='fas fa-trash-alt ms-4'
                                  />{' '}
                                </p>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col md='3'>
                                <button align='right' className='btn btn-danger my-4'>
                                  Remove Caldav Account
                                </button>
                                <br />
                              </Col>
                              <Col>
                                <button className='btn btn-danger my-4'>Remove Biostrap Account</button>
                              </Col>
                            </Row> */}

                            {localStorage.getItem('caldevAuth') == 'true' ? (
                              <></>
                            ) : (
                              [
                                this.state.syncButtonLoad ? (
                                  <Button
                                    color='primary'
                                    className='btn btn-primary'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.location.href = googleLink;
                                    }}
                                  >
                                    Sync With Google
                                  </Button>
                                ) : (
                                  <Button color='info' className='btn btn-primary' disabled>
                                    Syncing...
                                  </Button>
                                ),
                              ]
                            )}
                          </div>
                        </Media>
                      )}
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <div className='accordion accordion-flush'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='headingFlushOne'>
                      <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_updateInformation} style={{ cursor: 'pointer' }}>
                        Update Information
                      </button>
                    </h2>

                    <Collapse isOpen={this.state.updateInformation} className='accordion-collapse'>
                      <div className='accordion-body'>
                        {/* add update detail here */}
                        {this.state.showUpdateInfoMessage && (
                          <Row>
                            <Col>
                              <Alert color='warning' align='center'>
                                {this.state.updateInfoMessage}
                              </Alert>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <div>
                            <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                              First Name
                            </label>
                            <div className='col-md-12'>
                              <input className='form-control mt-3' type='text' defaultValue='' id='changeFirstName' required />
                            </div>
                          </div>
                          <div>
                            <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                              Last Name
                            </label>
                            <div className='col-md-12'>
                              <input className='form-control mt-3' type='text' defaultValue='' id='changeLastName' required />
                            </div>
                          </div>

                          <div>
                            <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                              Date of Birth
                            </label>
                            <div className='col-md-12'>
                              <input
                                className='form-control mt-3'
                                type='date'
                                defaultValue=''
                                onChange={(e) => {
                                  var d = new Date(e.target.value);
                                  var currentdate = new Date();
                                  var ynew = currentdate.getFullYear();
                                  var mnew = currentdate.getMonth();
                                  var dnew = currentdate.getDate();
                                  var yold = d.getFullYear();
                                  var mold = d.getMonth();
                                  var dold = d.getDate();
                                  var diff = ynew - yold;
                                  if (mold > mnew) diff--;
                                  else {
                                    if (mold == mnew) {
                                      if (dold > dnew) diff--;
                                    }
                                  }
                                  this.setState({
                                    age: diff,
                                  });
                                }}
                                id='changeDOB'
                                required
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                              Age: {this.state.age}
                            </label>
                          </div>
                          <div align='center'>
                            <Button color='primary' className='btn-primary mt-4' onClick={this.updateInformationFunc.bind(this)}>
                              Update
                            </Button>
                          </div>
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </CardBody>
            </Card>
            {this.state.notEqualPassword && (
              <Row>
                <Col>
                  <Alert color='danger' align='center'>
                    Password not the same as entered above or left empty
                  </Alert>
                </Col>
              </Row>
            )}
            {this.state.passwordUpdateSuccess && (
              <Row>
                <Col>
                  <Alert color='success' align='center'>
                    Password Updated
                  </Alert>
                </Col>
              </Row>
            )}
            <Card>
              <CardBody>
                <div className='accordion accordion-flush'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='headingFlushOne'>
                      <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_changePassword} style={{ cursor: 'pointer' }}>
                        Change Password
                      </button>
                    </h2>

                    <Collapse isOpen={this.state.changePassword} className='accordion-collapse'>
                      <div className='accordion-body'>
                        {/* add update detail here */}

                        <AvForm>
                          <div align='center' className='mb-4'>
                            <div className='mb-3 mt-5'>
                              <AvField id='newPassword' name='newPassword' label='New Password' className='form-control' placeholder='New password' type='password' required />
                            </div>
                          </div>
                          <div align='center' className='mb-4'>
                            <div className='mb-3 mt-5'>
                              <AvField id='comfirm-new-password' name='comfirm-new-password' label='Confirm Password' className='form-control' placeholder='Confirm password' type='password' required />
                            </div>
                          </div>
                        </AvForm>
                        {this.state.showSpinner ? (
                          <Spinner />
                        ) : (
                          <div align='center'>
                            <Button color='primary' className='btn-primary mt-4' onClick={this.updatePassword.bind(this)}>
                              Update
                            </Button>
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </div>
                </div>
              </CardBody>
            </Card>
            {/* <div align="center">
                    <Link to="/caldav-instructions" className="btn btn-primary">CALDAV Instructions</Link>
            </div> */}

            <Card>
              <CardBody>
                <div className='accordion accordion-flush'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='headingFlushOne'>
                      <button className='accordion-button fw-medium collapsed' type='button' onClick={this.t_biostrapIntegrate} style={{ cursor: 'pointer' }}>
                        Biostrap Setup
                      </button>
                    </h2>

                    <Collapse isOpen={this.state.biostrapIntegrate} className='accordion-collapse'>
                      <div className='accordion-body'>
                        {/* add update detail here */}
                        {this.state.biostrapAPI && (
                          <Row>
                            <Col>
                              <Alert color='warning' align='center'>
                                {this.state.biostrapMessage}
                              </Alert>
                            </Col>
                          </Row>
                        )}
                        <div align='center' className='mb-4'>
                          <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                            Email
                          </label>
                          <div className='col-md-8'>
                            <input className='form-control mt-3' type='email' id='biostrapEmail' required />
                          </div>
                        </div>

                        <div align='center'>
                          <Button color='primary' className='btn-primary mt-4' onClick={this.biostrapIntegrateFunction.bind(this)}>
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
        <RemoveAccountModal showModal={this.state.showModal} removeAccount={this.state.removeAccount} closeModal={this.closeModal.bind(this)} />
      </React.Fragment>
    );
  }
}

export default UserSettings;
