import logo from "./logo.svg";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";

// Import Routes
import { authProtectedRoutes, publicRoutes, adminRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./common/VerticalLayout/";
import NonAuthLayout from "./common/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isMemberRoute={true}
              isAdminRoute={false}
              exact
            />
          ))}

          {adminRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isAdminRoute={true}
              isMemberRoute={false}
            />
          ))}

          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isMemberRoute={false}
              isAdminRoute={false}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
