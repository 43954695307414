import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';

// Redux
//import { connect } from "react-redux"
import { Link, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// action
//import { userForgetPassword } from "../../store/actions"

class ResetPasswordMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    //this.props.userForgetPassword(values, this.props.history)
  }

  render() {
    return (
      <React.Fragment>
        <div className='account-pages my-5 pt-sm-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft'>
                    <Row>
                      <div className='text-primary p-4'>
                        <h2 align='center' className='text-primary'>
                          Recovery Email Sent
                        </h2>
                      </div>
                    </Row>
                  </div>
                  <CardBody>
                    <p align='center' className='text-primary'>
                      Please go to your email and click on the link to reset your password
                    </p>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center'>
                  <p>
                    Go back to{' '}
                    <Link to='login' className='fw-medium text-primary'>
                      Login
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ResetPasswordMessage;
