import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Row,
  Button,
  Col,
  Card,
  CardBody,
  Input,
  CardHeader,
  CardTitle,
  Table,
  Dropdown,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Container,
  Modal,
  Alert,
  ModalBody,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
// import ClinicNavbar from '../../../components/clinicNavbar';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
// import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
// import PrimaryModal from "../../../components/primaryModal";
// import { ENUMS } from "../../../utils";
import moment from "moment";
import AddOrganizationModal from "./AddOrganizationModal";
import AssignOrganizationModal from "./AssignOrganizationModal";
import DeactivateMemberModal from "./DeactivateMemberModal";
import ActivateMemberModal from "./ActivateMemberModal";
import RemoveFromOrganizationModal from "./RemoveFromOrganizationModal";
import ChangeRoleModal from "./ChangeRoleModal";
import { logout } from "../../../utils";
import ftd from "../../../assets/images/maxfit.me2.svg";
import Spinner from "../../../common/helper/spinner";
import PaginationComponent from "react-reactstrap-pagination";
import AddBiostrapEmailModal from "./AddBiostrapEmailModal";

// var role = localStorage.getItem('role');
class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      sizePerPage: 10,
      keyword: "",
      showModal: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
      showModal6: false,
      showModal7: false,
      orgSet: false,
      showOrderButton: false,
      showSpinner: true,
      addOrgModal: true,
      deletePatientId: "",
      btnprimary1: false,
      viewOrganizations: false,
      role: "",
      error: "",
      data: [],
      tableHeaders: [
        { text: "id", dataField: "id", sort: true, hidden: true },
        { text: "Name", dataField: "name", sort: true },
        { text: "Email", dataField: "email", sort: true },
        { text: "BioStrap Email", dataField: "biostrapEmail", sort: true },
        { text: "DOB", dataField: "dob", sort: true },
        { text: "Role", dataField: "role", sort: true },
        { text: "Organization", dataField: "organization", sort: true },
        { text: "IsActive?", dataField: "isActive", sort: true },
        {
          text: "Actions",
          dataField: "actions",
          headerStyle: (colum, colIndex) => {
            return { width: "80px", textAlign: "" };
          },
          formatter: (cellContent, row) => {
            return (
              <>
                <button
                  onClick={this.clickUser.bind(this, row.id)}
                  id={`details-${row.id}`}
                  value={row.id}
                  className="btn btn-primary"
                >
                  View Details
                </button>
              </>
            );
          },
        },
      ],

      selectedUser: "",
      showButtons: false,
      organizations: [],
      selectCounter: 0,
      user: "",
      searching: false,
    };
    this.t_viewOrganizations = this.t_viewOrganizations.bind(this);
  }

  t_viewOrganizations() {
    this.setState({ viewOrganizations: !this.state.viewOrganizations });
  }

  closeConfirmDeleteModal() {
    this.setState({ confirmDeleteModal: false });
  }

  async onUserSelect(row, isSelect) {
    await this.setState({
      selectedUser: isSelect ? row.id : "",
      selectCounter: isSelect
        ? this.state.selectCounter + 1
        : this.state.selectCounter - 1,
    });
    this.setState({
      showButtons: this.state.selectCounter == 1 ? true : false,
    });
  }

  //functions for pagination and search start

  filterChanged(v = "") {
    this.setState({ disableFilter: v.length === 0 ? true : false });
  }

  clearFilter() {
    this.getAllStaff();
  }
  clearSearch(keyword) {
    if (keyword === "") {
      this.filterChanged(keyword);
      this.getAllStaff();
    }
  }

  handlePageChange = (pageNo) => {
    const { sizePerPage = 10, keyword = "" } = this.state;
    this.setState({ page: pageNo });

    this.getAllStaffPageChange(sizePerPage, pageNo);
  };

  getAllStaffPageChange(sizePerPage, pageNo) {
    this.setState({ searching: true });
    if (localStorage.getItem("role") != "reportBuilder") {
      axiosConfig
        .get(`user/getuser?currentPage=${pageNo}&pageSize=${sizePerPage}`, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          var data = [];
          if (response.data.userlist) {
            await response.data.userlist.forEach((user) => {
              data.push({
                id: user._id,
                name: user.firstName,
                email: user.email,
                dob: user.dob,
                role: user.role,
                isActive: user.isActive,
                biostrapEmail: user.bioStrapNameByAdmin,
                organization: user.organization_name,
              });
            });
          }

          for (var i = 0; i < data.length; i++) {
            var d = new Date(data[i].dob);

            var currentDay = "" + d.getDate(),
              currentMonth = "" + (d.getMonth() + 1),
              currentYear = d.getFullYear();
            if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
            if (currentDay.length < 2) currentDay = "0" + currentDay;

            var formattedDate = [currentMonth, currentDay, currentYear].join(
              "-"
            );
            data[i].dob = formattedDate;
          }

          console.log("PAGE CHANGE", data);
          await this.setState({
            data: data,
            showSpinner: false,
            searching: false,
          });
        })
        .catch((error) => {
          this.setState({
            showSpinner: false,
            error:
              "An error occurred while trying to fetch user list. " +
              error?.response?.data?.message,
          });
          errorResponse(error);
        });
    } else {
      this.setState({
        searching: false,
      });
    }
  }

  getAllStaffSearch(keyword) {
    this.setState({
      searching: true,
    });
    console.log(keyword);
    if (localStorage.getItem("role") != "reportBuilder") {
      axiosConfig
        .post(
          "admins/search",
          {
            keyword: keyword,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          var data = [];
          if (response.data.Data) {
            await response.data.Data.forEach((user) => {
              data.push({
                id: user._id,
                name: user.firstName,
                email: user.email,
                dob: user.dob,
                role: user.role,
                isActive: user.isActive,
                biostrapEmail: user.bioStrapNameByAdmin,
                organization: user.organization_name,
              });
            });
          }
          for (var i = 0; i < data.length; i++) {
            var d = new Date(data[i].dob);

            var currentDay = "" + d.getDate(),
              currentMonth = "" + (d.getMonth() + 1),
              currentYear = d.getFullYear();
            if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
            if (currentDay.length < 2) currentDay = "0" + currentDay;

            var formattedDate = [currentMonth, currentDay, currentYear].join(
              "-"
            );
            data[i].dob = formattedDate;
          }
          console.log(data);
          await this.setState({
            data: data,
            showSpinner: false,
            searching: false,
          });
        })
        .catch((error) => {
          this.setState({
            showSpinner: false,
            error:
              "An error occurred while trying to fetch user list. " +
              error?.response?.data?.message,
          });
          errorResponse(error);
        });
    } else {
      this.setState({
        searching: false,
      });
    }
  }

  getAllStaff(keyword = "", limit = 10, pageNo = 1) {
    this.setState({
      page: pageNo,
      sizePerPage: limit,
      keyword,
      showSpinner: true,
      searching: true,
    });
    if (localStorage.getItem("role") != "reportBuilder") {
      axiosConfig
        .get("user/getuser", {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          console.log("Users: ", response);
          var data = [];
          if (response.data.userlist) {
            await response.data.userlist.forEach((user) => {
              data.push({
                id: user._id,
                name: user.firstName,
                email: user.email,
                dob: user.dob,
                role: user.role,
                isActive: user.isActive,
                biostrapEmail: user.bioStrapNameByAdmin,
                organization: user.organization_name,
              });
            });
          }
          for (var i = 0; i < data.length; i++) {
            var d = new Date(data[i].dob);

            var currentDay = "" + d.getDate(),
              currentMonth = "" + (d.getMonth() + 1),
              currentYear = d.getFullYear();
            if (currentMonth.length < 2) currentMonth = "0" + currentMonth;
            if (currentDay.length < 2) currentDay = "0" + currentDay;

            var formattedDate = [currentMonth, currentDay, currentYear].join(
              "-"
            );
            data[i].dob = formattedDate;
          }
          console.log(data);
          await this.setState({
            data: data,
            showSpinner: false,
            searching: false,
          });
        })
        .catch((error) => {
          this.setState({
            showSpinner: false,
            error:
              "An error occurred while trying to fetch user list. " +
              error?.response?.data?.message,
          });
          errorResponse(error);
        });
    } else {
      var dt = [];
      dt.push({
        id: JSON.parse(localStorage.getItem("user"))._id,
        name: JSON.parse(localStorage.getItem("user")).firstName,
        email: JSON.parse(localStorage.getItem("user")).email,
        dob: JSON.parse(localStorage.getItem("user")).dob,
        role: JSON.parse(localStorage.getItem("user")).role,
        isActive: JSON.parse(localStorage.getItem("user")).isActive,
        biostrapEmail: JSON.parse(localStorage.getItem("user"))
          .bioStrapNameByAdmin,
        organization: JSON.parse(localStorage.getItem("user"))
          .organization_name,
      });
      this.setState({
        data: dt,
        searching: false,
      });
    }
  }

  //functions for pagination and search end

  componentDidMount() {
    this.setState({
      role: localStorage.getItem("role"),
      user: JSON.parse(localStorage.getItem("user")),
    });

    this.getAllStaff();
    setTimeout(() => {
      if (this.state.role == "superAdmin") {
        axiosConfig
          .get("organization/get", {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          })
          .then(async (response) => {
            var data = [];

            response.data.forEach((user) => {
              data.push({
                id: user._id,
                name: user.Name,
                desc: user.Discription,
                type: user.Type,
                address: user.Address,
              });
            });
            this.setState({
              organizations: data,
              showSpinner: false,
              orgSet: true,
              error: "",
            });
            console.log("organizations: ", data);
          })
          .catch((error) => {
            this.setState({
              showSpinner: false,
              error:
                "An error occurred while trying to fetch organization list. " +
                error?.response?.data?.message,
            });
            errorResponse(error);
          });
      }
    }, 100);
  }

  addOrganization() {
    this.setState({
      addOrgModal: true,
    });
  }
  closeModal() {
    this.setState({
      showModal: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
      showModal6: false,
      showModal7: false,
    });
  }
  getAllOrganizations() {}
  clickUser(rowID) {
    console.log(document.getElementById("details-" + rowID).value);
    localStorage.setItem(
      "selectedUser",
      document.getElementById("details-" + rowID).value
    );
    setTimeout(() => {
      this.props.history.push("/user-info");
    }, 1000);
    // this.setState({
    //   selectedUser: row.id,
    // });
    // console.log(this.state.selectedUser);
  }
  logoutFunction() {
    logout();
    this.props.history.push("/login");
  }
  render() {
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.data.length,
      custom: true,
    };
    const defaultSorted = [{ dataField: "id", order: "asc" }];
    const { SearchBar } = Search;
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      onSelect: (e, selected) => {
        this.onUserSelect(e, selected);
      },
      //   onSelectAll: (selected, rows) => {
      //     this.onAllPatientSelect(selected, rows);
      //   },
    };

    return (
      <React.Fragment>
        {/* <ClinicNavbar /> */}
        <div className="admin-home">
          <MetaTags>
            <title>Home | Marek Health</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Card>
                <Row>
                  <Col xs="4" md="4">
                    <div align="left">
                      <img src={ftd} alt="" height="100" />
                    </div>
                  </Col>
                  <Col xs="4" md="4">
                    <div align="center" className="admin-heading">
                      <h4>Admin Portal</h4>
                    </div>
                  </Col>
                  <Col xs="4" md="4">
                    <div align="right" className="mt-4">
                      <button
                        onClick={this.logoutFunction.bind(this)}
                        className="btn btn-danger"
                      >
                        Logout
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Row>
            <Row>
              <Col sm="2">
                <h5 className="p-3 text-muted">
                  <Link to="/admin-settings">
                    <i className="fas fa-user-cog mx-2" />
                  </Link>

                  {this.state.user.firstName}
                </h5>
              </Col>
              <Col sm="2">
                <h5
                  className="p-3 text-muted cursor-pointer"
                  style={{ width: 180 }}
                  onClick={() => {
                    this.props.history.push("/report-builder");
                  }}
                >
                  <Link to="/report-builder">
                    <i className="fas fa-flask mx-2" />
                  </Link>
                  Report Builder
                </h5>
              </Col>
            </Row>

            {localStorage.getItem("role") == "superAdmin" && this.state.orgSet && (
              <Card>
                <div className="accordion accordion-flush">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushOne">
                      <button
                        align="center"
                        className="accordion-button fw-medium collapsed"
                        type="button"
                        onClick={this.t_viewOrganizations}
                        style={{ cursor: "pointer" }}
                      >
                        View Organizations
                      </button>
                    </h2>
                    <Collapse
                      isOpen={this.state.viewOrganizations}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead className="table-light">
                              <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Address</th>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.organizations.map((org) => {
                                return (
                                  <tr>
                                    <td>{org.id}</td>
                                    <td>{org.name}</td>
                                    <td>{org.type}</td>

                                    <td>{org.address}</td>
                                    <td>{org.desc}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </Card>
            )}

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {/* {this.state.showSpinner ? <Spinner /> : null} */}
                    {this.state.error ? (
                      <Alert color="danger" className="text-center">
                        {this.state.error}
                      </Alert>
                    ) : null}
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.tableHeaders}
                      data={this.state.data}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.tableHeaders}
                          data={this.state.data}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <div>
                                <Row>
                                  <div id="container" align="right">
                                    {this.state.role == "superAdmin" && (
                                      <Button
                                        className="btn-rounded mx-2"
                                        color="primary"
                                        onClick={() => {
                                          this.setState({
                                            showModal: true,
                                          });
                                        }}
                                      >
                                        Add an Organization
                                      </Button>
                                    )}

                                    {this.state.role == "superAdmin" && (
                                      <Button
                                        className={`btn-rounded mx-2 ${
                                          this.state.showButtons
                                            ? ""
                                            : "disabled"
                                        }`}
                                        color="primary"
                                        onClick={() => {
                                          this.getAllOrganizations();
                                          this.setState({
                                            showModal2: true,
                                          });
                                        }}
                                      >
                                        Assign to Organization
                                      </Button>
                                    )}
                                    {this.state.role != "reportBuilder" && (
                                      <Button
                                        color="primary"
                                        className={`btn-rounded mx-2 ${
                                          this.state.showButtons
                                            ? ""
                                            : "disabled"
                                        }`}
                                        onClick={() => {
                                          this.setState({
                                            showModal3: true,
                                          });
                                        }}
                                      >
                                        Remove from Organization
                                      </Button>
                                    )}

                                    {this.state.role == "superAdmin" && (
                                      <Button
                                        color="primary"
                                        className={`btn-rounded mx-2 ${
                                          this.state.showButtons
                                            ? "disable"
                                            : "disabled"
                                        }`}
                                        onClick={() => {
                                          this.setState({
                                            showModal5: true,
                                          });
                                        }}
                                      >
                                        Change Role
                                      </Button>
                                    )}

                                    {this.state.role == "superAdmin" && (
                                      <Button
                                        color="primary"
                                        className={`btn-rounded mx-2 ${
                                          this.state.showButtons
                                            ? "disable"
                                            : "disabled"
                                        }`}
                                        onClick={() => {
                                          this.setState({
                                            showModal7: true,
                                          });
                                        }}
                                      >
                                        Add Biostrap Email
                                      </Button>
                                    )}

                                    {this.state.role == "superAdmin" && (
                                      <Button
                                        color="primary"
                                        className={`btn-rounded mx-2 ${
                                          this.state.showButtons
                                            ? ""
                                            : "disabled"
                                        }`}
                                        onClick={() => {
                                          this.setState({
                                            showModal4: true,
                                          });
                                        }}
                                      >
                                        Deactivate
                                      </Button>
                                    )}
                                    {this.state.role == "superAdmin" && (
                                      <Button
                                        color="primary"
                                        className={`btn-rounded mx-2 ${
                                          this.state.showButtons
                                            ? ""
                                            : "disabled"
                                        }`}
                                        onClick={() => {
                                          this.setState({
                                            showModal6: true,
                                          });
                                        }}
                                      >
                                        Activate
                                      </Button>
                                    )}
                                  </div>
                                </Row>
                              </div>

                              {/* <Row className='  my-3'>
                                <Col className='d-flex justify-content-start search-box'>
                                  <SearchBar placeholder='Search table' className='tableSearchInput' />
                                </Col>
                              </Row> */}

                              <Row className="my-3">
                                <Col xs={6} sm={8} m={1} lg={2}>
                                  <div className="search-box me-2">
                                    <div className="position-relative">
                                      <Input
                                        value={this.state.keyword}
                                        onChange={(e) => {
                                          this.setState({
                                            keyword: e.target.value,
                                          });
                                          this.filterChanged(e.target.value);
                                          this.clearSearch(e.target.value);
                                        }}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="Search..."
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col xs={4} sm={3} m={9} lg={1}>
                                  <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                      var key = this.state.keyword;
                                      var limit = this.state.sizePerPage;
                                      var pageNo = this.state.page;

                                      this.getAllStaffSearch(key);
                                    }}
                                  >
                                    Search
                                  </button>
                                </Col>
                              </Row>

                              {!this.state.searching ? (
                                <Row>
                                  <Col xl="12">
                                    <div
                                      className="table-responsive"
                                      style={{ minHeight: "300px" }}
                                    >
                                      <BootstrapTable
                                        selectRow={selectRow}
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />

                                      {this.state.data.length === 0 ? (
                                        <p className="w-100 text-center m-5">
                                          No data found!
                                        </p>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Spinner />
                              )}

                              <Row className="align-items-md-center mt-3 px-3">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationComponent
                                      size="sm"
                                      totalItems={
                                        this.state.sizePerPage *
                                          this.state.pagination?.totalPages || 0
                                      }
                                      pageSize={this.state.sizePerPage}
                                      onSelect={(e) => this.handlePageChange(e)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <AddOrganizationModal
          showModal={this.state.showModal}
          closeModal={this.closeModal.bind(this)}
        />
        <AssignOrganizationModal
          showModal={this.state.showModal2}
          id={this.state.selectedUser}
          organizations={this.state.organizations}
          closeModal={this.closeModal.bind(this)}
        />
        <RemoveFromOrganizationModal
          showModal={this.state.showModal3}
          id={this.state.selectedUser}
          closeModal={this.closeModal.bind(this)}
        />
        <DeactivateMemberModal
          showModal={this.state.showModal4}
          id={this.state.selectedUser}
          closeModal={this.closeModal.bind(this)}
        />
        <ChangeRoleModal
          showModal={this.state.showModal5}
          id={this.state.selectedUser}
          closeModal={this.closeModal.bind(this)}
        />
        <ActivateMemberModal
          showModal={this.state.showModal6}
          id={this.state.selectedUser}
          closeModal={this.closeModal.bind(this)}
        />
        <AddBiostrapEmailModal
          showModal={this.state.showModal7}
          id={this.state.selectedUser}
          closeModal={this.closeModal.bind(this)}
        />

        {/* <PrimaryModal question='Are you sure you want to delete this patient?' showModal={this.state.confirmDeleteModal} closeModal={this.closeConfirmDeleteModal.bind(this)} onClickYes={this.deletePatient.bind(this)} /> */}
      </React.Fragment>
    );
  }
}

export default Home;
