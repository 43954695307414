import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';

// Redux
//import { connect } from "react-redux"
import { Link, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// action
//import { userForgetPassword } from "../../store/actions"

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo.svg';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';

var passwordToken;
var passwordID;
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notEqualPassword: false,
      passwordUpdateSuccess: false,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  componentDidMount() {
    passwordToken = this.props.location.search.slice(7, this.props.location.search.search('&id='));
    passwordID = this.props.location.search.slice(this.props.location.search.search('&id=') + 4, this.props.location.search.length);
    console.log(passwordToken);
    console.log(passwordID);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    var newPassword = document.getElementById('newPassword').value;
    var confirmPassword = document.getElementById('confirmPassword').value;
    console.log(newPassword);
    console.log(confirmPassword);
    if (newPassword == '') {
      this.setState({
        passwordUpdateSuccess: false,
        notEqualPassword: true,
      });
    } else if (newPassword != confirmPassword) {
      this.setState({
        passwordUpdateSuccess: false,
        notEqualPassword: true,
      });
    } else if (newPassword == confirmPassword) {
      //api call here
      axiosConfig
        .post('usermanagement/setpassword', {
          user_id: passwordID,
          token: passwordToken,
          password: newPassword,
        })
        .then(async (response) => {
          this.setState({
            passwordUpdateSuccess: true,
            notEqualPassword: false,
          });
          console.log(response.data);
          this.props.history.push('/login');
        })
        .catch((error) => {
          errorResponse(error);
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='account-pages my-5 pt-sm-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft'>
                    <Row>
                      <div className='text-primary p-4'>
                        <h3 align='center' className='text-primary'>
                          Update Password
                        </h3>
                        <p align='center' className='text-primary'>
                          Please enter and confirm your new password
                        </p>
                      </div>
                    </Row>
                  </div>
                  {this.state.notEqualPassword && (
                    <Row>
                      <Col>
                        <Alert color='danger' align='center'>
                          Password not the same as entered above or left empty
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  {this.state.passwordUpdateSuccess && (
                    <Row>
                      <Col>
                        <Alert color='success' align='center'>
                          Password Updated
                        </Alert>
                      </Col>
                    </Row>
                  )}

                  <CardBody className='pt-0'>
                    <div className='p-2'>
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color='danger' style={{ marginTop: '13px' }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}
                      {this.props.forgetSuccessMsg ? (
                        <Alert color='success' style={{ marginTop: '13px' }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm className='form-horizontal' onValidSubmit={this.handleValidSubmit}>
                        <div className='mb-3 mt-5'>
                          <AvField id='newPassword' name='newPassword' label='New Password' className='form-control' placeholder='Enter password' type='password' required />
                        </div>
                        <div className='mb-3 mt-5'>
                          <AvField id='confirmPassword' name='confirmPassword' label='Confirm Password' className='form-control' placeholder='Confirm password' type='password' required />
                        </div>
                        <div className='text-end'>
                          <button className='btn btn-primary btn-block mt-4' type='submit'>
                            Update
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-5 text-center'>
                  <p>
                    Go back to{' '}
                    <Link to='login' className='fw-medium text-primary'>
                      Login
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
