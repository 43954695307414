import React, { useEffect, useState } from 'react';

import { Alert, Button, Card, CardBody, Col, Container, Modal, Label, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import { WindowScrollController } from '@fullcalendar/react';
import Spinner from '../../../common/helper/spinner';
var selectedOrg = '';
const AssignOrganizationModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const toggle = () => {
    props.closeModal();
  };

  const submitButton = () => {
    if (selectedOrg == '') {
      console.log('No organization selected');
      setMessage('No organization selected');
      setShowMessage(true);
    } else {
      // I need userid and organization id
      console.log('user id: ', props.id);
      console.log('org id: ', selectedOrg);
      setShowMessage(false);
      setShowSpinner(true);
      axiosConfig
        .post(
          '/organization/assign',
          {
            user_id: props.id,
            organization_id: selectedOrg,
          },
          {
            headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
          }
        )
        .then(async (response) => {
          console.log('******response*******');
          console.log(response);
          console.log('******response*******');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          errorResponse(error);
          setMessage(error.response.data.message);
          setShowMessage(true);
          setShowSpinner(false);
        });
    }
  };

  useEffect(() => {
    console.log('run');
  });

  return (
    <React.Fragment>
      {props.showModal ? (
        <Modal isOpen={props.showModal} className={props.className}>
          <ModalHeader>
            <div align='center'>
              <h3>Select an Organization</h3>
            </div>
          </ModalHeader>
          <ModalBody>
            {showMessage && (
              <Alert align='center' color='danger'>
                {message}
              </Alert>
            )}
            <Row>
              {props.organizations.map((org, idx) => {
                return (
                  //   <Button id={`org-${idx}`} className='my-2'>
                  //     {props.organizations[idx].name}
                  //   </Button>
                  <div className='form-check mb-2'>
                    <input
                      type='checkbox'
                      className='form-check-input input-mini'
                      id=''
                      value='checked'
                      onChange={(e) => {
                        if (e.target.checked) {
                          selectedOrg = props.organizations[idx].id;
                          console.log(selectedOrg);
                        }
                      }}
                    />
                    <Label className='form-check-label text-secondary' htmlFor='closeButton'>
                      {props.organizations[idx].name}
                    </Label>
                  </div>
                );
              })}
            </Row>
            <Row>
              {showSpinner && <Spinner />}

              <div align='center'>
                <Col>
                  <button type='button' className='btn btn-success me-2' onClick={submitButton}>
                    Assign
                  </button>
                  <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                    Close
                  </button>
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default AssignOrganizationModal;
