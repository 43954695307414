import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';

// Redux
//import { connect } from "react-redux"
import { Link, withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// action
//import { userForgetPassword } from "../../store/actions"

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/logo.svg';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';

var userID;
var token;
class EmailVerified extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    token = this.props.location.search.slice(7, this.props.location.search.search('&id='));
    userID = this.props.location.search.slice(this.props.location.search.search('&id=') + 4, this.props.location.search.length);
    console.log(token);
    console.log(userID);
    axiosConfig
      .post('usermanagement/verifyEmail', {
        user_id: userID,
        token: token,
      })
      .then(async (response) => {
        console.log(response.data);
        this.props.history.push('/login');
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className='account-pages my-5 pt-sm-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft'>
                    <Row>
                      <div className='text-primary p-4'>
                        <h3 align='center' className='text-primary'>
                          Verifying Email
                        </h3>
                      </div>
                    </Row>
                  </div>
                  <CardBody>
                    <p align='center' className='mt-3 text-primary'>
                      Please wait, you will be redirected to the login page once your email is verified.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EmailVerified;
