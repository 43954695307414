import React, { useEffect, useState } from 'react';

import { Alert, Col, Modal, FormGroup, Label, Input, ModalBody, Row } from 'reactstrap';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import Spinner from '../../../common/helper/spinner';

const AddCategoryModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const toggle = () => {
    setShowMessage(false);
    setShowSpinner(false);
    props.toggleEdit();
    props.closeModal();
  };

  const submitButton = () => {
    setShowSpinner(true);
    if (document.getElementById('categoryName').value && document.getElementById('categoryDescription').value) {
      if (!props.isEdit && !props.isDelete) {
        axiosConfig
          .post(
            '/labBuilder/Category',
            {
              categoryName: document.getElementById('categoryName').value,
              categoryDiscription: document.getElementById('categoryDescription').value,
              entity_id: props.entityID,
              hideResult: document.getElementById('hideResult').checked,
            },
            {
              headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
            }
          )
          .then(async (response) => {
            setShowSpinner(false);
            toggle();
          })
          .catch((error) => {
            errorResponse(error);
            setShowMessage(error.response.data.message);
            setShowSpinner(false);
          });
      } else if (props.isEdit) {
        axiosConfig
          .post(
            '/labBuilder/updateCaetgory',
            {
              categoryName: document.getElementById('categoryName').value,
              categoryDiscription: document.getElementById('categoryDescription').value,
              category_id: props.editID,
              hideResult: document.getElementById('hideResult').checked,
            },
            {
              headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
            }
          )
          .then(async (response) => {

            setShowSpinner(false);
            toggle();
          })
          .catch((error) => {
            errorResponse(error);
            setShowMessage(error.response.data.message);
            setShowSpinner(false);
          });
      } else if (props.isDelete) {
        axiosConfig
          .post(
            '/labBuilder/DeleteCategory',
            {
              category_id: props.editID,
            },
            {
              headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
            }
          )
          .then(async (response) => {
            setShowSpinner(false);
            toggle();
          })
          .catch((error) => {
            errorResponse(error);
            setShowMessage(error.response.data.message);
            setShowSpinner(false);
          });
      }
    } else {
      setShowSpinner(false);
      setShowMessage(true);
      setMessage('Please fill category name and category description');
    }

    // setShowSpinner(true);
  };

  useEffect(() => {});

  return (
    <React.Fragment>
      {props.showModal ? (
        <Modal isOpen={props.showModal} className={props.className}>
          {showMessage && (
            <Alert align='center' color='danger'>
              {message}
            </Alert>
          )}
          <ModalBody>
            <Row>
              <h3 className='my-3 mb-5' align='center'>
                Add Category
              </h3>
            </Row>

            <Row className='justify-content-center'>
              <Col sm='10' className='mb-4'>
                <FormGroup>
                  <Label htmlFor='categoryName' className='form-label'>
                    Category Name<span className='requiredStar'>*</span>
                  </Label>
                  <Input id='categoryName' className='form-control' placeholder='' type='text' required={props.step === 3 ? true : false} defaultValue={props.isEdit || props.isDelete ? props.editName : ''} />
                </FormGroup>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col sm='10' className='mb-4'>
                <FormGroup>
                  <Label htmlFor='categoryDescription' className='form-label'>
                    Category Description<span className='requiredStar'>*</span>
                  </Label>
                  <Input style={{ minHeight: 100 }} id='categoryDescription' className='form-control' placeholder='' type='textarea' required={props.step === 3 ? true : false} defaultValue={props.isEdit || props.isDelete ? props.editDescription : ''} />
                </FormGroup>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col sm='10' className='mb-4'>
                <div className='form-check mb-2'>
                  <input type='checkbox' className='form-check-input input-mini' id='hideResult' value='checked' defaultChecked={props.isEdit || props.isDelete ? props.editHideResult : false} />
                  <Label className='form-check-label text-secondary'>Hide if Result in Range</Label>
                </div>
              </Col>
            </Row>

            <Row className='mt-4'>
              {showSpinner && <Spinner />}

              <div align='center'>
                <Col>
                  <button style={{ width: 100 }} type='button' className='btn btn-light me-2' onClick={toggle}>
                    Cancel
                  </button>
                  {props.isDelete ? (
                    <button style={{ width: 100 }} type='button' className='btn btn-danger me-2' onClick={submitButton}>
                      Delete
                    </button>
                  ) : (
                    <button style={{ width: 100 }} type='button' className='btn btn-primary me-2' onClick={submitButton}>
                      Add
                    </button>
                  )}
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default AddCategoryModal;
