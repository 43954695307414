import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Label,
  FormGroup,
} from "reactstrap";
import Cookies from "universal-cookie";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../common/helper/spinner";
import AddCategoryModal from "./AddCategoryModal";

const ConditionLab = (props) => {
  return (
    <React.Fragment>
      {props.isEdit === "false" ? (
        <div>
          <Row className="my-1">
            <Col sm="4">
              {props.defaultCondition ? (
                <div>
                  {props.defaultCondition.operation === "AND" ? (
                    <select
                      required
                      id={`labcondition${props.index}`}
                      name={`labcondition${props.index}`}
                      className="form-select "
                    >
                      <option value="AND" selected>
                        AND
                      </option>
                      <option value="OR">OR</option>
                    </select>
                  ) : (
                    <select
                      required
                      id={`labcondition${props.index}`}
                      name={`labcondition${props.index}`}
                      className="form-select "
                    >
                      <option value="AND">AND</option>
                      <option value="OR" selected>
                        OR
                      </option>
                    </select>
                  )}
                </div>
              ) : (
                <select
                  required
                  id={`labcondition${props.index}`}
                  name={`labcondition${props.index}`}
                  className="form-select "
                >
                  <option value="AND" selected>
                    AND
                  </option>
                  <option value="OR">OR</option>
                </select>
              )}
            </Col>
            <Col sm="8">
              <FormGroup>
                <select
                  onClick={() => {
                    console.log(props.labs);
                  }}
                  required
                  id={`selectInternalLab${props.index}`}
                  name={`selectInternalLab${props.index}`}
                  className="form-select "
                >
                  {props.defaultCondition ? (
                    <option value={props.defaultCondition.id} selected>
                      {props.defaultCondition.labName}
                    </option>
                  ) : (
                    <option value="Select Lab" selected>
                      Select Lab
                    </option>
                  )}

                  {props.labs.map((item, i) => {
                    return (
                      <option
                        style={{ color: "#495057" }}
                        className="form-select"
                        value={item.id}
                      >
                        {item.displayTestName}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Label className="form-label">
              Optimal Range <span className="requiredStar">*</span>
            </Label>
            <Col>
              <FormGroup className="mb-4">
                {props.defaultCondition ? (
                  <div>
                    {props.defaultCondition.optimalRange.includes("-") ? (
                      <div>
                        <select
                          onChange={() => {
                            if (
                              document.getElementById(
                                "optimalRange" + props.index.toString()
                              ).value === "Non-numeric Value"
                            ) {
                              document.getElementById(
                                "nonNumericValue" + props.index.toString()
                              ).style.display = "block";
                              document.getElementById(
                                "lesserRange" + props.index.toString()
                              ).style.display = "none";
                              document.getElementById(
                                "greaterRange" + props.index.toString()
                              ).style.display = "none";
                            } else {
                              document.getElementById(
                                "nonNumericValue" + props.index.toString()
                              ).style.display = "none";

                              if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Greater than only"
                              ) {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "none";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "block";
                              } else if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Less than only"
                              ) {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "none";
                              } else {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "block";
                              }
                            }
                          }}
                          className="form-select"
                          id={`optimalRange${props.index}`}
                          type="select"
                          name={`optimalRange${props.index}`}
                          required
                        >
                          <p className="dropdownArrow">
                            <i className="fas fa-angle-down"></i>
                          </p>
                          <option
                            style={{ color: "#495057" }}
                            className="form-select"
                            selected
                          >
                            Range
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Less than only
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Greater than only
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                          >
                            Non-numeric Value
                          </option>
                        </select>
                      </div>
                    ) : null}
                    {props.defaultCondition.optimalRange.includes(">") ? (
                      <div>
                        <select
                          onChange={() => {
                            if (
                              document.getElementById(
                                "optimalRange" + props.index.toString()
                              ).value === "Non-numeric Value"
                            ) {
                              document.getElementById(
                                "nonNumericValue" + props.index.toString()
                              ).style.display = "block";
                              document.getElementById(
                                "lesserRange" + props.index.toString()
                              ).style.display = "none";
                              document.getElementById(
                                "greaterRange" + props.index.toString()
                              ).style.display = "none";
                            } else {
                              document.getElementById(
                                "nonNumericValue" + props.index.toString()
                              ).style.display = "none";

                              if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Greater than only"
                              ) {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "none";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "block";
                              } else if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Less than only"
                              ) {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "none";
                              } else {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "block";
                              }
                            }
                          }}
                          className="form-select"
                          id={`optimalRange${props.index}`}
                          type="select"
                          name={`optimalRange${props.index}`}
                          required
                        >
                          <p className="dropdownArrow">
                            <i className="fas fa-angle-down"></i>
                          </p>
                          <option
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Range
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Less than only
                          </option>
                          <option
                            selected
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Greater than only
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                          >
                            Non-numeric Value
                          </option>
                        </select>
                      </div>
                    ) : null}
                    {props.defaultCondition.optimalRange.includes("<") ? (
                      <div>
                        <select
                          onChange={() => {
                            if (
                              document.getElementById(
                                "optimalRange" + props.index.toString()
                              ).value === "Non-numeric Value"
                            ) {
                              document.getElementById(
                                "nonNumericValue" + props.index.toString()
                              ).style.display = "block";
                              document.getElementById(
                                "lesserRange" + props.index.toString()
                              ).style.display = "none";
                              document.getElementById(
                                "greaterRange" + props.index.toString()
                              ).style.display = "none";
                            } else {
                              document.getElementById(
                                "nonNumericValue" + props.index.toString()
                              ).style.display = "none";

                              if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Greater than only"
                              ) {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "none";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "block";
                              } else if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Less than only"
                              ) {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "none";
                              } else {
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "block";
                              }
                            }
                          }}
                          className="form-select"
                          id={`optimalRange${props.index}`}
                          type="select"
                          name={`optimalRange${props.index}`}
                          required
                        >
                          <p className="dropdownArrow">
                            <i className="fas fa-angle-down"></i>
                          </p>
                          <option
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Range
                          </option>
                          <option
                            selected
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Less than only
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-select"
                          >
                            Greater than only
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                          >
                            Non-numeric Value
                          </option>
                        </select>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <select
                      onChange={() => {
                        if (
                          document.getElementById(
                            "optimalRange" + props.index.toString()
                          ).value === "Non-numeric Value"
                        ) {
                          document.getElementById(
                            "nonNumericValue" + props.index.toString()
                          ).style.display = "block";
                          document.getElementById(
                            "lesserRange" + props.index.toString()
                          ).style.display = "none";
                          document.getElementById(
                            "greaterRange" + props.index.toString()
                          ).style.display = "none";
                        } else {
                          document.getElementById(
                            "nonNumericValue" + props.index.toString()
                          ).style.display = "none";

                          if (
                            document.getElementById(
                              "optimalRange" + props.index.toString()
                            ).value === "Greater than only"
                          ) {
                            document.getElementById(
                              "lesserRange" + props.index.toString()
                            ).style.display = "none";
                            document.getElementById(
                              "greaterRange" + props.index.toString()
                            ).style.display = "block";
                          } else if (
                            document.getElementById(
                              "optimalRange" + props.index.toString()
                            ).value === "Less than only"
                          ) {
                            document.getElementById(
                              "lesserRange" + props.index.toString()
                            ).style.display = "block";
                            document.getElementById(
                              "greaterRange" + props.index.toString()
                            ).style.display = "none";
                          } else {
                            document.getElementById(
                              "lesserRange" + props.index.toString()
                            ).style.display = "block";
                            document.getElementById(
                              "greaterRange" + props.index.toString()
                            ).style.display = "block";
                          }
                        }
                      }}
                      className="form-select"
                      id={`optimalRange${props.index}`}
                      type="select"
                      name={`optimalRange${props.index}`}
                      required
                    >
                      <p className="dropdownArrow">
                        <i className="fas fa-angle-down"></i>
                      </p>
                      <option
                        style={{ color: "#495057" }}
                        className="form-select"
                        selected
                      >
                        Range
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-select"
                      >
                        Less than only
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-select"
                      >
                        Greater than only
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-control"
                      >
                        Non-numeric Value
                      </option>
                    </select>
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  name={`greaterRange${props.index}`}
                  id={`greaterRange${props.index}`}
                  className="form-control"
                  type="text"
                  placeholder="Greater than"
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  name={`lesserRange${props.index}`}
                  id={`lesserRange${props.index}`}
                  className="form-control"
                  type="text"
                  placeholder="Less than"
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  className="form-select"
                  id={`nonNumericValue${props.index}`}
                  type="select"
                  name={`nonNumericValue${props.index}`}
                  style={{ display: "none" }}
                >
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    value="Negative"
                  >
                    Negative
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    value="Negative/Trace"
                  >
                    Negative/Trace
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    value="Yellow"
                  >
                    Yellow
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    value="Positive"
                  >
                    Positive
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    value="Positive/Trace"
                  >
                    Positive/Trace
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    value="Clear"
                  >
                    Clear
                  </option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <div>
            <Row className="my-1">
              <Col sm="4">
                {props.defaultCondition ? (
                  <div>
                    {props.defaultCondition.operation === "AND" ? (
                      <select
                        required
                        id={`labcondition${props.index}`}
                        name={`labcondition${props.index}`}
                        className="form-select "
                      >
                        <option value="AND" selected>
                          AND
                        </option>
                        <option value="OR">OR</option>
                      </select>
                    ) : (
                      <select
                        required
                        id={`labcondition${props.index}`}
                        name={`labcondition${props.index}`}
                        className="form-select "
                      >
                        <option value="AND">AND</option>
                        <option value="OR" selected>
                          OR
                        </option>
                      </select>
                    )}
                  </div>
                ) : (
                  <select
                    required
                    id={`labcondition${props.index}`}
                    name={`labcondition${props.index}`}
                    className="form-select "
                  >
                    <option value="AND" selected>
                      AND
                    </option>
                    <option value="OR">OR</option>
                  </select>
                )}
              </Col>
              <Col sm="8">
                <select
                  onClick={() => {
                    console.log(props.labs);
                  }}
                  required
                  id={`selectInternalLab${props.index}`}
                  name={`selectInternalLab${props.index}`}
                  className="form-select "
                >
                  {props.defaultCondition ? (
                    <option value={props.defaultCondition.id} selected>
                      {props.defaultCondition.labName}
                    </option>
                  ) : (
                    <option value="Select Lab" selected>
                      Select Lab
                    </option>
                  )}

                  {props.labs.map((item, i) => {
                    return (
                      <option
                        style={{ color: "#495057" }}
                        className="form-select"
                        value={item.id}
                      >
                        {item.displayTestName}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>

            <Row>
              <Label className="form-label">
                Optimal Range <span className="requiredStar">*</span>
              </Label>
              <Col xs="4">
                <FormGroup className="mb-4">
                  {props.defaultCondition ? (
                    <div>
                      {!props.defaultCondition.optimalRange.includes("-") &&
                      !props.defaultCondition.optimalRange.includes(">") &&
                      !props.defaultCondition.optimalRange.includes("<") ? (
                        <div>
                          <select
                            onChange={() => {
                              if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Non-numeric Value"
                              ) {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "none";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "none";
                              } else {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "none";

                                if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Greater than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "none";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                } else if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Less than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "none";
                                } else {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                }
                              }
                            }}
                            className="form-select"
                            id={`optimalRange${props.index}`}
                            type="select"
                            name={`optimalRange${props.index}`}
                            required
                          >
                            <p className="dropdownArrow">
                              <i className="fas fa-angle-down"></i>
                            </p>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Range
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Less than only
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Greater than only
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-control"
                              selected
                            >
                              Non-numeric Value
                            </option>
                          </select>
                        </div>
                      ) : null}

                      {props.defaultCondition.optimalRange.includes("-") ? (
                        <div>
                          <select
                            onChange={() => {
                              if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Non-numeric Value"
                              ) {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "none";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "none";
                              } else {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "none";

                                if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Greater than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "none";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                } else if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Less than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "none";
                                } else {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                }
                              }
                            }}
                            className="form-select"
                            id={`optimalRange${props.index}`}
                            type="select"
                            name={`optimalRange${props.index}`}
                            required
                          >
                            <p className="dropdownArrow">
                              <i className="fas fa-angle-down"></i>
                            </p>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                              selected
                            >
                              Range
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Less than only
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Greater than only
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-control"
                            >
                              Non-numeric Value
                            </option>
                          </select>
                        </div>
                      ) : null}
                      {props.defaultCondition.optimalRange.includes(">") ? (
                        <div>
                          <select
                            onChange={() => {
                              if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Non-numeric Value"
                              ) {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "none";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "none";
                              } else {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "none";

                                if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Greater than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "none";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                } else if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Less than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "none";
                                } else {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                }
                              }
                            }}
                            className="form-select"
                            id={`optimalRange${props.index}`}
                            type="select"
                            name={`optimalRange${props.index}`}
                            required
                          >
                            <p className="dropdownArrow">
                              <i className="fas fa-angle-down"></i>
                            </p>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Range
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Less than only
                            </option>
                            <option
                              selected
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Greater than only
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-control"
                            >
                              Non-numeric Value
                            </option>
                          </select>
                        </div>
                      ) : null}
                      {props.defaultCondition.optimalRange.includes("<") ? (
                        <div>
                          <select
                            onChange={() => {
                              if (
                                document.getElementById(
                                  "optimalRange" + props.index.toString()
                                ).value === "Non-numeric Value"
                              ) {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "block";
                                document.getElementById(
                                  "lesserRange" + props.index.toString()
                                ).style.display = "none";
                                document.getElementById(
                                  "greaterRange" + props.index.toString()
                                ).style.display = "none";
                              } else {
                                document.getElementById(
                                  "nonNumericValue" + props.index.toString()
                                ).style.display = "none";

                                if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Greater than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "none";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                } else if (
                                  document.getElementById(
                                    "optimalRange" + props.index.toString()
                                  ).value === "Less than only"
                                ) {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "none";
                                } else {
                                  document.getElementById(
                                    "lesserRange" + props.index.toString()
                                  ).style.display = "block";
                                  document.getElementById(
                                    "greaterRange" + props.index.toString()
                                  ).style.display = "block";
                                }
                              }
                            }}
                            className="form-select"
                            id={`optimalRange${props.index}`}
                            type="select"
                            name={`optimalRange${props.index}`}
                            required
                          >
                            <p className="dropdownArrow">
                              <i className="fas fa-angle-down"></i>
                            </p>

                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Range
                            </option>
                            <option
                              selected
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Less than only
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-select"
                            >
                              Greater than only
                            </option>
                            <option
                              style={{ color: "#495057" }}
                              className="form-control"
                            >
                              Non-numeric Value
                            </option>
                          </select>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <select
                        onChange={() => {
                          if (
                            document.getElementById(
                              "optimalRange" + props.index.toString()
                            ).value === "Non-numeric Value"
                          ) {
                            document.getElementById(
                              "nonNumericValue" + props.index.toString()
                            ).style.display = "block";
                            document.getElementById(
                              "lesserRange" + props.index.toString()
                            ).style.display = "none";
                            document.getElementById(
                              "greaterRange" + props.index.toString()
                            ).style.display = "none";
                          } else {
                            document.getElementById(
                              "nonNumericValue" + props.index.toString()
                            ).style.display = "none";

                            if (
                              document.getElementById(
                                "optimalRange" + props.index.toString()
                              ).value === "Greater than only"
                            ) {
                              document.getElementById(
                                "lesserRange" + props.index.toString()
                              ).style.display = "none";
                              document.getElementById(
                                "greaterRange" + props.index.toString()
                              ).style.display = "block";
                            } else if (
                              document.getElementById(
                                "optimalRange" + props.index.toString()
                              ).value === "Less than only"
                            ) {
                              document.getElementById(
                                "lesserRange" + props.index.toString()
                              ).style.display = "block";
                              document.getElementById(
                                "greaterRange" + props.index.toString()
                              ).style.display = "none";
                            } else {
                              document.getElementById(
                                "lesserRange" + props.index.toString()
                              ).style.display = "block";
                              document.getElementById(
                                "greaterRange" + props.index.toString()
                              ).style.display = "block";
                            }
                          }
                        }}
                        className="form-select"
                        id={`optimalRange${props.index}`}
                        type="select"
                        name={`optimalRange${props.index}`}
                        required
                      >
                        <p className="dropdownArrow">
                          <i className="fas fa-angle-down"></i>
                        </p>
                        <option
                          style={{ color: "#495057" }}
                          className="form-select"
                          selected
                        >
                          Range
                        </option>
                        <option
                          style={{ color: "#495057" }}
                          className="form-select"
                        >
                          Less than only
                        </option>
                        <option
                          style={{ color: "#495057" }}
                          className="form-select"
                        >
                          Greater than only
                        </option>
                        <option
                          style={{ color: "#495057" }}
                          className="form-control"
                        >
                          Non-numeric Value
                        </option>
                      </select>
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xs="4">
                <FormGroup className="mb-4">
                  {props.defaultCondition ? (
                    <div>
                      {props.defaultCondition.optimalRange.includes("-") ? (
                        <Input
                          name={`greaterRange${props.index}`}
                          id={`greaterRange${props.index}`}
                          className="form-control"
                          type="text"
                          placeholder="Greater than"
                          defaultValue={props.defaultCondition.optimalRange.substring(
                            0,
                            props.defaultCondition.optimalRange.indexOf("-")
                          )}
                        />
                      ) : null}
                      {props.defaultCondition.optimalRange.includes(">") ? (
                        <Input
                          name={`greaterRange${props.index}`}
                          id={`greaterRange${props.index}`}
                          className="form-control"
                          type="text"
                          placeholder="Greater than"
                          defaultValue={props.defaultCondition.optimalRange.substring(
                            1,
                            props.defaultCondition.optimalRange.length
                          )}
                        />
                      ) : null}
                      {!props.defaultCondition.optimalRange.includes("-") &&
                      !props.defaultCondition.optimalRange.includes(">") &&
                      !props.defaultCondition.optimalRange.includes("<") ? (
                        <Input
                          name={`greaterRange${props.index}`}
                          id={`greaterRange${props.index}`}
                          className="form-control"
                          type="text"
                          placeholder="Greater than"
                          style={{ display: "none" }}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <Input
                      name={`greaterRange${props.index}`}
                      id={`greaterRange${props.index}`}
                      className="form-control"
                      type="text"
                      placeholder="Greater than"
                    />
                  )}
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup className="mb-4">
                  {props.defaultCondition ? (
                    <div>
                      {props.defaultCondition.optimalRange.includes("-") ? (
                        <Input
                          name={`lesserRange${props.index}`}
                          id={`lesserRange${props.index}`}
                          className="form-control"
                          type="text"
                          placeholder="Less than"
                          defaultValue={props.defaultCondition.optimalRange.substring(
                            props.defaultCondition.optimalRange.indexOf("-") +
                              1,
                            props.defaultCondition.optimalRange.length
                          )}
                        />
                      ) : null}
                      {props.defaultCondition.optimalRange.includes("<") ? (
                        <Input
                          name={`lesserRange${props.index}`}
                          id={`lesserRange${props.index}`}
                          className="form-control"
                          type="text"
                          placeholder="Less than"
                          defaultValue={props.defaultCondition.optimalRange.substring(
                            1,
                            props.defaultCondition.optimalRange.length
                          )}
                        />
                      ) : null}
                      {!props.defaultCondition.optimalRange.includes("-") &&
                      !props.defaultCondition.optimalRange.includes(">") &&
                      !props.defaultCondition.optimalRange.includes("<") ? (
                        <Input
                          name={`lesserRange${props.index}`}
                          id={`lesserRange${props.index}`}
                          className="form-control"
                          type="text"
                          placeholder="Less than"
                          style={{ display: "none" }}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <Input
                      name={`lesserRange${props.index}`}
                      id={`lesserRange${props.index}`}
                      className="form-control"
                      type="text"
                      placeholder="Less than"
                    />
                  )}
                </FormGroup>
              </Col>

              <Col xs="4">
                <FormGroup className="mb-4">
                  {props.defaultCondition ? (
                    <div>
                      {!props.defaultCondition.optimalRange.includes("-") &&
                      !props.defaultCondition.optimalRange.includes(">") &&
                      !props.defaultCondition.optimalRange.includes("<") ? (
                        <select
                          className="form-select"
                          id={`nonNumericValue${props.index}`}
                          type="select"
                          name={`nonNumericValue${props.index}`}
                        >
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                            selected={
                              props.defaultCondition.optimalRange == "Negative"
                            }
                          >
                            Negative
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                            selected={
                              props.defaultCondition.optimalRange ==
                              "Negative/Trace"
                            }
                          >
                            Negative/Trace
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                            selected={
                              props.defaultCondition.optimalRange == "Clear"
                            }
                          >
                            Clear
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                            selected={
                              props.defaultCondition.optimalRange == "Yellow"
                            }
                          >
                            Yellow
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                            selected={
                              props.defaultCondition.optimalRange == "Positive"
                            }
                          >
                            Positive
                          </option>
                          <option
                            style={{ color: "#495057" }}
                            className="form-control"
                            selected={
                              props.defaultCondition.optimalRange ==
                              "Positive/Trace"
                            }
                          >
                            Positive/Trace
                          </option>
                        </select>
                      ) : null}
                    </div>
                  ) : (
                    <select
                      className="form-select"
                      id={`nonNumericValue${props.index}`}
                      type="select"
                      name={`nonNumericValue${props.index}`}
                    >
                      <option
                        style={{ color: "#495057" }}
                        className="form-control"
                        selected
                      >
                        Negative
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-control"
                      >
                        Negative/Trace
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-control"
                      >
                        Clear
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-control"
                      >
                        Yellow
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-control"
                      >
                        Positive
                      </option>
                      <option
                        style={{ color: "#495057" }}
                        className="form-control"
                      >
                        Positive/Trace
                      </option>
                    </select>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ConditionLab;
