import React, { useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap";
import Cookies from "universal-cookie";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import { logout } from "../../../utils";
import EntityDetails from "./EntityDetails";
import AttachImages from "./AttachImages";
import AddCategories from "./AddCategories";
import AddLabInformation from "./AddLabInformation";
import AssignCategories from "./AssignCategories";
import Lottie from "../../../common/helper/lottie";
import Spinner from "../../../common/helper/spinner";

import ftd from "../../../assets/images/maxfit.me2.svg";
import AddRecommendation from "./AddRecommendation";

const DuplicateReport = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState("");
  const [entityID, setEntityID] = useState(props.match.params.id);
  const handleValidSubmit = (e) => {
    e.preventDefault();

    var eID = document.getElementById("entityID").value;
    var templateName = document.getElementById("templateName").value;

    if (eID.length <= 10) {
      if (eID === eID.toUpperCase()) {
        setShowSpinner(true);
        axiosConfig
          .post(
            "/labBuilder/Duplicate",
            {
              templateName: templateName,
              entity_id: entityID,
              entityID: eID,
              dateAdded: new Date(),
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            setShowSpinner(false);
            props.history.push("/report-builder");
          })
          .catch((error) => {
            errorResponse(error);
            setError(error.response.data.message);
            setShowSpinner(false);
          });

        setError("");
      } else {
        setError(
          "Entity ID must be all CAPS and length must be less than or equal to 10 characters"
        );
      }
    } else {
      setError(
        "Entity ID must be all CAPS and length must be less than or equal to 10 characters"
      );
    }
  };

  const logoutFunction = () => {
    logout();
    props.history.push("/login");
  };

  return (
    <React.Fragment>
      <div className="admin-home">
        <MetaTags>
          <title>Duplicate Report | Marek Health</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Card>
              <Row>
                <Col xs="4" md="4">
                  <Link to="/report-builder" align="left">
                    <img src={ftd} alt="" height="100" />
                  </Link>
                </Col>
                <Col xs="4" md="4">
                  <div align="center" className="admin-heading">
                    <h4>Admin Portal</h4>
                  </div>
                </Col>
                <Col xs="4" md="4">
                  <div align="right" className="mt-4">
                    <button onClick={logoutFunction} className="btn btn-danger">
                      Logout
                    </button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>

          <h4 className="p-3 text-muted">ADD DETAILS TO DUPLICATE LAB</h4>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <form
                      id="newLabForm"
                      className="stepsForm form-horizontal px-sm-4 px-0"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      <div className="mb-5 px-sm-5 px-0">
                        {error && (
                          <Alert align="center" color="danger">
                            {error}
                          </Alert>
                        )}
                        {/* add entity details here */}
                        <Row className="justify-content-center">
                          <Col sm="8" className="my-4">
                            <FormGroup>
                              <Label
                                htmlFor="templateName"
                                className="form-label"
                              >
                                Template Name{" "}
                                <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                id="templateName"
                                className="form-control"
                                placeholder=""
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="8" className="mb-4">
                            <FormGroup>
                              <Label htmlFor="entityID" className="form-label">
                                Entity ID{" "}
                                <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                id="entityID"
                                className="form-control"
                                placeholder=""
                                type="text"
                                required={props.step === 1 ? true : false}
                              />
                              <Row>
                                <Label
                                  style={{ fontSize: 11 }}
                                  htmlFor="entityID"
                                  className="form-label mt-1"
                                >
                                  (ID must be in all CAPS and length must be
                                  less than or equal to 10 characters, e.g
                                  MRK0000020)
                                </Label>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      {showSpinner && <Spinner />}
                      <Row className="m-sm-5 m-0 text-center flexJCAC pt-3">
                        <button
                          to="/ReportBuilder"
                          className="btn btn-light mt-4 w-50 mx-1"
                          type="button"
                          onClick={() => {
                            props.history.push("/report-builder");
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className="btn btn-primary mt-4 w-50 ms-2"
                          type="submit"
                        >
                          Duplicate
                        </button>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DuplicateReport;
