import React from "react";
import { Alert, Col, FormGroup, Label, Input, Row } from "reactstrap";
import Spinner from "../../../common/helper/spinner";

const EntityDetails = (props) => {
  return (
    <React.Fragment>
      <div className={`${props.step === 1 ? "d-block" : "d-none"}`}>
        <Alert align="center" color="warning" className="mb-4">
          Please upload a pdf in workdrive in the correct entity folder with
          file format of
          Setting_EntityID_PatientID_firstname.lastname_dateofservice_dob (e.g.
          Setting_MT4_MH272_Jalali.Amir_08.06.91_11.11.21), please review the
          sample once file is uploaded.
        </Alert>
        <Row className="justify-content-center">
          {props.isEdit && !props.gotData && <Spinner />}
        </Row>
        <Row className="justify-content-center">
          <Col sm="4" className="mb-4">
            <FormGroup>
              <Label htmlFor="entityName" className="form-label">
                Entity Name <span className="requiredStar">*</span>
              </Label>
              <Input
                id="entityName"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.entityName
                    : ""
                }
                className="form-control"
                placeholder=""
                type="text"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
          <Col sm="4" className="mb-4">
            <FormGroup>
              <Label htmlFor="entityID" className="form-label">
                Entity ID <span className="requiredStar">*</span>
              </Label>
              <Input
                id="entityID"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.entityId
                    : ""
                }
                className="form-control"
                placeholder=""
                type="text"
                required={props.step === 1 ? true : false}
              />
              <Row>
                <Label
                  style={{ fontSize: 11 }}
                  htmlFor="entityID"
                  className="form-label mt-1"
                >
                  (ID must be in all CAPS and length must be less than or equal
                  to 10 characters, e.g MRK0000020)
                </Label>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="4" className="mb-4">
            <FormGroup>
              <Label htmlFor="reportTitle" className="form-label">
                Report Title <span className="requiredStar">*</span>
              </Label>
              <Input
                id="reportTitle"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.reportTitle
                    : ""
                }
                className="form-control"
                placeholder=""
                type="text"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
          <Col sm="4" className="mb-4">
            <FormGroup>
              <Label htmlFor="templateName" className="form-label">
                Template Name <span className="requiredStar">*</span>
              </Label>
              <Input
                id="templateName"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.templateName
                    : ""
                }
                className="form-control"
                placeholder=""
                type="text"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="8" className="mb-4">
            <FormGroup>
              <Label htmlFor="prefaceTitle" className="form-label">
                Preface Title <span className="requiredStar">*</span>
              </Label>
              <Input
                id="prefaceTitle"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.prefaceTitle
                    : ""
                }
                className="form-control"
                placeholder=""
                type="text"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="8" className="mb-4">
            <FormGroup>
              <Label htmlFor="prefaceText" className="form-label">
                Preface Text Input <span className="requiredStar">*</span>
              </Label>
              <Input
                style={{ minHeight: 80 }}
                id="prefaceText"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.prefaceText
                    : ""
                }
                className="form-control"
                placeholder=""
                type="textarea"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="4" className="mb-4">
            <FormGroup>
              <Label htmlFor="providerName" className="form-label">
                Provider Name <span className="requiredStar">*</span>
              </Label>
              <Input
                id="providerName"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.providerName
                    : ""
                }
                className="form-control"
                placeholder=""
                type="text"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
          <Col sm="4" className="mb-4">
            <FormGroup>
              <Label htmlFor="providerText" className="form-label">
                Provider Text <span className="requiredStar">*</span>
              </Label>
              <Input
                id="providerText"
                className="form-control"
                placeholder=""
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.providerText
                    : ""
                }
                type="text"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="8" className="mb-4">
            <FormGroup>
              <Label htmlFor="providerGreeting" className="form-label">
                Provider Greeting <span className="requiredStar">*</span>
              </Label>
              <Input
                style={{ minHeight: 80 }}
                id="providerGreeting"
                className="form-control"
                defaultValue={
                  props.defaultEntityDetailsData
                    ? props.defaultEntityDetailsData.providerGreeting
                    : ""
                }
                placeholder=""
                type="textarea"
                required={props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EntityDetails;
