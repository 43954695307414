import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Input, Row } from "reactstrap";
import Cookies from "universal-cookie";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../common/helper/spinner";
import AddLabInformationModal from "./AddLabInformationModal";
import UpdateLabInformationModal from "./UpdateLabInformationModal";
import DeleteModal from "./DeleteModal";

const AddLabInformation = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedLabTest, setSelectedLabTest] = useState("");
  const [searching, setSearching] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);

  const tableHeaders = [
    { text: "id", dataField: "id", sort: true, hidden: true },
    {
      text: "Test Name",
      dataField: "testName",
      sort: true,
      formatter: (_, row) => <span>{`${row.ApiTestId ? `${row.ApiTestId} -` : ""} ${row.testName}`}</span>,
    },
    {
      text: "Short Description",
      dataField: "shortDescription",
      formatter: (cellContent, row) => {
        var result = row.shortDescription.slice(0, 18);
        return (
          <>
            <p>
              {result}
              {row.shortDescription.length > 18 ? "..." : ""}
            </p>
          </>
        );
      },
    },
    {
      text: "Long Description",
      dataField: "longDescription",
      formatter: (cellContent, row) => {
        if (row.longDescription) {
          var result = row.longDescription.slice(0, 18);
          return (
            <>
              <p>
                {result}
                {row.longDescription.length > 18 ? "..." : ""}
              </p>
            </>
          );
        } else {
          return <p>-</p>;
        }
      },
    },
    { text: "Units", dataField: "unit", sort: true },
    { text: "Optimal Range", dataField: "optimalRange", sort: true },

    {
      text: "Actions",
      dataField: "actions",
      headerStyle: () => {
        return { width: "80px", textAlign: "" };
      },
      formatter: (cellContent, row) => {
        return (
          <>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setSelectedLabTest(row.id);
                setShowEditModal(true);
              }}
            >
              <i className="fas fa-edit medText mx-3"></i>
            </span>
            <span
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedLabTest(row.id);
                setShowDeleteModal(true);
              }}
            >
              <i className="fas fa-trash" style={{ color: "red" }}></i>
            </span>
          </>
        );
      },
    },
  ];

  function onClickNo() {
    setShowDeleteModal(false);
  }
  function onClickYes() {
    axiosConfig
      .post(
        "/labBuilder/DeleteTestLab",
        {
          labtest_id: selectedLabTest,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async () => {
        setShowDeleteModal(false);
        recallAPi();
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  useEffect(() => {
    setShowSpinner(true);
    axiosConfig
      .post(
        "/labBuilder/getAllLabTest",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: 1,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var dataList = [];

        await response.data.Data.forEach((s) => {
          var i, tn, dtn, sd, ld, u, or;
          if (s._id) {
            i = s._id;
          } else {
            i = "-";
          }
          if (s.testName) {
            tn = s.testName;
          } else {
            tn = "-";
          }
          if (s.displayTestName) {
            dtn = s.displayTestName;
          } else {
            dtn = "-";
          }
          if (s.shortDescription) {
            sd = s.shortDescription;
          } else {
            sd = "-";
          }
          if (s.longDescription) {
            ld = s.longDescription;
          } else {
            ld = "-";
          }
          if (s.unit) {
            u = s.unit;
          } else {
            u = "-";
          }
          if (s.optimalRange) {
            or = s.optimalRange;
          } else {
            or = "-";
          }

          dataList.push({
            id: i,
            ApiTestId: s.ApiTestId,
            testName: tn,
            displayTestName: dtn,
            shortDescription: sd,
            longDescription: ld,
            unit: u,
            optimalRange: or,
          });
        });
        setData(dataList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, []);

  const closeModal = () => {
    setShowModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  //Pagination start

  function clearSearch(keyword) {
    if (keyword === "") {
      // this.filterChanged(keyword);
      reloadAPINow();
    }
  }

  function handlePageChangeLab(pageNo) {
    setSizePerPage(10);
    setKeyword("");
    setPage(pageNo);

    getAllLabPageChange(sizePerPage, pageNo);
  }

  function getAllLabPageChange(sizePerPage, pageNo) {
    setSearching(true);
    axiosConfig
      .post(
        "labBuilder/getAllLabTest",
        {
          entity_id: props.entityID,
          keyword: keyword,
          currentPage: pageNo,
          pageSize: sizePerPage,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        setSearching(false);
        var dataList = [];

        await response.data.Data.forEach((s) => {
          var i, tn, dtn, sd, ld, u, or;
          if (s._id) {
            i = s._id;
          } else {
            i = "-";
          }
          if (s.testName) {
            tn = s.testName;
          } else {
            tn = "-";
          }
          if (s.displayTestName) {
            dtn = s.displayTestName;
          } else {
            dtn = "-";
          }
          if (s.shortDescription) {
            sd = s.shortDescription;
          } else {
            sd = "-";
          }
          if (s.longDescription) {
            ld = s.longDescription;
          } else {
            ld = "-";
          }
          if (s.unit) {
            u = s.unit;
          } else {
            u = "-";
          }
          if (s.optimalRange) {
            or = s.optimalRange;
          } else {
            or = "-";
          }

          dataList.push({
            id: i,
            ApiTestId: s.ApiTestId,
            testName: tn,
            displayTestName: dtn,
            shortDescription: sd,
            longDescription: ld,
            unit: u,
            optimalRange: or,
          });
        });
        setData(dataList);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  function recallAPi(searchPage = null) {
    setSearching(true);
    axiosConfig
      .post(
        "labBuilder/getAllLabTest",
        {
          entity_id: props.entityID,
          keyword: keyword,
          currentPage: searchPage || page,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        setSearching(false);
        var dataList = [];

        await response.data.Data.forEach((s) => {
          var i, tn, dtn, sd, ld, u, or;
          if (s._id) {
            i = s._id;
          } else {
            i = "-";
          }
          if (s.testName) {
            tn = s.testName;
          } else {
            tn = "-";
          }
          if (s.displayTestName) {
            dtn = s.displayTestName;
          } else {
            dtn = "-";
          }
          if (s.shortDescription) {
            sd = s.shortDescription;
          } else {
            sd = "-";
          }
          if (s.longDescription) {
            ld = s.longDescription;
          } else {
            ld = "-";
          }
          if (s.unit) {
            u = s.unit;
          } else {
            u = "-";
          }
          if (s.optimalRange) {
            or = s.optimalRange;
          } else {
            or = "-";
          }

          dataList.push({
            id: i,
            ApiTestId: s.ApiTestId,
            testName: tn,
            displayTestName: dtn,
            shortDescription: sd,
            longDescription: ld,
            unit: u,
            optimalRange: or,
          });
        });
        setData(dataList);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  function reloadAPINow(keyword = "", limit = 10, pageNo = 1) {
    setPage(pageNo);
    setSizePerPage(limit);
    setKeyword(keyword);

    setShowSpinner(true);

    axiosConfig
      .post(
        "labBuilder/getAllLabTest",
        {
          entity_id: props.entityID,
          keyword: "",
          currentPage: 1,
          pageSize: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var dataList = [];

        await response.data.Data.forEach((s) => {
          var i, tn, dtn, sd, ld, u, or;
          if (s._id) {
            i = s._id;
          } else {
            i = "-";
          }
          if (s.testName) {
            tn = s.testName;
          } else {
            tn = "-";
          }
          if (s.displayTestName) {
            dtn = s.displayTestName;
          } else {
            dtn = "-";
          }
          if (s.shortDescription) {
            sd = s.shortDescription;
          } else {
            sd = "-";
          }
          if (s.longDescription) {
            ld = s.longDescription;
          } else {
            ld = "-";
          }
          if (s.unit) {
            u = s.unit;
          } else {
            u = "-";
          }
          if (s.optimalRange) {
            or = s.optimalRange;
          } else {
            or = "-";
          }

          dataList.push({
            id: i,
            testName: tn,
            displayTestName: dtn,
            shortDescription: sd,
            longDescription: ld,
            unit: u,
            optimalRange: or,
          });
        });
        setData(dataList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }
  function uploadCsvLab(e) {
    const csv = e.target.files[0];
    if (csv) {
      const formData = new FormData();
      formData.append("file", csv);
      axiosConfig
        .post("uploadCategoryCSV/LabTestCSV", formData, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          console.log(response.data);
          setSuccess(response.data.message);
          reloadAPINow();
          setTimeout(() => {
            setSuccess("");
          }, 4000);
        })
        .catch((error) => {
          errorResponse(error);
          setError(error.response.data.message);
        });
    }
  }

  //Pagination end

  const pageOptions = {
    sizePerPage: 10,
    totalSize: data.length,
    custom: true,
  };
  const defaultSorted = [{ dataField: "id", order: "asc" }];

  return (
    <React.Fragment>
      <div className={`${props.step === 4 ? "d-block" : "d-none"}`}>
        <Row>
          <Alert align="center" color="warning" className="mb-4">
            Please upload a pdf in workdrive in the correct entity folder with file format of
            Setting_EntityID_PatientID_firstname.lastname_dateofservice_dob (e.g.
            Setting_MT4_MH272_Jalali.Amir_08.06.91_11.11.21), please review the sample once file is uploaded.
          </Alert>
          <Col xs="12">
            <Card>
              <CardBody>
                {showSpinner ? <Spinner /> : null}
                {error ? (
                  <Alert color="danger" className="text-center">
                    {error}
                  </Alert>
                ) : null}
                {success ? (
                  <Alert color="success" className="text-center">
                    {success}
                  </Alert>
                ) : null}

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={tableHeaders}
                  data={data}
                >
                  {({ paginationTableProps }) => (
                    <ToolkitProvider keyField="id" columns={tableHeaders} data={data} search>
                      {(toolkitProps) => (
                        <React.Fragment>
                          {
                            <Row className="my-3">
                              <Col xs={6} sm={8} m={1} lg={2}>
                                <div className="search-box me-2">
                                  <div className="position-relative">
                                    <Input
                                      value={keyword}
                                      onChange={(e) => {
                                        setKeyword(e.target.value);

                                        // this.filterChanged(e.target.value);
                                        clearSearch(e.target.value);
                                      }}
                                      type="text"
                                      className="form-control border-0"
                                      placeholder="Search..."
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>

                              <Col xs={4} sm={3} m={9} lg={1}>
                                <button
                                  style={{ maxWidth: 80 }}
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setPage(1);
                                    recallAPi(1);
                                  }}
                                >
                                  Search
                                </button>
                              </Col>
                            </Row>
                          }

                          <Row>
                            <div align="right">
                              <Col>
                                <button
                                  style={{ maxWidth: 150 }}
                                  className="btn btn-primary mx-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById("csvLab").click();
                                  }}
                                >
                                  Upload CSV
                                </button>
                                <Input
                                  accept=".csv"
                                  onChange={(e) => {
                                    uploadCsvLab(e);
                                  }}
                                  name="csvLab"
                                  className="form-control d-none"
                                  type="file"
                                  id="csvLab"
                                />
                                <button
                                  style={{ maxWidth: 150 }}
                                  type="button"
                                  onClick={() => {
                                    setShowModal(true);
                                  }}
                                  className="btn btn-primary"
                                >
                                  Add Test
                                </button>
                              </Col>
                            </div>
                          </Row>

                          {!searching ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive" style={{ minHeight: "300px" }}>
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {data.length === 0 ? <p className="w-100 text-center m-5">No data found!</p> : null}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Spinner />
                          )}
                          <Row>
                            <Col align="right">
                              <div
                                style={{ maxWidth: 200 }}
                                className="btn-group mt-2 mt-xl-0"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="labPaginationBack"
                                  id="labPaginationBack"
                                  autoComplete="off"
                                  onClick={() => {
                                    if (page === 1) {
                                      handlePageChangeLab(page);
                                    } else {
                                      var tempPage = page - 1;

                                      handlePageChangeLab(tempPage);
                                    }
                                  }}
                                />
                                <label className="btn btn-light" htmlFor="labPaginationBack">
                                  Back
                                </label>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="labPaginationStart"
                                  id="labPaginationStart"
                                  autoComplete="off"
                                  onClick={() => {
                                    var tempPage = page + 1;

                                    handlePageChangeLab(tempPage);
                                  }}
                                />
                                <label className="btn btn-light" htmlFor="labPaginationStart">
                                  Next
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <AddLabInformationModal
          dataSource={props.dataSource}
          entityID={props.entityID}
          showModal={showModal}
          closeModal={closeModal}
          reCallApi={recallAPi}
        />
        <DeleteModal
          onClickYes={onClickYes}
          onClickNo={onClickNo}
          showDeleteModal={showDeleteModal}
          closeModal={closeModal}
        />

        <UpdateLabInformationModal
          dataSource={props.dataSource}
          testData={data.find((o) => o.id === selectedLabTest)}
          labTest={selectedLabTest}
          showEditModal={showEditModal}
          closeModal={closeModal}
          reCallApi={recallAPi}
        />
      </div>
    </React.Fragment>
  );
};

export default AddLabInformation;
