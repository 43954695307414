import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Container, Input, Modal, FormGroup, Label, Row } from "reactstrap";
import Cookies from "universal-cookie";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Select from "react-dropdown-select";

const UpdateLabInformationModal = (props) => {
  const [complete, isComplete] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [labData, setLabData] = useState(null);
  const [selectedLab, setSelectedLab] = useState(null);
  const [optimalRRangeJSX, setROptimalRangeJSX] = useState("");
  const [optimalGRangeJSX, setGOptimalRangeJSX] = useState("");
  const [optimalLRangeJSX, setLOptimalRangeJSX] = useState("");
  const [optimalNRangeJSX, setNOptimalRangeJSX] = useState("");

  const [message, setMessage] = useState("");
  const [error, isError] = useState("");

  const testData = props.testData;

  useEffect(() => {
    if (props.dataSource === "API") {
      setFetching(true);
      axiosConfig
        .get("labBuilder/get/labNames", {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          let responseData = response?.data?.Data?.codes ?? [];
          // responseData = responseData.filter(
          //   (value, index, self) => index === self.findIndex((t) => t.description === value.description)
          // );
          setLabData(
            responseData?.map((element) => ({
              ...element,
              displayName: `${element.resultCode} - ${element.description}`,
            }))
          );
        })
        .catch((error) => {
          errorResponse(error);

          isError(error.response.data.message);
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }, [props.dataSource]);

  useEffect(() => {
    setGOptimalRangeJSX("");
    setROptimalRangeJSX("");
    setLOptimalRangeJSX("");
    setNOptimalRangeJSX("");

    if (props.showEditModal) {
      setSelectedLab(labData?.filter((lab) => lab.description === testData?.testName)?.[0]);
      //Greater

      //Lesser

      //Less than
      if (
        !testData?.optimalRange?.includes(">") &&
        !testData?.optimalRange?.includes("<") &&
        !testData?.optimalRange?.includes("-")
      ) {
        //case for non numeric
        setGOptimalRangeJSX("");
        setNOptimalRangeJSX(
          <Row>
            <Label className="form-label">
              Optimal Range <span className="requiredStar">*</span>
            </Label>
            <Label className="form-label">Range will be defined by the provider</Label>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  onChange={(e) => {
                    if (document.getElementById("optimalRange")?.value === "Non-numeric Value") {
                      document.getElementById("nonNumericValue").style.display = "block";
                      document.getElementById("lesserRange").style.display = "none";
                      document.getElementById("greaterRange").style.display = "none";
                    } else {
                      document.getElementById("nonNumericValue").style.display = "none";
                      if (document.getElementById("optimalRange")?.value === "Greater than only") {
                        document.getElementById("lesserRange").style.display = "none";
                        document.getElementById("greaterRange").style.display = "block";
                      } else if (document.getElementById("optimalRange")?.value === "Less than only") {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "none";
                      } else {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "block";
                      }
                    }
                  }}
                  className="form-select"
                  id="optimalRange"
                  type="select"
                  name="optimalRange"
                  required
                >
                  <p className="dropdownArrow">
                    <i className="fas fa-angle-down"></i>
                  </p>
                  <option style={{ color: "#495057" }} className="form-control">
                    Range
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Less than only
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Greater than only
                  </option>
                  <option selected style={{ color: "#495057" }} className="form-control">
                    Non-numeric Value
                  </option>
                </select>
              </FormGroup>
            </Col>

            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  name="greaterRange"
                  id="greaterRange"
                  className="form-control"
                  type="text"
                  placeholder="Greater than"
                  required
                  style={{ display: "none" }}
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  style={{ display: "none" }}
                  name="lesserRange"
                  id="lesserRange"
                  className="form-control"
                  type="text"
                  placeholder="Less than"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select className="form-select" id="nonNumericValue" type="select" name="nonNumericValue">
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    selected={testData?.optimalRange === "Negative"}
                  >
                    Negative
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    selected={testData?.optimalRange === "Negative/Trace"}
                  >
                    Negative/Trace
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    selected={testData?.optimalRange === "Clear"}
                  >
                    Clear
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    selected={testData?.optimalRange === "Yellow"}
                  >
                    Yellow
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    selected={testData?.optimalRange === "Positive"}
                  >
                    Positive
                  </option>
                  <option
                    style={{ color: "#495057" }}
                    className="form-control"
                    selected={testData?.optimalRange === "Positive/Trace"}
                  >
                    Positive/Trace
                  </option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        );
      } else if (testData?.optimalRange?.charAt(0) === ">") {
        setGOptimalRangeJSX(
          <Row>
            <Label className="form-label">
              Optimal Range <span className="requiredStar">*</span>
            </Label>
            <Label className="form-label">Range will be defined by the provider</Label>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  onChange={(e) => {
                    if (document.getElementById("optimalRange")?.value === "Non-numeric Value") {
                      document.getElementById("nonNumericValue").style.display = "block";
                      document.getElementById("lesserRange").style.display = "none";
                      document.getElementById("greaterRange").style.display = "none";
                    } else {
                      document.getElementById("nonNumericValue").style.display = "none";
                      if (document.getElementById("optimalRange")?.value === "Greater than only") {
                        document.getElementById("lesserRange").style.display = "none";
                        document.getElementById("greaterRange").style.display = "block";
                      } else if (document.getElementById("optimalRange")?.value === "Less than only") {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "none";
                      } else {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "block";
                      }
                    }
                  }}
                  className="form-select"
                  id="optimalRange"
                  type="select"
                  name="optimalRange"
                  required
                >
                  <p className="dropdownArrow">
                    <i className="fas fa-angle-down"></i>
                  </p>
                  <option style={{ color: "#495057" }} className="form-control">
                    Range
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Less than only
                  </option>
                  <option style={{ color: "#495057" }} className="form-control" selected>
                    Greater than only
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Non-numeric Value
                  </option>
                </select>
              </FormGroup>
            </Col>

            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  name="greaterRange"
                  defaultValue={testData?.optimalRange?.split(">")[1]}
                  id="greaterRange"
                  className="form-control"
                  type="text"
                  placeholder="Greater than"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  style={{ display: "none" }}
                  name="lesserRange"
                  id="lesserRange"
                  className="form-control"
                  type="text"
                  placeholder="Less than"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  className="form-select"
                  id="nonNumericValue"
                  type="select"
                  name="nonNumericValue"
                  style={{ display: "none" }}
                >
                  <option style={{ color: "#495057" }} className="form-control">
                    Negative
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Negative/Trace
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Clear
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Yellow
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Positive
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Positive/Trace
                  </option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        );
      } else if (testData?.optimalRange?.charAt(0) === "<") {
        setROptimalRangeJSX("");
        setGOptimalRangeJSX("");
        setNOptimalRangeJSX("");
        setLOptimalRangeJSX(
          <Row>
            <Label className="form-label">
              Optimal Range <span className="requiredStar">*</span>
            </Label>
            <Label className="form-label">Range will be defined by the provider</Label>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  onChange={(e) => {
                    if (document.getElementById("optimalRange")?.value === "Non-numeric Value") {
                      document.getElementById("nonNumericValue").style.display = "block";
                      document.getElementById("lesserRange").style.display = "none";
                      document.getElementById("greaterRange").style.display = "none";
                    } else {
                      document.getElementById("nonNumericValue").style.display = "none";
                      if (document.getElementById("optimalRange")?.value === "Greater than only") {
                        document.getElementById("lesserRange").style.display = "none";
                        document.getElementById("greaterRange").style.display = "block";
                      } else if (document.getElementById("optimalRange")?.value === "Less than only") {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "none";
                      } else {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "block";
                      }
                    }
                  }}
                  className="form-select"
                  id="optimalRange"
                  type="select"
                  name="optimalRange"
                  required
                >
                  <p className="dropdownArrow">
                    <i className="fas fa-angle-down"></i>
                  </p>
                  <option style={{ color: "#495057" }} className="form-control">
                    Range
                  </option>
                  <option style={{ color: "#495057" }} className="form-control" selected>
                    Less than only
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Greater than only
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Non-numeric Value
                  </option>
                </select>
              </FormGroup>
            </Col>

            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  style={{ display: "none" }}
                  name="greaterRange"
                  id="greaterRange"
                  className="form-control"
                  type="text"
                  placeholder="Greater than"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  name="lesserRange"
                  id="lesserRange"
                  defaultValue={testData?.optimalRange.split("<")[1]}
                  className="form-control"
                  type="text"
                  placeholder="Less than"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  className="form-select"
                  id="nonNumericValue"
                  type="select"
                  style={{ display: "none" }}
                  name="nonNumericValue"
                >
                  <option style={{ color: "#495057" }} className="form-control">
                    Negative
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Negative/Trace
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Clear
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Yellow
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Positive
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Positive/Trace
                  </option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        );
      } else {
        var rangeValues = testData?.optimalRange?.split("-");
        setGOptimalRangeJSX("");
        setLOptimalRangeJSX("");
        setNOptimalRangeJSX("");
        setROptimalRangeJSX(
          <Row>
            <Label className="form-label">
              Optimal Range <span className="requiredStar">*</span>
            </Label>
            <Label className="form-label">Range will be defined by the provider</Label>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  onChange={(e) => {
                    if (document.getElementById("optimalRange")?.value === "Non-numeric Value") {
                      document.getElementById("nonNumericValue").style.display = "block";
                      document.getElementById("lesserRange").style.display = "none";
                      document.getElementById("greaterRange").style.display = "none";
                    } else {
                      document.getElementById("nonNumericValue").style.display = "none";
                      if (document.getElementById("optimalRange")?.value === "Greater than only") {
                        document.getElementById("lesserRange").style.display = "none";
                        document.getElementById("greaterRange").style.display = "block";
                      } else if (document.getElementById("optimalRange")?.value === "Less than only") {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "none";
                      } else {
                        document.getElementById("lesserRange").style.display = "block";
                        document.getElementById("greaterRange").style.display = "block";
                      }
                    }
                  }}
                  className="form-select"
                  id="optimalRange"
                  type="select"
                  name="optimalRange"
                  required
                >
                  <p className="dropdownArrow">
                    <i className="fas fa-angle-down"></i>
                  </p>
                  <option style={{ color: "#495057" }} className="form-control">
                    Range
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Less than only
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Greater than only
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Non-numeric Value
                  </option>
                </select>
              </FormGroup>
            </Col>

            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  name="greaterRange"
                  id="greaterRange"
                  className="form-control"
                  defaultValue={rangeValues[0]}
                  type="text"
                  placeholder="Greater than"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <Input
                  name="lesserRange"
                  id="lesserRange"
                  className="form-control"
                  defaultValue={rangeValues[1]}
                  type="text"
                  placeholder="Less than"
                  required
                />
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="mb-4">
                <select
                  className="form-select"
                  id="nonNumericValue"
                  type="select"
                  name="nonNumericValue"
                  style={{ display: "none" }}
                >
                  <option style={{ color: "#495057" }} className="form-control">
                    Negative
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Negative/Trace
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Clear
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Yellow
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Positive
                  </option>
                  <option style={{ color: "#495057" }} className="form-control">
                    Positive/Trace
                  </option>
                </select>
              </FormGroup>
            </Col>
          </Row>
        );
      }
    }
  }, [labData, props.showEditModal, testData]);

  function handleValidSubmit(e) {
    var serialize = require("form-serialize");
    var form = document.getElementById("addLabInfoForm");
    var obj = serialize(form, { hash: true });

    var correctOptimalRange = "";

    if (document.getElementById("optimalRange")?.value === "Non-numeric Value") {
      correctOptimalRange = document.getElementById("nonNumericValue").value;
    } else if (document.getElementById("optimalRange")?.value == "Greater than only") {
      correctOptimalRange = ">" + obj.greaterRange;
    } else if (document.getElementById("optimalRange")?.value == "Less than only") {
      correctOptimalRange = "<" + obj.lesserRange;
    } else if (document.getElementById("optimalRange")?.value == "Range") {
      correctOptimalRange = obj.greaterRange + "-" + obj.lesserRange;
    }

    var body = {
      testName: obj.testName.trim(),
      displayTestName: obj.displayTestName,
      shortDescription: obj.shortDescription,
      longDescription: obj.longDescription,
      unit: obj.unit.trim(),
      optimalRange: correctOptimalRange,
      labtest_id: props.labTest,
    };
    if (props.dataSource === "API") {
      body.testName = selectedLab?.description;
      body.ApiTestId = selectedLab?.resultCode;
    }

    axiosConfig
      .post("/labBuilder/updateLabTest", body, {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async (response) => {
        setMessage(response.data.message);
        setGOptimalRangeJSX("");
        setLOptimalRangeJSX("");
        setROptimalRangeJSX("");
        props.reCallApi?.();
        closeIt();
      })
      .catch((error) => {
        errorResponse(error);

        isError(error.response.data.message);
      })
      .finally(() => {
        setSelectedLab(null);
      });
  }

  function closeIt() {
    isComplete(false);
    setGOptimalRangeJSX("");
    setLOptimalRangeJSX("");
    setROptimalRangeJSX("");
    setSelectedLab(null);
    props.closeModal();
  }

  console.log({ testData });
  return (
    <Modal isOpen={props.showEditModal} size="lg" centered={true} className="smallHeightmodal">
      <>
        <button type="button" onClick={closeIt} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <Container>
          <Row className="justify-content-center">
            <Card className="overflow-hidden">
              {complete ? null : (
                <>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <form id="addLabInfoForm" className="form-horizontal px-4">
                        {error ? (
                          <Alert color="danger" className="text-center">
                            {error}
                          </Alert>
                        ) : null}
                        <div className="my-5 px-4">
                          <div>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="testName" className="form-label">
                                  Test name (This name must be the exact same as the one in the lab file){" "}
                                  <span className="requiredStar">*</span>
                                </Label>

                                {props.dataSource === "API" ? (
                                  <Select
                                    required
                                    separator
                                    searchable
                                    name="testName"
                                    searchBy="displayName"
                                    className="form-control"
                                    loading={fetching}
                                    labelField="displayName"
                                    valueField="resultCode"
                                    multi={false}
                                    values={labData?.filter((lab) => lab.resultCode === testData?.ApiTestId)}
                                    options={labData}
                                    onChange={(value) => setSelectedLab(value?.[0])}
                                  />
                                ) : (
                                  <Input
                                    name="testName"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    required
                                    defaultValue={testData?.testName}
                                  />
                                )}
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="displayTestName" className="form-label">
                                  Display name <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="displayTestName"
                                  className="form-control"
                                  type="text"
                                  defaultValue={testData?.displayTestName}
                                  placeholder=""
                                  required
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="shortDescription" className="form-label">
                                  Short Description <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="shortDescription"
                                  defaultValue={testData?.shortDescription}
                                  className="form-control"
                                  type="textarea"
                                  required
                                />
                              </FormGroup>
                            </Row>

                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="longDescription" className="form-label">
                                  Long Description <span className="requiredStar"></span>
                                </Label>
                                <Input
                                  name="longDescription"
                                  defaultValue={testData?.longDescription}
                                  className="form-control"
                                  type="textarea"
                                />
                              </FormGroup>
                            </Row>

                            <Row>
                              <FormGroup className="mb-4">
                                <Label htmlFor="unit" className="form-label">
                                  Units <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="unit"
                                  defaultValue={selectedLab?.units || testData?.unit}
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  required
                                />
                              </FormGroup>
                            </Row>

                            {optimalRRangeJSX}
                            {optimalGRangeJSX}
                            {optimalLRangeJSX}
                            {optimalNRangeJSX}
                            {/* <Row>
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="recomendedAboveGoal"
                                  className="form-label"
                                >
                                  Recommendation Above Goal{" "}
                                  <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="recomendedAboveGoal"
                                  defaultValue={testData?.recomendedAboveGoal}
                                  className="form-control"
                                  type="textarea"
                                  required
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup className="mb">
                                <Label
                                  htmlFor="recommendedBelowGoal"
                                  className="form-label"
                                >
                                  Recommendation Below Goal{" "}
                                  <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="recommendedBelowGoal"
                                  defaultValue={testData?.recommendedBelowGoal}
                                  className="form-control"
                                  type="textarea"
                                  required
                                />
                              </FormGroup>
                            </Row> */}

                            <Row className="my-5 text-center">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleValidSubmit(e);
                                }}
                                className="btn btn-primary darkBg mt-3 mx-auto w-50"
                                type="submit"
                              >
                                Update
                              </button>
                            </Row>
                          </div>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Row>
        </Container>
      </>
    </Modal>
  );
};

export default UpdateLabInformationModal;
