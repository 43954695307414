import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Cookies from "universal-cookie";

import { Alert, Card, CardBody, Col, Input, Row } from "reactstrap";
import AssignCategoryModal from "./AssignCategoryModal";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";

const AssignCategories = (props) => {
  const pageSize = 10;
  const { step: currentStep = 1, entityID } = props || {};
  const [showAssignCategoriesModal, setShowCategoriesModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [labTests, setLabTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [isAssignState, setAssignState] = useState(false);

  const selectedTestsRef = useRef([]);
  const [fetchingLabTests, setFetchingLabTests] = useState(false);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const tableData = useMemo(() => constructTableData(labTests, categories), [categories, labTests]);

  const filteredCategories = useMemo(
    () =>
      generateCategoriesPropData({
        isAssignState,
        labTestsData: labTests,
        selectedTests,
        categories,
      }),
    [isAssignState, labTests, selectedTests, categories]
  );
  const toggleAssignCategoriesModal = () => setShowCategoriesModal((prev) => !prev);

  const selectLabTest = useCallback((id = "") => {
    const index = selectedTestsRef.current.findIndex((el) => el === id);
    if (index === -1) {
      const tempTests = [...selectedTestsRef.current, id];
      selectedTestsRef.current = tempTests;
      setSelectedTests(tempTests);
    } else {
      const tempTests = [
        ...selectedTestsRef.current.slice(0, index),
        ...selectedTestsRef.current.slice(index + 1, selectedTestsRef.current.length),
      ];
      setSelectedTests(tempTests);
      selectedTestsRef.current = tempTests;
    }
  }, []);

  const nextHandler = () => {
    if (!tableData.length) {
      return;
    }
    fetchLabTests({
      entityID,
      currentPage: currentPage + 1,
      pageSize,
      keyword,
      setLabTests,
      setFetchingLabTests,
    });
    setCurrentPage((prev) => prev + 1);
  };
  const backHandler = () => {
    if (currentPage === 1) {
      return;
    }
    fetchLabTests({
      entityID,
      currentPage: currentPage - 1,
      pageSize,
      keyword,
      setLabTests,
      setFetchingLabTests,
    });
    setCurrentPage((prev) => prev - 1);
  };

  const onChangeSearch = (e) => {
    const { target } = e || {};
    const { value = "" } = target || {};
    if (!value) {
      fetchLabTests({
        entityID,
        currentPage: 1,
        pageSize,
        keyword: value,
        setLabTests,
        setFetchingLabTests,
      });
      setCurrentPage(1);
    }
    setKeyword(value);
  };

  const onSearchHandler = () => {
    fetchLabTests({
      entityID,
      currentPage: 1,
      pageSize,
      keyword,
      setLabTests,
      setFetchingLabTests,
    });
  };

  const tableHeaders = useMemo(
    () => [
      { text: "id", dataField: "id", sort: true, hidden: true },
      {
        text: "Lab Name",
        dataField: "testName",
        sort: true,
        style: { width: 200 },
        formatter: (cell, row) => {
          const { id } = row || {};
          return (
            <span align="center">
              <Input
                type="checkbox"
                className="selection-input-4"
                onClick={() => {
                  selectLabTest(id);
                }}
              />
              <span>{`${row.ApiTestId ? `${row.ApiTestId} -` : ""} ${row.testName}`}</span>
            </span>
          );
        },
      },
      {
        text: "Category Name",
        dataField: "categoryName",
        sort: true,
        style: { width: 250 },
      },
      {
        text: "Category Description",
        dataField: "categoryDescription",
        formatter: (cellContent, row) => {
          var result = row.categoryDescription.slice(0, 120);
          return (
            <>
              <p>
                {result}
                {row.categoryDescription.length > 120 ? "..." : ""}
              </p>
            </>
          );
        },
      },
    ],
    [selectedTests, selectLabTest]
  );
  useEffect(
    () =>
      cdm({
        entityID,
        setCategories,
        setLabTests,
        setFetchingLabTests,
        setFetchingCategories,
      }),
    [entityID, props.step]
  );

  return (
    <>
      <div className={` ${currentStep === 5 ? "d-block" : "d-none"}`}>
        <Row>
          <Alert align="center" color="warning" className="mb-4">
            Please upload a pdf in workdrive in the correct entity folder with file format of
            Setting_EntityID_PatientID_firstname.lastname_dateofservice_dob (e.g.
            Setting_MT4_MH272_Jalali.Amir_08.06.91_11.11.21), please review the sample once file is uploaded.
          </Alert>
          <Col xs="12">
            <Card>
              <CardBody>
                <ErrorBar error="" />
                <SuccessBar successMessage="" />
                <Header
                  toggleAssignCategoriesModal={toggleAssignCategoriesModal}
                  setAssignState={setAssignState}
                  disableButton={!selectedTests.length}
                  onChangeSearch={onChangeSearch}
                  onSearchHandler={onSearchHandler}
                />
                <LabTable tableData={tableData} tableHeaders={tableHeaders} />
                <PaginationFooter nextHandler={nextHandler} backHandler={backHandler} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {showAssignCategoriesModal && (
        <AssignCategoryModal
          closeModal={toggleAssignCategoriesModal}
          isAssignState={isAssignState}
          show={showAssignCategoriesModal}
          categories={filteredCategories}
          selectedTests={selectedTests}
          onSuccess={() => {
            fetchLabTests({
              entityID,
              currentPage,
              pageSize,
              keyword,
              setLabTests,
              setFetchingLabTests,
            });
            toggleAssignCategoriesModal();
          }}
        />
      )}
    </>
  );
};

export default AssignCategories;

const ErrorBar = ({ error = "" }) => {
  if (!error) {
    return null;
  }
  return (
    <Alert color="danger" className="text-center">
      {error}
    </Alert>
  );
};
const SuccessBar = ({ successMessage = "" }) => {
  if (!successMessage) {
    return null;
  }
  return (
    <Alert color="success" className="text-center">
      {successMessage}
    </Alert>
  );
};

const Header = ({ toggleAssignCategoriesModal, setAssignState, disableButton, onSearchHandler, onChangeSearch }) => {
  return (
    <Row className="my-3 justify-content-between justify-content-md-between align-items-center">
      <Col sm="6">
        <Row>
          <span className="search-box me-2 w-50">
            <span className="position-relative">
              <Input
                type="text"
                className="form-control border-0 my-1"
                placeholder="Search..."
                onChange={onChangeSearch}
              />
              <i className="bx bx-search-alt search-icon" />
            </span>
          </span>
          <span
            className="btn-sm btn-rounded btn btn-primary d-flex justify-content-center align-items-center my-1"
            style={{ width: "100px" }}
            onClick={onSearchHandler}
          >
            Search
          </span>
        </Row>
      </Col>
      <Col sm="4">
        <div align="right">
          <button
            style={{ maxWidth: 170, maxHeight: 37 }}
            type="button"
            className="btn btn-danger my-1"
            onClick={() => {
              toggleAssignCategoriesModal();
              setAssignState(false);
            }}
            disabled={disableButton}
          >
            Unassign Category
          </button>
        </div>
      </Col>
      <Col sm="2">
        <div align="right">
          <button
            style={{ maxWidth: 170, maxHeight: 37 }}
            type="button"
            className="btn btn-primary my-1"
            onClick={() => {
              toggleAssignCategoriesModal();
              setAssignState(true);
            }}
            disabled={disableButton}
          >
            Assign Category
          </button>
        </div>
      </Col>
    </Row>
  );
};

const PaginationFooter = ({ nextHandler, backHandler }) => {
  return (
    <Row className="mt-2">
      <Col align="right">
        <div
          style={{ maxWidth: 200 }}
          className="btn-group mt-2 mt-xl-0"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="backButton"
            id="backButton"
            autoComplete="off"
            onClick={() => {
              backHandler();
            }}
          />
          <label className="btn btn-light" htmlFor="backButton">
            Back
          </label>

          <input
            type="radio"
            className="btn-check"
            name="nextButton"
            id="nextButton"
            autoComplete="off"
            onClick={() => {
              nextHandler();
            }}
          />
          <label className="btn btn-light" htmlFor="nextButton">
            Next
          </label>
        </div>
      </Col>
    </Row>
  );
};

const LabTable = ({ tableData = [], tableHeaders }) => {
  return (
    <>
      <Row>
        <Col xl="12">
          <div className="table-responsive" style={{ minHeight: "300px" }}>
            <BootstrapTable
              keyField={"id"}
              responsive
              bordered={false}
              striped={false}
              classes={"table align-middle table-nowrap"}
              headerWrapperClasses={"thead-light"}
              data={tableData}
              columns={tableHeaders}
            />

            {tableData === 0 ? <p className="w-100 text-center m-5">No data found!</p> : null}
          </div>
        </Col>
      </Row>
    </>
  );
};
const constructTableData = (labTests, categories) => {
  const tempData = [];
  labTests.forEach((cLabTest) => {
    let tempCategoryName = "-",
      categoryDescription = "-";
    let { id, testName, category_id, ApiTestId } = cLabTest;

    if (!!category_id.length) {
      let categoriesNames = [],
        categoryDescriptions = [];
      category_id.forEach((element) => {
        const selectedCat = categories.filter((cCat) => {
          return cCat.id === element;
        });
        const { name = "", description = "" } = selectedCat[0] || {};
        categoriesNames.push(name);
        categoryDescriptions.push(description);
      });
      tempCategoryName = categoriesNames.join(", ");
      categoryDescription = categoryDescriptions.join(", ");
    }

    tempData.push({
      id,
      testName,
      ApiTestId,
      categoryName: tempCategoryName,
      categoryDescription,
    });
  });
  return tempData;
};

const cdm = ({ entityID, setCategories, setLabTests, setFetchingCategories, setFetchingLabTests }) => {
  setFetchingCategories(true);
  axiosConfig
    .post(
      "labBuilder/getAllCategories",
      {
        entity_id: entityID,
      },
      {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      }
    )
    .then(async (response) => {
      var responseData = [];
      if (response.data.Data) {
        await response.data.Data.forEach((category) => {
          responseData.push({
            id: category._id,
            name: category.categoryName,
            description: category.categoryDiscription,
            hideResult: category.hideResult,
          });
        });
      }
      setCategories(responseData);
      setFetchingCategories(false);
    })
    .catch((error) => {
      errorResponse(error);
    });
  fetchLabTests({
    entityID,
    currentPage: 1,
    pageSize: 10,
    keyword: "",
    setLabTests,
    setFetchingLabTests,
  });
};

const fetchLabTests = ({ entityID, currentPage, pageSize, keyword, setLabTests, setFetchingLabTests }) => {
  setFetchingLabTests(true);
  axiosConfig
    .post(
      "labBuilder/getAllLabTest",
      {
        entity_id: entityID,
        currentPage,
        pageSize,
        keyword,
      },
      {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      }
    )
    .then(async (response) => {
      var responseData = [];
      if (response.data.Data) {
        await response.data.Data.forEach((labTest) => {
          const { _id: id, testName = "", category_id = [], ApiTestId = undefined } = labTest || {};
          responseData.push({
            id,
            testName,
            ApiTestId,
            category_id,
          });
        });
      }
      setLabTests(responseData);
      setFetchingLabTests(false);
    })
    .catch((error) => {
      errorResponse(error);
    });
};

const filterAssignedCategories = ({ labTestsData = [], selectedTests = [], categories = [] }) => {
  const selectedCatIds = labTestsData
    .filter((cLab) => selectedTests.includes(cLab.id))
    .flatMap((cFilteredLab) => cFilteredLab.category_id);
  return categories.filter((cCat) => selectedCatIds.includes(cCat.id));
};

const generateCategoriesPropData = ({
  isAssignState = false,
  labTestsData = [],
  selectedTests = [],
  categories = [],
}) => {
  if (isAssignState) {
    return categories;
  }
  console.log("filtered categories: ", filterAssignedCategories({ labTestsData, selectedTests, categories }));
  return filterAssignedCategories({ labTestsData, selectedTests, categories });
};
