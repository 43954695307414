import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class Apaexlinecolumn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: props.title,
          data: props.data,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },

        colors: ['#556ee6'],
        xaxis: {
          categories: props.xAxis,
        },
        yaxis: {
          title: {
            text: props.title,
          },
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart options={this.state.options} series={this.state.series} type='bar' height={350} className='apex-charts' />
      </React.Fragment>
    );
  }
}

export default Apaexlinecolumn;
