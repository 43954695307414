import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Cookies from "universal-cookie";
import Spinner from "../../../common/helper/spinner";
var role = "superAdmin";
const ChangeRoleModal = (props) => {
  const [dropdown, setDropdown] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const toggle = () => {
    props.closeModal();
  };

  const submitButton = () => {
    //I NEED TO ADD API CALL HERE
    setShowSpinner(true);
    axiosConfig
      .post(
        "/admins/editRole",
        {
          user_id: props.id,
          role: role,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        console.log("******response*******");
        console.log(response);
        console.log("******response*******");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        errorResponse(error);
        setMessage(error.response.data.message);
        setShowMessage(true);
        setShowSpinner(false);
      });
  };

  useEffect(() => {});

  return (
    <React.Fragment>
      {props.showModal ? (
        <Modal isOpen={props.showModal} className={props.className}>
          <ModalHeader>
            <div align="center">
              <h3>Change Role</h3>
            </div>
          </ModalHeader>
          <ModalBody>
            {showMessage && (
              <Alert align="center" color="danger">
                {message}
              </Alert>
            )}
            <div align="center" className="my-5">
              <Row>
                <div
                  className="btn-group mt-2 mt-xl-0"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    autoComplete="off"
                    defaultChecked
                    value="superadmin"
                    onChange={(e) => {
                      role = e.target.value;
                      console.log(role);
                    }}
                  />
                  <label className="btn btn-secondary" htmlFor="btnradio1">
                    Super Admin
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    autoComplete="off"
                    value="admin"
                    onChange={(e) => {
                      role = e.target.value;
                      console.log(role);
                    }}
                  />
                  <label className="btn btn-secondary" htmlFor="btnradio2">
                    Admin
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio3"
                    autoComplete="off"
                    value="member"
                    onChange={(e) => {
                      role = e.target.value;
                      console.log(role);
                    }}
                  />
                  <label className="btn btn-secondary" htmlFor="btnradio3">
                    Member
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio4"
                    autoComplete="off"
                    value="reportBuilder"
                    onChange={(e) => {
                      role = e.target.value;
                      console.log(role);
                    }}
                  />
                  <label className="btn btn-secondary" htmlFor="btnradio4">
                    Report Builder
                  </label>
                </div>
              </Row>
            </div>

            <Row>
              {showSpinner && <Spinner />}

              <div align="center">
                <Col>
                  <button
                    type="button"
                    className="btn btn-success me-2"
                    onClick={submitButton}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger me-2"
                    onClick={toggle}
                  >
                    Cancel
                  </button>
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default ChangeRoleModal;
