const LAB_DATA_SOURCE = [
  {
    id: 1,
    name: "PDF",
    value: "PDF",
  },
  {
    id: 2,
    name: "API",
    value: "API",
  },
];

export { LAB_DATA_SOURCE };
