import React, { useState } from "react";
import { Alert, Col, FormGroup, Label, Input, Row } from "reactstrap";

import Spinner from "../../../common/helper/spinner";

const AttachImages = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);

  function uploadFile(e, name) {
    setShowSpinner(true);

    const reader = new FileReader();
    const reader1 = new FileReader();
    const reader2 = new FileReader();
    const reader3 = new FileReader();

    if (name === "providerImage") {
      var file = e.target.files[0];
      reader.onloadend = () => {
        const base64String = reader.result;
        props.getImages(
          name,
          base64String.replace("data:image/png;base64,", "")
        );
      };
      reader.readAsDataURL(file);

      setShowSpinner(false);
    } else if (name === "waterMark") {
      var file1 = e.target.files[0];
      reader1.onloadend = () => {
        const base64String1 = reader1.result;
        props.getImages(
          name,
          base64String1.replace("data:image/png;base64,", "")
        );
      };
      reader1.readAsDataURL(file1);

      setShowSpinner(false);
    } else if (name === "coverPage") {
      var file2 = e.target.files[0];
      reader2.onloadend = () => {
        const base64String2 = reader2.result;

        props.getImages(
          name,
          base64String2.replace("data:image/png;base64,", "")
        );
      };
      reader2.readAsDataURL(file2);

      setShowSpinner(false);
    } else if (name === "signatureImage") {
      var file3 = e.target.files[0];
      reader3.onloadend = () => {
        const base64String3 = reader3.result;

        props.getImages(
          name,
          base64String3.replace("data:image/png;base64,", "")
        );
      };
      reader3.readAsDataURL(file3);

      setShowSpinner(false);
    }
  }

  return (
    <React.Fragment>
      <div className={`${props.step === 2 ? "d-block" : "d-none"}`}>
        <Row className="justify-content-center">
          <Alert align="center" color="warning" className="mb-4">
            Please upload a pdf in workdrive in the correct entity folder with
            file format of
            Setting_EntityID_PatientID_firstname.lastname_dateofservice_dob
            (e.g. Setting_MT4_MH272_Jalali.Amir_08.06.91_11.11.21), please
            review the sample once file is uploaded.
          </Alert>
          <Col sm="8" className="mb-4">
            {props.defaultImageData ? <h6>Uploaded</h6> : null}

            {showSpinner ? <Spinner /> : null}
            <FormGroup>
              <Label htmlFor="providerImage" className="form-label">
                Provider Image (.jpg or .png only)
                <span className="requiredStar">*</span>
              </Label>
              <Input
                name="providerImage"
                onChange={(e) => {
                  uploadFile(e, "providerImage");
                }}
                id="providerImage"
                accept=".png,.jpg"
                className="form-control"
                placeholder=""
                type="file"
                required={
                  props.step === 2 && !props.defaultImageData ? true : false
                }
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm="8" className="mb-4">
            {props.defaultImageData ? <h6>Uploaded</h6> : null}

            <FormGroup>
              <Label htmlFor="waterMark" className="form-label">
                Cover Page Logo (.svg or .png only)
                <span className="requiredStar">*</span>
              </Label>
              <Input
                name="waterMark"
                onChange={(e) => {
                  uploadFile(e, "waterMark");
                }}
                id="waterMark"
                accept=".svg, .png"
                className="form-control"
                placeholder=""
                type="file"
                required={
                  props.step === 2 && !props.defaultImageData ? true : false
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="8" className="mb-4">
            {props.defaultImageData ? <h6>Uploaded</h6> : null}

            <FormGroup>
              <Label htmlFor="coverPage" className="form-label">
                Background (.svg or .png only)
                <span className="requiredStar"></span>
              </Label>
              <Input
                name="coverPage"
                onChange={(e) => {
                  uploadFile(e, "coverPage");
                }}
                id="coverPage"
                accept=".svg, .png"
                className="form-control"
                placeholder=""
                type="file"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="8" className="mb-4">
            {props.defaultImageData ? <h6>Uploaded</h6> : null}

            <FormGroup>
              <Label htmlFor="signatureImage" className="form-label">
                Signature Image (.svg or .png only)
                <span className="requiredStar">*</span>
              </Label>
              <Input
                name="signatureImage"
                onChange={(e) => {
                  uploadFile(e, "signatureImage");
                }}
                id="signatureImage"
                accept=".svg, .png"
                className="form-control"
                placeholder=""
                type="file"
                required={
                  props.step === 2 && !props.defaultImageData ? true : false
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AttachImages;
