import React from "react";
import { Link } from "react-router-dom";
import Pulse from "react-reveal/Pulse";

import "./index.scss";
const LandingPage = (props) => {
  return (
    <div className="fitdatamax-landing-page">
      <Nav />
      <Header />
      <OurMission />
      <OurFeatures />
      <WhyUs />
      <Footer />
    </div>
  );
};

export default LandingPage;
//  parent body{}
const Loader = () => (
  <div id="preloader">
    <div class="jumper">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

// parent body
const Nav = () => (
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <a href="#" class="logo">
              <img
                src="/assets/images/landing_page/logo-1.png"
                alt="Softy Pinko"
                style={{
                  width: 200,
                  height: 120,
                  marginTop: -30,
                  marginLeft: -40,
                }}
              />
            </a>
            <ul class="nav">
              <li>
                <a href="#" class="active">
                  Home
                </a>
              </li>
              <li>
                <Link to="/register">Signup</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>{" "}
              </li>
            </ul>
            <a class="menu-trigger">
              <span>Menu</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </header>
);

// parent body

const Header = () => (
  <div
    class="welcome-area"
    id="welcome"
    style={{
      backgroundImage: "url(assets/images/landing_page/banner-bg-1.png)",
    }}
  >
    <div class="header-text">
      <div class="container">
        <div class="row">
          <div class="offset-xl-1 col-xl-10 offset-lg-2 col-lg-8 col-md-12 col-sm-12">
            <h1>
              {" "}
              <span>
                We empower serious fitness users with <br />
                <strong>data analytic</strong> tools to &nbsp;
                <strong>maximize efficiency</strong> improve &nbsp;
                <strong>health</strong> and exceed
                <strong>&nbsp;fitness goals</strong>
              </span>
            </h1>
            <p>
              Looking for a way to manage and optimize supplementation,
              training, and health data in one place
            </p>
            <Pulse delay={1000}>
              <div>
                <Link to="/register" class="main-button-slider">
                  Register now!
                </Link>
              </div>
            </Pulse>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Footer = () => (
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p class="copyright">
            Copyright &copy; 2021 Fitdatamax &nbsp;
            <br />
            <Link to="/privacy-policy" target="_blank">Privacy Policy</Link> &nbsp;-&nbsp;
            <Link to="/terms-of-service" target="_blank">Terms of Service</Link>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12"></div>
      </div>
    </div>
  </footer>
);

const WhyUs = () => (
  <section class="section" id="testimonials">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-heading">
            <h2 class="section-title">Why Fitdatamax?</h2>
          </div>
        </div>
        <div class="offset-lg-1 col-lg-10">
          <div class="center-text">
            <p>
              Fitness apps have played a key role in optimizing athletic
              performance in the digital age, but there is a huge problem: there
              are WAY to many of them and your data is scattered all over the
              place. It is impossible to make educated decisions that improve
              your performance when you have to memorize the layout of multiple
              apps, notebooks, calendars, etc. This leaves you with no way to
              visualize/analyze your data to see the bigger picture, pick out
              trends, and track the success of your health and fitness goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const OurFeatures = () => (
  <>
    <div className="overflow-hidden">
      <Pulse left delay={200}>
        <div>
          <section
            class="section padding-top-100 padding-bottom-0"
            id="features"
          >
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-4 col-md-12 col-sm-12 align-self-center"
                  data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
                >
                  <img
                    src="/assets/images/landing_page/ical.jpg"
                    class="bend-left img-fluid d-block"
                    alt="App"
                  />
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-5 col-md-12 col-sm-12 align-self-center mobile-top-fix">
                  <div class="left-heading">
                    <h2 class="section-title">Schedule Your Calendar</h2>
                  </div>
                  <div class="left-text">
                    <p>
                      Schedule Your Calendar In Minutes With The Leading Fitness
                      Scheduling Software and never miss a workout again.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Pulse>
    </div>
    <div className="overflow-hidden">
      <Pulse right delay={200}>
        <div>
          <section class="section padding-bottom-70">
            <div class="container">
              <div class="row justify-content-end">
                <div class="col-lg-5 col-md-12 col-sm-12 align-self-center mobile-bottom-fix">
                  <div class="left-heading">
                    <h2 class="section-title">Build schedule in minutes</h2>
                  </div>
                  <div class="left-text">
                    <p>
                      Schedule the right fitness event, at the right times,
                      across different roles or locations. Easily fill schedule
                      based on alert, activity and events.
                    </p>
                  </div>
                </div>
                <div class="col-lg-1"></div>
                <div
                  class="col-lg-4 col-md-12 col-sm-12 align-self-center mobile-bottom-fix-big"
                  data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
                >
                  <img
                    src="/assets/images/landing_page/schedule.jpg"
                    class="bend-right img-fluid d-block"
                    alt="App"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Pulse>
    </div>
    <div className="overflow-hidden">
      <Pulse left delay={200}>
        <section class="section padding-top-20 padding-bottom-0" id="features">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-4 col-md-12 col-sm-12 align-self-center"
                data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
              >
                <img
                  src="/assets/images/landing_page/integration.jpg"
                  class="img-fluid d-block bend-left"
                  alt="App"
                />
              </div>
              <div class="col-lg-1"></div>
              <div class="col-lg-5 col-md-12 col-sm-12 align-self-center mobile-top-fix">
                <div class="left-heading">
                  <h2 class="section-title">
                    Seamless Integration Fitness Apps
                  </h2>
                </div>
                <div class="left-text">
                  <p>
                    Sync your data with all major fitness apps and devices to
                    visualize and analyse your workout and fitness trends.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Pulse>
    </div>
    <div className="overflow-hidden">
      <Pulse right delay={200}>
        <section class="section padding-bottom-50">
          <div class="container">
            <div class="row  justify-content-end">
              <div class="col-lg-5 col-md-12 col-sm-12 align-self-center mobile-bottom-fix">
                <div class="left-heading">
                  <h2 class="section-title">
                    Share instantly with your friends
                  </h2>
                </div>
                <div class="left-text">
                  <p>
                    Send schedules straight to your friends via desktop or
                    mobile. And if you change the schedule, make sure staff see
                    the update by requesting a confirmation.
                  </p>
                </div>
              </div>
              <div class="col-lg-1"></div>
              <div
                class="col-lg-4 col-md-12 col-sm-12 align-self-center mobile-bottom-fix-big"
                data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
              >
                <img
                  src="/assets/images/landing_page/share.jpg"
                  class="bend-right img-fluid d-block"
                  alt="App"
                />
              </div>
            </div>
          </div>
        </section>
      </Pulse>
    </div>
  </>
);

const OurMission = () => (
      <section class="section home-feature">
  <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s"
            >
              <div class="features-small-item">
                <div class="icon">
                  <i>
                    <img src="/assets/images/landing_page/educate.png" alt="" />
                  </i>
                </div>
                <h5 class="features-title">Educate People</h5>
                <p>
                  Educate users on Health/Fitness/Tech subjects and dispel
                  “Bro-Science” with high quality 100% transparent and
                  maintained referenced materials.
                </p>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s"
            >
              <div class="features-small-item">
                <div class="icon">
                  <i>
                    <img src="/assets/images/landing_page/empower.png" alt="" />
                  </i>
                </div>
                <h5 class="features-title">Empower Athletes</h5>
                <p>
                  Enable users with a Data Analytics Platform and Tools that
                  allows them to achieve and maintain peak physical and mental
                  performance/health.
                </p>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s"
            >
              <div class="features-small-item">
                <div class="icon">
                  <i>
                    <img src="/assets/images/landing_page/promote.png" alt="" />
                  </i>
                </div>
                <h5 class="features-title">Promote Sportsmanship Spirit</h5>
                <p>
                  Promote good sportsmanship, ethics, and legal compliance by
                  providing up to date information on rules & regulations
                  pertaining to their sports discipline or personal goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
