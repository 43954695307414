import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Alert, Container, Col, Card, CardBody, Form, InputGroup, Label, Row, Button } from 'reactstrap';
import ReactApexCharts from 'react-apexcharts';

import MainForm from './Components/MainForm';
import { data } from 'jquery';

const HalfLifePlotter = () => {
  const [addAnotherCompound, setAddAnotherCompound] = useState([]);
  const [weeks, setWeeks] = useState('');
  const [dataReturned, setDataReturned] = useState({});
  const [dataRecords, setDataRecords] = useState([]);
  const [showGraph, setShowGraph] = useState(false);
  const [Series, setSeries] = useState([]);
  const [Options, setOptions] = useState({});
  const [count, setCount] = useState(0);
  const [showWeeks, setShowWeeks] = useState(true);
  const [showButton, setShowButton] = useState(false);

  const handleChange = (e) => {
    const Week = e.target.value;
    setWeeks(Week);
    setShowWeeks(false);
  };

  const passWeeks = () => {
    return weeks;
  };

  const handleCallback = (obj) => {
    setDataReturned(obj);
  };

  const addData = (obj) => {
    let isEmpty = true;
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== '') {
        isEmpty = false;
      } else {
        isEmpty = true;
      }
    }
    if (!isEmpty) {
      console.log('Not empty', obj);

      if (obj.id === dataRecords.length) {
        console.log('id and length are same', obj.id, dataRecords.length);
        setDataRecords((prevValues) => [...prevValues, obj]);
        setCount(count + 1);
      } else {
        let index = dataRecords.findIndex((data) => obj.id === data.id);
        console.log('Index is ', index);
        setDataRecords([...dataRecords.slice(0, index), (dataRecords[index] = obj), ...dataRecords.slice(index + 1)]);
      }
    } else {
      console.log('Object is Empty');
    }
    setDataReturned({});
    setShowButton(true);
  };

  const handleClick = () => {
    setAddAnotherCompound((prevValues, index) => {
      return [...prevValues, <MainForm Count={count} Week={passWeeks} CallBack={handleCallback} />];
    });
  };

  const Cal = () => {
    let tiempo;

    dataRecords.map((data, i) => {
      let schedule = data.Day;
      if (schedule === '0.5') {
        console.log(schedule);
        tiempo = 4;
      } else {
        console.log(schedule);
        tiempo = 2;
      }
    });

    let days = parseInt(weeks) * 7;
    let ejex = [];
    for (let i = 0; i < days; i++) {
      if (tiempo === 4) {
        ejex.push(i);
        ejex.push(i + 0.25);
        ejex.push(i + 0.5);
        ejex.push(i + 0.75);
      } else {
        ejex.push(i);
        ejex.push(i + 0.5);
      }
    }
    let series = [];
    let arrayCom = [];
    let cont = 0;

    var comData = [];
    for (let i = 0; i < ejex.length; i++) {
      comData.push(0);
    }

    dataRecords.map((data, i) => {
      // comData = []
      let dose = parseInt(data.Mg);
      let compound = data.Compound;
      let compoundform = data.Compound2;
      let schedule = data.Day;
      let from = data.StartingWeek;
      let to = data.EndingWeek;

      let half;
      let efect;
      if (compound === 'Testosterone') {
        if (compoundform === 'Suspension') {
          half = 0.5;
          efect = 1;
          console.log(half, efect);
        } else if (compoundform === 'Propionate') {
          half = 0.8;
          efect = 0.8;
        } else if (compoundform === 'Phenylpropionate') {
          half = 1.5;
          efect = 0.66;
        } else if (compoundform === 'Isocaproate') {
          half = 4.0;
          efect = 0.72;
        } else if (compoundform === 'Enanthate') {
          half = 4.5;
          efect = 0.7;
        } else if (compoundform === 'Cypionate') {
          half = 5.0;
          efect = 0.69;
        } else if (compoundform === 'Decanoate') {
          half = 7.5;
          efect = 0.62;
        } else if (compoundform === 'Undecanoate') {
          half = 20.9;
          efect = 0.61;
        }
      } else if (compound === 'Trenbolone') {
        if (compoundform === 'Suspension') {
          half = 0.5;
          efect = 1;
        } else if (compoundform === 'Enanthate') {
          half = 4.5;
          efect = 0.7;
        } else if (compoundform === 'Acetate') {
          half = 1;
          efect = 0.87;
        }
      } else if (compound === 'Masteron') {
        if (compoundform === 'Propionate (standard)') {
          half = 0.8;
          efect = 0.8;
        } else if (compoundform === 'Enanthate') {
          half = 4.5;
          efect = 0.7;
        }
      } else if (compound === 'Nandrolone (Decca/NPP)') {
        if (compoundform === 'Phenylpropionate (NPP)') {
          half = 1.5;
          efect = 0.67;
        } else if (compoundform === 'Decanoate (Deca Durabolin)') {
          half = 7.5;
          efect = 0.64;
        }
      } else if (compound === 'Equipoise') {
        half = 14;
        efect = 0.61;
      } else if (compound === 'Primobolan') {
        if (compoundform === 'Oral') {
          half = 0.2083;
          efect = 1;
        } else if (compoundform === 'Injectable') {
          half = 4.5;
          efect = 0.7;
        }
      } else if (compound === 'Halotestin') {
        half = 0.2916;
        efect = 1;
      } else if (compound === 'Anadrol') {
        half = 0.5833;
        efect = 1;
      } else if (compound === 'Dianabol') {
        half = 0.2083;
        efect = 1;
      } else if (compound === 'Turinabol') {
        half = 0.6666;
        efect = 1;
      } else if (compound === 'Winstrol') {
        if (compoundform === 'Oral') {
          half = 0.3333;
          efect = 1;
        } else if (compoundform === 'Injectable') {
          half = 1;
          efect = 0.87;
        }
      } else if (compound === 'Anavar') {
        half = 0.4166;
        efect = 1;
      } else if (compound === 'Superdrol') {
        half = 0.4166;
        efect = 1;
      } else if (compound === 'DNP') {
        if (compoundform === 'Crystal') {
          half = 1.5;
          efect = 0.75;
        } else if (compoundform === 'Powder') {
          half = 1.5;
          efect = 1;
        }
      } else if (compound === 'Aromasin') {
        half = 1;
        efect = 1;
      } else if (compound === 'Arimidex') {
        half = 1.95;
        efect = 1;
      }

      var inicio = parseInt(from) * 7 - 7;
      var diferenciaDias = parseInt(to) * 7 - inicio;

      if (arrayCom.indexOf(compound) !== -1) {
        switch (schedule) {
          case '0.5':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += 0.5 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }

            break;
          case '1':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '2':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '3':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '3.5':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseFloat(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '4':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '5':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '6':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '7':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '10':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '14':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '21':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '28':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '35':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '42':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '49':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '56':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case 'Mon':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case 'Tue':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 1 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case 'Wed':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 2 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case 'Thu':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 3 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case 'Fri':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 4 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case 'Sat':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 5 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case 'Sun':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 6 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          default: {
          }
        }
        // series.push({
        //   name: compound,
        //   data: comData,
        // });
        var comData2 = comData.slice();

        var j = 0;
        while (series[j]) {
          if (series[j].name == compound) {
            series[j].data = comData;
          }
          j++;
        }
        comData = comData2.slice();

        console.log('inside map() ', series);
      } else {
        //no encuentra, crea
        arrayCom.push(compound);

        comData = [];

        for (var i = 0; i < ejex.length; i++) {
          comData.push(0);
        }
        //( Exp(-(dia  ) * LN( 2 ) / half )* dose * weigh * LN( 2 )/ E$4,2)
        switch (schedule) {
          case '0.5':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += 0.5 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '1':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '2':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '3':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '3.5':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseFloat(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '4':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '5':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '6':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '7':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '10':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var temp;
                if (isNaN(comData[i + y + inicio * tiempo])) {
                  temp = 0;
                } else {
                  temp = comData[i + y + inicio * tiempo];
                }
                var dosisDiaSuma = dosisDia + temp;
                if (isNaN(dosisDiaSuma)) {
                } else {
                  dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                  comData[i + y + inicio * tiempo] = dosisDiaSuma;
                }
              }
            }
            break;
          case '14':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '21':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '28':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '35':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '42':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '49':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case '56':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += parseInt(schedule) * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case 'Mon':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 0; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case 'Tue':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 1 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case 'Wed':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 2 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case 'Thu':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 3 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case 'Fri':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 4 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case 'Sat':
            for (var i = 0; i < ejex.length - inicio * tiempo; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 5 * tiempo; y < diferenciaDias * tiempo; y += 7 * tiempo) {
                if (i + y + inicio * tiempo >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * tiempo];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * tiempo] = dosisDiaSuma;
              }
            }
            break;
          case 'Sun':
            for (var i = 0; i < ejex.length - inicio * 2; i++) {
              var dosisDia = (Math.exp((-ejex[i] * Math.log(2)) / half) * dose * efect * Math.log(2)) / half;
              dosisDia = +dosisDia.toFixed(2);
              for (var y = 6 * tiempo; y < diferenciaDias * 2; y += 7 * tiempo) {
                if (i + y + inicio * 2 >= ejex.length) {
                  break;
                }
                var dosisDiaSuma = dosisDia + comData[i + y + inicio * 2];
                dosisDiaSuma = +dosisDiaSuma.toFixed(2);
                comData[i + y + inicio * 2] = dosisDiaSuma;
              }
            }
            break;
          default:
        }
        series.push({
          name: compound,
          data: comData,
        });
      }
      setSeries(series);
    });

    setOptions({
      chart: {
        id: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: 'Release mg/day',
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: ejex.map(String),
        tickAmount: 20,
        tickPlacement: 'between',
        label: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
        },
      },
      yaxis: {
        title: {
          text: 'Miligrams',
        },
      },
    });
    console.log('This is ejex ', ejex);
    console.log('This is series', Series);
    console.log('data returned', dataReturned);
    console.log('data records', dataRecords);
  };

  useEffect(() => {
    Cal();
  }, [dataRecords]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <MetaTags>
          <title>Tools | Half-life Plotter</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <Alert color='danger' align='center'>
                WARNING: THIS IS NOT MEDICAL ADVICE!
                <br />
                THIS INFORMATION IS FOR EDUCATIONAL PURPOSES ONLY.
                <br />
                DO NOT USE MEDICATIONS/SUPPLEMENTS WITHOUT THE SUPERVISION AND DIRECTION OF A MEDICAL PROFESSIONAL.
              </Alert>
            </Col>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log('I got clicked');
                    addData(dataReturned);
                    setShowGraph(true);
                  }}
                >
                  <div>
                    <InputGroup className='select2-container mb-4' aria-required row>
                      <Label md='2' className='col-form-label'>
                        How many weeks would you like to graph?
                      </Label>
                      <Col md='10' xs='7'>
                        <select
                          required
                          id='selectNumberWeeks'
                          className='form-select  select2'
                          title='Weeks'
                          disabled={showWeeks ? false : true}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option selected disabled>
                            Select Weeks
                          </option>
                          <option value='1'>1 week</option>
                          <option value='2'>2 weeks </option>
                          <option value='3'>3 weeks</option>
                          <option value='4'>4 weeks</option>
                          <option value='5'>5 weeks</option>
                          <option value='6'>6 weeks</option>
                          <option value='7'>7 weeks</option>
                          <option value='8'>8 weeks</option>
                          <option value='9'>9 weeks</option>
                          <option value='10'>10 weeks</option>
                          <option value='11'>11 weeks</option>
                          <option value='12'>12 weeks</option>
                          <option value='13'>13 weeks</option>
                          <option value='14'>14 weeks</option>
                          <option value='15'>15 weeks</option>
                          <option value='16'>16 weeks</option>
                          <option value='17'>17 weeks</option>
                          <option value='18'>18 weeks</option>
                          <option value='19'>19 weeks</option>
                          <option value='20'>20 weeks</option>
                          <option value='21'>21 weeks</option>
                          <option value='22'>22 weeks</option>
                          <option value='23'>23 weeks</option>
                          <option value='24'>24 weeks</option>
                          <option value='25'>25 weeks</option>
                          <option value='26'>26 weeks</option>
                          <option value='27'>27 weeks</option>
                          <option value='28'>28 weeks</option>
                          <option value='29'>29 weeks</option>
                          <option value='30'>30 weeks</option>
                        </select>
                      </Col>
                    </InputGroup>
                  </div>

                  <Row>
                    <h6>What compounds are you taking?</h6>
                  </Row>
                  <MainForm Count={count} Week={passWeeks} CallBack={handleCallback} />

                  {addAnotherCompound}

                  {showGraph ? (
                    <div className='col-12' id='cycle-container'>
                      <Row>
                        <Col>
                          <ReactApexCharts options={Options} series={Series} type='area' height='350' />
                        </Col>
                      </Row>
                    </div>
                  ) : null}

                  <Row>
                    <Col>
                      <Row>
                        <Button type='submit' color='danger' className='mt-3'>
                          Plot
                        </Button>

                        <Button
                          color='light'
                          className='mt-3'
                          disabled={showButton ? false : true}
                          onClick={() => {
                            addData(dataReturned);
                            handleClick();
                            setShowButton(false);
                          }}
                        >
                          Add another compound
                        </Button>
                      </Row>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HalfLifePlotter;
