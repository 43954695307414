import React, { useEffect, useState } from 'react';

import { Alert, Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import Spinner from '../../../common/helper/spinner';
const AddOrganizationModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const toggle = () => {
    props.closeModal();
  };

  const submitButton = () => {
    var name = document.getElementById('orgName').value;
    var description = document.getElementById('orgDescription').value;
    var type = document.getElementById('orgType').value;
    var address = document.getElementById('orgAddress').value;
    console.log(name, description, type, address);
    setShowSpinner(true);
    axiosConfig
      .post(
        '/organization/add',
        {
          Name: name,
          Discription: description,
          Type: type,
          Address: address,
        },
        {
          headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
        }
      )
      .then(async (response) => {
        console.log('******response*******');
        console.log(response);
        console.log('******response*******');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        errorResponse(error);
        setMessage(error.response.data.message);
        setShowMessage(true);
        setShowSpinner(false);
      });
  };

  useEffect(() => {});

  return (
    <React.Fragment>
      {props.showModal ? (
        <Modal isOpen={props.showModal} className={props.className}>
          <ModalHeader>
            <div align='center'>
              <h3>Add an Organization</h3>
            </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              {showMessage && (
                <Alert align='center' color='danger'>
                  {message}
                </Alert>
              )}
              <div>
                <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                  Name
                </label>
                <div className='col-md-12'>
                  <input className='form-control mt-1 mb-2' type='text' defaultValue='' id='orgName' required />
                </div>
              </div>
              <div>
                <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                  Type
                </label>
                <div className='col-md-12'>
                  <input className='form-control mt-1 mb-2' type='text' defaultValue='' id='orgType' required />
                </div>
              </div>
              <div>
                <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                  Description
                </label>
                <div className='col-md-12'>
                  <input className='form-control mt-1 mb-2' type='text' defaultValue='' id='orgDescription' required />
                </div>
              </div>
              <div>
                <label htmlFor='example-number-input' className='col-md-5 col-form-label'>
                  Address
                </label>
                <div className='col-md-12'>
                  <input className='form-control mt-1 mb-2' type='text' defaultValue='' id='orgAddress' required />
                </div>
              </div>
            </Row>
            <Row>
              {showSpinner && <Spinner />}
              <div align='center'>
                <Col>
                  <button type='button' className='btn btn-success me-2' onClick={submitButton}>
                    Add
                  </button>
                  <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                    Close
                  </button>
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default AddOrganizationModal;
