import React, { useState } from 'react';
import { Modal, Row, Col } from 'reactstrap';

import { Link } from 'react-router-dom';

export default function DeleteModal(props) {
  const [complete, setComplete] = useState(false);

  function deleteIt(e) {
    e.preventDefault();
    setComplete(false);
    props.onClickYes();
    setComplete(true);
  }

  return (
    <Modal isOpen={props.showDeleteModal} size='md' centered={true} className='smallHeightmodal'>
      <>
        <button
          type='button'
          onClick={() => {
            props.closeModal();
          }}
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='modal-body flexJCAC flex-column text-center'>
          <h3>Do you really want to delete it?</h3>
          <Row className='mt-2 w-50'>
            <Col>
              <Link className='btn btn-danger px-4' style={{ width: 80 }} onClick={deleteIt}>
                Yes
              </Link>
            </Col>
            <Col>
              <Link
                className='btn btn-secondary px-4'
                style={{ width: 80 }}
                onClick={() => {
                  props.onClickNo ? props.onClickNo() : props.closeModal();
                }}
              >
                No
              </Link>
            </Col>
          </Row>
        </div>
      </>
    </Modal>
  );
}
