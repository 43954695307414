import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import Cookies from "universal-cookie";

import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";

import Spinner from "../../../common/helper/spinner";

import CronAction from "./CronAction";
import DeleteModal from "./DeleteModal";

const SavedReports = (props) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [searching, setSearching] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState("");

  const cronError = (error) => {
    errorResponse(error);
    setError(error.response.data.message);
  };

  const cronSuccess = (message) => {
    setSuccess(message);
  };

  const tableHeaders = [
    { text: "id", dataField: "id", sort: true, hidden: true },
    { text: "Lab Name", dataField: "name", sort: true },
    { text: "Organisation Name", dataField: "orgName", sort: true },
    { text: "Template Name", dataField: "templateName", sort: true },
    { text: "Created By", dataField: "createdBy", sort: true },
    { text: "Created On", dataField: "date", sort: true },
    { text: "Source", dataField: "dataSource", sort: true },
    {
      text: "Sample",
      dataField: "sampleReportUrl",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="ms-4">
              <label>-</label>
            </div>
          </>
        );
      },
    },

    {
      text: "Actions",
      dataField: "actions",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "" };
      },
      formatter: (cellContent, row) => {
        return (
          <>
              {row.dataSource === "API" && <CronAction row={row} cronError={cronError} cronSuccess={cronSuccess} />}
            <Link to={"/duplicate-report/" + row.id} className="cursor-pointer mx-3">
              <i className="fas fa-copy medText"></i>
            </Link>
            <Link to={"/edit-lab/" + row.id} className="cursor-pointer">
              <i className="fas fa-edit medText "></i>
            </Link>
            <span
              className="cursor-pointer mx-3"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedEntityId(row.id);
                setShowDeleteModal(true);
              }}
            >
              <i className="fas fa-trash" style={{ color: "red" }}></i>
            </span>
          </>
        );
      },
    },
  ];

  function onClickNo() {
    setShowDeleteModal(false);
  }
  function onClickYes() {
    axiosConfig
      .post(
        "/labBuilder/DeleteEntity",
        {
          entity_id: selectedEntityId,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        setShowDeleteModal(false);
        props.reloadAPINow();
      })
      .catch((error) => {
        errorResponse(error);
      });
  }

  const closeModal = () => {
    setShowModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  const pageOptions = {
    sizePerPage: 10,
    totalSize: data?.length,
    custom: true,
  };
  const defaultSorted = [{ dataField: "id", order: "asc" }];

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                {showSpinner ? <Spinner /> : null}
                {error ? (
                  <Alert color="danger" className="text-center">
                    {error}
                  </Alert>
                ) : null}
                {success ? (
                  <Alert color="success" className="text-center" isOpen={success} toggle={() => setSuccess("")}>
                    {success}
                  </Alert>
                ) : null}

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={tableHeaders}
                  data={props.sData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider keyField="id" columns={tableHeaders} data={props.sData} search>
                      {(toolkitProps) => (
                        <React.Fragment>
                          {!searching ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive" style={{ minHeight: "300px" }}>
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                  {props.sData.length === 0 ? (
                                    <p className="w-100 text-center m-5">No data found!</p>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Spinner />
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DeleteModal
          onClickYes={onClickYes}
          onClickNo={onClickNo}
          showDeleteModal={showDeleteModal}
          closeModal={closeModal}
        />
      </div>
    </React.Fragment>
  );
};

export default SavedReports;
